import * as types from './actionTypes';
import {store} from '../../App';

export function startFullLoadingSpinner(){
  return store.dispatch({type: types.START_FULL_LOADING_SPINNER})
}

export function endFullLoadingSpinner(){
  return store.dispatch({type: types.END_FULL_LOADING_SPINNER});
}
