import * as React from 'react';

import {SearchBar} from "./SearchBar";
import {SearchResults} from "./SearchResults";
import {DealerService} from "../../api/services/dealerService";
import {useEffect} from "react";
import {DealerMember, DealerMemberResponse} from "./dealerShareHelper";


export interface MemberTabState {
    search: string;
    results: DealerMember[];
    error: string;
    resultMsg: string;
}

enum SortFields {
    MEMBER_SINCE = 'memberSince',
    EMAIL = 'email',
    NAME = 'name'
}

interface MemberQuery {
    cb: number;
    sortField: SortFields,
    sortDirection: string,
    start: number,
    count: number;
    input: string
}

const initialQuery = {
    cb: new Date().getTime(),
    sortField: SortFields.MEMBER_SINCE,
    sortDirection: 'desc',
    start: 0,
    count: 10000,
    input: ''
}

const ERROR_NETWORK = "Unable to retrieve records. Please try again.";
const ERROR_EMPTY_SEARCH = "Please enter a search value."
const INITIAL_RESULT_MSG = "Please perform a search to see results";
const NO_RESULT_MSG = "There are no results for this search";

export const MemberTab: React.FC<{}> = () => {

    const initialState: MemberTabState = {
        search: '',
        results: [],
        error: '',
        resultMsg: INITIAL_RESULT_MSG
    }

    const [state, setState] = React.useState(initialState);

    const handleSearch = () => {
        loadMemberList({...initialQuery, input: state.search});
    }

    const loadMemberList = (query:MemberQuery) => {
        const fetchMemberList = async () => {
            const response:DealerMemberResponse = await DealerService.getDealerMembers(query);
            if (response) {
                if(response.totalResults === 0) {
                    setState({...state, results: response.member, resultMsg: NO_RESULT_MSG})
                } else {
                    setState({...state, results: response.member, error: '' })
                }
            }
        }
        fetchMemberList().catch((e) => {
            console.error('Error retrieving Dealership Members: ', e);
            setState({...state, resultMsg: ERROR_NETWORK })
        });
    }

    useEffect( () => {
        loadMemberList(initialQuery);
    }, []);

    return (
        <div>
            <SearchBar state={state} handleSearch={handleSearch} updateState={(state) => setState(state)} />
            <SearchResults items={state.results} message={state.resultMsg} />
        </div>
    )

}

