const MAX_FILE_NAME_CHAR_LENGTH = 40;

/**
 *
 * @param logo
 * @param termsAccepted
 * @param uniformDesign
 * @returns FormData {{file: *, artWorkLocation, uniformDesign: *, accepted: *}}
 */
export function getLogoUploadPayload(logo, termsAccepted, uniformDesign) {

  let formData = new FormData();
  formData.append("file", logo.file);
  formData.append("artWorkLocation", logo.name);
  formData.append("uniformDesign", uniformDesign);
  formData.append("accepted", termsAccepted);

  return formData;

}

/**
 * Encapsulates file object and filename in FormData object for persisting to API
 * @param file - Blob/File object to be persisted
 * @param fileName - Name the file will be stored in S3 bucket as
 * @param termsAccepted - User has accepted terms of agreement for image upload
 */
export function getDesignUploadPayload(canvas, fileName) {

  /*try {
    const canvasUri = canvas.toDataURL("image/png");
  } catch (e) {
    console.log('getDesignUpload -- toDataUrl (error): ', e);
  }*/

  const canvasUri = canvas.toDataURL("image/png");
  const canvasBlob = dataURItoBlob(canvasUri);

  //Helper that opens the image to be uploaded in a new tab, only on development environments.
  //Allows us to see what will be upload. Can turn this off if it gets annoying.
  /*if (process.env.NODE_ENV === 'development') {
    let fileURL = URL.createObjectURL(canvasBlob);
    window.open(fileURL);
  }*/

  let formData = new FormData();
  formData.append('file', canvasBlob);
  formData.append('fileName', fileName);
  formData.append('accepted', true);

  return formData;
}

/**
 * Convert base64/URLEncoded data component to raw binary data held in a string
 * @param dataURI - URI for canvas element converted to appropriate (image/png) type
 * @return Blob (for persisting to API as a file stream)
 */
export function dataURItoBlob(dataURI) {

  let byteString;

  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob( [ia], { type: mimeString } );

}

/**
 * Utility function for checking if logos have been selected for template
 * @param state
 * @returns {*|Array|boolean}
 */
export function hasLogos(state) {
  const hasLogos =
    state &&
    state.logoOptions &&
    state.logoOptions.length > 0;
  return hasLogos;
}

/**
 * Adds timestamp cache buster as url query param
 * @param url
 * @returns {*}
 */
export const decorateUrlWithCacheBuster = (url) => {
  if(isUrlPattern(url)) {
    if(url.includes('?')) {
      url += '&cb=' + Date.now();
    } else {
      url += '?cb=' + Date.now();
    }
  }
  return url;
};

/**
 * Tests if string matches a URL pattern
 * @param string
 * @returns {boolean}
 */
export const isUrlPattern = (string) => {
  const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  const regex = new RegExp(expression);
  return string.match(regex);
};

/**
 * Ensures file name is a proper name after sanitization
 * If name was all special characters, sanitization will rename file "-.jpg"
 * We want to put a more displayable/readable file name for viewing in their library
 * @param fileName
 * @returns {*}
 */
export const checkFileNameAfterSanitization = (fileName) => {
  const fileNameStr = fileName.substring(0, fileName.lastIndexOf("."));
  const extensionStr = fileName.substring(fileName.lastIndexOf("."), fileName.length);
  return (fileNameStr) ? fileName : `artwork${extensionStr}`;
};

/**
 * Ensures user submitted file names are sanitized (remove disallowed chars)
 * and limited to a manageable character length (40 chars)
 */
export const sanitizeAndTruncateFileName = (rawFileName) => {
  const fileName = rawFileName
    .substr(0, rawFileName.lastIndexOf('.')) // get file name w/o extension
    .replace(/([^a-zA-Z0-9 \-_.]+)/g, '') // replace illegal chars
    .substring(0, MAX_FILE_NAME_CHAR_LENGTH) // trim to max chars (40)
    .concat(rawFileName.substr(rawFileName.lastIndexOf('.'), rawFileName.length)); // combine new name with extension
  return checkFileNameAfterSanitization(fileName);
};

/**
 * Removes extension from file, returning just the filename
 * @param fileName
 */
export const removeExtensionFromFileName = (fileName) => {
  return fileName.substr(0, fileName.lastIndexOf('.'));
};
