// Provides content for the Main Landing Page (/landingPages/mainLandingPage.js)
export const mainLanding = {
    "seo": {
        "title": "Custom Team Uniforms | Nike Team | Softball",
        "description": "Nike TEAM Softball Landing Page"
    },
    "header": {
        "title": "WELCOME TO NIKE TEAM",
        "subtitle": "GET STARTED"
    },
    "instructionBox" : {
        "instruct1": {
            "step": "1",
            "title": "CHOOSE YOUR PRODUCT",
            "desc": "Shop the innovation in Nike TEAM"
        },
        "instruct2": {
            "step": "2",
            "title": "MAKE IT YOURS",
            "desc": "To your exact specifications"
        },
        "instruct3": {
            "step": "3",
            "title": "ADD TO CART",
            "desc": "Update player info and quantities"
        },
        "instruct4": {
            "step":"4",
            "title": "CHECK OUT",
            "desc": "Place your order and we'll deliver within three weeks or less"
        }
    },
    "stockUniformsBlock": {
        "header": "Stock Uniforms",
        "description": "Personalized with name, number, and custom artwork. Order now.",
        "image": {
            "src": "/landing/LP1_Stock.jpg",
            "altText": "Nike Team Softball - Stock Uniforms"
        },
        "cta": {
            "text": "Shop All",
            "route": "/en/en_US/nts/gridwall/any/any/625/?sort=rowNumber|desc&selected=hc_UN_Tops+hc_UN_Bottoms+uniformProgram_Custom_Stock+uniformProgram_Stock"
        }
    },
    "btoUniformsBlock": {
        "header": "Build to Order Uniforms",
        "description": "Highly customized and delivered in 60-90 days. Contact a dealer.",
        "image": {
            "src": "/landing/LP2_Custom.jpg",
            "altText": "Nike Team Softball - Build To Order Uniforms"
        },
        "cta": {
            "text": "Shop All",
            "route": "/en/en_US/nts/gridwall/any/any/314/?sort=rowNumber|desc&selected=hc_UN_Tops+hc_UN_Bottoms+uniformProgram_Digital+uniformProgram_DQT+uniformProgram_Custom"
        }
    },
    "graphicTeesBlock": {
        "header": "Graphic Tees & Fleece",
        "description": "Personalized with Nike graphics. Order now.",
        "image": {
            "src": "/landing/LP3_Graphic-Tee-Fleece.jpg",

            "altText": "Nike Team Softball - Graphic Tees & Fleece"
        },
        "cta": {
            "text": "Shop All",
            "route": "/en/en_US/nts/gridwall/any/any/746/?sort=rowNumber|desc&selected=hc_GT_Short_Sleeve+hc_GT_Long_Sleeve+hc_GT_Hoodies_and_Pullovers"
        }
    },
    "coachBlock": {
        "header": "Travel, Train, Coach",
        "description": "Fundamentals and equipment. Order now.",
        "image": {
            "src": "/landing/LP4_Authentics.jpg",
            "altText": "Nike Team Softball - Travel, Train, Coach"
        },
        "cta": {
            "text": "Shop All",
            "route": "/en/en_US/nts/gridwall/any/any/250/?sort=rowNumber|desc&selected=hc_Tops+hc_Bottoms+hc_Gear"
        }
    },
    "moreOptions": {
        "catalog": {
            "imageSrc": "/landing/Download_Catalog.jpg",
            "headerText": "Download a catalog",
            "callToAction": "download",
            "callToActionRoute": "/en/en_US/nts/catalogs"
        },
        "customizeHeader": "Customize more",
        "contactDealerHeader": "Contact a team dealer"
    }
}
