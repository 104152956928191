import * as React from "react";

import {Button, Modal} from "react-bootstrap";
import {AddFontForm} from "./AddFontForm";

import {getDealershipId} from "../../../helpers/userProfileHelpers";
import {EliteOptionsService} from "../../../api/services/eliteOptionsService";
import {
    checkAddFontsFormForErrors,
    defaultAddFontFormErrors,
    ERROR_ADD_FONT_GENERIC,
    ERROR_ADD_FONT_NETWORK,
    ERROR_NO_DEALERSHIP,
    getAddFontPayload
} from "../customFontsHelper";

export interface IFontForm {
    active: boolean;
    name: string;
    school: string;
    pngFile: File | null;
    factoryFile: File | null;
}

const defaultForm: IFontForm = {
    active: true,
    name: '',
    school: '',
    pngFile: null,
    factoryFile: null,
}

interface Props {
    onHide: () => void;
    onAdd: () => void;
}

export const AddCustomFontModal: React.FC<Props> = ({
    onHide,
    onAdd,
}) => {

    const [fontForm, setFontForm] = React.useState(defaultForm);
    const [formErrors, setFormErrors] = React.useState(defaultAddFontFormErrors);

    const onSubmit = () => {
        const formErrors = checkAddFontsFormForErrors(fontForm);
        if(formErrors.numErrors > 0) {
            setFormErrors({...formErrors});
        } else {
            const addFontCall = async () => {
                const dealershipId = getDealershipId();
                if(!dealershipId) {
                    setFormErrors({...formErrors, displayMsg: ERROR_NO_DEALERSHIP});
                    return;
                }
                const payload = getAddFontPayload(fontForm, dealershipId);
                const response = await EliteOptionsService.addCustomFont(payload);
                if(response && response.code === "SUCCESS" && response.data) {
                    setFormErrors({...formErrors, displayMsg: ''});
                    onAdd();
                } else {
                    const error = (response.error && response.message) ? response.message : ERROR_ADD_FONT_GENERIC
                    setFormErrors({...formErrors, displayMsg: error});
                }
            }
            addFontCall().catch((e) => {
                const error = (e.error && e.message) ? e.message : ERROR_ADD_FONT_NETWORK;
                setFormErrors({...formErrors, displayMsg: error})
            });
        }
    }

    return (
        <Modal className="show nts-custom-font-upload-modal" show={true} onHide={onHide} bsSize="large" aria-labelledby="contained-modal-title-lg">

            <Modal.Header closeButton>Add Custom Font</Modal.Header>

            <Modal.Body>
                <AddFontForm fontForm={fontForm} setFontForm={setFontForm} errors={formErrors}/>
                {formErrors.displayMsg && <p className="error" data-nts-id="customFont.addFont.text.error">{formErrors.displayMsg}</p>}
            </Modal.Body>

            <Modal.Footer>
                <Button bsStyle="default" onClick={onHide} data-nts-id="customFont.addFont.button.cancel">Cancel</Button>
                <Button bsStyle="primary" onClick={onSubmit} data-nts-id="customFont.addFont.button.submit">Submit</Button>
            </Modal.Footer>

        </Modal>
    );
};