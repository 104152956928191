import {
  getAuthorities,
  isArtworkRolesOnly
} from "./userProfileHelpers";
import {isRetailExperience} from "./retailHelpers";

const openOrderStatuses = ['OPEN', 'OPENQUOTE', 'SOFTALLOCATED'];
const wholesaleAuths = ['ROLE_CUSTOMER_SERVICE', 'ROLE_DEALER', 'ROLE_USER_ADMIN'];

/**
 * Provides XML formatted request for a stock product to be added to the user's cart
 * @param styleNumber
 * @param colorNumber
 * @param customStockId
 * @param api
 * @param isDealer
 * @param orders
 * @returns {string}
 */
export function getCartRequestXML(styleNumber, colorNumber, customStockId, api = '', isDealer, orders) {
  
  const requestStrBegin = `<request>`;
  
  let ordersXml = '';
  if (isDealer && orders) {
    ordersXml = getOrderRequestXML(orders);
  }
  
  const productXML = getProductRequestXML(styleNumber, colorNumber, customStockId, api);
  const requestStrEnd = `</request>`;
  
  return requestStrBegin + ordersXml + productXML + requestStrEnd;
  
}

/**
 * Formats <orders></orders> XML string
 * @param orders [{order: {id:"", name:"", formattedTotalPrice:"", hoursLeft:0 ... }]
 * @returns {string}
 */
function getOrderRequestXML(orders) {
  
  let xmlStr = '';
  
  if (orders && orders.length > 0) {
    xmlStr += '<orders>';
    orders.map(o => {
      return xmlStr += `<id>${o.id}</id>`;
    });
    xmlStr += '</orders>';
  } else {
    xmlStr += '<orders />';
  }
  
  xmlStr += '<isPilot>false</isPilot>';
  
  return xmlStr;
  
}

/**
 * Formats <productGroup> XML string
 * @param styleNumber
 * @param colorNumber
 * @param customId
 * @param api
 * @param name
 * @returns {string}
 */
function getProductRequestXML(styleNumber, colorNumber, customId, api = '', name = '') {
  
  let xmlStr = '';
  
  if (styleNumber && colorNumber) {
    const styleColorNumber = styleNumber + "-" + colorNumber;
    xmlStr += '<productGroup>';
    (customId) ? xmlStr += `<uid>${customId}</uid>` : xmlStr += '';
    xmlStr += `<product id="${styleColorNumber}">`;
    xmlStr += `<styleNumber>${styleNumber}</styleNumber>`;
    xmlStr += `<colorNumber>${colorNumber}</colorNumber>`;
    xmlStr += `<name>${name}</name>`;
    xmlStr += '</product>';
    xmlStr += (api) ? `<api>${api}</api>` : '';
    xmlStr += '</productGroup>';
  } else if(customId) {
    xmlStr += '<productGroup>';
    xmlStr += `<name>${name}</name>`;
    xmlStr += `<uid>${customId}</uid>`;
    xmlStr += '<layer>Uniform</layer>';
    xmlStr += '</productGroup>';
  } else {
    xmlStr += '<productGroup />';
  }
  
  return xmlStr;
  
}

/**
 * Provides converter for JS date to string dd/mm/yyyy format
 * @param date
 * @returns {string}
 */
export function getFormattedDate(date) {
  
  let dd = date.getDate();
  let mm = date.getMonth() + 1; //January is 0!
  let yyyy = date.getFullYear();
  
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  
  return mm + '-' + dd + '-' + yyyy;
  
}

/**
 * Models payload for StockOrder object in StockOrder Services
 * @param id - Managed by back end
 * @param stockOrderId - Managed by back end
 * @param cartIdSap - Managed by back end
 * @param poNumber - Managed by back end
 * @param cartStatus - Managed by back end
 * @param productList - List of Products to add to cart
 * @param shippingData - Shipping info from checkout page
 * @returns {{id: (*|null), stockOrderId: (*|null), cartIdSap: (*|null), poNumber: (*|null), cartStatus: (*|null), stockProductList: (*|Array), shippingData: (*|{})}}
 */
export function getStockOrderPayload(id, stockOrderId, cartIdSap, poNumber, cartStatus, productList, shippingData) {
  return {
    id: id || null,
    stockOrderId: stockOrderId || null,
    sapCartId: cartIdSap || null,
    poNumber: poNumber || null,
    cartStatus: cartStatus || "SAVED",
    stockProductList: productList || [],
    shippingData: shippingData || {}
  };
}

/**
 * Models payload for StockOrder.Product object in StockOrder Services
 * @param productId - UUID managed by UI
 * @param styleColor - Product styleColor (xxxxx-xxx)
 * @param name - Product name
 * @param wholeSale - Wholesale price of product
 * @param retail - Retail price of product
 * @param embellishmentList - List<String, Number> of embellishments added to custom designs
 * @param crds - ?
 * @param graphicDesignID - InspirationID of customized stock product
 * @param poNumber - Managed by back end
 * @param distributionCenters - Managed by back end
 * @param orderNumber - Managed by back end
 * @param confirmedTime - Managed by back end
 * @returns {{productId: (*|null), styleColor: (*|string), name: (*|string), wholeSale: (*|number), retail: (*|number), crds: (*|Array), graphicDesignID: (*|string), poNumber: (*|string), distributionCenters: (*|Array), orderNumber: (*|string), confirmedTime: (*|string)}}
 */
export function getStockOrderProductPayload(productId, styleColor, name, wholeSale, retail, embellishmentList, crds, graphicDesignID, poNumber, distributionCenters, orderNumber, confirmedTime, isFillin) {
  return {
    productId: productId || null,
    styleColor: styleColor || '',
    name: name || '',
    pricingDetails: getProductPricingDetails(wholeSale, retail, embellishmentList),
    crds: crds || [],
    reOrder: isFillin,
    graphicDesignID: graphicDesignID || '',
    poNumber: poNumber || '',
    distributionCenters: distributionCenters || [],
    orderNumber: orderNumber || '',
    confirmedTime: confirmedTime || ''
  };
}

/**
 * Populates pricingDetails object with prices/totals in cent form (converted from dollars)
 * @param wholeSale
 * @param retail:
 * @param embellishmentList
 * @returns {{}}
 */
function getProductPricingDetails(wholeSale, retail, embellishmentList) {
  
  let embellishmentTotal = 0;
  let details = {};
  details.embellishmentDetails = {};
  
  embellishmentList && embellishmentList.length > 0 && embellishmentList.map((item) => {
    const price = (+item.price * 100);
    details.embellishmentDetails['"' + item.vasName + '"'] = price;
    embellishmentTotal += +price;
    return item;
  });
  
  details.wholeSale = wholeSale || 0.00;
  details.retail = retail || 0.00;
  
  details.retailFullEmbellishmentPrice = retail + embellishmentTotal;
  details.wholeSaleFullEmbellishmentPrice = wholeSale + embellishmentTotal;
  
  return details;
  
}

/**
 * Determines if order is in an 'OPEN' status (not submitted, eligible for updates)
 * @param orderStatus
 */
export function isOpenOrderStatus(orderStatus) {
  return openOrderStatuses.includes(orderStatus.replace(/\s/g,'').toUpperCase());
}

/**
 * Determines if given user/state should display wholesale or retail pricing
 */
export function isWholesalePricing() {
  if(isRetailExperience()) {
    return false;
  } else {
    const userAuths = getAuthorities();
    const hasWholesaleAuthority = wholesaleAuths.some(auth => userAuths.indexOf(auth) >= 0);
    return hasWholesaleAuthority && !isArtworkRolesOnly();
  }
}

/**
 * Parses confirmation page URL to determine order type
 * URL Example: https://niketeam.com/en/en_US/nts/confirmation/{type}/12345
 * @param url
 */
export function getOrderConfirmationTypeFromUrl(url) {
  if(url && url.split('/')) {
    const split = url.split('/');
    return (split[split.length - 2]) ? split[split.length - 2] : 'order';
  }
  return 'order';
}

/**
 * Obtains cart name (from window or api call) by cartId
 * @param cartId
 * @returns {Promise<any>}
 */
export function getCartNameById(cartId) {
  return new Promise((resolve, reject) => {
    if(window && window.TeamSportsPageData && window.TeamSportsPageData.orderDetail && window.TeamSportsPageData.orderDetail.name) {
      resolve(window.TeamSportsPageData.orderDetail.name);
    } else {
      resolve('no name')
    }
  });
}