import {WebStorageStateStore} from "oidc-client-ts";

import {urlNtsEnvironment} from "../../js/api/services";
import {getEnvironment} from "../../js/helpers/browserHelpers";
import {
    ENVIRONMENT_LOCAL,
    ENVIRONMENT_PROD,
    ENVIRONMENT_STG, ENVIRONMENT_UAT,
    TEAM_HOME_URL_PATH
} from "../../js/helpers/constants";
import {
    ACCOUNTS_CONFIG_PREPROD,
    ACCOUNTS_CONFIG_PROD,
    LS_PRE_LOGIN_URL_KEY,
    OidcAccountsConfig, OidcMetadataConfig, OidcUserManagerConfig
} from "./constants";

const env = getEnvironment();

interface AuthProviderConfig {
    identityConfig: OidcUserManagerConfig;
    userStore: WebStorageStateStore;
    metadataConfig: OidcMetadataConfig;
}

// Obtain OIDC settings for setting up the UserManager object
export const getAuthProviderConfig = ():AuthProviderConfig => {
    const oidcConfig = ([ENVIRONMENT_PROD, ENVIRONMENT_UAT, ENVIRONMENT_STG].includes(env)) ? ACCOUNTS_CONFIG_PROD : ACCOUNTS_CONFIG_PREPROD;
    return {
        identityConfig: getIdentityConfig(oidcConfig),
        userStore: getUserStore(),
        metadataConfig: getMetadataConfig(oidcConfig)
    }
}

// Provides OIDC Identity/UserManager config object
const getIdentityConfig = (oidcConfig:OidcAccountsConfig):OidcUserManagerConfig => {
    // For deployed environments, use web-legacy-ui /p/pdp URL for redirect URIs
    const baseUrl = (ENVIRONMENT_LOCAL.includes(env)) ? 'http://localhost:3000': `${urlNtsEnvironment}/en/en_US/p/pdp`;
    return {
        authority: oidcConfig.url,
        client_id: oidcConfig.clientId,
        redirect_uri: `${baseUrl}/oidc/login`,
        response_type: 'code',
        scope: 'openid offline_access',
        silent_redirect_uri: `${baseUrl}/oidc/login`,
        post_logout_redirect_uri: `${baseUrl}/oidc/logout`,
        automaticSilentRenew: true
    }
}

// Provides OIDC Metadata config object
const getMetadataConfig = (oidcConfig:OidcAccountsConfig):OidcMetadataConfig => {
    return {
        issuer: oidcConfig.url,
        jwks_uri: `${oidcConfig.url}/discovery/keys`,
        authorization_endpoint: `${oidcConfig.url}/authorize/v1`,
        token_endpoint: `${oidcConfig.url}/token/v1`,
        userinfo_endpoint: `${oidcConfig.url}/user_info/v1`,
        end_session_endpoint: `${oidcConfig.url}/rp_logout/v1`,
        check_session_iframe: `${oidcConfig.url}/checksessioniframe`,
        revocation_endpoint: `${oidcConfig.url}/revocation/v1`,
        introspection_endpoint: `${oidcConfig.url}/introspect/v1`
    }
}

// Local storage store for OIDC user object
const getUserStore = ():WebStorageStateStore => {
    return new WebStorageStateStore({ store: window.localStorage })
}

// Track URL to redirect user after login
export const setPreloginUrl = (url:string):void => {
    localStorage.setItem(LS_PRE_LOGIN_URL_KEY, url);
}

// Retrieve URL to redirect user after login
export const getPreloginUrl = ():string => {
    const url = localStorage.getItem(LS_PRE_LOGIN_URL_KEY);
    return (url) ? url : TEAM_HOME_URL_PATH;
}
