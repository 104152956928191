import * as React from "react";

import {CustomFont} from "../../reducers/customFontsReducer";
import {CustomFontsTile} from "./CustomFontsTile";

interface Props {
    fonts: CustomFont[],
    error: string;
    onFontSelect: (font:CustomFont) => void;
}

export const CustomFontsGrid: React.FC<Props> = ({
    fonts,
    error,
    onFontSelect
}) => {
    return (
        <div className="custom-fonts-grid">
            {fonts.length > 0 && fonts.map((font, i) =>
                <div onClick={() => onFontSelect(font)} key={i}>
                    <CustomFontsTile key={i} index={i+1} font={font} />
                </div>
            )}
            {error && <div className="page-error">{error}</div>}
        </div>
    )
};