import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

interface Logo {
  id: string;
  file: string;
  fileName: string;
  fileType: string;
  url: string;
  name: string;
}

export interface ArtworkState {
  artworkTermsState: {
    showArtworkTermsModal: boolean;
  };
  artworkErrorState: {
    showArtworkErrorModal: boolean;
    fileName: string;
    message: string;
  };
  logos: {
    [id: string]: Logo;
  };
  imageColorSelector: {
    showModal: boolean;
    fileName: string | null;
    logoId: string | null;
    logoUrl: string | null;
    template: string | null;
    file: string | null;
  };
  library: {
    showArtworkDetailsModal: boolean;
    selectedArtworkDetails: unknown;
  };
}

const initialState: ArtworkState = {
  artworkTermsState: {
    showArtworkTermsModal: false,
  },
  artworkErrorState: {
    showArtworkErrorModal: false,
    fileName: '',
    message: ''
  },
  logos: {},
  imageColorSelector: {
    showModal: false,
    fileName: null,
    logoId: null,
    logoUrl: null,
    template: null,
    file: null
  },
  library: {
    showArtworkDetailsModal: false,
    selectedArtworkDetails: {}
  }
};

// TODO: These actions can be separate types later
interface ArtworkAction extends Action {
  state: {
    showArtworkErrorModal: boolean;
    showModal: boolean;
    fileName: string;
    logoId: string;
    logoUrl: string;
    template: string;
    file: string;
    show: boolean;
    message: string;
  };
  callback: unknown;
  show: boolean;
  details: unknown;
  logo: Logo;
}

const artworkReducer: Reducer<ArtworkState> = (
    state = initialState,
    incomingAction
) => {
  const action = incomingAction as unknown as ArtworkAction;
  switch (action.type) {

    case types.SET_ARTWORK_TERMS_MODAL_STATE:
      return {
        ...state,
        artworkTermsState: {
          showArtworkTermsModal: action.show,
          callback: action.callback
        }
      };

    case types.SET_ARTWORK_ERROR_MODAL_STATE:
      return {
        ...state,
        artworkErrorState: {
          showArtworkErrorModal: action.state.show,
          fileName: action.state.fileName,
          message: action.state.message
        }
      };

    case types.SET_ARTWORK_ERROR_MODAL_HIDDEN_STATE: {
      return {
        ...state,
        artworkErrorState: action.state
      };
    }

    case types.LOAD_CUSTOM_LOGO:
      return {
        ...state,
        logos: {
          ...state.logos,
          [action.logo.id]: action.logo
        }

      };

    case types.SET_IMG_COLOR_SELECTOR_MODAL_DISPLAY_STATE:
      return {
        ...state,
        imageColorSelector: {
          showModal: action.state.showModal,
          fileName: action.state.fileName,
          logoId: action.state.logoId,
          logoUrl: action.state.logoUrl,
          template: action.state.template,
          file: action.state.file
        }
      };

    case types.SET_ARTWORK_DETAILS_MODAL_STATE:
      return {
        ...state,
        library: {
          showArtworkDetailsModal: action.show,
          selectedArtworkDetails: action.details
        }
      };

    default:
      return state;
  }
}

export default artworkReducer;