import {LandingPageConfig} from "../sportVariance/config";

/**
 * Controls header (banner image & text) settings for each sport/gender combo
 */
export const promoPageConfig: LandingPageConfig = {
    mens: {
        baseball: {
            display: {
                title: "Men's Promo Baseball",
                gender: "Men's",
                sport: "Baseball",
            },
            banner: {
                style: "image-only",
                imgSrc: "/sport_lp/desktop_mens_baseball.png",
                imgSrcMobile: "/sport_lp/mobile_mens_baseball.png",
                imgDesc: "Mens Baseball Banner",
                copyTitle: "Geared For Greatness",
                copyDesc: "",
                copyPos: "top",
                copyPosMobile: "bottom",
                copyTheme: "dark",
                copyThemeMobile: "light"
            },
            nav: [],
            clearAll: {
                display: "Clear All",
                url: ``,
            },
        },
        basketball: {
            display: {
                title: "Men's Promo Basketball",
                gender: "Men's",
                sport: "Basketball",
            },
            banner: {
                style: "image-only",
                imgSrc: "/sport_lp/desktop_mens_basketball.png",
                imgSrcMobile: "/sport_lp/mobile_mens_basketball.png",
                imgDesc: "Mens Basketball Banner",
                copyTitle: "Next Generation of Hoops",
                copyDesc: "",
                copyPos: "top",
                copyPosMobile: "bottom",
                copyTheme: "dark",
                copyThemeMobile: "light"
            },
            nav: [],
            clearAll: {
                display: "Clear All",
                url: ``,
            },
        },
        football: {
            display: {
                title: "Men's Promo Football",
                gender: "Men's",
                sport: "Football",
            },
            banner: {
                style: "image-video",
                imgSrc: "/sport_lp/mobile_mens_football.png",
                imgSrcMobile: "/sport_lp/mobile_mens_football.png",
                imgDesc: "Mens Football Banner",
                copyTitle: "Made For The Field",
                copyDesc: "",
                copyPos: "bottom",
                copyPosMobile: "bottom",
                copyTheme: "light",
                copyThemeMobile: "light",
                videoSrc: "/sport_lp/Nike_StrongerThanOne_JoshJacobs_LongForm_1080p_16x9_IGTV_Captioned_English_Delivery_201123.mp4",
                videoType: "video/mp4",
                videoImgSrc: ""
            },
            nav: [],
            clearAll: {
                display: "Clear All",
                url: ``,
            },
        },
    },
    womens: {
        basketball: {
            display: {
                title: "Women's Promo Basketball",
                gender: "Women's",
                sport: "Basketball",
            },
            banner: {
                style: "image-only",
                imgSrc: "/logos/niketeam_jocktag_full_banner.png",
                imgSrcMobile: "/logos/niketeam_jocktag_full_banner.png",
                imgDesc: "Womens Basketball Banner",
                copyTitle: "",
                copyDesc: "",
                copyPos: "top",
                copyPosMobile: "bottom",
                copyTheme: "dark",
                copyThemeMobile: "light"
            },
            nav: [],
            clearAll: {
                display: "Clear All",
                url: ``,
            },
        },
        softball: {
            display: {
                title: "Women's Promo Softball",
                gender: "Women's",
                sport: "Softball",
            },
            banner: {
                style: "image-only",
                imgSrc: "/sport_lp/desktop_womens_softball.png",
                imgSrcMobile: "/sport_lp/mobile_womens_softball.png",
                imgDesc: "Womens Softball Banner",
                copyTitle: "Geared For Greatness",
                copyDesc: "",
                copyPos: "top",
                copyPosMobile: "bottom",
                copyTheme: "light",
                copyThemeMobile: "light"
            },
            nav: [],
            clearAll: {
                display: "Clear All",
                url: ``,
            },
        },
    },
};