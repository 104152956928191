import {combineReducers} from 'redux';
import {connectRouter, RouterState} from 'connected-react-router';
import {History} from 'history';

import gridwall, {GridwallState} from './gridwallReducer';
import styleTemplates, {StyleTemplatesState} from './styleTemplatesReducer';
import productDetails, {ProductDetailsState} from './productDetailsReducer';
import productAvailability, {ProductAvailabilityState} from './productAvailabilityReducer';
import selectedStyle, {SelectedStyleState} from './selectedStyleReducer';
import uniformDesign, {UniformDesignState} from './uniformDesignReducer';
import builderState, {BuilderState} from './builderStateReducer';
import pdpState, {PdpState} from './pdpStateReducer';
import fontRules, {FontRulesState} from './fontRulesReducer';
import dealerArtwork, {DealerArtworkState} from './dealerArtworkReducer';
import checkoutState, {CheckoutState} from './checkoutReducer';
import messageState, {MessageState} from './messageModalReducer';
import toastState, {ToastState} from './toastReducer';
import loadingSpinnerState, {LoadingSpinnerState} from './loadingSpinnerReducer';
import loadingSmallSpinnerReducer, {LoadingSmallSpinnerState} from './loadingSmallSpinnerReducer';
import ordersState, {OrdersState} from './ordersReducer';
import productImages, {ProductImagesState} from './productImagesReducer';
import userState, {UserProfileState} from './userProfileReducer';
import colorsState, {ColorsState} from './colorsReducer';
import authState, {AuthState} from './authStateReducer';
import navigationState, {NavigationState} from './navigationReducer';
import cartSelectorState, {CartSelectorState} from './cartSelectorReducer';
import couponState, {CouponState} from './couponStateReducer';
import artworkState, {ArtworkState} from './artworkStateReducer';
import covidState, {CovidState} from './covidStateReducer';
import lockerState, {LockerState} from './lockerReducer';
import customFontsState, {CustomFontsState} from "./customFontsReducer";
import customColorsState, {CustomColorsState} from "./customColorsReducer";
import orderDeadlinesState, {OrderDeadlinesState} from "./orderDeadlinesReducer";

export interface AppState {
  artworkState: ArtworkState;
  authState: AuthState;
  builderState: BuilderState;
  cartSelectorState: CartSelectorState;
  checkoutState: CheckoutState;
  colorsState: ColorsState;
  couponState: CouponState;
  covidState: CovidState;
  customColorsState: CustomColorsState;
  customFontsState: CustomFontsState;
  dealerArtwork: DealerArtworkState;
  fontRules: FontRulesState;
  gridwall: GridwallState;
  loadingSmallSpinnerReducer: LoadingSmallSpinnerState;
  loadingSpinnerState: LoadingSpinnerState;
  lockerState: LockerState;
  messageState: MessageState;
  navigationState: NavigationState;
  orderDeadlinesState: OrderDeadlinesState;
  ordersState: OrdersState;
  pdpState: PdpState;
  productAvailability: ProductAvailabilityState;
  productDetails: ProductDetailsState;
  productImages: ProductImagesState;
  router: RouterState;
  selectedStyle: SelectedStyleState;
  styleTemplates: StyleTemplatesState;
  toastState: ToastState;
  uniformDesign: UniformDesignState;
  userState: UserProfileState;
}

const createRootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  gridwall,
  styleTemplates,
  productDetails,
  selectedStyle,
  uniformDesign,
  builderState,
  checkoutState,
  pdpState,
  fontRules,
  dealerArtwork,
  messageState,
  ordersState,
  loadingSpinnerState,
  loadingSmallSpinnerReducer,
  lockerState,
  productImages,
  userState,
  toastState,
  colorsState,
  authState,
  productAvailability,
  navigationState,
  cartSelectorState,
  couponState,
  artworkState,
  covidState,
  customFontsState,
  customColorsState,
  orderDeadlinesState
});

export default createRootReducer;
