import * as React from "react";
import {useEffect} from "react";
import {Dispatch} from "redux";
import {connect} from "react-redux";

// services & helpers
import {EliteOptionsService} from "../../api/services/eliteOptionsService";
import {getDealershipId} from "../../helpers/userProfileHelpers";
import {
    DEFAULT_GET_FONTS_PAYLOAD, EliteOptionsStatusType,
    ERROR_NETWORK_CALL, ERROR_NO_DEALERSHIP,
    ERROR_NO_RESULTS,
} from "./customFontsHelper";
import {
    setCustomFontList, setSelectedCustomFont,
    showAddFontModal,
    showFontDetailsModal,
    showInactiveCustomFonts
} from "../../actions/customFontsActions";

// types
import {AppState} from "../../reducers";
import {CustomFont} from "../../reducers/customFontsReducer";

// components
import {Col, Row} from "react-bootstrap";
import {CustomFontsActionRow} from "./CustomFontsActionRow";
import {CustomFontsGrid} from "./CustomFontsGrid";
import {AddCustomFontModal} from "./addFont/AddCustomFontModal";
import {FontDetailsModal} from "./fontDetails/fontDetailsModal";

export const CUSTOM_FONTS_PAGE_ROLES = ['ROLE_DEALER_ELITE','ROLE_USER_ADMIN','ROLE_CUSTOMER_SERVICE'];

interface StateProps {
    customFontState: AppState['customFontsState'],
    customFontList: CustomFont[],
    showAddFontModal: boolean,
    showFontDetailsModal: boolean,
    showInactiveFonts: boolean,
    selectedFont: CustomFont | null,
}

interface DispatchProps {
    dispatchShowAddFontModal: (show:boolean) => void
    dispatchShowFontDetailsModal: (show:boolean) => void,
    dispatchSetCustomFontList: (list:CustomFont[]) => void,
    dispatchShowInactiveCustomFonts: (show:boolean) => void,
    dispatchSetSelectedCustomFont: (font: CustomFont | null) => void,
}

type Props = StateProps & DispatchProps;

const CustomFontsClass: React.FC<Props> = ({
    dispatchShowAddFontModal,
    dispatchShowFontDetailsModal,
    dispatchShowInactiveCustomFonts,
    dispatchSetCustomFontList,
    dispatchSetSelectedCustomFont,
    customFontState,
    customFontList,
    showAddFontModal,
    showFontDetailsModal,
    showInactiveFonts,
    selectedFont,
}) => {

    const [searchValue, setSearchValue] = React.useState('');
    const [pageError, setPageError] = React.useState('');
    const dealershipId = getDealershipId();

    const loadFonts = () => {
        const fetchFontData = async () => {
            if(!dealershipId) {
                setPageError(ERROR_NO_DEALERSHIP);
                return;
            }
            const response = await EliteOptionsService.getCustomFontsForDealership(DEFAULT_GET_FONTS_PAYLOAD, dealershipId);
            const hasFonts = response && response.data && response.data.result && response.data.result.length > 0;
            if(hasFonts) {
                setPageError('');
                dispatchSetCustomFontList(response.data.result);
            } else {
                const error = (response.error && response.message) ? response.message : ERROR_NO_RESULTS;
                setPageError(error);
            }
        }
        fetchFontData().catch((e) => {
            const error = (e.error && e.message) ? e.message : ERROR_NETWORK_CALL;
            setPageError(error);
        });
    }

    useEffect( () => {
        loadFonts();
    }, []);

    const handleAddCustomFont = () => {
        dispatchShowAddFontModal(false);
        loadFonts();
    }

    const handleFontSelect = (clear: boolean, font:CustomFont | null, refresh: boolean = false) => {
        if(clear) {
            dispatchSetSelectedCustomFont(null);
            dispatchShowFontDetailsModal(false);
        } else {
            dispatchSetSelectedCustomFont(font);
            dispatchShowFontDetailsModal(true);
        }
        if(refresh) {
            loadFonts();
        }
    }

    let filteredFontList: CustomFont[] | [] = customFontList;

    if(searchValue) {
        filteredFontList = filteredFontList.filter((font) =>
            font.data.fontName.toLowerCase().includes(searchValue.trim().toLowerCase()) || font.data.schoolName?.toLowerCase().includes(searchValue.trim().toLowerCase())
        )
    }

    if(!showInactiveFonts) {
        filteredFontList = filteredFontList.filter(font => font.status === EliteOptionsStatusType.ACTIVE);
    }

    return (
        <div className="container nts-custom-fonts-page">
            <Row>
                <Col xs={12}>
                    <div className="title">Custom Fonts</div>
                    
                    {dealershipId &&
                        <CustomFontsActionRow
                            onAddFont={() => dispatchShowAddFontModal(true)}
                            onSearch={(val) => setSearchValue(val)}
                            onShowInactive={(show) => dispatchShowInactiveCustomFonts(show)}
                            searchValue={searchValue}
                            showInactive={showInactiveFonts}
                        />
                    }

                    <CustomFontsGrid fonts={filteredFontList} error={pageError} onFontSelect={(font) => handleFontSelect(false, font, false)} />

                    {showAddFontModal &&
                        <AddCustomFontModal
                            onHide={() => dispatchShowAddFontModal(false)}
                            onAdd={() => handleAddCustomFont()}
                        />
                    }

                    {showFontDetailsModal && selectedFont !== null &&
                        <FontDetailsModal
                            font={selectedFont}
                            onHide={(refresh) => handleFontSelect(true, null, refresh)}
                        />
                    }

                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    customFontState: state.customFontsState,
    customFontList: state.customFontsState.customFontList,
    showAddFontModal: state.customFontsState.showAddFontModal,
    showFontDetailsModal: state.customFontsState.showFontDetailsModal,
    showInactiveFonts: state.customFontsState.showInactiveFonts,
    selectedFont: state.customFontsState.selectedFont,
});

const mapDispatchToProps = (dispatch: Dispatch<unknown>): DispatchProps => ({
    dispatchShowAddFontModal: (show: boolean) => dispatch(showAddFontModal(show)),
    dispatchShowFontDetailsModal: (show: boolean) => dispatch(showFontDetailsModal(show)),
    dispatchSetCustomFontList: (list: CustomFont[]) => dispatch(setCustomFontList(list)),
    dispatchShowInactiveCustomFonts: (show: boolean) => dispatch(showInactiveCustomFonts(show)),
    dispatchSetSelectedCustomFont: (font: CustomFont | null) => dispatch(setSelectedCustomFont(font)),
})

export const CustomFontsPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomFontsClass);