import * as React from 'react';

import {OrderDeadline} from "./orderDeadlinesHelper";
import {OrderDeadlinesTable} from "./OrderDeadlinesTable";
import {StyleList} from "./StyleList";

interface Props {
    shipGroup: OrderDeadline;
}

export const ShipGroupSection: React.FC<Props> = ({shipGroup}) => {
    const [showStyles, setShowStyles] = React.useState(false);
    return (
        <div className="ship-group-section">
            <div>
                <h2>{shipGroup.name}</h2>
                <OrderDeadlinesTable orderDeadlines={shipGroup.deadlines} />
            </div>
            <div className="style-list-control" onClick={() => setShowStyles(!showStyles)}>
                <span className={showStyles? 'g72-minus' : 'g72-plus'}>&nbsp;</span>
                <span>Show Styles</span>
            </div>
            {showStyles &&
                <div>{shipGroup.styles.length > 0 && <StyleList styles={shipGroup.styles} />}</div>
            }
        </div>
    );
};
