import * as React from "react";

import {CustomFont} from "../../reducers/customFontsReducer";
import {urlNtsEnvironment} from "../../api/services";
import {EliteOptionsStatusType} from "./customFontsHelper";

interface Props {
    font: CustomFont,
    index: number
}

export const CustomFontsTile: React.FC<Props> = ({
    font,
    index
}) => {
    const imageUrl = urlNtsEnvironment + '/elite-options/' + font.data.displayUrl;
    return (
        <div className="custom-fonts-tile" data-nts-id={`customFonts.grid.tile.${index}`}>
            <img width="200" height="200" src={imageUrl} alt={`${font.data.fontName} preview`} />
            <div className="details">
                {font.data.fontName && <h5>{font.data.fontName}</h5>}
                {font.data.schoolName && <div>{font.data.schoolName}</div>}
                {font.data.numberOfColors && <div>{font.data.numberOfColors} color{font.data.numberOfColors > 1 && 's'}</div>}
                {font.status && <div>{(font.status ===  EliteOptionsStatusType.ACTIVE) ? 'active' : 'inactive'}</div>}
            </div>
        </div>
    );
}