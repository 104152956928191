import React, {ReactElement} from "react";

const AuthContext = React.createContext({
    signinRedirect: () => ({}),
    signinRedirectCallback: () => ({}),
    signinSilentCallback: () => ({}),
    signoutRedirect: () => ({}),
    signoutRedirectCallback: () => ({}),
    isAuthenticated: () => ({})
});

interface Props {
    authService: any;
    children: ReactElement;
}

export const AuthProvider: React.FC<Props> = ({
    authService,
    children
}) => (
    <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>
)

export const AuthConsumer = AuthContext.Consumer;
