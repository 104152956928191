import * as React from 'react';

import {SearchBar} from "./SearchBar";
import {SearchResults} from "./SearchResults";

import {getQueryOptions, QuoteFilterOption, AnalyticsState, AnalyticsReportType} from "./adminAnalyticsHelper";
import {AdminReportingService} from "../../../api/services/adminReportingService";
import {getDateAsString} from "../../../helpers/checkoutHelpers";

export enum OrderFilterOption {
    DEALER = 'dealer',
    STYLE = 'style',
}

interface Props {
    type: AnalyticsReportType;
}

export const ReportTab: React.FC<Props> = ({
   type
}) => {

    const queryOptions = getQueryOptions(type);
    const today = getDateAsString(new Date(), 'dashes');

    const initialState: AnalyticsState = {
        options: queryOptions,
        filter: (type === AnalyticsReportType.ORDERS) ? OrderFilterOption.DEALER : QuoteFilterOption.REQUESTOR,
        toDate: today,
        fromDate: today,
        results: [],
        error: ''
    }

    const [state, setState] = React.useState(initialState);

    const handleSearch = async () => {

        if(state.toDate < state.fromDate) {
            setState({...state, error: 'FROM date should occur before TO date.'});
            return;
        }

        const payload = {
            type: type,
            groupBy: state.filter,
            fromDate: state.fromDate,
            toDate: state.toDate
        }

        const response = await AdminReportingService.getAnalyticsResults(payload);

        if(response) {
            if(type === AnalyticsReportType.ORDERS) {
                if(response.orders && response.orders.length < 1) {
                    setState({...state, results: [], error: 'No results found'});
                } else {
                    setState({...state, results: response.orders, error: ''});
                }
            } else {
                if(response.quotes && response.quotes.length < 1) {
                    setState({...state, results: [], error: 'No results found'});
                } else {
                    setState({...state, results: response.quotes, error: ''});
                }
            }
        } else {
            setState({...state, results: [], error: 'Unable to retrieve records. Please try again.'});
        }

    }

    return (
        <div>
            <SearchBar state={state} handleSearch={handleSearch} updateState={(state) => setState(state)} />
            {state.error && <div className="error">{state.error}</div>}
            {state.results.length > 0 && <SearchResults type={type} state={state} />}
        </div>
    )

}

