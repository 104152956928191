import React from 'react';
import ReactTable from 'react-table';
import {connect} from 'react-redux';

import {store} from "../../../App";
import {getCurrentOrders} from "../../actions/orderActions";
import {navigateToShoppingHome} from "../../helpers/navbarHelpers";
import {getCurrentOrdersTableConfig} from "./tableConfigUtil";

/**
 * Provides page for viewing past (submitted) orders
 */
export class OrdersClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentOrdersLoaded: false,
      searchValue: ''
    };
  }

  componentWillMount() {
    this.loadCurrentOrders();
  }

  loadCurrentOrders = () => {
    store.dispatch(getCurrentOrders(this.state.searchValue)).then(() => {
      this.setState({
        currentOrdersLoaded: true
      })
    }).catch(e => {
      console.error('Error getting currrent orders', e);
    });
  };

  defaultFilterMethod(filter, row) {
    return String(row[filter.id]).toUpperCase().includes(filter.value.toUpperCase())
  }

  render() {
    return (
        <div className="container nts-past-orders-container">

          <div className="row nts-past-orders-header">
            <div className="col-xs-5 col-sm-10">
              <h1>CARTS</h1>
            </div>
            <div className="col-xs-7 col-sm-2">
              <button
                className="btn btn-default past-orders-cont-shop"
                onClick={navigateToShoppingHome}
              >
                <img alt="" src="/img/carrot.svg" className="past-order-btn-caret"/>
                <span>CONTINUE SHOPPING</span>
              </button>
            </div>
          </div>

          <div className="row nts-table-wrapper">
            <div className="col-xs-12" style={{overflow:"auto"}}>
              <ReactTable
                  className="nts-table"
                  data={this.props.ordersState.currentOrderData.orders}
                  filterable
                  defaultFilterMethod={this.defaultFilterMethod}
                  noDataText="NO CARTS FOUND"
                  loading={!this.state.currentOrdersLoaded}
                  columns={getCurrentOrdersTableConfig()}
                  minRows={0}
                  style={{height:"auto"}}
                  getTrProps={(state, rowInfo) => {
                    return {
                      onClick: () => {
                        window.location = window.location.protocol + '//' + window.location.host + '/en/en_US/p/order,detail?cartId=' + rowInfo.row.id;
                      }
                    }
                  }}
              />
            </div>
          </div>

        </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    ordersState: state.ordersState
  }
};

export const OrdersPage = connect(
    mapStateToProps
)(OrdersClass);