import {calculateEndpointType} from './common';

const endpointTypeToUrl = {
  local: 'https://niketeam-api-qa.nike.net/identity-api/api/v1',
  dev:   'https://niketeam-api-dev.nike.net/identity-api/api/v1',
  qa:    'https://niketeam-api-qa.nike.net/identity-api/api/v1',
  stg:   'https://niketeam-api-stg.nike.net/identity-api/api/v1',
  uat:   'https://niketeam-api-uat.nike.net/identity-api/api/v1',
  prod:  'https://niketeam-api.nike.net/identity-api/api/v1'
};

export default function ntsIdentityServicesUrl(location) {
  const type = calculateEndpointType(location, 'prod');
  return endpointTypeToUrl[type];
}
