// Constants
import {cookies} from "../api/services";
import {
  NTS_MAX_COOKIE_EXPIRY
} from "./constants";
import {getCookieDomain, getDateObjectMinutesFromNow} from "./uniteHelpers";

export const EXPERIENCE_COOKIE_KEY = 'nts_experience_type';

// Experience Types
export const RETAILER_KEY_SCHEELS = 'scheels';


/**
 * Sets LS flag for the experience type of the current user (based on URL)
 * @param path -- (window.location.pathname)
 */
export const setRetailExperienceType = (path) => {

  if(path.includes(RETAILER_KEY_SCHEELS)) {
    cookies.set(EXPERIENCE_COOKIE_KEY, RETAILER_KEY_SCHEELS, {domain: getCookieDomain(), path: '/', expires: getDateObjectMinutesFromNow(NTS_MAX_COOKIE_EXPIRY)});
  }

};

/**
 * Checks if given experience is the experience type of the current user
 * @param experienceType
 * @returns {boolean}
 */
export const isRetailExperience = () => {
  return !!cookies.get(EXPERIENCE_COOKIE_KEY);
};

/**
 * Provides retailer specific configs to support Retailer Experiences
 * @param retailer
 * @returns {*}
 */
export const getRetailerConfig = (retailer = '') => {

  if(!retailer) {
    retailer =  cookies.get(EXPERIENCE_COOKIE_KEY);
  }

  switch (retailer) {

    case RETAILER_KEY_SCHEELS:
      return {
        retailer: 'scheels',
        homePagePath: '/en/en_US/nts/scheels',
        localStorageKey: 'scheels'
      };

    // TODO: Add case for each retailer here...

    default:
      return null;

  }
};