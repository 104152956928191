import React from 'react';
import {connect} from 'react-redux';

const ToastComponent = ({toastState}) => {
  return toastState.toast && <div key={(new Date()).getTime() / 1000} className="nts-toast">{toastState.toast}</div>;
};

const mapStateToProps = state => {
  return {
    toastState: state.toastState
  }
};

export const Toast = connect(
  mapStateToProps
)(ToastComponent);