import React from "react";
/**
 * Documents download in SalesTools page under SalesTool Nav bar object
 */
export const SalesToolsPage = () => {

  return (

      <div className="container nts-faq-help-page">

        <div className="nts-faq-title-bar row">
          <div className="col-xs-12">
            <h1>SALES TOOLS</h1>
          </div>
        </div>

        <div className="nts-faq-container topic-container">
          <h1 className="nts-faq-container-title">DOCUMENTS</h1>
          <div className="nts-faq-topic-content" data-nts-qa="salesToolsPage.documents.links">
            <a href='https://niketeam.nike.com/niketeamsports/content/downloads/sales_tools/NikeStockUniformEmbellishmentOrderForm.xls'>Nike Stock Uniform Embellishment Order.xls</a>
            <br /><br />
            <a href='https://niketeam.nike.com/niketeamsports/content/downloads/sales_tools/NikeUniformDefectiveReplacementOrderForm.xls'>Nike Uniform Defective Replacement Order.xls</a>
            <br /><br />
            <a href='https://niketeam.nike.com/niketeamsports/content/downloads/sales_tools/TeamDealerHeadwearOrderGuideSSR.pdf' target="_blank" rel="noreferrer">Team Dealer Headwear Order Guide (SSR).pdf</a>
            <br /><br />
            <a href='https://niketeam.nike.com/niketeamsports/content/downloads/sales_tools/EmbellishmentPricing-02-04-2019.xlsx'>Nike Print Pricing.xlsx</a>
            <br /><br />
            <a href='https://www.nike.com/help/a/size-charts' target="_blank" rel="noreferrer">Nike Size Charts</a>
          </div>
        </div>

      </div>

  );

};

export default SalesToolsPage;