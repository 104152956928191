import * as React from 'react';
import Select from "react-select";

import {ReactComponent as TrashAlt} from '../../../img/SVG/trash-alt.svg';
import {Filter} from '../../reducers/lockerReducer';

interface Props {
    filters: Filter[];
    selectedFilter: Filter | null;
    filterClickHandler: (filter: Filter | null) => void;
    removeSet: () => void;
}

export const LockerFilter: React.FC<Props> = ({
    filters,
    selectedFilter,
    filterClickHandler,
    removeSet,
}) => {
    return (
        <div className="filters">
            <Select
                className="filter-select"
                onChange={(selected:any) => filterClickHandler(selected)}
                options={filters}
                placeholder="Select sport or set"
                value={(selectedFilter) ? selectedFilter.value : ''}
            />
            {selectedFilter && selectedFilter.isSet && (
                <TrashAlt className="trash-icon" title="Remove Set" name="Remove Set" onClick={removeSet} />
            )}
        </div>
    );
};
