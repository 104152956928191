import {calculateEndpointType} from './common';

const endpointTypeToUrl = {
  local: 'http://localhost:3000',
  dev: 'https://ntsdev.nikedev.com',
  qa: 'https://ntsqa.nikedev.com',
  stg: 'https://ntsstage.nike.com',
  uat: 'https://ntsuat.nike.com',
  prod: 'https://niketeam.nike.com'
};

export default function ntsEnvironmentUrl(location) {
  const type = calculateEndpointType(location, 'prod');
  return endpointTypeToUrl[type];
}
