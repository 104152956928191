import quickSearchBaseUrl from "../config/quickSearchBaseUrl";
import {fetchRequestXml, getMock, mockAll, mockForIeAndSafari} from "./index";
import {isAdminRole} from "../../helpers/userProfileHelpers";
import searchBaseUrl from "../config/searchBaseUrl";
import {GridWallItemsMock} from "../mocks";

/**
 * Quick product search for gridwall filtering
 */
export const QuickSearchService = {
  /**
   * @param {string} searchString
   * @param {boolean} showHidden
   */
  get: (searchString, showHidden) => {
    const params = {
      'lang_locale': 'en_US',
      pageMode: 5,
      Ntt: searchString,
    };
    const queryString = new URLSearchParams(params);
    if (showHidden) {
      queryString.append('showHidden', true);
    }
    return fetchQuickSearchResults(`${quickSearchBaseUrl(window.location)}?${queryString}`);
  }
};

function fetchQuickSearchResults(searchUrl) {
  const request = new Request(searchUrl, {method: 'GET', headers: {'Accept': 'application/xml'}});
  return fetchRequestXml(request, false).then(json => {
    let products = [];
    let respProducts = json.response.template.productContainer.products.productGroup;
    if (respProducts && respProducts.length > 0) {
      respProducts.forEach((prod) => {
        products.push(prod.product);
      })
    } else if (typeof(respProducts) === 'object' && respProducts.product) {
      products.push(respProducts.product);
    }
    return products;
  })
}

/**
 * Obtains product details from search service
 */
export const ProductService = {

  getFiltered: (selectedFilters, sort, showHidden, customSearchString) => {
    let hiddenParam = showHidden && isAdminRole() ? "&showHidden=true" : '';
    return fetchGridwallItems(searchBaseUrl(window.location) +
        '?Ntt=' + customSearchString + '&N=' + selectedFilters +
        '&pageNum=1&pageMode=1000&sortString='
        + encodeURIComponent(sort) + '&lang_locale=en_US' + hiddenParam);
  },
  getByStyle: (styleNumber) => {
    if (mockForIeAndSafari || mockAll) {
      return getMock(GridWallItemsMock)
    }
    return fetchGridwallItems(searchBaseUrl(window.location) + '?Ntt=styleNumber:' + styleNumber + '&lang_locale=en_US');
  },
  getPromoItems: (sport, gender) => {
    const queryParams = `Ntt=&N=${sport}+${gender}&pageNum=1&pageMode=1000&sortString=rowNumber%7Cdesc&lang_locale=en_US&showHidden=false&showPromoOnly=true`;
    return fetchGridwallItems(`${searchBaseUrl(window.location)}?${queryParams}`);
  }
};

function fetchGridwallItems(searchUrl) {
  const request = new Request(searchUrl, {method: 'GET', headers: {'Accept': 'application/xml'}});
  return fetchRequestXml(request).then(json => {
    let products = [];
    if (json.response.template.products.productGroup) {
      let productGroups = json.response.template.products.productGroup;
      if (productGroups && productGroups.forEach) {
        json.response.template.products.productGroup.forEach((element) => {
          products.push(fetchProductFromProductGroup(element));
        });
      }
      else {
        // Single product group returned when PDP page is loaded directly using a style number.
        products.push(fetchProductFromProductGroup(productGroups));
      }
    }
    return products;
  })
}

function fetchProductFromProductGroup(productGroup) {
  if (!productGroup || !productGroup.product || productGroup.product.length === 0) return;
  //If there is only one colorway, it's not in an array.
  //move it too an array so we can handle it the same as multiple.
  let product = productGroup.product[0];
  if (typeof product === 'undefined') {
    let newProduct = productGroup.product;
    productGroup.product = [];
    productGroup.product.push(newProduct);
    product = newProduct;
  }

  //Compile a list of all colorways
  product.colors = [];
  productGroup.product.forEach((product) => {
    productGroup.product[0].colors.push(product.colorNumber)
  });

  product.numberOfColors = productGroup.product.length;

  return product;
}
