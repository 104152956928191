import {AssortmentService} from "../api/services/assortmentService";
import {UniformDesignService} from "../api/services/templateApiService";
import {EmbellishedDesignPriceService} from "../api/services/templateApiService";
import {ProductService} from "../api/services/productSearchService";
import {ProductDetails} from "../api/services/productDetailsService";

export function embodeeDesignCheckFillinStatus(designId) {
  return new Promise((resolve, reject) => {
    AssortmentService.findByDesignId(designId)
      .then(result => {
        if (!result || !result.response) {
          reject("No design found for this ID");
        } else {
          const orderStatus = result.response.status;
          // Validate order has been previously confirmed (ordered)
          if ((orderStatus === "OPEN" || orderStatus === "SOFT ALLOCATED") && !result.response.parentOrderId) {
            resolve({
              order: result.response,
              designId: designId,
              error: "This uniform is currently associated to an open order, so a fill-in order can't be created yet"
            });
            // This checks both TeamSportsPageData for cart page fillin requests, and orderStatus.stillAvailable for fillin page requests
          } else if (result.response && result.response.productsStillAvailable === "false") {
            resolve({
              order: result.response,
              designId: designId,
              error: "This design can no longer be ordered."
            });
          } else {
            resolve({
              order: result.response,
              designId: designId
            });

          }
        }
      }).catch((e) => {
      console.log(e);
      reject("There was an error searching for this Uniform ID");
    });
  });
}

export function validateFillinPersonalizedStockDesign(designId) {
  return new Promise((resolve, reject) => {
    UniformDesignService.get(designId)
      .then(design => {
        if (design.id) {
          getProductDetails(design.styleCode, design.colorCode, designId, true).then((data) => {
            data.design = design;
            data.error = validate(data, design.styleCode, true);
            resolve(data);
          });
        }
        else {
          let data = {
            error: "No design found for this ID"
          };

          resolve(data);
        }
      })
      .catch(error => {
        //UniformDesignService.get call failed
        console.log(error);
        reject("There was an error searching for this Uniform ID");
      });
  });
}

export function verifyFillinBlankStockProduct(styleColor) {
  let styleCode = styleColor.split('-')[0];
  let colorCode = styleColor.split('-')[1];
  return new Promise((resolve, reject) => {
    getProductDetails(styleCode, colorCode).then((data) => {
      data.error = validate(data, styleCode, false);
      resolve(data);
    }).catch(e => reject(e));
  });
}

function validate(data, styleCode, isSapDesign) {
  if (isSapDesign && data.design.confirmedOrders === 0) {
    return "This design has not been processed yet, so it is not eligible for fill in orders"
  }
  if (!data.productDetails) {
    return "Style " + styleCode + " is no longer available to order";
  }
}

export function getProductDetails(styleCode, colorCode, designId, isFillin) {
  return new Promise((resolve, reject) => {
    let productDetailsPromise = ProductDetails.get(styleCode + "-" + colorCode);
    let styleDetailsPromise = ProductService.getByStyle(styleCode);
    let designPromise = null;
    if(!isFillin){
      designPromise = designId ? EmbellishedDesignPriceService.get(designId, 6, false) : null;
    }else{
      designPromise = designId ? EmbellishedDesignPriceService.get(designId, 6, true) : null;
    }
    Promise.all([productDetailsPromise, styleDetailsPromise, designPromise])
      .then(function (values) {
        let productDetails = null;
        if (values[0].length > 0) {
          productDetails = values[0][0];
        }

        let styleDetails = null;
        if (values[1].length > 0) {
          styleDetails = buildSelectedStyleFromProduct(values[1][0], styleCode, colorCode)
        }

        let priceDetails =  null;
        if (values[2]) {
          priceDetails = values[2];
        }
        resolve({
          productDetails: productDetails,
          selectedStyle: styleDetails,
          priceDetails: priceDetails
        });
      })
      .catch(error => {
        //ProductService.getByStyle call failed
        console.log(error);
        reject("Unexpected network error, please try again later");
      });
  });
}

function buildSelectedStyleFromProduct(product, styleCode, colorCode) {
  console.log("In buildSelectedStyleFromProduct, " + styleCode + "-" + colorCode);
  return {
    colorNumber: colorCode,
    styleNumber: styleCode,
    details: {
      ...product,
      styleNumber: styleCode,
      colorNumber: colorCode
    }
  }
}
