import * as React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"; 
import { useForm } from 'react-hook-form';
import { showMessageModal } from "../../../actions/messageModalActions";
import { dispatchShowToast } from "../../../actions/toastActions";
import { AdminFactoryService } from "../../../api/services/adminFactoryService";
import { AddFactoryShipGroupState } from "./adminFactoryShipGroupHelper";

interface Props {
  state: AddFactoryShipGroupState,
  factoryId: number,
  onHide: () => void,
  onSubmitSuccess: () => void,
}

export const AddFactoryShipGroup: React.FC<Props> = ({
  state,
  factoryId,
  onHide,
  onSubmitSuccess
}) => {
  const trapSpacesForRequiredFields =  (value: any) => !!value.trim(); 
  const [disableState, setDisableState] = React.useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  React.useEffect(() => {
    if (state.show) {
      setDisableState(false);
      reset()
    }
  }, [state.show])
  const onSubmit =async (data: any) => {
    setDisableState(true);
    const reqBody = {
      ...data,
      fcr_id:factoryId
    }
    AdminFactoryService.addFactoryShipGroup(reqBody).then((response)=>{
      setDisableState(false);
      onSubmitSuccess()
    }).catch((err)=>{ 
      setDisableState(false);
      // dispatchShowToast(err.message);
      showMessageModal({ header: "ADD SHIP GROUP ERROR", message: err.message, required: true });
    }); 
  };
  return (
    <Modal className="show nts-terms-modal nts-factory-modal"
      backdrop="static"
      keyboard={false}
      onHide={onHide}
      show={state.show}
      bsSize={"medium"} aria-labelledby={"contained-modal-title-lg"}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-lg">
          {state.edit ? 'UPDATE' : 'ADD'} FACTORY SHIP GROUP
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-left">
        <form className="nts-basic-form nts-profile-form" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12} sm={12}>
              <div className="nts-basic-form-section form-section-spacer">
                <label>
                  <span>Ship Group Name <span className="required">*</span></span>
                  <input type="text"
                    className={`form-input`}
                    {...register("name", {
                      required: true,
                      validate: trapSpacesForRequiredFields,
                      pattern: {
                        value: /^[A-Z0-9a-z\s-.]+$/i,
                        message: "invalid name"
                      },
                      maxLength: 30
                    })}
                  />
                  {errors.name && errors.name.type === "required" && (
                    <div className="required">You must enter your name</div>
                  )}
                   {errors.name && errors.name.type === "validate" && (
                    <div className="required">You must enter your name</div>
                  )}
                  {errors.name && errors.name.type === "maxLength" && (
                    <div className="required">No longer than 30 characters</div>
                  )}
                  {errors.name && errors.name.type === "pattern" && (
                    <div className="required">Only A-Z, a-z, 0-9, period, space and dash allow</div>
                  )}
                </label>

              </div>
            </Col> 
            <Col xs={12}>
              <div className="nts-profile-req-container"><span className="required">*</span> Required Fields</div>
            </Col>

          </Row>
          <div className={'container-modal text-right'}>
            <button className={'pdp-cart-btn btn btn-default mr-15'} type="button" onClick={onHide}>
              Cancel
            </button>
            <button className={'pdp-cart-btn btn btn-primary'} type="submit" disabled={disableState}> 
              {state.edit ? 'UPDATE' : 'ADD'}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
