import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

import {LoadingPage} from "../loadingPage/LoadingPage";

class ProtectedRouteClass extends React.Component {

    render() {

        return this.props.isLoggedIn ?
            (<Route {...this.props} />) :
            (this.props.isLoading ?
                (<LoadingPage />) :
                (<Redirect to="/en/en_US/nts" />)
            );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.userState.isLoading,
        isLoggedIn: state.userState.isLoggedIn
    }
};

export const ProtectedRoute = connect(
    mapStateToProps
)(ProtectedRouteClass);
