import * as React from 'react';

import {naturalSortBy, SortDirection} from '../../helpers/typedArrayHelpers';
import {Catalog} from './CatalogPage';
import CatalogTile from './CatalogTile';

interface Props {
    baseUrl: string;
    cacheBuster: string;
    catalogs: Catalog[];
}

const calcCurrentCatalogs = (catalogs: Catalog[]) => {
    const currentCatalogs = catalogs.filter(cat => cat.current === true);
    const alphaCatalogs = naturalSortBy(currentCatalogs, (cat: Catalog) => cat.label, SortDirection.ASC);
    return alphaCatalogs;
}

const CurrentCatalogs: React.FC<Props> = ({
    baseUrl,
    cacheBuster,
    catalogs,
}) => {
    const renderTile = (cat: Catalog, idx: number) => {
        return (
            <CatalogTile
                key={idx}
                catalogUrl={`${baseUrl}/${cat.catalog}?cb=${cacheBuster}`}
                label={cat.label}
                thumbnailUrl={`${baseUrl}/${cat.thumbnail}?cb=${cacheBuster}`}
            />
        );
    };

    const currentCatalogs = calcCurrentCatalogs(catalogs);
    return (
        <div className="thumbnails-container">
        {
            currentCatalogs.map(renderTile)
        }
        </div>
    );
};

export default CurrentCatalogs;
