import * as React from "react";
import {connect} from "react-redux";

// types
import {AppState} from "../../reducers";

// services & helpers
import {hasAtLeastOneRole} from "../../helpers/userProfileHelpers";
import {Redirect, Route} from "react-router-dom";
import {LoadingPage} from "../loadingPage/LoadingPage";
import {ComponentClass} from "react";

interface StateProps {
    isLoggedIn: boolean,
    isLoading: boolean,
}

// TODO: Find correct type for 'component'
interface Props {
    isLoggedIn: boolean,
    isLoading: boolean,
    roles: string[],
    path: string,
    component: ComponentClass | any
}

const RoleProtectedRouteClass: React.FC<Props> = ({
    isLoggedIn,
    isLoading,
    roles,
    path,
    component
}) => {

    const hasRole = hasAtLeastOneRole(roles);

    return isLoggedIn && hasRole ?
        (<Route path={path} component={component} />) :
            (isLoading ?
                    (<LoadingPage />) :
                    (<Redirect to="/en/en_US/nts" />)
            );
};

const mapStateToProps = (state: AppState): StateProps => ({
    isLoggedIn: state.userState.isLoggedIn,
    isLoading: state.userState.isLoading,
});

export const RoleProtectedRoute = connect(
    mapStateToProps,
)(RoleProtectedRouteClass);