import React, {useState, useEffect} from "react";

import {Row, Col} from 'react-bootstrap';
import {ShipGroupSection} from "./ShipGroupSection";

import {getOrderDeadlines} from "../../api/services/orderDeadlinesService";
import {OrderDeadline} from "./orderDeadlinesHelper";

export const OrderDeadlinesPage: React.FC = () => {

    const [orderDeadlines, setOrderDeadlines] = useState<OrderDeadline[]>([]);
    const [orderStyles, setOrderStyles] = useState<any[]>([]);
    const [orderSearchedData, setOrderSearchedData] = useState<OrderDeadline[]>([]);
    const [pageError, setPageError] = useState('');
    const [styleIds, setStyleIds] = useState<any[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('')

    const loadOrderDeadlines = () => {  
        const fetchOrderDeadlines = async () => {
            const response = await getOrderDeadlines()
            if (response) {
                setOrderDeadlines(response);
                setOrderStyles(response);                
                setPageError('');
            }
        }
        fetchOrderDeadlines().catch((e) => {
            console.error('Error retrieving Order Deadlines: ', e);
            setPageError('Unable to obtain Order Deadlines. Please try again.');
        });
    }

    useEffect( () => {
        loadOrderDeadlines();
    }, []);

    const onSearch = (event: any) => {
        const searchText =  event.target.value;       
        const arr:any=[];
        setSearchValue(searchText);
        if(searchText.length<2) {
            setShowDropdown(false);
            setOrderDeadlines(orderStyles)      
            return;
        }       
        setShowDropdown(true);
        const filteredStyleIdData= orderStyles.filter((order: any) => {
            let isStyleNumberExists= false;
            order.styles.forEach((style: any) => {
                if(style.styleNumber.toLowerCase().includes(searchText.toLowerCase())) {
                    arr.push(style.styleNumber);
                    isStyleNumberExists = true;
                }
            })
            return isStyleNumberExists;
        })   
        if(event.key === 'Enter') {
            event.preventDefault();
            setShowDropdown(false);
            setOrderDeadlines(filteredStyleIdData)  
        }           
        setStyleIds(arr);
        setOrderSearchedData(filteredStyleIdData);
    }

    const onStyleIdClick = (id: any) => {        
         const filteredData= orderSearchedData.filter((order: any) => {                                    
            return order.styles.some((style: any) => style.styleNumber.toLowerCase().includes(id.toLowerCase()));
        })     
        setSearchValue(id);   
        setOrderDeadlines(filteredData);
        setShowDropdown(false);
    }

    const onClear =() => {
        setSearchValue('');
        setShowDropdown(false);
        setStyleIds([]);
        setOrderDeadlines(orderStyles)
    }

    return (
        <div className="container" id="nts-deadlines-page">
            <Row>
                <Col xs={12}>
                    <div className='row-alignment'>
                        <div className="title">Order Deadlines</div>
                        <div className='search-bar'>
                            <span><i className="g72-search" /></span>
                            <input type="text"
                                name="search"
                                style={{border: "none"}}
                                placeholder="Search by code"
                                maxLength={15}
                                value={searchValue}
                                onFocus={() => styleIds.length > 0 ? setShowDropdown(true): setShowDropdown(false)}
                                onChange={onSearch}
                                onKeyDown={onSearch}
                            />
                            {searchValue.length >1 && <span className="delete-icon" onClick={onClear}></span>}
                           
                        </div>
                    </div>
                    {showDropdown &&
                        <div className="dropdown-item-container">
                            {styleIds.length>0 
                                ? styleIds.map((id) => <div className="dropdown-item" onClick={() => onStyleIdClick(id)}>{id}</div>)
                                : 'No data'
                            }
                        </div>
                    }
                    {orderDeadlines && orderDeadlines.length > 0
                        ?(
                            <div>
                                <p className="disclaimer">The cutoff time for submitting orders on the day of the order deadline is 4:49 p.m. PDT (3:59 p.m. PST)</p>
                                {orderDeadlines.map((shipGroup) => <ShipGroupSection shipGroup={shipGroup} key={shipGroup.name} />)}
                                <p>{orderDeadlines.length} ship groups</p>
                            </div>
                        ): <h3 style={{textAlign: 'center'}}>No Data found</h3>
                    }
                    {pageError && <div>{pageError}</div>}
                </Col>
            </Row>
        </div>
    );
};
