import * as React from "react";

import {Button, Modal} from "react-bootstrap";
import {AddColorForm} from "./AddColorForm";

import {getDealershipId} from "../../../helpers/userProfileHelpers";
import {EliteOptionsService} from "../../../api/services/eliteOptionsService";
import {
    checkAddColorsFormForErrors,
    defaultAddColorFormErrors,
    ERROR_ADD_COLOR_GENERIC,
    ERROR_ADD_COLOR_NETWORK,
    ERROR_NO_DEALERSHIP,
    getAddColorPayload
} from "../customColorsHelper";

export interface ColorForm {
    active: boolean;
    name: string;
    school: string;
    colorCode: string;
    factoryHexCode: string;
    presentationHexCode: string;
}

const defaultForm: ColorForm = {
    active: true,
    name: '',
    school: '',
    colorCode: '',
    factoryHexCode: '',
    presentationHexCode: '',
}

interface Props {
    onHide: () => void;
    onAdd: () => void;
}

export const AddCustomColorModal: React.FC<Props> = ({
    onHide,
    onAdd,
}) => {

    const [colorForm, setColorForm] = React.useState(defaultForm);
    const [formErrors, setFormErrors] = React.useState(defaultAddColorFormErrors);

    const onSubmit = () => {
        const formErrors = checkAddColorsFormForErrors(colorForm);
        if(formErrors.numErrors > 0) {
            setFormErrors({...formErrors});
        } else {
            const addColorCall = async () => {
                const dealershipId = getDealershipId();
                if(!dealershipId) {
                    setFormErrors({...formErrors, displayMsg: ERROR_NO_DEALERSHIP});
                    return;
                }
                const payload = getAddColorPayload(colorForm, dealershipId);
                const response = await EliteOptionsService.addCustomColor(payload);
                if(response && response.code === "SUCCESS" && response.data) {
                    setFormErrors({...formErrors, displayMsg: ''});
                    onAdd();
                } else {
                    const error = (response.error && response.message) ? response.message : ERROR_ADD_COLOR_GENERIC
                    setFormErrors({...formErrors, displayMsg: error});
                }
            }
            addColorCall().catch((e) => {
                const error = (e.error && e.message) ? e.message : ERROR_ADD_COLOR_NETWORK;
                setFormErrors({...formErrors, displayMsg: error})
            });
        }
    }

    return (
        <Modal className="show nts-custom-color-upload-modal" show={true} onHide={onHide} bsSize="large" aria-labelledby="contained-modal-title-lg">

            <Modal.Header closeButton>Add Custom Color</Modal.Header>

            <Modal.Body>
                <AddColorForm colorForm={colorForm} setColorForm={setColorForm} errors={formErrors}/>
                {formErrors.displayMsg && <p className="error" data-nts-id="customColor.addColor.text.error">{formErrors.displayMsg}</p>}
            </Modal.Body>

            <Modal.Footer>
                <Button bsStyle="default" onClick={onHide} data-nts-id="customColor.addColor.button.cancel">Cancel</Button>
                <Button bsStyle="primary" onClick={onSubmit} data-nts-id="customColor.addColor.button.submit">Submit</Button>
            </Modal.Footer>

        </Modal>
    );
};