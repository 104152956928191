import {fetchRequest, urlNtsServices} from "./index";
import {LS_ACCESS_TOKEN} from "../../helpers/constants";

export const DealerService = {
    getDealerMembers: (query) => {
        const url = `${urlNtsServices}/dealer/member/share/list`;
        const queryStr = `?cb=${query.cb}&sortField=${query.sortField}&sortDirection=${query.sortDirection}&start=${query.start}&count=${query.count}&input=${query.input}`
        const request = new Request(url + queryStr, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'content-type':'application/json',
                'Authorization': localStorage.getItem(LS_ACCESS_TOKEN) || '',
            },
            data: query
        });
        return fetchRequest(request);
    }
};