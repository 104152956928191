import React from 'react';
import {dateStringSort} from "../../helpers/arrayHelpers";
import {isConsumer} from "../../helpers/userProfileHelpers";

/**
 * Provides 'react-table' column configuration for Past Orders table
 * @info - https://www.npmjs.com/package/react-table#columns
 * @returns [] - Column Config Array
 */
export function getPastQuotesTableConfig() {
  const consumerRole = isConsumer();
  return [
    {
      Header: 'ID',
      accessor: 'id',
      show: false
    },
    {
      Header: (consumerRole) ? 'QUOTE ID' : 'QUOTE NAME',
      accessor: (consumerRole) ? 'id' : 'name'
    },
    {
      Header: 'CUSTOMER PO',
      accessor: 'customerOrderNumber',
      show: (consumerRole) ? false : true
    },
    {
      Header: 'NIKE PO',
      accessor: 'purchaseOrderNumber',
      width: 100,
      show: (consumerRole) ? false : true
    },
    {
      Header: 'NIKE SO',
      accessor: 'salesOrderNumber',
      width: 100,
      show: (consumerRole) ? false : true
    },
    {
      Header: 'CREATED',
      accessor: 'createDate',
      width: 150,
      sortMethod: dateStringSort
    },
    {
      Header: 'STATUS LAST UPDATED',
      accessor: 'statLastMod',
      width: 150,
      sortMethod: dateStringSort
    },
    //TODO: Not implemented yet.
    // {
    //   Header: 'TRACKING NUMBER',
    //   accessor: 'trackingNumber'
    // },
    {
      Header: 'STATUS',
      accessor: 'status',
      Cell: rowInfo => (
          <span>
          {rowInfo.original.status &&
          rowInfo.original.status === 'ERROR' ? (
              <div>IN REVIEW</div>
          ) : (
              <div>{rowInfo.original.status}</div>
          )}
        </span>
      )
    },
    {
      filterable : true,
      Header: 'UD NUMBER',
      accessor: 'udNumbers',
      width: 300,
      filterMethod: (filter, row) => {
        if (row._original.udNumbers)
          return row._original.udNumbers.toUpperCase().includes(filter.value.toUpperCase());
        else
          return false;
      },
      Cell: rowInfo => (
          <span style={{fontSize: "14px"}}>
          {rowInfo.original.udNumbers &&
          rowInfo.original.udNumbers.split(',').map((ud, i) => {
            if (ud !== "null")
              return (<div key={i}>{ud}</div>);
            else
              return "";
          })}
        </span>
      )
    },
    {
      filterable : true,
      Header: 'TRACKING NUMBERS',
      accessor: 'trackingNumber',
      width: 300,
      filterMethod: (filter, row) => {
        if (row._original.trackingNumber)
          return row._original.trackingNumber.toUpperCase().includes(filter.value.toUpperCase());
        else
          return false;
      },
      Cell: rowInfo => (
          <span style={{fontSize: "14px"}}>
          {rowInfo.original.trackingNumber &&
          rowInfo.original.trackingNumber.split(',').map((tn, i) => {
            if (tn !== "null")
              return (<div data-link={tn} key={i} onClick={handleTrackingClick} className="nts-table-tracking-nbr">{tn.substr(tn.lastIndexOf("=")+1)} </div>);
            else
              return "";
          })}
        </span>
      ),
      show: (consumerRole) ? false : true
    }
  ];
}

function handleTrackingClick (e) {
  e.stopPropagation();
  const url = e.target.dataset.link;
  window.open(url);
}