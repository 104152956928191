import * as React from "react";
import { FactoryState } from "./adminFactoryHelper";

interface Props {
    state: FactoryState,
    handleCreate: () => void
}

export const SearchBar: React.FC<Props> = ({
   state,
   handleCreate
}) => {
    return (
        <div className="nts-tab-search-bar" id="btn-add-factory">
            <button type="button" className="btn btn-primary" onClick={handleCreate}>Add Factory</button>
            {state.error && <div className="error">{state.error}</div>}
        </div>
    )
}
