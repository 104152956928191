import * as React from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import cx from 'classnames';

// components
import {ColorWheelSvg} from '../shared/colorWheelSvg';
import {ReactComponent as TrashAlt} from '../../../img/SVG/trash-alt.svg';
import {ReactComponent as CartPlus} from '../../../img/SVG/cart-plus.svg';
import {Checkbox} from './checkbox';
import {GridItemThumbs} from "./gridItemThumbs";

// services + helpers
import {AppState} from '../../reducers';
import {Filter} from '../../reducers/lockerReducer';
import {LockerService} from '../../api/services/lockerService';
import {isConsumerSelector} from '../../helpers/typedUserProfileHelpers';
import {LockerItem} from '../../reducers/lockerReducer';
import {toggleSelectLockerItem, setSelectedFilter, setLockerItemImage} from '../../actions/lockerActions';
import {showMessageModal} from '../../actions/messageModalActions';
import {useIsMobile, useHandleFillinHelper, useNavigateTo} from './hooks';
import {addToCart, buildProductLocation} from './lockerPageHelper';
import {NtsLink} from "../shared/ntsLink";

interface OwnProps {
    item: LockerItem;
}

interface StateProps {
    isConsumer: boolean;
    isLoading: boolean;
    selectionMode: boolean;
    selectedFilter: Filter | null;
}

interface DispatchProps {
    dispatchSetSelectedFilter: (filter: Filter | null) => void;
    selectLockerItem: (elementId: string) => void;
    dispatchSetLockerItemImage: (elementId: string, imageUrl: string) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const GridItemInner: React.FC<Props> = ({
    dispatchSetSelectedFilter,
    dispatchSetLockerItemImage,
    isConsumer,
    isLoading,
    item,
    selectedFilter,
    selectionMode,
    selectLockerItem,
}) => {
    const [hovering, setHovering] = React.useState(false);
    const isMobile = useIsMobile();
    const { handleFillinHelper } = useHandleFillinHelper();
    const navigateTo = useNavigateTo();

    const handleMouseOver = () => {
        setHovering(true);
    };
    const handleMouseOut = () => {
        setHovering(false);
    }

    const removeLockerItem = async () => {
        await LockerService.removeLockerItem(item.elementId);
        dispatchSetSelectedFilter(selectedFilter);
    };
    const onLockerItemClick = async () => {
        if (isLoading || selectionMode || !item.isOrderable) {
            return;
        }
        if (!isConsumer) {
            const handled = await handleFillinHelper(item);
            if (handled) {
                return;
            }
        }
        const url = buildProductLocation(item);
        navigateTo(url);
    };
    const setCurrentImage = (imgUrl: string) => {
        if(imgUrl) {
            dispatchSetLockerItemImage(item.elementId, imgUrl);
        }
        setHovering(true);
    }
    const isFullBodyImage = (imgUrl: string) => {
        return imgUrl.includes('/builder/ud/image/');
    }
    return (
        <div className="grid-item-wrapper">
            <div className="grid-item" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <div className={cx('grid-image-wrapper', {
                    'prebuild-image': item.productType === 'jba',
                    'not-orderable': !item.isOrderable,
                    'custom-sap': item.productType !== 'sap' && item.uniformDesignId
                })}>
                    {selectionMode || !item.isOrderable ? (
                        <img className={cx('grid-image', { 'prebuild-image': item.productType === 'jba', 'full-body': isFullBodyImage(item.imageUrl) })} alt="" src={item.imageUrl} />
                    ) : (
                        <NtsLink to={buildProductLocation(item)}>
                            <img className={cx('grid-image', { 'prebuild-image': item.productType === 'jba', 'full-body': isFullBodyImage(item.imageUrl) })} alt="" src={item.imageUrl} />
                        </NtsLink>
                    )}
                </div>
                {(isMobile || (item.images && item.images.length > 0)) &&
                    <GridItemThumbs images={item.images} onSelect={setCurrentImage} />
                }
                <div className="uniform-id-line">
                    {item.productType === 'jba' && (
                        <span>
                            <ColorWheelSvg className="inline-color-wheel" height={12} width={12} />
                            <span className="custom-personal">Customize</span>
                        </span>
                    )}
                    {item.productType !== 'jba' && item.uniformDesignId && (
                        <span>
                            <ColorWheelSvg className="inline-color-wheel" height={12} width={12} />
                            <span className="custom-personal">Personalize</span>
                        </span>
                    )}
                    <span className="uid">{item.uniformDesignId}</span>
                </div>
                <div className="name-line">
                    {item.displayName}
                </div>
                <div className="price-line">
                    {item.isOrderable ? item.formattedRetailPrice : 'Not Orderable'}
                </div>
                {!selectionMode && (
                    <TrashAlt
                        title="Remove Item"
                        className={cx('trash-icon', { 'active': hovering || isMobile })}
                        onClick={() => showMessageModal({
                            header: '',
                            message: 'Are you sure you want to remove this item from your locker?',
                            cancelable: true,
                            callback: removeLockerItem,
                        })}
                    />
                )}
                {!isConsumer && !selectionMode && item.isOrderable && (
                    <CartPlus
                        className={cx('add-to-cart-icon', { 'active': hovering || isMobile })}
                        onClick={() => addToCart(item)}
                    />
                )}
                {selectionMode && (
                    <Checkbox
                        checked={item.selected}
                        className="checkbox"
                        onClick={() => selectLockerItem(item.elementId)}
                    />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    isConsumer: isConsumerSelector(state),
    isLoading: state.loadingSpinnerState.isLoading,
    selectionMode: state.lockerState.selectionMode,
    selectedFilter: state.lockerState.selectedFilter,
});

const mapDispatchToProps = (dispatch: Dispatch<AppState>): DispatchProps => ({
    selectLockerItem: (elementId) => dispatch(toggleSelectLockerItem(elementId)),
    dispatchSetSelectedFilter: (filter) => dispatch(setSelectedFilter(filter)),
    dispatchSetLockerItemImage: (elementId, imageUrl) => dispatch(setLockerItemImage(elementId, imageUrl)),
});

export const GridItem = connect(
    mapStateToProps,
    mapDispatchToProps,
)(GridItemInner);
