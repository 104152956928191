import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';
import {EliteOptionsStatusType} from "../components/customFonts/customFontsHelper";

export interface CustomFont {
    PK: string;
    SK: string;
    createdBy: string;
    createdTimeStamp: string;
    data: {
        factoryFileKeyPath: string;
        displayUrl: string;
        schoolName: string;
        fontName: string;
        numberOfColors: number;
    };
    status: EliteOptionsStatusType;
    updatedBy: string;
    updatedTimeStamp: string;
}

export interface CustomFontsState {
    showAddFontModal: boolean;
    showFontDetailsModal: boolean;
    customFontList: CustomFont[],
    showInactiveFonts: boolean;
    selectedFont: CustomFont | null;
}

const initialState: CustomFontsState = {
    showAddFontModal: false,
    showFontDetailsModal: false,
    customFontList: [],
    showInactiveFonts: false,
    selectedFont: null
};

interface CustomFontsAction extends Action {
    show: boolean;
    list: [];
    font: CustomFont | null;
}

const customFontsReducer: Reducer<CustomFontsState> = (
    state = initialState,
    incomingAction,
) => {
    const action = incomingAction as unknown as CustomFontsAction;

    switch (action.type) {
        case types.SHOW_CUSTOM_FONT_ADD_MODAL:
            return {
                ...state,
                showAddFontModal: action.show
            };

        case types.SHOW_CUSTOM_FONT_DETAILS_MODAL:
            return {
                ...state,
                showFontDetailsModal: action.show
            };

        case types.SET_SELECTED_CUSTOM_FONT:
            return {
                ...state,
                selectedFont: action.font
            };

        case types.SET_CUSTOM_FONT_LIST:
            return {
                ...state,
                customFontList: action.list
            };

        case types.SHOW_INACTIVE_CUSTOM_FONTS:
            return {
                ...state,
                showInactiveFonts: action.show
            };

        default:
            return state;
    }
};

export default customFontsReducer;