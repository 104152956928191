import {AssortmentService} from "../api/services/assortmentService";
import {CheckoutService} from "../api/services/checkoutService";
import {NtsStockOrderService} from "../api/services/stockOrderService";
import {
  getCartRequestXML,
  getStockOrderPayload,
  getStockOrderProductPayload,
  getFormattedDate
} from "../helpers/orderHelpers";
import {hasCustomizations} from "../helpers/designHelpers";
import {getFindOrdersQueryStr} from './dealerActions';
import {showToast} from './toastActions';
import UUID from '../helpers/uuid';
import {store} from '../../App';

import * as types from '../actions/actionTypes';
import {showMessageModal} from "./messageModalActions";
import {
  getConsumerCouponSoldTo,
  getUserSoldTo
} from "../helpers/userProfileHelpers";

//--- File: orderActions.js
//----Desc: Manages Cart & Locker related actions

/**
 * Handles saving stock/custom stock products to cart for consumers and dealers
 * @param product
 * @param details
 * @param isDealer
 * @param orders
 * @param design (uniformDesign)
 * @param curDesignPrice - Object containing embellishment/price list{ embellishments: Map<String, Number> }
 */
export function addSapProductsToCarts(product, details, design, isDealer, orders, curDesignPrice, isFillin) {
  const isCustomized = hasCustomizations(design);
  const embellishmentList = (isCustomized && curDesignPrice && curDesignPrice.embellishments) ? curDesignPrice.embellishments : [];
  const customStockId = (isCustomized) ? design.id : '';
  const styleColorNumber = product.styleNumber + '-' + product.colorNumber;
  const productListPayload = getStockOrderProductPayload(UUID(), styleColorNumber, product.details.displayName, details.wholesalePrice, details.retailPrice, embellishmentList, [], customStockId, '', [], '', '', isFillin);
  const stockOrderPayload = getStockOrderPayload(null, null, null, null, null, [productListPayload], null);

  if (isDealer) {
    if (orders && orders.length > 0) {
      let promises = [];
      orders.forEach(order => {
        const addProductPromise = new Promise((resolve) => {
          addSapProductToCart(order.id, stockOrderPayload, order.couponCode).then((response) => {
            resolve(response);
          });
        });
        promises.push(addProductPromise);
      });
      return promises;
    }

  } else {
    const getOrdersPromise = getOrders();
    getOrdersPromise.then(result => {

      if (result && result.orders && result.orders.length > 0) {

        const orderId = result.orders[0].id;
        const addProductPromise = addSapProductToCart(orderId, stockOrderPayload);
        addProductPromise.then(result => {
          handleAddProductResponse(result);
        })

      } else {

        const today = new Date();
        const cartName = getFormattedDate(today);
        const createOrderPromise = createCart(cartName);
        createOrderPromise.then(response => {
          const addProductPromise = addSapProductToCart(response.id, stockOrderPayload);
          addProductPromise.then(result => {
            handleAddProductResponse(result);
          });
        });

      }

    })
  }
}

/**
 * Handles request to add JBA product to multiple carts
 * @param orderId
 * @param uniformId
 * @param name
 * @param orders
 * @returns {Array}
 */
export function addJbaProductsToCarts(product, orders) {
  const payload = getCartRequestXML('', '', product.uniformId, '', true, orders);
  const addToCartPromise = AssortmentService.addAssortment('order', payload);
  addToCartPromise.then(result => {
    store.dispatch(showToast("ITEM ADDED TO CART"));
  });
  addToCartPromise.catch(error => {
    console.error('AssormentService.addAssortment (error): ', error);
    store.dispatch(showToast("FAILED TO ADD TO CART"));
  });
}

/**
 * Handles saving stock/custom stock products to cart for consumers and dealers -> then navigates to new cart page
 * @param product
 * @param details
 * @param design (uniformDesign)
 * @param order (cart)
 */
export function addToCartAndNavigate(product, details, design, order) {

  const customStockId = (hasCustomizations(design)) ? design.id : '';
  const styleColorNumber = product.styleNumber + '-' + product.colorNumber;
  const productListPayload = getStockOrderProductPayload(UUID(), styleColorNumber, product.details.displayName, details.wholesalePrice, details.retailPrice, [], customStockId, '', [], '', '', false);
  const stockOrderPayload = getStockOrderPayload(null, null, null, null, null, [productListPayload], null);

  const addProductPromise = addSapProductToCart(+order.id, stockOrderPayload);
  addProductPromise.then(result => {
    window.location = window.location.protocol + '//' + window.location.host + '/en/en_US/p/order,detail?cartId=' + order.id;
  });

}

/**
 *
 * @returns {Promise.<TResult>}
 */
export function getOrders(searchValue) {
  return AssortmentService.getCurrentOrders(getFindOrdersQueryStr(searchValue, true, 0, 0, 'lastUpdated', 'desc', false, false, "ALL"))
    .then(result => {
      return result;
    })
    .catch(error => {
      return error;
    })
}

/**
 * Service call to add a product to a cart(s)
 * @param cartId
 * @param payload
 * @param isCouponCart
 * @returns {Promise.<TResult>}
 */
function addSapProductToCart(cartId, payload, isCouponCart = false) {
  const soldTo = (isCouponCart) ? getConsumerCouponSoldTo() : getUserSoldTo();
  return NtsStockOrderService.addToCart(cartId, payload, soldTo)
    .then(result => {
      return result;
    })
    .catch(error => {
      showMessageModal({
        header: "ERROR ADDING ITEM",
        message: (error.message) ? error.message : "There was an error adding your item. Please try again later."
      });
    });
}

/**
 * Service call to create a new cart
 * @param cartName
 * @param isFillIn
 * @param ordStatId - (optional) Integer for cart status (open quote [20] vs open [2])
 * @returns {Promise.<TResult>}
 */
export function createCart(cartName, isFillIn = false, ordStatId = '') {
  return AssortmentService.createCart(cartName, isFillIn, ordStatId)
    .then(result => {
      return result;
    })
    .catch(error => {
      return error;
    });
}

/**
 * Service call to add a product to the locker
 * @param styleNumber
 * @param colorNumber
 * @param customStockId
 * @returns {Promise.<TResult>}
 */
function addProductToLocker(styleNumber, colorNumber, customStockId, api) {
  return AssortmentService.addAssortment('locker', getCartRequestXML(styleNumber, colorNumber, customStockId, api, false, []))
    .then(result => {
      return result;
    })
    .catch(error => {
      return error;
    });
}

/**
 * Handles adding a product to the locker for consumers and dealers
 * @param styleNumber
 * @param colorNumber
 * @param design
 */
export function addToLocker(styleNumber, colorNumber, design) {

  const customStockId = (hasCustomizations(design)) ? design.id : '';
  let imageURL = design ? (((design.templates || {}).Front || {}).imageUrl) ? ((design.templates || {}).Front || {}).imageUrl : ((design.templates || {}).Back || {}).imageUrl : '';
  imageURL = !imageURL && design ? (((design.templates || {})['Reversible Front'] || {}).imageUrl) ? ((design.templates || {})['Reversible Front'] || {}).imageUrl : ((design.templates || {})['Reversible Back'] || {}).imageUrl : imageURL;
  const addProductPromise = addProductToLocker(styleNumber, colorNumber, customStockId, imageURL);
  addProductPromise.then(result => {
    store.dispatch(showToast("ITEM ADDED TO LOCKER"))
  });

}

/**
 * Service call to complete checkout
 * @param data
 */
export function completeCheckout(data) {
  return CheckoutService.post(data)
    .then(result => {
      return result;
    })
    .catch(error => {
      return error;
    });
}

/**
 * Service call to complete checkout
 * @param data
 */
export function completeQuoteCheckout(data) {
  return CheckoutService.postQuote(data)
    .then(result => {
      return result;
    })
    .catch(error => {
      return error;
    });
}

/**
 * Service call to get Past Orders
 * @param searchFilter (keyword search)
 * @returns {function(*)}
 */
export function getPastOrders(searchFilter) {
  return dispatch => {
    return AssortmentService.getPastOrders(getFindOrdersQueryStr(searchFilter, false, 0, 0, 'lastUpdated', 'desc', true, false, 'ORDERS'))
      .then(result => {
        return dispatch(dispatchLoadPastOrders(result));
      })
      .catch(error => {
        throw(error);
      });
  }
}

function dispatchLoadPastOrders(pastOrderData) {
  // TODO PMENTS-2247: uncomment following logic when ready to release
  // for (const item of pastOrderData.orders) {
  //   if(item.shippings && item.shippings.shipping) {
  //     if(Array.isArray(item.shippings.shipping)) {
  //       let tracking_number = [];
  //      for (const jitem of item.shippings.shipping) {
  //       if(jitem) {
  //         let tracking = jitem.tracking_numbers.split(",");
  //         for (const track of tracking) {
  //           tracking_number.push(track+"^"+jitem.carrier);
  //         }
  //       }
  //      }
  //      item.shippingInfo  = tracking_number.join("|");
  //     }else{
  //       item.shippingInfo  = item.shippings.shipping.tracking_numbers+"^"+item.shippings.shipping.carrier;
  //       item.shippings = [item.shippings.shipping];
  //     }
  //   } else {
  //     item.shippings = [];
  //     item.shippingInfo  = "";
  //   }
  //   if(item.shippingMethods && item.shippingMethods.shippingMethod) {
  //     if (Array.isArray(item.shippingMethods.shippingMethod)) {
  //       let shippingMethod = [];
  //       for (const jitem of item.shippingMethods.shippingMethod) {
  //         if (jitem == "VL") {
  //           shippingMethod.push("Vessel");
  //         } else if (jitem == "AF") {
  //           shippingMethod.push("Air Freight");
  //         }
  //       }
  //       item.shippingDesc = shippingMethod.join(",");
  //     } else {
  //       item.shippingDesc =
  //         item.shippingMethods.shippingMethod == "VL"
  //           ? "Vessel"
  //           : "Air Freight";
  //     }
  //   } else {
  //     item.shippingDesc  = "";
  //   }
  //   if(item.reasonDescriptions && item.reasonDescriptions.reasonDescription) {
  //     if(Array.isArray(item.reasonDescriptions.reasonDescription)) {
  //      item.reasonCode  = item.reasonDescriptions.reasonDescription.join(",");
  //     }else{
  //       item.reasonCode  = item.reasonDescriptions.reasonDescription;
  //     }
  //   } else {
  //     item.reasonCode  = "";
  //   }
  // }
  return {type: types.LOAD_PAST_ORDERS, pastOrderData}
}

/**
 * Service call to get Current Orders
 * @returns {function(*)}
 */
export function getCurrentOrders(searchValue) {
  return dispatch => {
    return getOrders(searchValue).then(result => {
      return dispatch(dispatchLoadCurrentOrders(result));
    });
  }
}

function dispatchLoadCurrentOrders(currentOrderData) {
  return {type: types.LOAD_CURRENT_ORDERS, currentOrderData}
}

/**
 * Service call to get Past Quotes
 * @returns {function(*)}
 */
export function getPastQuotes(searchValue) {
  return dispatch => {
    return AssortmentService.getPastQuotes(getFindOrdersQueryStr(searchValue, false, 0, 0, 'lastUpdated', 'desc', false, false, 'QUOTES'))
    .then(result => {
      return dispatch(dispatchLoadPastQuotes(result));
    })
    .catch(error => {
      throw(error);
    });
  }
}

function dispatchLoadPastQuotes(pastQuoteData) {
  return {type: types.LOAD_PAST_QUOTES, pastQuoteData}
}

/**
 * Service call for simple cart add of an Embodee Uniform
 * @param orderId
 * @param uniformId
 * @param orderName
 */
export function createJbaFillIn(orderId, uniformId, orderName) {
  const createJbaFillInPromise = AssortmentService.createJbaFillIn(orderId, uniformId, orderName);
  createJbaFillInPromise.then(result => {
    if (result && result.response && result.response.id) {
      window.location = window.location.protocol + '//' + window.location.host + '/en/en_US/p/order,detail?cartId=' + result.response.id;
    } else {
      window.location = window.location.protocol + '//' + window.location.host + '/en/en_US/nts/orders';
    }
  }).catch(error => {
    showMessageModal({
      header: "ERROR CREATING ORDER",
      message: "There was an error creating your order, please try again in a few minutes or contact customer support."
    });
  });
}

/**
 * Produces the XML payload for a list of SAP products
 * To be used for consumer - single cart only [ dealer - multi cart use addSapProductsToCarts() above ]
 * @param product
 * @param details
 * @param design
 * @param curDesignPrice
 * @param type -- ORDER or QUOTE
 * @returns {*}
 */
export function addSapProductsForConsumer(product, details, design, curDesignPrice, type) {
  const isCustomized = hasCustomizations(design);
  const embellishmentList = (isCustomized && curDesignPrice && curDesignPrice.embellishments) ? curDesignPrice.embellishments : [];
  const customStockId = (isCustomized) ? design.id : '';
  const styleColorNumber = product.styleNumber + '-' + product.colorNumber;
  const productListPayload = getStockOrderProductPayload(UUID(), styleColorNumber, product.details.displayName, details.wholesalePrice, details.retailPrice, embellishmentList, [], customStockId, '', [], '', '', false);
  if(type === 'ORDER') {
    return AssortmentService.addSapProductsToConsumerOrder([productListPayload]);
  } else {
    return AssortmentService.addSapProductsToConsumerQuote([productListPayload]);
  }
}

/**
 * Displays success/error messaging based on response
 * @param response
 */
export function handleAddProductResponse(response) {
  if(response && response.error) {
    showMessageModal({
      header: "ERROR ADDING ITEM",
      message: (response.message) ? response.message : "There was an error adding your item. Please try again later."
    })
  } else {
    const message = (response.message) ? response.message : "ITEM ADDED TO CART";
    store.dispatch(showToast(message));
  }
}