import React from 'react';
import {ControlLabel, FormControl, FormGroup, HelpBlock} from 'react-bootstrap';

import {NikeSelect} from "./nikeSelect";

// Allowed chars: a-z, A-Z, 0-9
// apostrophe, space, hyphen, period, comma, slash, or hashtag
const TEXT_REGEX = /^[a-zA-Z0-9',.\-#"&\s/]*$/;

export class FieldGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value ? this.props.value : ""
    };

    this.onSelect = this.onSelect.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  getValue() {
    return this.input.value;
  }

  onSelect(e) {
    if (e) {
      this.setState({value: e.value});
    } else {
      this.setState({value: null});
    }
      if (this.props.onChange) {
          this.props.onChange(e);
      }
  }

  onChange(event) {
    let newValue = event.target.value;

    if (this.props.sanitizer) {
      newValue = this.props.sanitizer(newValue);
    }
    if (this.props.validator) {
      if(this.props.validator(event.target.value)) {
        this.setState({value: newValue});
      }
    } else {
      this.setState({value: newValue});
    }

    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  onKeyPress = (e) => {
    if (this.props.disabled) {
      e.preventDefault();
    } else {
      if (e.key === "Enter" && this.props.onEnter) {
        this.props.onEnter();
      }

      if (!TEXT_REGEX.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  onPaste = (e) => {
    if (this.props.disabled ||
        !TEXT_REGEX.test(e.clipboardData.getData("text"))) {
      e.preventDefault();
    }
  };

  render() {
    return (
      <FormGroup controlId={this.props.id} validationState={this.props.validationState ? this.props.validationState(this.state.value) : null}
                 className={this.props.className}>
        <ControlLabel>{this.props.label ? this.props.label : "TEXT"}</ControlLabel>
        {this.props.componentClass === "select" ?
          <NikeSelect
            clearable={this.props.clearable}
            options={this.props.options}
            value={this.state.value}
            style={this.props.style}
            ref={(ref) => this.input = ref}
            onChange={this.onSelect}
          />
          :
          <FormControl
            name={this.props.name}
            style={this.props.style}
            inputRef={(ref) => this.input = ref}
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
            onPaste={this.onPaste}
            disabled={this.props.disabled}
            placeholder={this.props.placeholder}
            type={this.props.type}
            maxLength={this.props.maxLength}
            value={this.state.value}
          />
        }
        {this.props.help && <HelpBlock>{this.props.help}</HelpBlock>}
      </FormGroup>
    );
  }
}
