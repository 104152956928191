import React from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';

import {AppState} from '../../reducers';
import {FieldGroup} from '../fieldGroup';
import {isJbaDesignId, isSapDesignId} from '../../helpers/designHelpers';
import {showCartSelectionModal} from '../../actions/cartSelectorActions';
import {hideFillinHelperModal} from '../../actions/navigationActions';

import {
  embodeeDesignCheckFillinStatus,
  validateFillinPersonalizedStockDesign
} from "../../helpers/fillInUtils";
import {alphaNumericWithDashSanitizer} from "../../helpers/textValidationHelpers";

interface StateProps {
  fillinHelperModalDesignId?: string;
}

interface DispatchProps {
  hideFillinHelperModal: () => void;
}

type Props = StateProps & DispatchProps;

interface State {
  error: string;
  isJba: boolean;
  defaultName: string;
  uniformId: string;
  jbaSrcOrderId: string | null;
}

export class FillInOrder extends React.Component<Props, State> {
  uniformId: FieldGroup | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      error: "",
      isJba: false,
      defaultName: "",
      uniformId: "",
      jbaSrcOrderId: null
    }
  }

  checkAutofillDesignId = () => {
    const urlUD = new URLSearchParams(window.location.search).get('ud');
    if (this.uniformId &&
      this.props.fillinHelperModalDesignId) {
      this.uniformId.setState({ value: this.props.fillinHelperModalDesignId });
      this.props.hideFillinHelperModal();
    } else if(this.uniformId && urlUD && isJbaDesignId(urlUD)) {
      this.uniformId.setState({ value: urlUD });
      this.props.hideFillinHelperModal();
    }
  };

  onSubmit = () => {
    const designId = this.uniformId
      ? this.uniformId.getValue().trim()
      : '';
    if (isSapDesignId(designId)) {
      this.processPersonalizedStockDesign(designId);
    } else if (designId.substring(0, 2).toUpperCase() === "UD") {
      this.processEmbodeeDesign(designId);
    } else {
      this.setState({error: "Not a valid Uniform ID"});
    }
  };

  processEmbodeeDesign = (designId: string) => {
    embodeeDesignCheckFillinStatus(designId)
      .then((result) => {
        if (result.error) {
          this.setState({error: result.error});
          return;
        }
        this.setState({
          error: "",
          isJba: true,
          defaultName: result.order.name,
          jbaSrcOrderId: result.order.id
        });
        showCartSelectionModal({
          isFillIn: true,
          isJba: true,
          defaultName: result.order.name,
          jbaSrcOrderId: result.order.id,
          products: [{
            uniformId: designId
          }]
        });
      })
      .catch(error => {
        this.setState({error: error});
      });
  };

  processPersonalizedStockDesign = (designId: string) => {
    validateFillinPersonalizedStockDesign(designId)
      .then((result) => {
        if (result.error) {
          this.setState({error: result.error.message ? result.error.message : result.error});
          if (result.error.message) {
            console.log(result.error);
          }
          return;
        }

        this.setState({
          error: ""
        });

        showCartSelectionModal({
          isFillIn: true,
          isJba: false,
          defaultName: "",
          products: [{
            selectedStyle: result.selectedStyle,
            productDetails: result.productDetails,
            design: result.design,
            priceDetails: result.priceDetails
          }]
        });

      })
      .catch((error) => {
        this.setState({error: error});
      });
  };

  clearEntry = () => {
    if (this.uniformId) {
      this.uniformId.setState({ value: '' });
    }
  };

  render() {
    return (
      <div className="container nts-fill-in-order-page">
        <div className="header">CREATE FILL-IN CART</div>
        <div>
          <span>
            <FieldGroup
              className="input-area"
              id="uniformId"
              componentClass="text"
              label="UNIFORM ID"
              maxLength="40"
              ref={(instance) => {
                this.uniformId = instance;
                this.checkAutofillDesignId();
              }}
              sanitizer={alphaNumericWithDashSanitizer}
              onEnter={this.onSubmit}
            />
          </span>
          <span>
            <Button type="submit" className="submit-button" bsStyle="primary"
                    onClick={this.onSubmit}>SUBMIT</Button>
          </span>
        </div>

        {this.state.error &&
        <div className="error-message">{this.state.error}</div>
        }
      </div>
    );
  }
}


const mapStateToProps = (state: AppState): StateProps => {
  return {
    fillinHelperModalDesignId: state.navigationState.fillinHelperModal.ud,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<unknown>): DispatchProps => {
  return {
    hideFillinHelperModal: () => dispatch(hideFillinHelperModal()),
  };
};

export const FillInOrderPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FillInOrder);
