import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';
import {EliteOptionsStatusType} from "../components/customColors/customColorsHelper";

export interface CustomColor {
    colorId: number;
    dealershipId: number;
    status: EliteOptionsStatusType;
    colorName: string;
    factoryHexCode: string;
    code: string;
    schoolName: string;
    presentationHexCode: string;
    createdTimeStamp: string;
    updatedTimeStamp: string;
    createdBy: string;
    updatedBy: string;
}

export interface CustomColorsState {
    showAddColorModal: boolean;
    showColorDetailsModal: boolean;
    customColorList: CustomColor[],
    showInactiveColors: boolean;
    selectedColor: CustomColor | null;
}

const initialState: CustomColorsState = {
    showAddColorModal: false,
    showColorDetailsModal: false,
    customColorList: [],
    showInactiveColors: false,
    selectedColor: null
};

interface CustomColorsAction extends Action {
    show: boolean;
    list: [];
    color: CustomColor | null;
}

const customColorsReducer: Reducer<CustomColorsState> = (
    state = initialState,
    incomingAction,
) => {
    const action = incomingAction as unknown as CustomColorsAction;

    switch (action.type) {
        case types.SHOW_CUSTOM_COLOR_ADD_MODAL:
            return {
                ...state,
                showAddColorModal: action.show
            };

        case types.SHOW_CUSTOM_COLOR_DETAILS_MODAL:
            return {
                ...state,
                showColorDetailsModal: action.show
            };

        case types.SET_SELECTED_CUSTOM_COLOR:
            return {
                ...state,
                selectedColor: action.color
            };

        case types.SET_CUSTOM_COLOR_LIST:
            return {
                ...state,
                customColorList: action.list
            };

        case types.SHOW_INACTIVE_CUSTOM_COLORS:
            return {
                ...state,
                showInactiveColors: action.show
            };

        default:
            return state;
    }
};

export default customColorsReducer;