import * as React from "react";
import { dispatchShowToast } from "../../../actions/toastActions";
import { AdminFactoryService } from "../../../api/services/adminFactoryService";
import { AddFactoryShipGroup } from "./AddFactoryShipGroup";
import { AddFactoryShipGroupState, FactoryShipGroupState } from "./adminFactoryShipGroupHelper";
import { RemoveFactoryShipGroup } from "./RemoveFactoryShipGroup";
import { EditShipGroup } from "../edit-ship-group/EditShipGroup";
import { SearchBar } from "./SearchBar";
import { SearchResults } from "./SearchResults";
import { showMessageModal } from "../../../actions/messageModalActions";

export const FactoryShipGroupData: React.FC = () => {
  const initialState: FactoryShipGroupState = {
    results: [],
    error: "",
    message: "Please create ship group to to see results.",
    page: 0,
    size: 10,
    count: 0,
    keyword: '',
    factory:  {id: 0, name: '', code: '', updatedAt: '',priority:'', fcr_comm_cd: 'EDI', fcr_supplier_id: null, fhs_fcr_cd: '' },
    factoryId: 0,
    sort: [{
      id: 'last_mod_tmst',
      desc: true
    }]
  };
  const initialCreateState: AddFactoryShipGroupState = {
    show: false,
    edit: false,
    item: {}
  };
  const initialShipStyleCreateState: AddFactoryShipGroupState = {
    show: false,
    edit: false,
    item: {}
  };
  const [state, setState] = React.useState(initialState);
  const [createState, setCreateState] = React.useState(initialCreateState);
  const [removeState, setRemoveState] = React.useState(initialCreateState);
  const [editStyleState, setEditStyleState] = React.useState(initialCreateState);
  const [loading, setLoading] = React.useState(false);
  

  React.useEffect(()=> {
    const urlSplit = window.location.pathname.split('/'); 
    setLoading(true);
    getData(state.page, state.size, Number(urlSplit[urlSplit.length - 1]), state.keyword, state.sort[0]);
  }, [])

  const getData = async (page: number, size: number, factoryId: number, keyword: string,  sort: any) => {
    const response = await AdminFactoryService.getFactoryShipGroupList(factoryId, `page=${(page + 1)}&pageSize=${size}&search=${keyword}&sortColumn=${sort.id}&sortDesc=${sort.desc ? 'DESC': 'ASC'}`);
    if(response){
      setLoading(false);
      setState(prevState => ({
        ...prevState, 
        results: response.shipGroups,
        page: page,
        count: response.pages.totalCount,
        size: size,
        factory: {
          id: response.factory.team_fcr_id,
          name:  response.factory.team_fcr_nm,
          code: response.factory.fcr_cd,
          priority:response.factory.priority || '',
          fcr_comm_cd: response.fcr_comm_cd,
          fcr_supplier_id: response.fcr_supplier_id,
          fhs_fcr_cd: response.fhs_fcr_cd,
          updatedAt: ''
        },
        factoryId: factoryId
      }))
    }
  }
  
  const handlePagination = (size: number, page: number) => {
    setState(prevState => ({
      ...prevState,
      page: page,
      size: size
    })) 
    if(!loading) {
      setLoading(true);
      getData(page, size, state.factoryId, state.keyword, state.sort[0]);
    }
  }

  const handleDelete = (item: any) => {
    console.log(item)
    setRemoveState(prevState => ({
      ...prevState,
      show: true,
      item: item
    }))
  }

  const updateState = (keyword: string) => { 
    setState(prevState => ({
      ...prevState,
      keyword: keyword,
      page: 0
    }));
  }

  const handleSearch = () => {
    if(!loading){
      setLoading(true);
      getData(0, state.size, state.factoryId, state.keyword, state.sort[0]);
    }
  }
  const handleCreate = () => {
    setCreateState(prevState => ({
      ...prevState,
      edit: false,
      show: true
    })) 
  };

  const handleCloseCreate = () => {
    setCreateState(prevState => ({
      ...prevState,
      show: false
    }))
  }

  const handleCloseEditStyle = () => {
    setEditStyleState(prevState => ({
      ...prevState,
      show: false
    }));
    if(!loading) {
      setLoading(true);
      getData(state.page, state.size, state.factoryId, state.keyword, state.sort[0]);
    }
  }

  const handleSubmit = () => {
    handleCloseCreate();
    dispatchShowToast('Ship group created successfully.');
    getData(state.page, state.size, state.factoryId, state.keyword, state.sort[0]);
  }

  const handleNavigate =  (item: any) => {
    setEditStyleState(prevState => ({
      ...prevState,
      item: item,
      show: true
    }))
  }

  const handleSort = (item: any) => {
    setState(prevState => ({
      ...prevState,
      sort: item
    }))
    getData(state.page, state.size, state.factoryId, state.keyword, item[0]);
  }

  const handleCloseRemove = () => {
    setRemoveState(prevState => ({
      ...prevState,
      show: false
    }))
  }
  const handleRemoveSuccess = (item: any) => {
    AdminFactoryService.deleteFactoryShipGroup(item.team_shp_grp_id).then((response)=>{
       setRemoveState(prevState => ({
        ...prevState,
        show: false
      }))
      dispatchShowToast('Factory ship group deleted successfully.');
      getData(state.page, state.size, state.factoryId, state.keyword, state.sort[0]);
    }).catch((err)=>{  
      // dispatchShowToast(err.message);
      showMessageModal({ header: "DELETE SHIP GROUP ERROR", message: err.message, required: true });
    });
  }

  const handleEdit = (item: any)=>{
    if(state.results.length > 0){
      const results = state.results;
      results[item.index].edit = true;
      setState(prevState => ({
        ...prevState,
        results: results
      }))
    }
  }

  const handleEditChange = (value:any, index:any, field:any) => {
    if(state.results.length > 0){
      const results = state.results;
      results[index][field] = value;
      setState(prevState => ({
        ...prevState,
        results: results
      }))
    }
  }

  const handleUpdate = (item:any, index:any) => {
    if(state.results.length > 0){
      AdminFactoryService.updateFactoryShipGroup(item.team_shp_grp_id, {
        team_shp_grp_nm: item.team_shp_grp_nm,
        team_fcr_id: state.factoryId
      }).then((response)=>{
        dispatchShowToast('Factory ship group updated successfully.');
        const results = state.results;
        results[index].edit = false;
        setState(prevState => ({
          ...prevState,
          results: results
        }))
        getData(state.page, state.size, state.factoryId, state.keyword, state.sort[0]);
      }).catch((err)=>{  
        // dispatchShowToast(err.message);
        showMessageModal({ header: "EDIT SHIP GROUP ERROR", message: err.message, required: true });
      });
    }
  }

  return (   
    <div> 
      <h1>{state.factory.name}</h1>
      <ul className="admin-tabs">
          <li className="selected">SHIP GROUP LIST</li>
      </ul>
      <div className="admin-tabs-content">
        <SearchBar state={state} handleCreate={handleCreate} updateState={updateState} handleSearch={handleSearch} loading={loading}/>
        <AddFactoryShipGroup state={createState} factoryId={state.factoryId} onHide={handleCloseCreate} onSubmitSuccess={handleSubmit}/>
        <SearchResults state={state}  
        handlePagination={handlePagination} 
        handleDelete={handleDelete}  
        handleNavigate={handleNavigate}  
        handleSort={handleSort} 
        handleEdit={handleEdit} 
        handleEditChange={handleEditChange}
        handleUpdate={handleUpdate}
        />
        <EditShipGroup state={editStyleState} onHide={handleCloseEditStyle} />
        <RemoveFactoryShipGroup state={removeState} onHide={handleCloseRemove} onSuccess={handleRemoveSuccess}/>
      </div>
       
    </div>
  );
};
