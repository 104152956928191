import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

export interface FontRulesState {
  [fontType: string]: {
    [boxSize: string]: {
      id: string;
      fontType: string;
      boxSize: string;
    };
  };
}

// TODO: These actions can be separate types later
interface FontRulesAction extends Action {
  fontRules: {
    id: string;
    fontType: string;
    boxSize: string;
  }[];
}

const initialState: FontRulesState = {};

const fontRulesReducer: Reducer<FontRulesState> = (
  state = initialState,
  incomingAction,
) => {
  const action = incomingAction as unknown as FontRulesAction;
  let rules: FontRulesState = {};
  switch (action.type) {
    case types.GET_MAX_CHARACTERS:
      let fontType = action.fontRules[0].fontType;
      let boxSize = action.fontRules[0].boxSize;
      return{
        ...state,
        [fontType]: {
          ...state[fontType],
          [boxSize]: action.fontRules[0]
        }
      };
    case types.GET_ALL_RULES:
      action.fontRules &&
      action.fontRules.forEach &&
      action.fontRules.forEach((rule) => {
        rules = {
          ...rules,
          [rule.fontType]: {
            ...rules[rule.fontType],
            [rule.boxSize]: rule
          }
        };
      });
      return {
        ...state,
        ...rules,
      };
    default:
      return state;
  }
}

export default fontRulesReducer;