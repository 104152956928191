import {AdminAnalyticsResponse} from "../../../api/services/adminReportingService";

export interface AnalyticsState {
    options: QueryOptions;
    filter: OrderFilterOption | QuoteFilterOption;
    toDate: string;
    fromDate: string;
    results: AdminAnalyticsResponse[],
    error: string;
}

export enum AnalyticsReportType {
    ORDERS = 'orders',
    QUOTES = 'quotes',
}

export enum OrderFilterOption {
    DEALER = 'dealer',
    STYLE = 'style',
}

export enum QuoteFilterOption {
    REQUESTOR = 'requestor',
    DEALER = 'dealer',
    STYLE = 'style',
}

export interface QueryOptions {
    [key: string]: string
}

/**
 * Retrieves list of Key-value pairs of query options to show in <select>
 *     key (field name) = value for <option>
 *     value (field value) = display text for <option>
 */
export const getQueryOptions = (type:string) => {
    let options:QueryOptions = {}
    if(type === AnalyticsReportType.ORDERS) {
        options["dealer"] = "dealer";
        options["style"] = "style";
    } else {
        options["requestor"] = "requestor";
        options["dealer"] = "dealer";
        options["style"] = "style";
    }
    return options;
}