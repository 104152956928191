import React from "react";

import { AuthConsumer } from "../providers/authProvider";
import { LoadingPage } from "../../js/components/loadingPage/LoadingPage";

// Uses AuthConsumer context to perform login handling
export const LoginCallback: React.FC<{}> = () => (
    <AuthConsumer>
        {({ signinRedirectCallback }) => {
            signinRedirectCallback();
            return <LoadingPage />;
        }}
    </AuthConsumer>
);
