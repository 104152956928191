import * as React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { AddFactoryState, AdminFactoryResponse } from "./adminFactoryHelper";

interface Props {
    state: AddFactoryState;
    onHide: () => void;
    onSuccess: (item: AdminFactoryResponse) => void
}

export const RemoveFactory: React.FC<Props> = ({
    state,
    onHide,
    onSuccess
}) => {
    return (
        <Modal className="show nts-terms-modal"
            backdrop="static"
            keyboard={false} 
            onHide={onHide} 
            show={state.show}
            bsSize={"medium"} aria-labelledby={"contained-modal-title-lg"}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">
                    REMOVE FACTORY
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to remove {state.item.name}?</p>
            </Modal.Body>
            <Modal.Footer>
                <div className={'container-modal'}>
                    <Button className={'pdp-cart-btn btn btn-default '} bsStyle={null} onClick={onHide}>
                        Cancel
                    </Button>   
                    <Button className={'pdp-cart-btn btn btn-primary'} bsStyle={null} onClick={()=>onSuccess(state.item)}>
                        Remove
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
