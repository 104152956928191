import {AUTH_HEADER_PREFIX, LS_ACCESS_TOKEN} from "../../helpers/constants";
import {
  getImpersonatedUserId,
  isImpersonated
} from "../../helpers/userProfileHelpers";
import {fetchRequest, urlNtsStockOrderServices} from "./index";

export const CouponService = {

  applyCoupon: (couponCode, cartId) => {
    const request = new Request(`${urlNtsStockOrderServices}/coupon/apply/v1/${couponCode}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      },
      body: JSON.stringify({cartId: cartId})
    });
    return fetchRequest(request, true, 'UNITE');
  },

  removeCoupon: (cartId) => {
    const request = new Request(`${urlNtsStockOrderServices}/coupon/remove/v1`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      },
      body: JSON.stringify({cartId: cartId})
    });
    return fetchRequest(request, true, 'UNITE');
  },

  lookupCoupon: (couponCode) => {
    const request = new Request(`${urlNtsStockOrderServices}/coupon/validate/v1/${couponCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      }
    });
    return fetchRequest(request, true, 'UNITE');
  },

  lookupCartCoupon: (couponCode,cartId) => {
    const request = new Request(`${urlNtsStockOrderServices}/coupon/validateCartCoupon/v1/${couponCode}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      },
      body: JSON.stringify({cartId: cartId})
    });
    return fetchRequest(request, true, 'UNITE');
  },

  getCouponByCartId: (cartId) => {
    const request = new Request(`${urlNtsStockOrderServices}/order/coupon-lookup/v1/${cartId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      }
    });
    return fetchRequest(request, true, 'UNITE');
  },

  getCouponByCartIdAndUserId: (payload) => {
    const filter = `?filter=userId(${payload.userId})`;
    const request = new Request(`${urlNtsStockOrderServices}/order/coupon-lookup/v1/${payload.cartId}/${filter}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      }
    });
    return fetchRequest(request, true, 'UNITE');
  },

  submitOrder: (payload) => {
    const enc = encodeURIComponent;
    let query = `?customerTeamName=${enc(payload.customerTeamName)}`;
    query += `&customerType=${enc(payload.customerType)}`;
    query += `&customerCity=${enc(payload.customerCity)}`;
    query += `&customerState=${enc(payload.customerState)}`;
    query += `&phoneNum=${enc(payload.phoneNum)}`;
    const request = new Request(`${urlNtsStockOrderServices}/order/coupon-submit/v1/${query}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      },
      body: JSON.stringify(payload)
    });
    return fetchRequest(request);
  },

  createDealerCouponCart: (couponCode) => {
    const userId = (isImpersonated()) ? getImpersonatedUserId() : null;
    const request = new Request(`${urlNtsStockOrderServices}/order/orders/v1`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      },
      body: JSON.stringify({id:'', name: '', couponCode: couponCode, userId})
    });
    return fetchRequest(request);
  }

};
