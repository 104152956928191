import React from "react";
import { Dropdown, MenuItem } from "react-bootstrap";

import {
    DATE_SLASH_FORMAT,
    DATE_TIME_AMPM_FORMAT,
    getDateTimeAsString
} from "../../../helpers/checkoutHelpers";
import { urlNtsServices } from "../../../api/services";
import { cookies } from "../../../api/services";
import { COOKIE_IMPERSONATED_AS_ADMIN, COOKIE_IMPERSONATED_USER_ID, ENVIRONMENT_PROD } from "../../../helpers/constants";
import { getCookieDomain, getDateObjectMinutesFromNow } from "../../../helpers/uniteHelpers";
import { getEnvironment } from "../../../helpers/browserHelpers";
import { checkForLoggedInUser } from "../../../actions/userProfileActions";

export interface ReportRecord {
    lastUpdated: number;
    orderId: number;
    orderName: string;
    orderStatus: string;
    salesOrderNumber: string;
    purchaseOrderNumber: string;
    uid: string;
    zipExists: boolean;
    teamUserId: string;
}

interface ReportRowWrapper {
    original: ReportRecord
}

// Order statuses that will show the Download Icon
const downloadEnabledStatuses = ["SENT TO FACTORY", "COMPLETED", "ERROR"]

export const reportingTableConfig = [
    {
        Header: 'ORDER ID',
        accessor: 'orderId',
        show: false
    },
    {
        Header: 'ORDER NAME',
        accessor: 'orderName'
    },
    {
        Header: 'SALES ORD #',
        accessor: 'salesOrderNumber'
    },
    {
        Header: 'PO #',
        accessor: 'purchaseOrderNumber'
    },
    {
        Header: 'UID',
        accessor: 'uid'
    },
    {
        Header: 'LAST UPDATED',
        accessor: 'lastUpdated',
        Cell: (rowInfo:ReportRowWrapper) => {
            return (<span>{getDateTimeAsString(new Date(rowInfo.original.lastUpdated), DATE_SLASH_FORMAT, DATE_TIME_AMPM_FORMAT)}</span>);
        }
    },
    {
        Header: 'STATUS',
        accessor: 'orderStatus',
    },
    {
        Header: 'IMPERSONATE / DOWNLOAD',
        accessor: 'zipExists',
        width: 170,
        Cell: (rowInfo:ReportRowWrapper) => {
            return (
                <div style={{ position: 'absolute', width: '150px', display: 'flex', justifyContent: 'center' }}>
                    <Dropdown id='impersonateUser' className='user-impersonate-dropdown-wrapper'>
                        <Dropdown.Toggle bsStyle='link' noCaret>
                            <span className='user-impersonate-icon-no-background'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='user-impersonate-dropdown-menu'>
                            <MenuItem
                                className='user-impersonate-dropdown-option'
                                onClick={() => handleImpersonation(true, rowInfo.original.teamUserId)}
                            >
                                Impersonate as Admin
                            </MenuItem>
                            <MenuItem
                                className='user-impersonate-dropdown-option'
                                onClick={() => handleImpersonation(false, rowInfo.original.teamUserId)}
                            >
                                Impersonate as User
                            </MenuItem>
                        </Dropdown.Menu>
                    </Dropdown>
                    {rowInfo.original.zipExists && downloadEnabledStatuses.includes(rowInfo.original.orderStatus) ?
                        <span className="download-icon" onClick={() => downloadZip(rowInfo.original)}>
                            {rowInfo.original.orderStatus}
                        </span>
                        : <span style={{ width: '25px', height: '25px' }}></span>
                    }
                </div>
            );
        }
    },
]

/**
 * Opens window to perform download of order zip file
 * @param item
 */
const downloadZip = (item:ReportRecord) => {
    if(item.orderId) {
        window.open(`${urlNtsServices}/reporting/order/download_factory_file?orderId=${item.orderId}`);
    }
}

const handleImpersonation = (asAdmin:boolean, teamUserId:string) => {
    const expiryTime = getDateObjectMinutesFromNow(1000);
    const envType = getEnvironment();
    const cookieDomain = getCookieDomain();

    const adminCookieValue = asAdmin ? 'true' : 'false';

    if (envType === ENVIRONMENT_PROD) {
        cookies.set(COOKIE_IMPERSONATED_USER_ID, teamUserId, { domain: cookieDomain, path: '/', secure: true, expires: expiryTime });
        cookies.set(COOKIE_IMPERSONATED_AS_ADMIN, adminCookieValue, { domain: cookieDomain, path: '/', secure: true, expires: expiryTime });
    } else {
        cookies.set(COOKIE_IMPERSONATED_USER_ID, teamUserId, { domain: cookieDomain, path: '/', expires: expiryTime });
        cookies.set(COOKIE_IMPERSONATED_AS_ADMIN, adminCookieValue, { domain: cookieDomain, path: '/', expires: expiryTime });
    }

    checkForLoggedInUser().then(() => console.debug('User impersonated'));
}
