import React from 'react';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';

import {
  dispatchCouponLookupState,
  dispatchDisplayCouponLookupModal,
  dispatchLoadCouponDetails
} from "../../../actions/couponActions";
import {AssortmentService} from "../../../api/services/assortmentService";
import {CouponService} from "../../../api/services/couponService";
import {store} from "../../../../App";
import {showToast} from "../../../actions/toastActions";
import {navigateToCartPage} from "../../../helpers/navbarHelpers";
import {isConsumer, isDealerRole} from "../../../helpers/userProfileHelpers";
import {Spacer} from '../../shared/spacer';
import {getCartNameById} from "../../../helpers/orderHelpers";

export class CouponLookupModalComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      couponEntry: '',
      couponLoaded: false,
      couponDetails: {},
      validEntry: true,
      cartName: ''
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps && nextProps.couponState && !nextProps.couponState.showCouponLookupModal) {
      this.setState({
        couponEntry: '',
        couponLoaded: false,
        error: '',
        cartName: ''
      })
    }
  }

  onHide = () => {
    dispatchDisplayCouponLookupModal(false);
  };

  onCouponLookupClick = () => {
    if(this.state.couponEntry) {
      CouponService.lookupCoupon(this.state.couponEntry.trim()).then(response => {
        if(response && response.error && !response.code.includes('INACTIVE')) {
          this.setState({
            error: response.message,
            couponEntry: ''
          })
        } else {
          const today = new Date();
          const activationDate = new Date(response.data.couponActivationDate);
          this.setState({
            couponLoaded: true,
            couponDetails: {
              code: response.data.couponCode,
              balance: response.data.balanceAmount,
              expirationDate: response.data.couponExpirationDate,
              activationDate: (activationDate && activationDate > today) ? response.data.couponActivationDate : ''
            }
          });
        }
      }).catch(error => {
        this.setState({
          error: (error && error.message) ? error.message : 'There was an issue retrieving your coupon details. Please try again later.'
        });
      });
    } else {
      this.setState({
        error: 'Please enter a valid coupon code'
      });
    }
  };

  onCouponRemoveClick = () => {
    if(isDealerRole()) {
      dispatchCouponLookupState('dealerRemove');
      getCartNameById(this.props.couponState.coupon.cartId).then(response => {
        this.setState({
          cartName: response
        });
      });
    } else {
      CouponService.removeCoupon(this.props.couponState.coupon.cartId).then(response => {
        if(response && !response.error) {
          this.onHide();
          store.dispatch(showToast("COUPON REMOVED"));
          dispatchLoadCouponDetails({});
          navigateToCartPage();
        } else {
          this.setState({
            error: response.message,
            couponEntry: ''
          })
        }
      }).catch(error => {
        this.setState({
          error: (error && error.message) ? error.message : 'There was an issue removing your coupon. Please try again later.'
        });
      })
    }
  };

  onDealerCouponRemoveClick = () => {
    const encodedCartName = encodeURIComponent(this.state.cartName);
    CouponService.removeCoupon(this.props.couponState.coupon.cartId).then(response => {
      if(response && !response.error) {
        AssortmentService.updateCartName(encodedCartName, this.props.couponState.coupon.cartId).then(response => {
          this.onHide();
          store.dispatch(showToast("COUPON REMOVED"));
          dispatchLoadCouponDetails({});
          navigateToCartPage();
        }).catch(e => {
          this.setState({
            error: (e && e.message) ? e.message : 'There was an issue updating your cart. Please try again later.'
          });
        });
      } else {
        this.setState({
          error: response.message,
          couponEntry: ''
        });
      }
    }).catch(error => {
      this.setState({
        error: (error && error.message) ? error.message : 'There was an issue removing your coupon. Please try again later.'
      });
    });
  };

  onCouponApplyClick = () => {
    if(this.state.couponEntry) {
      const cartId = new URLSearchParams(window.location.search).get('cartId');
      CouponService.applyCoupon(this.state.couponEntry.trim(), cartId).then(response => {
        if(response && response.error) {
          this.setState({
            error: response.message,
            couponEntry: ''
          })
        } else {
          this.onHide();
          store.dispatch(showToast("COUPON APPLIED"));
          window.location.reload();
        }
      }).catch(error => {
        this.setState({
          error: (error && error.message) ? error.message : 'There was an issue applying your coupon. Please try again later.'
        });
      });
    } else {
      this.setState({
        error: 'Please enter a valid coupon code.'
      });
    }
  };

  getCartIdFromUrl = () => {
    let isCartPage = window.location.search.includes('order,detail');
    if(isCartPage) {
      //On cart page (url = /en/en_US/p/order,detail?cartId={cartId})
      let search = window.location.search;
      let params = new URLSearchParams(search);
      return params.get('cartId');
    } else {
      //On checkout page (url = /en/en_US/nts/checkout/coupon/{cartId})
      const urlSplit = window.location.pathname.split('/');
      return urlSplit[urlSplit.length - 1];
    }
  };

  getModalImplementation = (state) => {
    switch (state.couponModalType) {
      case 'apply':
        return {
          header: 'APPLY COUPON',
          subheader: 'Please enter coupon code',
          btnText: 'APPLY COUPON',
          btnAction: this.onCouponApplyClick,
          showInput: true,
          showCancelBtn: false
        };
      case 'remove':
        const subHdrText = (isConsumer()) ? 'Are you sure you want to remove your coupon? You will still be able to check out with a credit card.' : 'Are you sure you want to remove your coupon?';
        return {
          header: 'REMOVE COUPON',
          subheader: subHdrText,
          btnText: 'REMOVE COUPON',
          btnAction: this.onCouponRemoveClick,
          showInput: false,
          showCancelBtn: true
        };
      case 'dealerRemove':
        return {
          header: 'REMOVE COUPON',
          subheader: 'Please update your cart name',
          btnText: 'UPDATE',
          btnAction: this.onDealerCouponRemoveClick,
          showInput: false,
          showCancelBtn: false
        };
      default:
        return {
          header: (this.state.couponLoaded) ? 'COUPON INFO' : 'LOOK UP COUPON',
          subheader: 'Please enter coupon code',
          btnText: 'LOOK UP COUPON',
          btnAction: this.onCouponLookupClick,
          showInput: true,
          showCancelBtn: false
        };
    }
  };

  handleCreateCart = () => {
    CouponService.createDealerCouponCart(this.state.couponEntry.trim()).then(response => {
      if(response && response.error) {
        this.setState({
          error: response.message,
          couponEntry: ''
        })
      } else {
        this.onHide();
        store.dispatch(showToast("COUPON CART CREATED"));
      }
    }).catch(error => {
      this.setState({
        error: (error && error.message) ? error.message : 'There was an issue creating your cart. Please try again later.'
      });
    });
  };

  render() {

    const inputClass = (this.state.error) ? 'nts-coupon-input error' : 'nts-coupon-input';
    const {header, subheader, btnText, btnAction, showInput, showCancelBtn} = this.getModalImplementation(this.props.couponState);

    return (
      <Modal className="show nts-coupon-modal" show={this.props.couponState.showCouponLookupModal} onHide={this.onHide}>

        <Modal.Header closeButton>
          <div className="nts-modal-header">{header}</div>
        </Modal.Header>

        <Modal.Body>

          {!this.state.couponLoaded &&
            <div className="nts-coupon-lookup-entry">
              <div className="nts-modal-subheader">{subheader}</div>
              {this.state.error && <div className="nts-coupon-error">{this.state.error}</div>}
              {showInput &&
                <input type="text" className={inputClass} value={this.state.couponEntry}
                  onChange={e => this.setState({couponEntry: e.target.value }) }
                  onKeyPress={e => {if(e.key === 'Enter'){ btnAction() }}}
                />
              }
              {showCancelBtn && <button type="button" className="btn btn-default" style={{marginBottom:"15px"}} onClick={this.onHide}>Cancel</button>}
              {isDealerRole() && this.state.cartName && <input type="text" className={inputClass} value={this.state.cartName} onChange={e => this.setState({cartName: e.target.value }) } />}
              <button type="button" className="btn btn-primary" onClick={btnAction}>{btnText}</button>
            </div>
          }

          {this.state.couponLoaded && this.state.couponDetails &&
            <div className="nts-coupon-lookup-details">
              {this.state.couponDetails.code && <p className="nts-coupon-detail"><strong>Coupon Code:</strong> {this.state.couponDetails.code}</p>}
              {this.state.couponDetails.balance && <p className="nts-coupon-detail"><strong>Balance:</strong> {this.state.couponDetails.balance.toLocaleString("en-US", {style: "currency", currency: "USD"})}</p> }
              {this.state.couponDetails.activationDate && <p className="nts-coupon-detail"><strong>Activation Date:</strong> {this.state.couponDetails.activationDate}</p>}
              {this.state.couponDetails.expirationDate && <p className="nts-coupon-detail"><strong>Expiration Date:</strong> {this.state.couponDetails.expirationDate}</p>}
              <Spacer size={30}/>
              <button type="button" className={`btn ${isDealerRole()?'btn-default':'btn-primary'}`} onClick={this.onHide}>DONE</button>
              <Spacer size={15}/>
              {isDealerRole() && <button type="button" className="btn btn-primary" disabled={this.state.couponDetails.activationDate} onClick={this.handleCreateCart}>CREATE COUPON CART</button>}
            </div>
          }

        </Modal.Body>

      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    couponState: state.couponState
  }
};

export const CouponLookupModal = connect(
    mapStateToProps
)(CouponLookupModalComponent);
