import {calculateEndpointType} from './common';

//TODO: Could be combined with apiBaseUrl as some of the code is the same.
//TODO: Update with atOnce urls for other environements
const endpointTypeToUrl = {
  local: 'http://localhost.nikedev.com/services/atonce',
  dev: 'https://ntsdev.nikedev.com/services/atonce',
  qa: 'https://ntsqa.nikedev.com/services/atonce',
  stg: 'https://ntsstage.nike.com/services/atonce',
  uat: 'https://ntsuat.nike.com/services/atonce',
  prod: 'https://niketeam.nike.com/services/atonce'
};

export default function atOnceBaseUrl(location) {
  const type = calculateEndpointType(location, 'prod');
  return endpointTypeToUrl[type];
}
