import {fetchRequest, urlEmbodeeServices} from "./index";

export const EmbodeeService = {

  getInspirationsByStyle: (styleNumber) => {
    let url = `${urlEmbodeeServices}/builder/ud/list/${styleNumber}/single`;
    const request = new Request(url, {
      method: 'GET',
      headers: {'Content-Type': 'application/xml', 'Accept': 'text/html,application/xhtml+xml,application/xml'}
    });
    return fetchRequest(request);
  }

};