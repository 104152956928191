import {fetchRequestSimple, urlNtsServices} from "./index";
import {LS_ACCESS_TOKEN} from "../../helpers/constants";

export const CheckoutService = {

  post: (data) => {
    let esc = encodeURIComponent;
    let query = Object.keys(data.params)
    .map(k => esc(k) + '=' + esc(data.params[k]))
    .join('&');
    let url = `${urlNtsServices}/assortment/order/orderNew/submit?${query}`;
    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify(data.body),
      headers: {
        'Content-Type': "application/json",
        'Accept': "application/json",
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      }
    });
    return fetchRequestSimple(request)
    .then(response => {
      return response;
    }).catch(error => {
      return error;
    });
  },

  postQuote: (data) => {
    let url = `${urlNtsServices}/quote/${data.params.cartId}`;
    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify(data.body),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      }
    });
    return fetchRequestSimple(request)
    .then(response => {
      return response;
    }).catch(error => {
      return error;
    });
  }

};