import * as React from "react";
import ReactTable from "react-table";
import { formatIsoDateString } from "../../../helpers/checkoutHelpers";
import { FactoryShipGroupState } from "./adminFactoryShipGroupHelper";

interface Props {
  state: FactoryShipGroupState;
  handleNavigate: (item: any) => void;
  handleSort: (item: any) => void;
  handlePagination: (page: number, size: number) => void;
  handleDelete: (item: any) => void;
  handleEdit: (item: any) => void;
  handleEditChange: (value: any, index: any, field: any) => void;
  handleUpdate: (item: any, index: any) => void;
}

export const SearchResults: React.FC<Props> = ({ state, handlePagination, handleSort, handleNavigate, handleDelete, handleEdit, handleEditChange, handleUpdate }) => {
  return (
    <div className="nts-tab-search-results">
      <ReactTable
        className="nts-table"
        data={state.results}
        noDataText={state.message}
        loading={false}
        pages={Math.ceil(state.count / state.size)}
        page={state.page}
        pageSize={state.size}
        manual={true}
        sortable={true}
        sorted={state.sort}
        onSortedChange={(newSorted, column, shiftKey) => {
          handleSort(newSorted)
        }}
        onPageChange={(page: number) => {
          handlePagination(state.size, page);
        }}
        onPageSizeChange={(newPageSize: number, newPage: number) => {
          handlePagination(newPageSize, 0);
        }}
        columns={[
          {
            Header: "GROUP ID",
            accessor: "team_shp_grp_id",
            show: false,
          },
          {
            Header: "SHIP GROUP",
            accessor: "team_shp_grp_nm",
            Cell: (rowInfo: any) => {
              if (rowInfo.original.edit) {
                return (
                  <div className="nts-factory-row-wrapper">
                    <input type="text" maxLength={30} value={rowInfo.original.team_shp_grp_nm} onChange={(event) => handleEditChange(event.target.value, rowInfo.index, 'team_shp_grp_nm')} />
                    <button className={'pdp-cart-btn btn btn-primary mr-15'} onClick={() => handleUpdate(rowInfo.original, rowInfo.index)}>
                      Save
                    </button>
                  </div>
                )
              } else {
                return (
                  <div className="nts-factory-row-wrapper">
                    <span>{rowInfo.original.team_shp_grp_nm}</span>
                    <span className="edit-icon" onClick={() => handleEdit(rowInfo)}></span>
                  </div>
                );
              }
            },
          },
          {
            Header: "NO OF STYLES",
            accessor: "number_of_style",
          },
          {
            Header: "LAST UPDATED",
            accessor: "last_mod_tmst",
            Cell: (rowInfo: any) => formatIsoDateString(rowInfo.original.last_mod_tmst),
          },
          {
            Header: "ACTION",
            accessor: "",
            Cell: (rowInfo: any) => {
              return (
                <div style={{ position: "relative" }}>
                  <span className="delete-icon icon" onClick={() => handleDelete(rowInfo.original)}></span>
                  <span className="cog-icon icon" onClick={() => handleNavigate(rowInfo.original)}></span>
                </div>
              );
            },
          },
        ]}
        minRows={0}
        style={{ height: "auto" }}
      />
    </div>
  );
};
