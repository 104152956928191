import React from 'react';
import {connect} from 'react-redux';
import {dispatchDisplayCouponLookupModal} from "../../../actions/couponActions";
import {isCouponBarSupportedPage} from "../../../helpers/couponHelpers";
import {isConsumer} from "../../../helpers/userProfileHelpers";
import {CouponBalanceTooltip} from "./couponBalanceInfoTooltip";

export class CouponBarClass extends React.Component {

  removeCoupon = () => {
    dispatchDisplayCouponLookupModal(true, 'remove');
  };

  getRemainingBalanceInfo = () => {
    const couponBalance = (isNaN(this.props.couponState.coupon.effectiveBalanceAmount)) ? 0 : this.props.couponState.coupon.effectiveBalanceAmount;
    return {
      balanceColor: (couponBalance >= 0) ? 'green' : 'red',
      balanceValue: couponBalance.toLocaleString("en-US", {style: "currency", currency: "USD"})
    }
  };

  render() {
    const hasCouponDetails =
        this.props.couponState.coupon &&
        Object.keys(this.props.couponState.coupon).length > 0 &&
        ((this.props.couponState.coupon.code && this.props.couponState.coupon.balance) ||
        (this.props.couponState.coupon.code && this.props.couponState.coupon.error)) &&
        isCouponBarSupportedPage();

    const {balanceColor, balanceValue} = (hasCouponDetails) ? this.getRemainingBalanceInfo() : {balanceColor:null, balanceValue:null};

    if(hasCouponDetails) {
      return (
          <div className="nts-coupon-bar">
            <span className="nts-coupon-bar-remove-x" onClick={this.removeCoupon}>&#10005;</span>
            <span className="nts-coupon-bar-code">REDEEM COUPON - {this.props.couponState.coupon.code.toUpperCase()}</span>
            <span className="nts-coupon-label-spacer xs-hide">&nbsp;</span>
            {this.props.couponState.coupon.error &&
              <span className="nts-coupon-bar-error">{this.props.couponState.coupon.error}</span>
            }
            {!this.props.couponState.coupon.error &&
              <span>
                <span className="nts-coupon-bar-expire">Exp {this.props.couponState.coupon.expirationDate}</span>
                <span className="nts-coupon-label-spacer">&nbsp;</span>
                {balanceColor === 'red' && <CouponBalanceTooltip />}
                <span className="nts-coupon-bar-balance">Remaining Balance: <span style={{color:balanceColor}}>{balanceValue}</span></span>
              </span>
            }
            {isConsumer() && <span className="nts-coupon-bar-remove-link xs-hide" onClick={this.removeCoupon}>Remove Coupon</span>}
          </div>
      );
    } else {
      return null;
    }
  }

}

const mapStateToProps = state => {
  return {
    couponState: state.couponState
  }
};

export const CouponBar = connect(
    mapStateToProps
)(CouponBarClass);
