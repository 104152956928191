import {ColorForm} from "./addColor/AddCustomColorModal";
import {CustomColor} from "../../reducers/customColorsReducer";
import {HEX_COLOR_PATTERN_REGEX} from "../../helpers/constants";

export const ERROR_NETWORK_CALL = "Unable to obtain colors. Please try again.";
export const ERROR_NO_RESULTS = "No colors found. Please add a custom color.";
export const ERROR_NO_DEALERSHIP = "Unable to obtain dealership. Please log in / impersonate again.";
export const ERROR_MISSING_FORM_FIELDS = "Please fill out the missing items.";
export const ERROR_ADD_COLOR_NETWORK = "Unable to call add color service. Please try again.";
export const ERROR_ADD_COLOR_GENERIC = "Error while creating new color. Please try again";
export const ERROR_UPDATE_COLOR_GENERIC = "Error while updating color. Please try again";
export const CUSTOM_COLOR_DB_PREFIX = "CSTM_COLOR#";

export enum EliteOptionsServiceType {
    CUSTOM_FONT = 'CUSTOM_FONT',
    CUSTOM_COLOR = 'CUSTOM_COLOR',
}

export enum EliteOptionsStatusType {
    ACTIVE = 'A',
    INACTIVE = 'I',
}

export interface GetCustomColorsPayload {
    pageSize: number;
    type: EliteOptionsServiceType;
    offset?: string;
    pageDirection: string;
}

export interface UpdateColorPayload {
    colorId: number;
    dealershipId: string;
    colorName: string;
    schoolName: string;
    status: EliteOptionsStatusType;
    code: string;
    factoryHexCode: string;
    presentationHexCode: string;
}

export const DEFAULT_GET_COLORS_PAYLOAD: GetCustomColorsPayload = {
    pageSize: 1000,
    type: EliteOptionsServiceType.CUSTOM_COLOR,
    pageDirection: "DESC"
}

export interface AddColorFormErrors {
    name: boolean;
    school: boolean;
    colorCode: boolean;
    factoryHexCode: boolean;
    presentationHexCode: boolean;
    displayMsg: string;
    numErrors: number;
}

export const defaultAddColorFormErrors: AddColorFormErrors = {
    name: false,
    school: false,
    colorCode: false,
    factoryHexCode: false,
    presentationHexCode: false,
    displayMsg: '',
    numErrors: 0,
}

export const getAddColorPayload = (form:ColorForm, dealershipId:string) => {
    return {
        status: (form.active) ? EliteOptionsStatusType.ACTIVE : EliteOptionsStatusType.INACTIVE,
        dealershipId: parseInt(dealershipId),
        colorName: form.name,
        schoolName: form.school,
        code: form.colorCode,
        factoryHexCode: form.factoryHexCode,
        presentationHexCode: form.presentationHexCode,
    }
}

export const getUploadColorPayload = (color: CustomColor, dealershipId: string) => {
    return {
        colorId: color.colorId,
        dealershipId: dealershipId,
        colorName: color.colorName,
        schoolName: (color.schoolName) ? color.schoolName : '',
        status: color.status,
        code: (color.code) ? color.code : '',
        factoryHexCode: color.factoryHexCode,
        presentationHexCode: color.presentationHexCode,
    }
}

export const checkAddColorsFormForErrors = (form:ColorForm) => {
    let errors = {...defaultAddColorFormErrors};
    let numErrors = 0;
    if(!form.name || form.name.trim().length === 0 || form.name.trim().length > 30 ) {
        errors.name = true;
        numErrors++;
    }
    if(!form.colorCode || form.colorCode.trim().length === 0 || form.colorCode.trim().length > 3) {
        errors.colorCode = true;
        numErrors++;
    }
    if(!form.factoryHexCode || form.factoryHexCode.trim().length ===0 || form.factoryHexCode.trim().length > 7 || !HEX_COLOR_PATTERN_REGEX.test(form.factoryHexCode)) {
        errors.factoryHexCode = true;
        numErrors++;
    }
    if(!form.presentationHexCode || form.presentationHexCode.trim().length === 0 || form.presentationHexCode.trim().length > 7 || !HEX_COLOR_PATTERN_REGEX.test(form.presentationHexCode)) {
        errors.presentationHexCode = true;
        numErrors++;
    }
    if(numErrors > 0) {
        errors.displayMsg = ERROR_MISSING_FORM_FIELDS;
        errors.numErrors = numErrors;
    }
    return errors;
}