import{Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';
import {ENVIRONMENT_LOCAL} from "../helpers/constants";

export interface NavigationState {
  dealerState: {
    value: string;
    label: string;
  } | null;
  environment: string;
  display: {
    account: boolean;
    artwork: boolean;
    cartIcon: boolean;
    couponLookup: boolean;
    dealerLocator: boolean;
    dealerCouponLookup: boolean;
    fillin: boolean;
    help: boolean;
    locker: boolean;
    login: boolean;
    orderDeadlines: boolean;
    pastOrders: boolean;
    profile: boolean;
    pastQuotes: boolean;
    trackIt: boolean;
    salesTools: boolean;
  };
  fillinHelperModal: {
    show: boolean;
    mode: 'fill in' | 'update design' | 'navbar search';
    ud?: string;
    isInLocker: boolean;
    cartId?: string;
  };
}

const defaultState: NavigationState = {
  dealerState: null,
  environment: ENVIRONMENT_LOCAL,
  display: {
    account: false,
    artwork: false,
    cartIcon: false,
    couponLookup: false,
    dealerLocator: true,
    dealerCouponLookup: false,
    fillin: false,
    help: true,
    locker: false,
    login: true,
    orderDeadlines: false,
    pastOrders: false,
    profile: false,
    pastQuotes: false,
    trackIt: false,
    salesTools: false
  },
  fillinHelperModal: {
    show: false,
    mode: 'fill in',
    isInLocker: false,
  },
};

// TODO: These actions can be separate types later
interface NavigationAction extends Action {
  environment: string;
  state: NavigationState['display'] & NavigationState['dealerState'];
  mode: NavigationState['fillinHelperModal']['mode'];
  ud: string;
  isInLocker: boolean;
  cartId?: string;
}

const navigationReducer: Reducer<NavigationState> = (
  state = defaultState,
  incomingAction
) => {
  const action = incomingAction as unknown as NavigationAction;

  switch (action.type) {
    case types.PRE_POPULATE_DEALER_STATE:
      return {
        ...state,
        dealerState: action.state
      };

    case types.LOAD_NAV_STATE:
      return {
        ...state,
        display: action.state
      };

    case types.CLEAR_NAV_STATE:
      return {...defaultState};

    case types.SET_ENVIRONMENT:
      return {
        ...state,
        environment: action.environment
      };

    case types.SHOW_FILLIN_HELPER_MODAL:
      return {
        ...state,
        fillinHelperModal: {
          ...state.fillinHelperModal,
          show: true,
          mode: action.mode,
          ud: action.ud,
          isInLocker: action.isInLocker,
          cartId: action.cartId,
        },
      };

    case types.HIDE_FILLIN_HELPER_MODAL:
      return {
        ...state,
        fillinHelperModal: {
          ...defaultState.fillinHelperModal,
        },
      };
      
    default:
      return state;
  }
};

export default navigationReducer;
