import * as React from 'react';

import { ReactComponent as CheckBlank } from '../../../img/SVG/check-blank.svg';
import { ReactComponent as CheckFilled } from '../../../img/SVG/check-filled.svg';

interface Props {
    checked: boolean;
    onClick: () => void;
    className?: string;
}

export const Checkbox: React.FC<Props> = ({
    checked,
    className,
    onClick,
}) => {
    const CheckIcon = checked ? CheckFilled : CheckBlank;
    return (
        <CheckIcon className={className} onClick={onClick} />
    );
};
