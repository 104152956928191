import React from 'react';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';

import {LockerService} from '../../api/services/lockerService';
import {
    checkDesignSearch,
    parseLockerItems,
    parseFilters,
} from './lockerPageHelper';
import {loadFilters, appendLockerItems} from '../../actions/lockerActions';
import {showFillinHelperModal} from '../../actions/navigationActions';
import {startFullLoadingSpinner, endFullLoadingSpinner} from '../../actions/loadingSpinnerActions';
import {Filter, LockerItem} from '../../reducers/lockerReducer';

export const useGetLocker = () => {
    const dispatch = useDispatch();

    const getLocker = async (pageStart: number, filter: Filter | null) => {
        startFullLoadingSpinner();
        let setId, sport;
        if(filter) {
            setId = filter.value !== '' && filter.isSet ? filter.value : undefined;
            sport = filter.value !== '' && !filter.isSet ? filter.value : undefined;
        }
        const response = await LockerService.getLocker(pageStart, setId, sport)
            .catch((e: unknown) => console.error('Error fetching locker', e));

        if (response) {
            const { items, totalRecords } = parseLockerItems(response);
            dispatch(appendLockerItems(items, totalRecords));
            if (!filter || filter.value === '') {
                const { sets, sports } = parseFilters(response);
                dispatch(loadFilters(sets, sports));
            }
        }
        endFullLoadingSpinner();
    };

    return { getLocker };
};

export const useIntersectionObserver = (elementRef: React.RefObject<Element>) => {
    const [entry, setEntry] = React.useState<IntersectionObserverEntry>();

    const updateEntry = ([e]: IntersectionObserverEntry[]) => {
        setEntry(e);
    };

    React.useEffect(() => {
        const node = elementRef.current;
        const hasIOSupport = !!window.IntersectionObserver;

        if (!node || !hasIOSupport) {
            console.error('Invalid target or IntersectionObserver not supported');
            return;
        }

        const observerParams: IntersectionObserverInit = {
            root: null,
            rootMargin: '0px',
            threshold: 1,
        };
        const observer = new IntersectionObserver(updateEntry, observerParams);

        observer.observe(node);

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef]);

    return entry;
};

const MIN_DESKTOP_WIDTH = 993;
const getIsMobile = (width: number) => width < MIN_DESKTOP_WIDTH;

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = React.useState(getIsMobile(window.innerWidth));

    React.useEffect(() => {
        const handleWindowResize = () => setIsMobile(getIsMobile(window.innerWidth));

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return isMobile;
};

export const useHandleFillinHelper = () => {
    const dispatch = useDispatch();

    const handleFillinHelper = async (item: LockerItem) => {
        if (item.productType !== 'jba') {
            return false;
        }

        const designSearchData = await checkDesignSearch(item.uniformDesignId);
        if (designSearchData.belongsToPastOrder) {
            dispatch(showFillinHelperModal('fill in', item.uniformDesignId));
            return true;
        } else if (designSearchData.cartId) {
            dispatch(showFillinHelperModal('update design', item.uniformDesignId));
            return true;
        }

        return false;
    };

    return { handleFillinHelper };
};

/**
 * Handles navigation between react pages or outside
 */
export const useNavigateTo = () => {
    const dispatch = useDispatch();

    const navigateTo = (url: string) => {
        if (url.includes('/nts')) {
            dispatch(push(url));
        } else {
            window.location.href = url;
        }
    };

    return navigateTo;
};
