import React from "react";
import {Row, Col} from 'react-bootstrap';

// helpers/utils
import {history} from "../../store";
import {getRetailerConfig, isRetailExperience} from "../../helpers/retailHelpers";
import {getAkamaiImageBaseUrl} from "../../api/config/ntsDownloadsBaseUrl";
import {mainLanding} from "../../../content/mainLandingPage";

// components
import {MoreOptionsRow} from "./shared/moreOptionsRow";
import {NtsLink} from "../shared/ntsLink";
import {NtsLazyImage} from "../shared/ntsLazyImage/ntsLazyImage";

const imageUrl = getAkamaiImageBaseUrl();

export class MainLandingPage extends React.Component {

  componentWillMount() {
    if(isRetailExperience()) {
      history.push(getRetailerConfig().homePagePath);
    }
  }

  render() {

    /*const mobileBannerStyle = {backgroundImage: 'url('+urlAkamaiContent+'nike-blm-banner-mobile.jpg)'};
    const desktopBannerStyle = {backgroundImage: 'url('+urlAkamaiContent+'nike-blm-banner.jpg)'};*/

    return (
      <div id="nts-main-landing" className="landing">
        <div className="container">
          <Row style={{position:"relative"}}>
            <Col xsHidden sm={12}>
              <h1 className="header">{mainLanding.header.title}</h1>
            </Col>
          </Row>

          {/* ===( BANNER VIDEO CONTAINER )===*/}
          {/*<Row>
            <Col xs={12} className="nts-video-container">
              <h2 className="sub-header">THIS TEAM WINS. EVERYONE WINS.</h2>
              <div className="nts-video-iframe">
                <iframe width="853" height="480"
                        src="https://www.youtube.com/embed/S29GZOR-k9U"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
              </div>
            </Col>
          </Row>*/}

          {/*===( BANNER IMAGE CONTAINER )===*/}

          {/*<Row className="nts-homepage-message-section">*/}
            {/*<Col xs={12} className="nts-homepage-message-banner">
              <a target="_blank" rel="noopener noreferrer" href="https://purpose.nike.com/how-we-stand-up-for-equality">
                <Jumbotron className="primary-banner desktop" alt={'You Cant Stop Us Campaign'} style={desktopBannerStyle}></Jumbotron>
                <Jumbotron className="primary-banner mobile" alt={'You Cant Stop Us Campaign'} style={mobileBannerStyle}></Jumbotron>
                <button className="btn btn-default">Learn More</button>
              </a>
            </Col>*/}
            {/*<Col xs={12} className="nts-join-us-message-container">
              <h1>YOU CAN'T STOP US</h1>
              <p>There's a reason we love comeback stories. We see ourselves in them — our resolve, our grit, our hope. Now, more than ever, we need these stories to remind us what we're capable of. And by becoming a Nike Member, we can create our incredible comeback stories together.</p>
              <div className="cta-container">
                <a href="https://www.nike.com/you-cant-stop-us" rel="noopener noreferrer" target="_blank" className="cta-button">JOIN US</a>
              </div>
            </Col>*/}
          {/*</Row>*/}

          {/*<div className="horiz-rule"></div>*/}

          <Row>
            <Col xs={12} smHidden mdHidden lgHidden>

              <h1 className="header">{mainLanding.header.title}</h1>
            </Col>
            <Col xs={12}>
              <h2 className="sub-header">{mainLanding.header.subtitle}</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} mdPull={2} md={8} mdPush={2} className="nts-instruction-flow">
              <Row>
                <Col xs={6} md={3} className="nts-instruction-box box-1">
                  <div className="number-icon">{mainLanding.instructionBox.instruct1.step}</div>
                  <div className="instruction-title">{mainLanding.instructionBox.instruct1.title}</div>
                  <div className="instruction-detail">{mainLanding.instructionBox.instruct1.desc}</div>
                </Col>
                <Col xs={6} md={3} className="nts-instruction-box box-2">
                  <div className="number-icon">{mainLanding.instructionBox.instruct2.step}</div>
                  <div className="instruction-title">{mainLanding.instructionBox.instruct2.title}</div>
                  <div className="instruction-detail">{mainLanding.instructionBox.instruct2.desc}</div>
                </Col>
                <Col xs={6} md={3} className="nts-instruction-box box-3">
                  <div className="number-icon">{mainLanding.instructionBox.instruct3.step}</div>
                  <div className="instruction-title">{mainLanding.instructionBox.instruct3.title}</div>
                  <div className="instruction-detail">{mainLanding.instructionBox.instruct3.desc}</div>
                </Col>
                <Col xs={6} md={3} className="nts-instruction-box box-4">
                  <div className="number-icon">{mainLanding.instructionBox.instruct4.step}</div>
                  <div className="instruction-title">{mainLanding.instructionBox.instruct4.title}</div>
                  <div className="instruction-detail">{mainLanding.instructionBox.instruct4.desc}</div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={3} className="nts-cta-container">
              <NtsLazyImage src={`${imageUrl}${mainLanding.stockUniformsBlock.image.src}`} alt={mainLanding.stockUniformsBlock.image.altText} />
              <p className="cta-header">{mainLanding.stockUniformsBlock.header}</p>
              <p className="cta-desc">{mainLanding.stockUniformsBlock.description}</p>
              <NtsLink to={mainLanding.stockUniformsBlock.cta.route} className="cta-button">{mainLanding.stockUniformsBlock.cta.text}</NtsLink>
            </Col>
            <Col xs={6} sm={3} className="nts-cta-container">
              <NtsLazyImage src={`${imageUrl}${mainLanding.btoUniformsBlock.image.src}`} alt={mainLanding.btoUniformsBlock.image.altText} />
              <p className="cta-header">{mainLanding.btoUniformsBlock.header}</p>
              <p className="cta-desc">{mainLanding.btoUniformsBlock.description}</p>
              <NtsLink to={mainLanding.btoUniformsBlock.cta.route} className="cta-button">{mainLanding.btoUniformsBlock.cta.text}</NtsLink>
            </Col>
            <Col xs={6} sm={3} className="nts-cta-container">
              <NtsLazyImage src={`${imageUrl}${mainLanding.graphicTeesBlock.image.src}`} alt={mainLanding.graphicTeesBlock.image.altText} />
              <p className="cta-header">{mainLanding.graphicTeesBlock.header}</p>
              <p className="cta-desc">{mainLanding.graphicTeesBlock.description}</p>
              <NtsLink to={mainLanding.graphicTeesBlock.cta.route} className="cta-button">{mainLanding.graphicTeesBlock.cta.text}</NtsLink>
            </Col>
            <Col xs={6} sm={3} className="nts-cta-container">
              <NtsLazyImage src={`${imageUrl}${mainLanding.coachBlock.image.src}`} alt={mainLanding.coachBlock.image.altText} />
              <p className="cta-header">{mainLanding.coachBlock.header}</p>
              <p className="cta-desc">{mainLanding.coachBlock.description}</p>
              <NtsLink to={mainLanding.coachBlock.cta.route} className="cta-button">{mainLanding.coachBlock.cta.text}</NtsLink>
            </Col>
          </Row>
  
          <div className="horiz-rule"></div>
          
          <MoreOptionsRow content={mainLanding} />
          
        </div>
      </div>
    );
  }
}