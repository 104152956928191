import * as types from './actionTypes';
import {ColorsService} from "../api/services/templateApiService";

export function getAllColors() {
  return dispatch => {
    return ColorsService.get().then(colors => {
      dispatch({type: types.LOAD_COLORS, colors});
    }).catch(error => {
      throw(error);
    });
  };
}
