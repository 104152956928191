import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

export interface OrdersState {
  selectedView: string;
  pastOrderData: {
    orders: unknown[];
    searchKey?: string;
    searchValue?: string;
  };
  currentOrderData: {
    orders: unknown[];
    searchKey?: string;
    searchValue?: string;
  };
  pastQuoteData: {
    quotes: unknown[];
    searchKey?: string;
    searchValue?: string;
  };
}

const initialState: OrdersState = {
  selectedView: 'CURRENT',
  pastOrderData: {
    orders: [],
    searchKey: 'ORDER_NAME',
    searchValue: ''
  },
  currentOrderData: {
    orders: [],
    searchKey: 'ORDER_NAME',
    searchValue: ''
  },
  pastQuoteData: {
    quotes: [],
    searchKey: 'ORDER_NAME',
    searchValue: ''
  }
};

// TODO: These actions can be separate types later
interface OrdersAction extends Action {
  pastOrderData: {
    orders: unknown[];
  };
  currentOrderData: {
    orders: unknown[];
  };
  pastQuoteData: {
    orders: unknown[];
  };
}

const ordersReducer: Reducer<OrdersState> = (
  state = initialState,
  incomingAction
) => {
  const action = incomingAction as unknown as OrdersAction;

  switch (action.type) {
    case types.LOAD_PAST_ORDERS:
      return {
        ...state,
        pastOrderData: {
          orders: action.pastOrderData.orders
        }
      };

    case types.LOAD_CURRENT_ORDERS:
      return {
        ...state,
        currentOrderData: {
          orders: action.currentOrderData.orders
        }
      };

    case types.LOAD_PAST_QUOTES:
      return {
        ...state,
        pastQuoteData: {
          quotes: action.pastQuoteData.orders
        }
      };
    default:
      return state;
  }
};

export default ordersReducer;
