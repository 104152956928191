import {Reducer} from 'redux';

import * as types from '../actions/actionTypes';

// TODO: Use a boolean?
export interface LoadingSmallSpinnerState {
  // smallLoadingSpinner uses 0 for false, 1 for true
  smallLoadingSpinner: number;
}

const initialState: LoadingSmallSpinnerState = {
  smallLoadingSpinner: 0
};

const loadingSmallSpinnerReducer: Reducer<LoadingSmallSpinnerState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.START_SMALL_LOADING_SPINNER:
      return {
        ...state,
        smallLoadingSpinner: state.smallLoadingSpinner + 1
      };

    case types.END_SMALL_LOADING_SPINNER:
      let newNumber = state.smallLoadingSpinner - 1;
      if (newNumber < 0)
        newNumber = 0;

      return {
        ...state,
        smallLoadingSpinner: newNumber
      };

    default:
      return state;
  }
};

export default loadingSmallSpinnerReducer;
