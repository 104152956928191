import {
  emailValidator,
  phoneNumberValidator,
} from '../../helpers/textValidationHelpers';

export function getSanitizedUrlParams(dealer, form) {
  return `&to=${encodeURIComponent(dealer.email)}&from=${encodeURIComponent(
      form.email)}&first_name=${encodeURIComponent(
      form.firstName)}&last_name=${encodeURIComponent(
      form.lastName)}&phone=${encodeURIComponent(
      form.phone)}&city=${encodeURIComponent(
      form.city)}&state=${encodeURIComponent(
      form.state)}&topic=${encodeURIComponent(
      form.topic)}&message=${encodeURIComponent(form.message)}`;
}

export function validateForm(form) {
  let validations = {
    firstName: !(form.firstName && form.firstName !== ''),
    lastName: !(form.lastName && form.lastName !== ''),
    email: !(form.email && emailValidator(form.email)),
    phone: !(form.phone && phoneNumberValidator(form.phone)),
    city: !(form.city && form.city !== ''),
    state: !(form.state && form.state !== ''),
    topic: !(form.topic && form.topic !== ''),
    message: !(form.message && form.message !== '')
  };
  let errorCount = 0;
  Object.keys(validations).forEach(v => {
    if (validations[v] === true) {
      errorCount++;
    }
  });
  return {
    errorCount: errorCount,
    errors: {
      ...validations
    }
  }
}
  