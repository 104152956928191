import React from 'react';
import {Row, Col} from 'react-bootstrap';
import Select from 'react-select';
import {connect} from 'react-redux';

import {DealerContactButton} from './dealerContact';
import {getStateList} from '../../helpers/stateList';
import {DealerLocations} from '../../api/services/ntsWebappService';
import {DealerContactForm} from "./dealerContactForm";

export class FindDealer extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      stateSelected: { value: null },
      dealerSelected: null,
      currentStep: 1
    }
  }
  
  componentDidMount() {
    if(this.props.navState && this.props.navState.dealerState) {
      this.getStateContacts(this.props.navState.dealerState.value, this.props.navState.dealerState.label);
    }
  }

  onStateChange = (e) => {
    this.getStateContacts(e.value, e.label);
  };
  
  getStateContacts = (stateVal, stateName) => {
    DealerLocations.get(stateVal)
      .then(result => {
        this.setState({
          currentStep: 2,
          locations: result,
          stateSelected : { label: stateName, value: stateVal },
          dealerSelected: null
        });
        return result;
      })
      .catch(error => {
        this.setState({
          currentStep: 2,
          locations: [],
          stateSelected : { label: stateName, value: stateVal },
          dealerSelected: null
        });
        throw(error);
      });
  };

  contactOnClick = (e) => {
    const dealer = this.state.locations.filter(loc => {
      return +loc.id === +e.target.dataset.id
    })[0];
    this.setState({
      dealerSelected: dealer,
      currentStep: 3
    })
  };

  handleSubmitSuccess = () => {
    this.setState({
      currentStep: 4
    });
  };

  goBackStep = () => {
    this.setState({
      currentStep: this.state.currentStep - 1
    })
  };
  
  render() {
    
    const stateList = getStateList();

    this.form = {};
    
    return (
      <div className="container nts-find-dealer-page">

        <Row>
          <Col xs={12}>
            <h1>CONTACT A DEALER</h1>
            <p>You can buy everything you see on Niketeam.com from your local authorized Nike Team Dealers</p>
            {this.state.currentStep === 1 &&
              <label className="nts-find-dealer-control">
                <span className="nts-find-dealer-label">SEARCH FOR A DEALER</span>
                <Select
                    value={this.state.stateSelected.value}
                    options={stateList}
                    onChange={this.onStateChange}
                    placeholder="SELECT..."
                    clearable={false}
                />
              </label>
            }
            {[1,4].indexOf(this.state.currentStep) === -1 &&
              <div className="nts-dealer-contact-back" onClick={this.goBackStep}>
                <span className="glyphicon glyphicon-chevron-left"></span>
                <span>GO BACK</span>
              </div>
            }
          </Col>
        </Row>

        <Row className="nts-dealer-results">
          {this.state.currentStep === 2 &&
            <Col xs={12}>
              <div>
                <h2>RECOMMENDED DEALERS FOR: {this.state.stateSelected.label}</h2>
                <Row>
                  {this.state.locations.map(location => {
                    return <DealerContactButton location={location} key={location.id} onClick={this.contactOnClick} />
                  })}
                </Row>
              </div>
            </Col>
          }

          {this.state.currentStep === 3 &&
            <DealerContactForm dealer={this.state.dealerSelected} form={this.form} onSuccess={this.handleSubmitSuccess} states={stateList} />
          }

          {this.state.currentStep === 4 &&
            <Col xs={12}>
              <h4 style={{marginTop:'40px'}}>THANK YOU. YOUR EMAIL WAS SENT SUCCESSFULLY.</h4>
            </Col>
          }

        </Row>

      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    navState: state.navigationState
  }
};

export const FindDealerPage = connect(
  mapStateToProps
)(FindDealer);