import React from 'react';
import {NtsLink} from './../../shared/ntsLink';

export const KidsOptions = (props) => {

  return(
    <div className="nts-nav-bar-flex">

      <div className="nts-nav-bar-col">
        <a href='/en/en_US/nts/sport/kids/baseball' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Baseball</a>
        <a href='/en/en_US/nts/sport/kids/basketball' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Basketball</a>
        <a href='/en/en_US/nts/sport/kids/football' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Football</a>
        <a href='/en/en_US/nts/sport/kids/lacrosse' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Lacrosse</a>
        <a href='/en/en_US/nts/sport/kids/running' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Running</a>
        <a href='/en/en_US/nts/sport/kids/soccer' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Soccer</a>
        <a href='/en/en_US/nts/sport/kids/tennis' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Tennis</a>
        <a href='/en/en_US/nts/sport/kids/training' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Training</a>
        <a href='/en/en_US/nts/sport/kids/volleyball' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Volleyball</a>
      </div>

      <div className="nts-nav-bar-col with-left-bar hidden-xs hidden-sm">
        <div className="nts-nav-bar-title">Uniforms</div>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/109/?sort=rowNumber|desc&selected=hc_UN_Tops' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Tops</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/110/?sort=rowNumber|desc&selected=hc_UN_Bottoms' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Bottoms</NtsLink>
      </div>

      <div className="nts-nav-bar-col hidden-xs hidden-sm">
        <div className="nts-nav-bar-title">Clothing Tops</div>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/112/?sort=rowNumber|desc&selected=hc_Baselayer_and_Nike_Pro' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Baselayer & Nike Pro</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/113/?sort=rowNumber|desc&selected=hc_Short_Sleeve' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Short Sleeves</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/114/?sort=rowNumber|desc&selected=hc_Long_Sleeve' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Long Sleeves</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/115/?sort=rowNumber|desc&selected=hc_Hoodies_and_Pullovers' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Hoodies & Pullovers</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/116/?sort=rowNumber|desc&selected=hc_Sleeveless_and_Tanks' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Sleeveless & Tanks</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/117/?sort=rowNumber|desc&selected=hc_Jackets' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Jackets</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/118/?sort=rowNumber|desc&selected=hc_Polos' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Polos</NtsLink>
      </div>

      <div className="nts-nav-bar-col hidden-xs hidden-sm">
        <div className="nts-nav-bar-title">Clothing Bottoms</div>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/120/?sort=rowNumber|desc&selected=hc_Shorts' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Shorts</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/121/?sort=rowNumber|desc&selected=hc_Pants' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Pants</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/123/?sort=rowNumber|desc&selected=hc_Tights_and_Capris' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Tights & Capris</NtsLink>
      </div>

      <div className="nts-nav-bar-col hidden-xs hidden-sm">
        <div className="nts-nav-bar-title">Gear</div>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/25/?sort=rowNumber|desc&selected=hc_Bags' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Bags</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/25/?sort=rowNumber|desc&selected=hc_Balls' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Balls</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/25/?sort=rowNumber|desc&selected=hc_Belts' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Belts</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/27/?sort=rowNumber|desc&selected=hc_Gloves' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Gloves</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/28/?sort=rowNumber|desc&selected=hc_Headwear' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Headwear</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/29/?sort=rowNumber|desc&selected=hc_Socks' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Socks</NtsLink>
      </div>

      <div className="nts-nav-bar-col hidden-xs hidden-sm">
        <div className="nts-nav-bar-title">Graphic Tees</div>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/130/?sort=rowNumber|desc&selected=hc_GT_Short_Sleeve' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Short Sleeve</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/131/?sort=rowNumber|desc&selected=hc_GT_Long_Sleeve' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Long Sleeve</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/BG/any/132/?sort=rowNumber|desc&selected=hc_GT_Hoodies_and_Pullovers' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Hoodies & Pullovers</NtsLink>
      </div>

    </div>
  )
};
