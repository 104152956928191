import {calculateEndpointType} from './common';

const endpointTypeToUrl = {
  local: 'http://localhost:8090/template-api/api',
  dev: 'https://niketeam-api-dev.nike.net/template-api/api',
  qa: 'https://niketeam-api-qa.nike.net/template-api/api',
  stg: 'https://niketeam-api-stg.nike.net/template-api/api',
  uat: 'https://niketeam-api-uat.nike.net/template-api/api',
  prod: 'https://niketeam-api.nike.net/template-api/api'
};

export default function apiBaseUrl(location) {
  const type = calculateEndpointType(location, 'prod');
  return endpointTypeToUrl[type];
}
