import * as React from 'react';
import { FactoryData } from './FactoryData';


export const AdminFactoryPage: React.FC = () => {

    return (
        <div className="nts-admin-page nts-admin-factory-page nts-admin-analytics-page">
            <div>
                <h1>FACTORY</h1>
                <ul className="admin-tabs">
                 <li className="selected">FACTORY LIST</li>
                </ul>
                <div className="admin-tabs-content">
                    <FactoryData />
                </div>
            </div>
        </div>
    )

}
