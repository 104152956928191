export const dealerArtworkMock = {
  "start": 0,
  "count": 8,
  "totalPages": 1,
  "totalResults": 4,
  "isValid": false,
  "artwork": [{
    "id": 41268,
    "email": "test@nike.com",
    "name": "bright_ceramic_test_1",
    "sport": "Basketball",
    "sportCode": null,
    "school": null,
    "url": "https://images.nike.net/is/image/GTMDEMO/41268_bright_ceramic_test_1",
    "date": 1479928202660,
    "backgroundHex": "#eeeeee",
    "colorCount": 0,
    "statusCode": "ACT",
    "active": "Active",
    "uploaded": "A"
  },  {
    "id": 41255,
    "email": "jonas.reyes@nike.com",
    "name": "blue_test_1",
    "sport": "Basketball",
    "sportCode": null,
    "school": "MHS",
    "url": "https://images.nike.net/is/image/GTMDEMO/41255_blue_test_1",
    "date": 1478021288427,
    "backgroundHex": "#111111",
    "colorCount": 0,
    "statusCode": "ACT",
    "active": "Active",
    "uploaded": "A"
  },
    {
      "id": 41258,
      "email": "test@nike.com",
      "name": "test_4",
      "sport": "Basketball",
      "sportCode": null,
      "school": null,
      "url": "https://images.nike.net/is/image/GTMDEMO/41258_test_4",
      "date": 1478035163866,
      "backgroundHex": "#FFFFFF",
      "colorCount": 0,
      "statusCode": "ACT",
      "active": "",
      "uploaded": "A"
    },
    {
      "id": 41256,
      "email": "jonas.reyes@nike.com",
      "name": "test_purple_1",
      "sport": "Hockey",
      "sportCode": null,
      "school": null,
      "url": "https://images.nike.net/is/image/GTMDEMO/41256_test_purple_1",
      "date": 1478033436855,
      "backgroundHex": "#FFFFFF",
      "colorCount": 0,
      "statusCode": "ACT",
      "active": "Active",
      "uploaded": "A"
    },
    {
      "id": 41255,
      "email": "jonas.reyes@nike.com",
      "name": "blue_test_1",
      "sport": null,
      "sportCode": "Hockey",
      "school": null,
      "url": "https://images.nike.net/is/image/GTMDEMO/41255_blue_test_1",
      "date": 1478021288427,
      "backgroundHex": "#FFFFFF",
      "colorCount": 0,
      "statusCode": "ACT",
      "active": "Active",
      "uploaded": "A"
    },
    {
      "id": 41268,
      "email": "test@nike.com",
      "name": "bright_ceramic_test_1",
      "sport": null,
      "sportCode": null,
      "school": null,
      "url": "https://images.nike.net/is/image/GTMDEMO/41268_bright_ceramic_test_1",
      "date": 1479928202660,
      "backgroundHex": "#FFFFFF",
      "colorCount": 0,
      "statusCode": "ACT",
      "active": "Active",
      "uploaded": "A"
    },
    {
      "id": 41258,
      "email": "test@nike.com",
      "name": "test_4",
      "sport": null,
      "sportCode": null,
      "school": "NCCS",
      "url": "https://images.nike.net/is/image/GTMDEMO/41258_test_4",
      "date": 1478035163866,
      "backgroundHex": "#FFFFFF",
      "colorCount": 0,
      "statusCode": "ACT",
      "active": "Active",
      "uploaded": "A"
    },
    {
      "id": 41255,
      "email": "jonas.reyes@nike.com",
      "name": "blue_test_1",
      "sport": "SportsBall",
      "sportCode": null,
      "school": "MHS",
      "url": "https://images.nike.net/is/image/GTMDEMO/41255_blue_test_1",
      "date": 1478021288427,
      "backgroundHex": "#FFFFFF",
      "colorCount": 0,
      "statusCode": "ACT",
      "active": "Active",
      "uploaded": "A"
    },
    {
      "id": 41256,
      "email": "jonas.reyes@nike.com",
      "name": "test_purple_1",
      "sport": "SportsBall",
      "sportCode": null,
      "school": "NCCS",
      "url": "https://images.nike.net/is/image/GTMDEMO/41256_test_purple_1",
      "date": 1478033436855,
      "backgroundHex": "#FFFFFF",
      "colorCount": 0,
      "statusCode": "ACT",
      "active": "Active",
      "uploaded": "A"
    },

    {
    "id": 41268,
    "email": "jonas.reyes@nike.com",
    "name": "bright_ceramic_test_1",
    "sport": "Hockey",
    "sportCode": null,
    "school": "MHSs",
    "url": "https://images.nike.net/is/image/GTMDEMO/41268_bright_ceramic_test_1",
    "date": 1479928202660,
    "backgroundHex": "#FFFFFF",
    "colorCount": 0,
    "statusCode": "ACT",
    "active": "Active",
    "uploaded": "A"
  },
    {
      "id": 41258,
      "email": "jonas.reyes@nike.com",
      "name": "test_4",
      "sport": "SportsBall",
      "sportCode": null,
      "school": null,
      "url": "https://images.nike.net/is/image/GTMDEMO/41258_test_4",
      "date": 1478035163866,
      "backgroundHex": "#FFFFFF",
      "colorCount": 0,
      "statusCode": "ACT",
      "active": "Active",
      "uploaded": "A"
    },
    {
      "id": 41256,
      "email": "jonas.reyes@nike.com",
      "name": "test_purple_1",
      "sport": null,
      "sportCode": null,
      "school": null,
      "url": "https://images.nike.net/is/image/GTMDEMO/41256_test_purple_1",
      "date": 1478033436855,
      "backgroundHex": "#FFFFFF",
      "colorCount": 0,
      "statusCode": "ACT",
      "active": "Active",
      "uploaded": "A"
    },
    {
      "id": 41255,
      "email": "jonas.reyes@nike.com",
      "name": "blue_test_1",
      "sport": "SportsBall",
      "sportCode": null,
      "school": null,
      "url": "https://images.nike.net/is/image/GTMDEMO/41255_blue_test_1",
      "date": 1478021288427,
      "backgroundHex": "#FFFFFF",
      "colorCount": 0,
      "statusCode": "ACT",
      "active": "Active",
      "uploaded": "A"
    },
    {
      "id": 41268,
      "email": "jonas.reyes@nike.com",
      "name": "bright_ceramic_test_1",
      "sport": null,
      "sportCode": null,
      "school": null,
      "url": "https://images.nike.net/is/image/GTMDEMO/41268_bright_ceramic_test_1",
      "date": 1479928202660,
      "backgroundHex": "#FFFFFF",
      "colorCount": 0,
      "statusCode": "ACT",
      "active": "Active",
      "uploaded": "A"
    },
    {
      "id": 41258,
      "email": "jonas.reyes@nike.com",
      "name": "test_4",
      "sport": null,
      "sportCode": null,
      "school": null,
      "url": "https://images.nike.net/is/image/GTMDEMO/41258_test_4",
      "date": 1478035163866,
      "backgroundHex": "#FFFFFF",
      "colorCount": 0,
      "statusCode": "ACT",
      "active": "Active",
      "uploaded": "A"
    },
    {
      "id": 41256,
      "email": "jonas.reyes@nike.com",
      "name": "test_purple_1",
      "sport": null,
      "sportCode": null,
      "school": null,
      "url": "https://images.nike.net/is/image/GTMDEMO/41256_test_purple_1",
      "date": 1478033436855,
      "backgroundHex": "#FFFFFF",
      "colorCount": 0,
      "statusCode": "ACT",
      "active": "Active",
      "uploaded": "A"
    },
    {
      "id": 41255,
      "email": "jonas.reyes@nike.com",
      "name": "blue_test_1",
      "sport": null,
      "sportCode": null,
      "school": null,
      "url": "https://images.nike.net/is/image/GTMDEMO/41255_blue_test_1",
      "date": 1478021288427,
      "backgroundHex": "#FFFFFF",
      "colorCount": 0,
      "statusCode": "ACT",
      "active": "Active",
      "uploaded": "A"
    }]
};

export const StyleTemplateMock = {
    "id": "f33e7255-8497-4c47-8ee3-f1e5416e6f48",
    "styleCode": "683333",
    "name": "W NK JSY FASTBREAK STOCK",
    "templates": {
        "Front": {
            "id": "2bed600c-101a-441c-9592-ab5a4f83aff2",
            "name": "BL-Front-3",
            "viewType": "Front",
            "status": "UNPUBLISHED",
            "productType": "Crew",
            "sport": "Baseball",
            "data": {
                "components": [
                    {
                        "type": "graphic",
                        "id": "82e2d804-9c4d-4bc0-aeba-5b039b298d7b",
                        "name": "",
                        "positionX": -100,
                        "positionY": -350,
                        "height": 1000,
                        "width": 1000,
                        "rotationDegrees": 1,
                        "availableGraphics": [
                            {
                                "id": "c114dc41-05fa-490d-954c-9b6d766d29cd",
                                "name": "#18 Never Stops",
                                "data": {
                                    "type": "composite",
                                    "image": {
                                        "url": "http://images.nike.net/is/image/GTM/base_t_cg?$layer_0_opac=0&$layer_0_size=1000,1000&$PNG%20-%20Large%20size%20-%20High%20resolution$&$t1_hide=1&$l1_hide=0&$l1_src=GTMDEMO/artwork_p&$l1_size=230,230&$l1_pos=0,-380&$g1_hide=0&$g1_src=Never-Stops-Swoosh-Orange&$g1_size=750,800&$g1_pos=0,60"
                                    },
                                    "components": [
                                        {
                                            "type": "logo",
                                            "id": "cj5u5okvq00033h5g0zoe1d8u",
                                            "name": "",
                                            "positionX": 0,
                                            "positionY": -380,
                                            "height": 230,
                                            "width": 230,
                                            "rotationDegrees": 0,
                                            "label": "ARTWORK"
                                        },
                                        {
                                            "type": "graphic",
                                            "id": "cj5u5qes300043h5gzt812g7n",
                                            "name": "",
                                            "positionX": 0,
                                            "positionY": 60,
                                            "height": 800,
                                            "width": 750,
                                            "rotationDegrees": 0,
                                            "availableGraphics": [
                                                {
                                                    "id": "27fe3a82-683e-4949-85ba-ae2b7beb5ee9",
                                                    "name": "Never Stops Swoosh",
                                                    "displayName": "Never Stops with Swoosh",
                                                    "data": {
                                                        "type": "simple",
                                                        "image": {
                                                            "url": "Never-Stops-Swoosh"
                                                        },
                                                        "availableColors": [
                                                            {
                                                                "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                                "name": "White",
                                                                "rgb": {
                                                                    "red": 255,
                                                                    "green": 255,
                                                                    "blue": 255
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                                "name": "Orange",
                                                                "rgb": {
                                                                    "red": 241,
                                                                    "green": 95,
                                                                    "blue": 49
                                                                },
                                                                "selected": true
                                                            },
                                                            {
                                                                "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                                "name": "Pink Fire",
                                                                "rgb": {
                                                                    "red": 231,
                                                                    "green": 127,
                                                                    "blue": 179
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                                "name": "Teal",
                                                                "rgb": {
                                                                    "red": 3,
                                                                    "green": 96,
                                                                    "blue": 112
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                                "name": "Dark Green",
                                                                "rgb": {
                                                                    "red": 0,
                                                                    "green": 88,
                                                                    "blue": 55
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                                "name": "Crimson",
                                                                "rgb": {
                                                                    "red": 170,
                                                                    "green": 31,
                                                                    "blue": 55
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                                "name": "Blue Grey",
                                                                "rgb": {
                                                                    "red": 181,
                                                                    "green": 183,
                                                                    "blue": 186
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                                "name": "Purple",
                                                                "rgb": {
                                                                    "red": 70,
                                                                    "green": 56,
                                                                    "blue": 140
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                                "name": "Light Blue",
                                                                "rgb": {
                                                                    "red": 87,
                                                                    "green": 153,
                                                                    "blue": 206
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                                "name": "Anthracite",
                                                                "rgb": {
                                                                    "red": 68,
                                                                    "green": 68,
                                                                    "blue": 78
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                                "name": "Brown",
                                                                "rgb": {
                                                                    "red": 56,
                                                                    "green": 44,
                                                                    "blue": 38
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                                "name": "Scarlet",
                                                                "rgb": {
                                                                    "red": 209,
                                                                    "green": 34,
                                                                    "blue": 64
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                                "name": "Dark Maroon",
                                                                "rgb": {
                                                                    "red": 95,
                                                                    "green": 11,
                                                                    "blue": 47
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                                "name": "Cardinal",
                                                                "rgb": {
                                                                    "red": 126,
                                                                    "green": 25,
                                                                    "blue": 56
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "ec68de0a-466c-4f2e-9ec5-115add7af6b2",
                                                                "name": "Desert Orange",
                                                                "rgb": {
                                                                    "red": 166,
                                                                    "green": 84,
                                                                    "blue": 31
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "55e7944b-7ca8-408f-803c-3e226be43789",
                                                                "name": "Vegas Gold",
                                                                "rgb": {
                                                                    "red": 220,
                                                                    "green": 202,
                                                                    "blue": 150
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                                                "name": "Royal",
                                                                "rgb": {
                                                                    "red": 0,
                                                                    "green": 79,
                                                                    "blue": 163
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "61b2f7f3-14eb-4f8f-981e-a8e38c528c01",
                                                                "name": "Black",
                                                                "rgb": {
                                                                    "red": 27,
                                                                    "green": 29,
                                                                    "blue": 37
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "2b4de46c-622f-4427-bba1-eaea608bea50",
                                                                "name": "Kelly Green",
                                                                "rgb": {
                                                                    "red": 0,
                                                                    "green": 147,
                                                                    "blue": 75
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "a3e3dc9b-e48c-4880-a084-d2649ba8b0c0",
                                                                "name": "Bright Gold",
                                                                "rgb": {
                                                                    "red": 253,
                                                                    "green": 191,
                                                                    "blue": 87
                                                                },
                                                                "selected": false
                                                            }
                                                        ]
                                                    },
                                                    "selected": true
                                                }
                                            ]
                                        }
                                    ]
                                },
                                "selected": false
                            },
                            {
                                "id": "c6e2d206-0b37-498a-8468-31906d662a1f",
                                "name": "randyTestGraphic",
                                "data": {
                                    "type": "composite",
                                    "image": {
                                        "url": "http://images.nike.net/is/image/GTM/base_t_cg?$layer_0_opac=0&$layer_0_size=1000,1000&$PNG%20-%20Large%20size%20-%20High%20resolution$&$t1_hide=1&$l1_hide=1&$g1_hide=0&$g1_src=Mentality-Cardinal&$g1_size=1000,1000&$g1_pos=0,0"
                                    },
                                    "components": [
                                        {
                                            "type": "graphic",
                                            "id": "cj6wfwabh00003e5op1g2cs4e",
                                            "name": "",
                                            "positionX": 0,
                                            "positionY": 0,
                                            "height": 1000,
                                            "width": 1000,
                                            "rotationDegrees": 0,
                                            "availableGraphics": [
                                                {
                                                    "id": "f0b6ade3-b26c-4c51-a470-3ed91c17d707",
                                                    "name": "Mentality",
                                                    "displayName": "Mentality",
                                                    "data": {
                                                        "type": "simple",
                                                        "image": {
                                                            "url": "Mentality"
                                                        },
                                                        "availableColors": [
                                                            {
                                                                "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                                "name": "White",
                                                                "rgb": {
                                                                    "red": 255,
                                                                    "green": 255,
                                                                    "blue": 255
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                                "name": "Orange",
                                                                "rgb": {
                                                                    "red": 241,
                                                                    "green": 95,
                                                                    "blue": 49
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                                "name": "Pink Fire",
                                                                "rgb": {
                                                                    "red": 231,
                                                                    "green": 127,
                                                                    "blue": 179
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                                "name": "Teal",
                                                                "rgb": {
                                                                    "red": 3,
                                                                    "green": 96,
                                                                    "blue": 112
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                                "name": "Crimson",
                                                                "rgb": {
                                                                    "red": 170,
                                                                    "green": 31,
                                                                    "blue": 55
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                                "name": "Dark Green",
                                                                "rgb": {
                                                                    "red": 0,
                                                                    "green": 88,
                                                                    "blue": 55
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                                "name": "Blue Grey",
                                                                "rgb": {
                                                                    "red": 181,
                                                                    "green": 183,
                                                                    "blue": 186
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                                "name": "Purple",
                                                                "rgb": {
                                                                    "red": 70,
                                                                    "green": 56,
                                                                    "blue": 140
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                                "name": "Light Blue",
                                                                "rgb": {
                                                                    "red": 87,
                                                                    "green": 153,
                                                                    "blue": 206
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                                "name": "Anthracite",
                                                                "rgb": {
                                                                    "red": 68,
                                                                    "green": 68,
                                                                    "blue": 78
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                                "name": "Brown",
                                                                "rgb": {
                                                                    "red": 56,
                                                                    "green": 44,
                                                                    "blue": 38
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                                "name": "Scarlet",
                                                                "rgb": {
                                                                    "red": 209,
                                                                    "green": 34,
                                                                    "blue": 64
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                                "name": "Dark Maroon",
                                                                "rgb": {
                                                                    "red": 95,
                                                                    "green": 11,
                                                                    "blue": 47
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                                "name": "Cardinal",
                                                                "rgb": {
                                                                    "red": 126,
                                                                    "green": 25,
                                                                    "blue": 56
                                                                },
                                                                "selected": true
                                                            }
                                                        ]
                                                    },
                                                    "selected": true
                                                }
                                            ]
                                        }
                                    ]
                                },
                                "selected": false
                            },
                            {
                                "id": "4d206210-970c-4cb5-abcd-ec1c56f24297",
                                "name": "JP - Color Category Test 1",
                                "pricingType": "Uniform",
                                "data": {
                                    "type": "composite",
                                    "image": {
                                        "url": "http://images.nike.net/is/image/GTMDEMO/base_t_cg?$layer_0_opac=0&$layer_0_size=1000,1000&$PNG%20-%20Large%20size%20-%20High%20resolution$&$t1_hide=1&$l1_hide=1&$g1_hide=0&$g1_src=Swoosh-Orange&$g1_size=500,500&$g1_pos=0,350"
                                    },
                                    "components": [
                                        {
                                            "type": "graphic",
                                            "id": "cj7m8alud0000385p3htf8x2z",
                                            "name": "",
                                            "positionX": 0,
                                            "positionY": 350,
                                            "height": 500,
                                            "width": 500,
                                            "rotationDegrees": 0,
                                            "availableGraphics": [
                                                {
                                                    "id": "853906f0-ff3a-4cfc-98b6-d934b3924128",
                                                    "name": "Swoosh",
                                                    "displayName": "Swoosh",
                                                    "data": {
                                                        "type": "simple",
                                                        "image": {
                                                            "url": "Swoosh"
                                                        },
                                                        "availableColors": [
                                                            {
                                                                "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                                "name": "White",
                                                                "rgb": {
                                                                    "red": 255,
                                                                    "green": 255,
                                                                    "blue": 255
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                                "name": "Orange",
                                                                "rgb": {
                                                                    "red": 241,
                                                                    "green": 95,
                                                                    "blue": 49
                                                                },
                                                                "selected": true
                                                            },
                                                            {
                                                                "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                                "name": "Pink Fire",
                                                                "rgb": {
                                                                    "red": 231,
                                                                    "green": 127,
                                                                    "blue": 179
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                                "name": "Teal",
                                                                "rgb": {
                                                                    "red": 3,
                                                                    "green": 96,
                                                                    "blue": 112
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                                "name": "Dark Green",
                                                                "rgb": {
                                                                    "red": 0,
                                                                    "green": 88,
                                                                    "blue": 55
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                                "name": "Crimson",
                                                                "rgb": {
                                                                    "red": 170,
                                                                    "green": 31,
                                                                    "blue": 55
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                                "name": "Blue Grey",
                                                                "rgb": {
                                                                    "red": 181,
                                                                    "green": 183,
                                                                    "blue": 186
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                                "name": "Purple",
                                                                "rgb": {
                                                                    "red": 70,
                                                                    "green": 56,
                                                                    "blue": 140
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                                "name": "Light Blue",
                                                                "rgb": {
                                                                    "red": 87,
                                                                    "green": 153,
                                                                    "blue": 206
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                                "name": "Anthracite",
                                                                "rgb": {
                                                                    "red": 68,
                                                                    "green": 68,
                                                                    "blue": 78
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                                "name": "Brown",
                                                                "rgb": {
                                                                    "red": 56,
                                                                    "green": 44,
                                                                    "blue": 38
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                                "name": "Scarlet",
                                                                "rgb": {
                                                                    "red": 209,
                                                                    "green": 34,
                                                                    "blue": 64
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                                "name": "Dark Maroon",
                                                                "rgb": {
                                                                    "red": 95,
                                                                    "green": 11,
                                                                    "blue": 47
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                                "name": "Cardinal",
                                                                "rgb": {
                                                                    "red": 126,
                                                                    "green": 25,
                                                                    "blue": 56
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "ec68de0a-466c-4f2e-9ec5-115add7af6b2",
                                                                "name": "Desert Orange",
                                                                "rgb": {
                                                                    "red": 166,
                                                                    "green": 84,
                                                                    "blue": 31
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "55e7944b-7ca8-408f-803c-3e226be43789",
                                                                "name": "Vegas Gold",
                                                                "rgb": {
                                                                    "red": 220,
                                                                    "green": 202,
                                                                    "blue": 150
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                                                "name": "Royal",
                                                                "rgb": {
                                                                    "red": 0,
                                                                    "green": 79,
                                                                    "blue": 163
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "61b2f7f3-14eb-4f8f-981e-a8e38c528c01",
                                                                "name": "Black",
                                                                "rgb": {
                                                                    "red": 27,
                                                                    "green": 29,
                                                                    "blue": 37
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "2b4de46c-622f-4427-bba1-eaea608bea50",
                                                                "name": "Kelly Green",
                                                                "rgb": {
                                                                    "red": 0,
                                                                    "green": 147,
                                                                    "blue": 75
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "a3e3dc9b-e48c-4880-a084-d2649ba8b0c0",
                                                                "name": "Bright Gold",
                                                                "rgb": {
                                                                    "red": 253,
                                                                    "green": 191,
                                                                    "blue": 87
                                                                },
                                                                "selected": false
                                                            }
                                                        ],
                                                        "colorCategory": "PRIMARY"
                                                    },
                                                    "selected": true
                                                }
                                            ]
                                        },
                                        {
                                            "type": "text",
                                            "id": "cj7m8b8co0001385prm2afw5i",
                                            "name": "PRIMARY",
                                            "positionX": 0,
                                            "positionY": 50,
                                            "height": 1000,
                                            "width": 1000,
                                            "rotationDegrees": 0,
                                            "availableFonts": [
                                                {
                                                    "id": "fd747f60-4757-4deb-a124-75143dbd9297",
                                                    "name": "bureauagencyregular",
                                                    "displayName": "Bureau",
                                                    "type": "Bureau",
                                                    "defaultMaxChars": 10,
                                                    "selected": true
                                                }
                                            ],
                                            "availableColors": [
                                                {
                                                    "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                    "name": "White",
                                                    "rgb": {
                                                        "red": 255,
                                                        "green": 255,
                                                        "blue": 255
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                    "name": "Orange",
                                                    "rgb": {
                                                        "red": 241,
                                                        "green": 95,
                                                        "blue": 49
                                                    },
                                                    "selected": true
                                                },
                                                {
                                                    "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                    "name": "Pink Fire",
                                                    "rgb": {
                                                        "red": 231,
                                                        "green": 127,
                                                        "blue": 179
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                    "name": "Teal",
                                                    "rgb": {
                                                        "red": 3,
                                                        "green": 96,
                                                        "blue": 112
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                    "name": "Dark Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 88,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                    "name": "Crimson",
                                                    "rgb": {
                                                        "red": 170,
                                                        "green": 31,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                    "name": "Blue Grey",
                                                    "rgb": {
                                                        "red": 181,
                                                        "green": 183,
                                                        "blue": 186
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                    "name": "Purple",
                                                    "rgb": {
                                                        "red": 70,
                                                        "green": 56,
                                                        "blue": 140
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                    "name": "Light Blue",
                                                    "rgb": {
                                                        "red": 87,
                                                        "green": 153,
                                                        "blue": 206
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                    "name": "Anthracite",
                                                    "rgb": {
                                                        "red": 68,
                                                        "green": 68,
                                                        "blue": 78
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                    "name": "Brown",
                                                    "rgb": {
                                                        "red": 56,
                                                        "green": 44,
                                                        "blue": 38
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                    "name": "Scarlet",
                                                    "rgb": {
                                                        "red": 209,
                                                        "green": 34,
                                                        "blue": 64
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                    "name": "Dark Maroon",
                                                    "rgb": {
                                                        "red": 95,
                                                        "green": 11,
                                                        "blue": 47
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                    "name": "Cardinal",
                                                    "rgb": {
                                                        "red": 126,
                                                        "green": 25,
                                                        "blue": 56
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "ec68de0a-466c-4f2e-9ec5-115add7af6b2",
                                                    "name": "Desert Orange",
                                                    "rgb": {
                                                        "red": 166,
                                                        "green": 84,
                                                        "blue": 31
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "55e7944b-7ca8-408f-803c-3e226be43789",
                                                    "name": "Vegas Gold",
                                                    "rgb": {
                                                        "red": 220,
                                                        "green": 202,
                                                        "blue": 150
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                                    "name": "Royal",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 79,
                                                        "blue": 163
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "61b2f7f3-14eb-4f8f-981e-a8e38c528c01",
                                                    "name": "Black",
                                                    "rgb": {
                                                        "red": 27,
                                                        "green": 29,
                                                        "blue": 37
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2b4de46c-622f-4427-bba1-eaea608bea50",
                                                    "name": "Kelly Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 147,
                                                        "blue": 75
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a3e3dc9b-e48c-4880-a084-d2649ba8b0c0",
                                                    "name": "Bright Gold",
                                                    "rgb": {
                                                        "red": 253,
                                                        "green": 191,
                                                        "blue": 87
                                                    },
                                                    "selected": false
                                                }
                                            ],
                                            "availableSecondaryColors": [
                                                {
                                                    "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                    "name": "White",
                                                    "rgb": {
                                                        "red": 255,
                                                        "green": 255,
                                                        "blue": 255
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                    "name": "Orange",
                                                    "rgb": {
                                                        "red": 241,
                                                        "green": 95,
                                                        "blue": 49
                                                    },
                                                    "selected": true
                                                },
                                                {
                                                    "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                    "name": "Pink Fire",
                                                    "rgb": {
                                                        "red": 231,
                                                        "green": 127,
                                                        "blue": 179
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                    "name": "Teal",
                                                    "rgb": {
                                                        "red": 3,
                                                        "green": 96,
                                                        "blue": 112
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                    "name": "Dark Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 88,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                    "name": "Crimson",
                                                    "rgb": {
                                                        "red": 170,
                                                        "green": 31,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                    "name": "Blue Grey",
                                                    "rgb": {
                                                        "red": 181,
                                                        "green": 183,
                                                        "blue": 186
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                    "name": "Purple",
                                                    "rgb": {
                                                        "red": 70,
                                                        "green": 56,
                                                        "blue": 140
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                    "name": "Light Blue",
                                                    "rgb": {
                                                        "red": 87,
                                                        "green": 153,
                                                        "blue": 206
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                    "name": "Anthracite",
                                                    "rgb": {
                                                        "red": 68,
                                                        "green": 68,
                                                        "blue": 78
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                    "name": "Brown",
                                                    "rgb": {
                                                        "red": 56,
                                                        "green": 44,
                                                        "blue": 38
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                    "name": "Scarlet",
                                                    "rgb": {
                                                        "red": 209,
                                                        "green": 34,
                                                        "blue": 64
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                    "name": "Dark Maroon",
                                                    "rgb": {
                                                        "red": 95,
                                                        "green": 11,
                                                        "blue": 47
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                    "name": "Cardinal",
                                                    "rgb": {
                                                        "red": 126,
                                                        "green": 25,
                                                        "blue": 56
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "ec68de0a-466c-4f2e-9ec5-115add7af6b2",
                                                    "name": "Desert Orange",
                                                    "rgb": {
                                                        "red": 166,
                                                        "green": 84,
                                                        "blue": 31
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "55e7944b-7ca8-408f-803c-3e226be43789",
                                                    "name": "Vegas Gold",
                                                    "rgb": {
                                                        "red": 220,
                                                        "green": 202,
                                                        "blue": 150
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                                    "name": "Royal",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 79,
                                                        "blue": 163
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "61b2f7f3-14eb-4f8f-981e-a8e38c528c01",
                                                    "name": "Black",
                                                    "rgb": {
                                                        "red": 27,
                                                        "green": 29,
                                                        "blue": 37
                                                    },
                                                    "selected": true
                                                },
                                                {
                                                    "id": "2b4de46c-622f-4427-bba1-eaea608bea50",
                                                    "name": "Kelly Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 147,
                                                        "blue": 75
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a3e3dc9b-e48c-4880-a084-d2649ba8b0c0",
                                                    "name": "Bright Gold",
                                                    "rgb": {
                                                        "red": 253,
                                                        "green": 191,
                                                        "blue": 87
                                                    },
                                                    "selected": false
                                                }
                                            ],
                                            "availableBackgroundColors": [],
                                            "label": "PRIMARY FILL ",
                                            "fontSize": 250,
                                            "bgHeight": 1000,
                                            "bgWidth": 1000,
                                            "maxCharacters": 11,
                                            "minCharacters": 1,
                                            "textPositionX": 0,
                                            "textPositionY": 0,
                                            "textDistort": "Straight",
                                            "textEditable": "Editable",
                                            "letterSpacing": 1,
                                            "textCategory": "GENERAL",
                                            "primaryColorCategory": "PRIMARY",
                                            "secondaryColorCategory": "CUSTOM",
                                            "backgroundColorCategory": "CUSTOM"
                                        },
                                        {
                                            "type": "text",
                                            "id": "cj7m8d8cd0002385pb09s32yw",
                                            "name": "SECONDARY",
                                            "positionX": 0,
                                            "positionY": 400,
                                            "height": 1000,
                                            "width": 1000,
                                            "rotationDegrees": 0,
                                            "availableFonts": [
                                                {
                                                    "id": "fd747f60-4757-4deb-a124-75143dbd9297",
                                                    "name": "bureauagencyregular",
                                                    "displayName": "Bureau",
                                                    "type": "Bureau",
                                                    "defaultMaxChars": 10,
                                                    "selected": true
                                                }
                                            ],
                                            "availableColors": [
                                                {
                                                    "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                    "name": "White",
                                                    "rgb": {
                                                        "red": 255,
                                                        "green": 255,
                                                        "blue": 255
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                    "name": "Orange",
                                                    "rgb": {
                                                        "red": 241,
                                                        "green": 95,
                                                        "blue": 49
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                    "name": "Pink Fire",
                                                    "rgb": {
                                                        "red": 231,
                                                        "green": 127,
                                                        "blue": 179
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                    "name": "Teal",
                                                    "rgb": {
                                                        "red": 3,
                                                        "green": 96,
                                                        "blue": 112
                                                    },
                                                    "selected": true
                                                },
                                                {
                                                    "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                    "name": "Dark Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 88,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                    "name": "Crimson",
                                                    "rgb": {
                                                        "red": 170,
                                                        "green": 31,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                    "name": "Blue Grey",
                                                    "rgb": {
                                                        "red": 181,
                                                        "green": 183,
                                                        "blue": 186
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                    "name": "Purple",
                                                    "rgb": {
                                                        "red": 70,
                                                        "green": 56,
                                                        "blue": 140
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                    "name": "Light Blue",
                                                    "rgb": {
                                                        "red": 87,
                                                        "green": 153,
                                                        "blue": 206
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                    "name": "Anthracite",
                                                    "rgb": {
                                                        "red": 68,
                                                        "green": 68,
                                                        "blue": 78
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                    "name": "Brown",
                                                    "rgb": {
                                                        "red": 56,
                                                        "green": 44,
                                                        "blue": 38
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                    "name": "Scarlet",
                                                    "rgb": {
                                                        "red": 209,
                                                        "green": 34,
                                                        "blue": 64
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                    "name": "Dark Maroon",
                                                    "rgb": {
                                                        "red": 95,
                                                        "green": 11,
                                                        "blue": 47
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                    "name": "Cardinal",
                                                    "rgb": {
                                                        "red": 126,
                                                        "green": 25,
                                                        "blue": 56
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "ec68de0a-466c-4f2e-9ec5-115add7af6b2",
                                                    "name": "Desert Orange",
                                                    "rgb": {
                                                        "red": 166,
                                                        "green": 84,
                                                        "blue": 31
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "55e7944b-7ca8-408f-803c-3e226be43789",
                                                    "name": "Vegas Gold",
                                                    "rgb": {
                                                        "red": 220,
                                                        "green": 202,
                                                        "blue": 150
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                                    "name": "Royal",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 79,
                                                        "blue": 163
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "61b2f7f3-14eb-4f8f-981e-a8e38c528c01",
                                                    "name": "Black",
                                                    "rgb": {
                                                        "red": 27,
                                                        "green": 29,
                                                        "blue": 37
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2b4de46c-622f-4427-bba1-eaea608bea50",
                                                    "name": "Kelly Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 147,
                                                        "blue": 75
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a3e3dc9b-e48c-4880-a084-d2649ba8b0c0",
                                                    "name": "Bright Gold",
                                                    "rgb": {
                                                        "red": 253,
                                                        "green": 191,
                                                        "blue": 87
                                                    },
                                                    "selected": false
                                                }
                                            ],
                                            "availableSecondaryColors": [
                                                {
                                                    "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                    "name": "White",
                                                    "rgb": {
                                                        "red": 255,
                                                        "green": 255,
                                                        "blue": 255
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                    "name": "Orange",
                                                    "rgb": {
                                                        "red": 241,
                                                        "green": 95,
                                                        "blue": 49
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                    "name": "Pink Fire",
                                                    "rgb": {
                                                        "red": 231,
                                                        "green": 127,
                                                        "blue": 179
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                    "name": "Teal",
                                                    "rgb": {
                                                        "red": 3,
                                                        "green": 96,
                                                        "blue": 112
                                                    },
                                                    "selected": true
                                                },
                                                {
                                                    "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                    "name": "Dark Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 88,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                    "name": "Crimson",
                                                    "rgb": {
                                                        "red": 170,
                                                        "green": 31,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                    "name": "Blue Grey",
                                                    "rgb": {
                                                        "red": 181,
                                                        "green": 183,
                                                        "blue": 186
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                    "name": "Purple",
                                                    "rgb": {
                                                        "red": 70,
                                                        "green": 56,
                                                        "blue": 140
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                    "name": "Light Blue",
                                                    "rgb": {
                                                        "red": 87,
                                                        "green": 153,
                                                        "blue": 206
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                    "name": "Anthracite",
                                                    "rgb": {
                                                        "red": 68,
                                                        "green": 68,
                                                        "blue": 78
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                    "name": "Brown",
                                                    "rgb": {
                                                        "red": 56,
                                                        "green": 44,
                                                        "blue": 38
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                    "name": "Scarlet",
                                                    "rgb": {
                                                        "red": 209,
                                                        "green": 34,
                                                        "blue": 64
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                    "name": "Dark Maroon",
                                                    "rgb": {
                                                        "red": 95,
                                                        "green": 11,
                                                        "blue": 47
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                    "name": "Cardinal",
                                                    "rgb": {
                                                        "red": 126,
                                                        "green": 25,
                                                        "blue": 56
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "ec68de0a-466c-4f2e-9ec5-115add7af6b2",
                                                    "name": "Desert Orange",
                                                    "rgb": {
                                                        "red": 166,
                                                        "green": 84,
                                                        "blue": 31
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "55e7944b-7ca8-408f-803c-3e226be43789",
                                                    "name": "Vegas Gold",
                                                    "rgb": {
                                                        "red": 220,
                                                        "green": 202,
                                                        "blue": 150
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                                    "name": "Royal",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 79,
                                                        "blue": 163
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "61b2f7f3-14eb-4f8f-981e-a8e38c528c01",
                                                    "name": "Black",
                                                    "rgb": {
                                                        "red": 27,
                                                        "green": 29,
                                                        "blue": 37
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2b4de46c-622f-4427-bba1-eaea608bea50",
                                                    "name": "Kelly Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 147,
                                                        "blue": 75
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a3e3dc9b-e48c-4880-a084-d2649ba8b0c0",
                                                    "name": "Bright Gold",
                                                    "rgb": {
                                                        "red": 253,
                                                        "green": 191,
                                                        "blue": 87
                                                    },
                                                    "selected": false
                                                }
                                            ],
                                            "availableBackgroundColors": [],
                                            "label": "SECONDARY FILL",
                                            "fontSize": 250,
                                            "bgHeight": 1000,
                                            "bgWidth": 1000,
                                            "maxCharacters": 9,
                                            "minCharacters": 1,
                                            "textPositionX": 0,
                                            "textPositionY": 0,
                                            "textDistort": "Straight",
                                            "textEditable": "Editable",
                                            "letterSpacing": 1,
                                            "textCategory": "GENERAL",
                                            "primaryColorCategory": "SECONDARY",
                                            "secondaryColorCategory": "CUSTOM",
                                            "backgroundColorCategory": "CUSTOM"
                                        }
                                    ]
                                },
                                "selected": false
                            },
                            {
                                "id": "6d399330-1930-4c86-911f-ee8c27964fbb",
                                "name": "#39 Mentality",
                                "data": {
                                    "type": "composite",
                                    "image": {
                                        "url": "http://images.nike.net/is/image/GTM/base_t_cg?$layer_0_opac=0&$layer_0_size=1000,1000&$PNG%20-%20Large%20size%20-%20High%20resolution$&$t1_hide=1&$l1_hide=0&$l1_src=GTMDEMO/artwork_p&$l1_size=308,192&$l1_pos=2,242&$g1_hide=1"
                                    },
                                    "components": [
                                        {
                                            "type": "text",
                                            "id": "cj62cr6120000355gj9n77el9",
                                            "name": "OREGON",
                                            "positionX": 0,
                                            "positionY": -98,
                                            "height": 1000,
                                            "width": 1000,
                                            "rotationDegrees": 0,
                                            "availableFonts": [
                                                {
                                                    "id": "e12de5ae-df24-4515-920d-080dba4bbb50",
                                                    "name": "FutureCourt-SemiBold",
                                                    "displayName": "Futura Semi Bold",
                                                    "type": "Futura",
                                                    "defaultMaxChars": 10,
                                                    "selected": true
                                                }
                                            ],
                                            "availableColors": [
                                                {
                                                    "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                    "name": "White",
                                                    "rgb": {
                                                        "red": 255,
                                                        "green": 255,
                                                        "blue": 255
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                    "name": "Orange",
                                                    "rgb": {
                                                        "red": 241,
                                                        "green": 95,
                                                        "blue": 49
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                    "name": "Pink Fire",
                                                    "rgb": {
                                                        "red": 231,
                                                        "green": 127,
                                                        "blue": 179
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                    "name": "Teal",
                                                    "rgb": {
                                                        "red": 3,
                                                        "green": 96,
                                                        "blue": 112
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                    "name": "Dark Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 88,
                                                        "blue": 55
                                                    },
                                                    "selected": true
                                                },
                                                {
                                                    "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                    "name": "Crimson",
                                                    "rgb": {
                                                        "red": 170,
                                                        "green": 31,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                    "name": "Blue Grey",
                                                    "rgb": {
                                                        "red": 181,
                                                        "green": 183,
                                                        "blue": 186
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                    "name": "Purple",
                                                    "rgb": {
                                                        "red": 70,
                                                        "green": 56,
                                                        "blue": 140
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                    "name": "Light Blue",
                                                    "rgb": {
                                                        "red": 87,
                                                        "green": 153,
                                                        "blue": 206
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                    "name": "Anthracite",
                                                    "rgb": {
                                                        "red": 68,
                                                        "green": 68,
                                                        "blue": 78
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                    "name": "Brown",
                                                    "rgb": {
                                                        "red": 56,
                                                        "green": 44,
                                                        "blue": 38
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                    "name": "Scarlet",
                                                    "rgb": {
                                                        "red": 209,
                                                        "green": 34,
                                                        "blue": 64
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                    "name": "Dark Maroon",
                                                    "rgb": {
                                                        "red": 95,
                                                        "green": 11,
                                                        "blue": 47
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                    "name": "Cardinal",
                                                    "rgb": {
                                                        "red": 126,
                                                        "green": 25,
                                                        "blue": 56
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "ec68de0a-466c-4f2e-9ec5-115add7af6b2",
                                                    "name": "Desert Orange",
                                                    "rgb": {
                                                        "red": 166,
                                                        "green": 84,
                                                        "blue": 31
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                                    "name": "Royal",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 79,
                                                        "blue": 163
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "61b2f7f3-14eb-4f8f-981e-a8e38c528c01",
                                                    "name": "Black",
                                                    "rgb": {
                                                        "red": 27,
                                                        "green": 29,
                                                        "blue": 37
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a3e3dc9b-e48c-4880-a084-d2649ba8b0c0",
                                                    "name": "Bright Gold",
                                                    "rgb": {
                                                        "red": 253,
                                                        "green": 191,
                                                        "blue": 87
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "55e7944b-7ca8-408f-803c-3e226be43789",
                                                    "name": "Vegas Gold",
                                                    "rgb": {
                                                        "red": 220,
                                                        "green": 202,
                                                        "blue": 150
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2b4de46c-622f-4427-bba1-eaea608bea50",
                                                    "name": "Kelly Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 147,
                                                        "blue": 75
                                                    },
                                                    "selected": false
                                                }
                                            ],
                                            "availableSecondaryColors": [],
                                            "availableBackgroundColors": [],
                                            "label": "TEAM NAME",
                                            "fontSize": 443,
                                            "bgHeight": 1000,
                                            "bgWidth": 1000,
                                            "maxCharacters": 13,
                                            "minCharacters": 1,
                                            "textPositionX": 0,
                                            "textPositionY": 0,
                                            "textDistort": "Straight",
                                            "textEditable": "Editable",
                                            "letterSpacing": 3
                                        },
                                        {
                                            "type": "text",
                                            "id": "cj62cvnnv0001355gkvzehx0q",
                                            "name": "MENTALITY",
                                            "positionX": 0,
                                            "positionY": 294,
                                            "height": 1000,
                                            "width": 1000,
                                            "rotationDegrees": 0,
                                            "availableFonts": [
                                                {
                                                    "id": "fd747f60-4757-4deb-a124-75143dbd9297",
                                                    "name": "bureauagencyregular",
                                                    "displayName": "Bureau",
                                                    "type": "Bureau",
                                                    "defaultMaxChars": 10,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "e12de5ae-df24-4515-920d-080dba4bbb50",
                                                    "name": "FutureCourt-SemiBold",
                                                    "displayName": "Futura Semi Bold",
                                                    "type": "Futura",
                                                    "defaultMaxChars": 10,
                                                    "selected": true
                                                }
                                            ],
                                            "availableColors": [
                                                {
                                                    "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                    "name": "White",
                                                    "rgb": {
                                                        "red": 255,
                                                        "green": 255,
                                                        "blue": 255
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                    "name": "Orange",
                                                    "rgb": {
                                                        "red": 241,
                                                        "green": 95,
                                                        "blue": 49
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                    "name": "Pink Fire",
                                                    "rgb": {
                                                        "red": 231,
                                                        "green": 127,
                                                        "blue": 179
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                    "name": "Teal",
                                                    "rgb": {
                                                        "red": 3,
                                                        "green": 96,
                                                        "blue": 112
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                    "name": "Dark Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 88,
                                                        "blue": 55
                                                    },
                                                    "selected": true
                                                },
                                                {
                                                    "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                    "name": "Crimson",
                                                    "rgb": {
                                                        "red": 170,
                                                        "green": 31,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                    "name": "Blue Grey",
                                                    "rgb": {
                                                        "red": 181,
                                                        "green": 183,
                                                        "blue": 186
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                    "name": "Purple",
                                                    "rgb": {
                                                        "red": 70,
                                                        "green": 56,
                                                        "blue": 140
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                    "name": "Light Blue",
                                                    "rgb": {
                                                        "red": 87,
                                                        "green": 153,
                                                        "blue": 206
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                    "name": "Anthracite",
                                                    "rgb": {
                                                        "red": 68,
                                                        "green": 68,
                                                        "blue": 78
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                    "name": "Brown",
                                                    "rgb": {
                                                        "red": 56,
                                                        "green": 44,
                                                        "blue": 38
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                    "name": "Scarlet",
                                                    "rgb": {
                                                        "red": 209,
                                                        "green": 34,
                                                        "blue": 64
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                    "name": "Dark Maroon",
                                                    "rgb": {
                                                        "red": 95,
                                                        "green": 11,
                                                        "blue": 47
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                    "name": "Cardinal",
                                                    "rgb": {
                                                        "red": 126,
                                                        "green": 25,
                                                        "blue": 56
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "ec68de0a-466c-4f2e-9ec5-115add7af6b2",
                                                    "name": "Desert Orange",
                                                    "rgb": {
                                                        "red": 166,
                                                        "green": 84,
                                                        "blue": 31
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "55e7944b-7ca8-408f-803c-3e226be43789",
                                                    "name": "Vegas Gold",
                                                    "rgb": {
                                                        "red": 220,
                                                        "green": 202,
                                                        "blue": 150
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                                    "name": "Royal",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 79,
                                                        "blue": 163
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "61b2f7f3-14eb-4f8f-981e-a8e38c528c01",
                                                    "name": "Black",
                                                    "rgb": {
                                                        "red": 27,
                                                        "green": 29,
                                                        "blue": 37
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a3e3dc9b-e48c-4880-a084-d2649ba8b0c0",
                                                    "name": "Bright Gold",
                                                    "rgb": {
                                                        "red": 253,
                                                        "green": 191,
                                                        "blue": 87
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2b4de46c-622f-4427-bba1-eaea608bea50",
                                                    "name": "Kelly Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 147,
                                                        "blue": 75
                                                    },
                                                    "selected": false
                                                }
                                            ],
                                            "availableSecondaryColors": [],
                                            "availableBackgroundColors": [],
                                            "label": "MENTALITY",
                                            "fontSize": 305,
                                            "bgHeight": 1000,
                                            "bgWidth": 1000,
                                            "maxCharacters": 11,
                                            "minCharacters": 1,
                                            "textPositionX": 0,
                                            "textPositionY": 0,
                                            "textDistort": "Straight",
                                            "textEditable": "Fixed",
                                            "letterSpacing": 3
                                        },
                                        {
                                            "type": "logo",
                                            "id": "cj62czyhf0002355gwc4ew5rn",
                                            "name": "",
                                            "positionX": 2,
                                            "positionY": 242,
                                            "height": 192,
                                            "width": 308,
                                            "rotationDegrees": 0,
                                            "label": "Artwork"
                                        }
                                    ]
                                },
                                "selected": false
                            },
                            {
                                "id": "ae20a4ec-4a4b-4681-b179-04a5ac799a34",
                                "name": "TwoImages",
                                "pricingType": "Uniform",
                                "data": {
                                    "type": "composite",
                                    "image": {
                                        "url": "http://images.nike.net/is/image/GTMDEMO/base_t_cg?$layer_0_opac=0&$layer_0_size=1000,1000&$PNG%20-%20Large%20size%20-%20High%20resolution$&$t1_hide=1&$l1_hide=1&$g1_hide=0&$g1_src=Always-Reppin-Orange&$g1_size=1000,1000&$g1_pos=0,0&$g2_hide=0&$g2_src=Basketball-Object-Black&$g2_size=1000,1000&$g2_pos=0,300"
                                    },
                                    "components": [
                                        {
                                            "type": "graphic",
                                            "id": "cj7tkj2mj0000385sw2cuhmrr",
                                            "name": "",
                                            "positionX": 0,
                                            "positionY": 0,
                                            "height": 1000,
                                            "width": 1000,
                                            "rotationDegrees": 0,
                                            "availableGraphics": [
                                                {
                                                    "id": "eaf372a7-4fb0-4f48-b000-71235b57bc51",
                                                    "name": "Always Reppin",
                                                    "displayName": "Always Reppin",
                                                    "data": {
                                                        "type": "simple",
                                                        "image": {
                                                            "url": "Always-Reppin"
                                                        },
                                                        "availableColors": [
                                                            {
                                                                "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                                "name": "White",
                                                                "rgb": {
                                                                    "red": 255,
                                                                    "green": 255,
                                                                    "blue": 255
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                                "name": "Orange",
                                                                "rgb": {
                                                                    "red": 241,
                                                                    "green": 95,
                                                                    "blue": 49
                                                                },
                                                                "selected": true
                                                            },
                                                            {
                                                                "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                                "name": "Pink Fire",
                                                                "rgb": {
                                                                    "red": 231,
                                                                    "green": 127,
                                                                    "blue": 179
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                                "name": "Teal",
                                                                "rgb": {
                                                                    "red": 3,
                                                                    "green": 96,
                                                                    "blue": 112
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                                "name": "Dark Green",
                                                                "rgb": {
                                                                    "red": 0,
                                                                    "green": 88,
                                                                    "blue": 55
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                                "name": "Crimson",
                                                                "rgb": {
                                                                    "red": 170,
                                                                    "green": 31,
                                                                    "blue": 55
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                                "name": "Blue Grey",
                                                                "rgb": {
                                                                    "red": 181,
                                                                    "green": 183,
                                                                    "blue": 186
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                                "name": "Purple",
                                                                "rgb": {
                                                                    "red": 70,
                                                                    "green": 56,
                                                                    "blue": 140
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                                "name": "Light Blue",
                                                                "rgb": {
                                                                    "red": 87,
                                                                    "green": 153,
                                                                    "blue": 206
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                                "name": "Anthracite",
                                                                "rgb": {
                                                                    "red": 68,
                                                                    "green": 68,
                                                                    "blue": 78
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                                "name": "Brown",
                                                                "rgb": {
                                                                    "red": 56,
                                                                    "green": 44,
                                                                    "blue": 38
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                                "name": "Scarlet",
                                                                "rgb": {
                                                                    "red": 209,
                                                                    "green": 34,
                                                                    "blue": 64
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                                "name": "Dark Maroon",
                                                                "rgb": {
                                                                    "red": 95,
                                                                    "green": 11,
                                                                    "blue": 47
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                                "name": "Cardinal",
                                                                "rgb": {
                                                                    "red": 126,
                                                                    "green": 25,
                                                                    "blue": 56
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "ec68de0a-466c-4f2e-9ec5-115add7af6b2",
                                                                "name": "Desert Orange",
                                                                "rgb": {
                                                                    "red": 166,
                                                                    "green": 84,
                                                                    "blue": 31
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "55e7944b-7ca8-408f-803c-3e226be43789",
                                                                "name": "Vegas Gold",
                                                                "rgb": {
                                                                    "red": 220,
                                                                    "green": 202,
                                                                    "blue": 150
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                                                "name": "Royal",
                                                                "rgb": {
                                                                    "red": 0,
                                                                    "green": 79,
                                                                    "blue": 163
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "61b2f7f3-14eb-4f8f-981e-a8e38c528c01",
                                                                "name": "Black",
                                                                "rgb": {
                                                                    "red": 27,
                                                                    "green": 29,
                                                                    "blue": 37
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "2b4de46c-622f-4427-bba1-eaea608bea50",
                                                                "name": "Kelly Green",
                                                                "rgb": {
                                                                    "red": 0,
                                                                    "green": 147,
                                                                    "blue": 75
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "a3e3dc9b-e48c-4880-a084-d2649ba8b0c0",
                                                                "name": "Bright Gold",
                                                                "rgb": {
                                                                    "red": 253,
                                                                    "green": 191,
                                                                    "blue": 87
                                                                },
                                                                "selected": false
                                                            }
                                                        ],
                                                        "colorCategory": "CUSTOM"
                                                    },
                                                    "selected": true
                                                }
                                            ]
                                        },
                                        {
                                            "type": "graphic",
                                            "id": "cj7tkjgiq0001385skp10e7zm",
                                            "name": "",
                                            "positionX": 0,
                                            "positionY": 300,
                                            "height": 1000,
                                            "width": 1000,
                                            "rotationDegrees": 0,
                                            "availableGraphics": [
                                                {
                                                    "id": "c041f10a-e805-49cc-8876-aabc65c0224e",
                                                    "name": "Basketball Object",
                                                    "displayName": "Basketball Object",
                                                    "data": {
                                                        "type": "simple",
                                                        "image": {
                                                            "url": "Basketball-Object"
                                                        },
                                                        "availableColors": [
                                                            {
                                                                "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                                "name": "White",
                                                                "rgb": {
                                                                    "red": 255,
                                                                    "green": 255,
                                                                    "blue": 255
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                                "name": "Orange",
                                                                "rgb": {
                                                                    "red": 241,
                                                                    "green": 95,
                                                                    "blue": 49
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                                "name": "Pink Fire",
                                                                "rgb": {
                                                                    "red": 231,
                                                                    "green": 127,
                                                                    "blue": 179
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                                "name": "Teal",
                                                                "rgb": {
                                                                    "red": 3,
                                                                    "green": 96,
                                                                    "blue": 112
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                                "name": "Dark Green",
                                                                "rgb": {
                                                                    "red": 0,
                                                                    "green": 88,
                                                                    "blue": 55
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                                "name": "Crimson",
                                                                "rgb": {
                                                                    "red": 170,
                                                                    "green": 31,
                                                                    "blue": 55
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                                "name": "Blue Grey",
                                                                "rgb": {
                                                                    "red": 181,
                                                                    "green": 183,
                                                                    "blue": 186
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                                "name": "Purple",
                                                                "rgb": {
                                                                    "red": 70,
                                                                    "green": 56,
                                                                    "blue": 140
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                                "name": "Light Blue",
                                                                "rgb": {
                                                                    "red": 87,
                                                                    "green": 153,
                                                                    "blue": 206
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                                "name": "Anthracite",
                                                                "rgb": {
                                                                    "red": 68,
                                                                    "green": 68,
                                                                    "blue": 78
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                                "name": "Brown",
                                                                "rgb": {
                                                                    "red": 56,
                                                                    "green": 44,
                                                                    "blue": 38
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                                "name": "Scarlet",
                                                                "rgb": {
                                                                    "red": 209,
                                                                    "green": 34,
                                                                    "blue": 64
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                                "name": "Dark Maroon",
                                                                "rgb": {
                                                                    "red": 95,
                                                                    "green": 11,
                                                                    "blue": 47
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                                "name": "Cardinal",
                                                                "rgb": {
                                                                    "red": 126,
                                                                    "green": 25,
                                                                    "blue": 56
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "ec68de0a-466c-4f2e-9ec5-115add7af6b2",
                                                                "name": "Desert Orange",
                                                                "rgb": {
                                                                    "red": 166,
                                                                    "green": 84,
                                                                    "blue": 31
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "55e7944b-7ca8-408f-803c-3e226be43789",
                                                                "name": "Vegas Gold",
                                                                "rgb": {
                                                                    "red": 220,
                                                                    "green": 202,
                                                                    "blue": 150
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                                                "name": "Royal",
                                                                "rgb": {
                                                                    "red": 0,
                                                                    "green": 79,
                                                                    "blue": 163
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "61b2f7f3-14eb-4f8f-981e-a8e38c528c01",
                                                                "name": "Black",
                                                                "rgb": {
                                                                    "red": 27,
                                                                    "green": 29,
                                                                    "blue": 37
                                                                },
                                                                "selected": true
                                                            },
                                                            {
                                                                "id": "2b4de46c-622f-4427-bba1-eaea608bea50",
                                                                "name": "Kelly Green",
                                                                "rgb": {
                                                                    "red": 0,
                                                                    "green": 147,
                                                                    "blue": 75
                                                                },
                                                                "selected": false
                                                            },
                                                            {
                                                                "id": "a3e3dc9b-e48c-4880-a084-d2649ba8b0c0",
                                                                "name": "Bright Gold",
                                                                "rgb": {
                                                                    "red": 253,
                                                                    "green": 191,
                                                                    "blue": 87
                                                                },
                                                                "selected": false
                                                            }
                                                        ],
                                                        "colorCategory": "CUSTOM"
                                                    },
                                                    "selected": true
                                                }
                                            ]
                                        },
                                        {
                                            "type": "text",
                                            "id": "cj7tkklll0002385szuzp8i7w",
                                            "name": "GRIZZLYS",
                                            "positionX": 0,
                                            "positionY": 0,
                                            "height": 1000,
                                            "width": 1000,
                                            "rotationDegrees": 0,
                                            "availableFonts": [
                                                {
                                                    "id": "e12de5ae-df24-4515-920d-080dba4bbb50",
                                                    "name": "FutureCourt-SemiBold",
                                                    "displayName": "Futura Semi Bold",
                                                    "type": "Futura",
                                                    "defaultMaxChars": 10,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "fd747f60-4757-4deb-a124-75143dbd9297",
                                                    "name": "bureauagencyregular",
                                                    "displayName": "Bureau",
                                                    "type": "Bureau",
                                                    "defaultMaxChars": 10,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "df5fd123-910e-4467-a6e9-fc51e785a25a",
                                                    "name": "FuturConExtBold",
                                                    "displayName": "Futura Extra Bold",
                                                    "type": "Futura",
                                                    "defaultMaxChars": 10,
                                                    "selected": true
                                                }
                                            ],
                                            "availableColors": [
                                                {
                                                    "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                    "name": "White",
                                                    "rgb": {
                                                        "red": 255,
                                                        "green": 255,
                                                        "blue": 255
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                    "name": "Orange",
                                                    "rgb": {
                                                        "red": 241,
                                                        "green": 95,
                                                        "blue": 49
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                    "name": "Pink Fire",
                                                    "rgb": {
                                                        "red": 231,
                                                        "green": 127,
                                                        "blue": 179
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                    "name": "Teal",
                                                    "rgb": {
                                                        "red": 3,
                                                        "green": 96,
                                                        "blue": 112
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                    "name": "Dark Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 88,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                    "name": "Crimson",
                                                    "rgb": {
                                                        "red": 170,
                                                        "green": 31,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                    "name": "Blue Grey",
                                                    "rgb": {
                                                        "red": 181,
                                                        "green": 183,
                                                        "blue": 186
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                    "name": "Purple",
                                                    "rgb": {
                                                        "red": 70,
                                                        "green": 56,
                                                        "blue": 140
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                    "name": "Light Blue",
                                                    "rgb": {
                                                        "red": 87,
                                                        "green": 153,
                                                        "blue": 206
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                    "name": "Anthracite",
                                                    "rgb": {
                                                        "red": 68,
                                                        "green": 68,
                                                        "blue": 78
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                    "name": "Brown",
                                                    "rgb": {
                                                        "red": 56,
                                                        "green": 44,
                                                        "blue": 38
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                    "name": "Scarlet",
                                                    "rgb": {
                                                        "red": 209,
                                                        "green": 34,
                                                        "blue": 64
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                    "name": "Dark Maroon",
                                                    "rgb": {
                                                        "red": 95,
                                                        "green": 11,
                                                        "blue": 47
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                    "name": "Cardinal",
                                                    "rgb": {
                                                        "red": 126,
                                                        "green": 25,
                                                        "blue": 56
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "ec68de0a-466c-4f2e-9ec5-115add7af6b2",
                                                    "name": "Desert Orange",
                                                    "rgb": {
                                                        "red": 166,
                                                        "green": 84,
                                                        "blue": 31
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "55e7944b-7ca8-408f-803c-3e226be43789",
                                                    "name": "Vegas Gold",
                                                    "rgb": {
                                                        "red": 220,
                                                        "green": 202,
                                                        "blue": 150
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                                    "name": "Royal",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 79,
                                                        "blue": 163
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "61b2f7f3-14eb-4f8f-981e-a8e38c528c01",
                                                    "name": "Black",
                                                    "rgb": {
                                                        "red": 27,
                                                        "green": 29,
                                                        "blue": 37
                                                    },
                                                    "selected": true
                                                },
                                                {
                                                    "id": "2b4de46c-622f-4427-bba1-eaea608bea50",
                                                    "name": "Kelly Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 147,
                                                        "blue": 75
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a3e3dc9b-e48c-4880-a084-d2649ba8b0c0",
                                                    "name": "Bright Gold",
                                                    "rgb": {
                                                        "red": 253,
                                                        "green": 191,
                                                        "blue": 87
                                                    },
                                                    "selected": false
                                                }
                                            ],
                                            "availableSecondaryColors": [
                                                {
                                                    "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                    "name": "White",
                                                    "rgb": {
                                                        "red": 255,
                                                        "green": 255,
                                                        "blue": 255
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                    "name": "Orange",
                                                    "rgb": {
                                                        "red": 241,
                                                        "green": 95,
                                                        "blue": 49
                                                    },
                                                    "selected": true
                                                },
                                                {
                                                    "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                    "name": "Pink Fire",
                                                    "rgb": {
                                                        "red": 231,
                                                        "green": 127,
                                                        "blue": 179
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                    "name": "Teal",
                                                    "rgb": {
                                                        "red": 3,
                                                        "green": 96,
                                                        "blue": 112
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                    "name": "Dark Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 88,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                    "name": "Crimson",
                                                    "rgb": {
                                                        "red": 170,
                                                        "green": 31,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                    "name": "Blue Grey",
                                                    "rgb": {
                                                        "red": 181,
                                                        "green": 183,
                                                        "blue": 186
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                    "name": "Purple",
                                                    "rgb": {
                                                        "red": 70,
                                                        "green": 56,
                                                        "blue": 140
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                    "name": "Light Blue",
                                                    "rgb": {
                                                        "red": 87,
                                                        "green": 153,
                                                        "blue": 206
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                    "name": "Anthracite",
                                                    "rgb": {
                                                        "red": 68,
                                                        "green": 68,
                                                        "blue": 78
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                    "name": "Brown",
                                                    "rgb": {
                                                        "red": 56,
                                                        "green": 44,
                                                        "blue": 38
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                    "name": "Scarlet",
                                                    "rgb": {
                                                        "red": 209,
                                                        "green": 34,
                                                        "blue": 64
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                    "name": "Dark Maroon",
                                                    "rgb": {
                                                        "red": 95,
                                                        "green": 11,
                                                        "blue": 47
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                    "name": "Cardinal",
                                                    "rgb": {
                                                        "red": 126,
                                                        "green": 25,
                                                        "blue": 56
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "ec68de0a-466c-4f2e-9ec5-115add7af6b2",
                                                    "name": "Desert Orange",
                                                    "rgb": {
                                                        "red": 166,
                                                        "green": 84,
                                                        "blue": 31
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "55e7944b-7ca8-408f-803c-3e226be43789",
                                                    "name": "Vegas Gold",
                                                    "rgb": {
                                                        "red": 220,
                                                        "green": 202,
                                                        "blue": 150
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                                    "name": "Royal",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 79,
                                                        "blue": 163
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "61b2f7f3-14eb-4f8f-981e-a8e38c528c01",
                                                    "name": "Black",
                                                    "rgb": {
                                                        "red": 27,
                                                        "green": 29,
                                                        "blue": 37
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2b4de46c-622f-4427-bba1-eaea608bea50",
                                                    "name": "Kelly Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 147,
                                                        "blue": 75
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a3e3dc9b-e48c-4880-a084-d2649ba8b0c0",
                                                    "name": "Bright Gold",
                                                    "rgb": {
                                                        "red": 253,
                                                        "green": 191,
                                                        "blue": 87
                                                    },
                                                    "selected": false
                                                }
                                            ],
                                            "availableBackgroundColors": [],
                                            "label": "",
                                            "fontSize": 232,
                                            "bgHeight": 1000,
                                            "bgWidth": 1000,
                                            "maxCharacters": 11,
                                            "minCharacters": 1,
                                            "textPositionX": 0,
                                            "textPositionY": 0,
                                            "textDistort": "Straight",
                                            "textEditable": "Editable",
                                            "letterSpacing": 1,
                                            "textCategory": "GENERAL",
                                            "primaryColorCategory": "CUSTOM",
                                            "secondaryColorCategory": "CUSTOM",
                                            "backgroundColorCategory": "CUSTOM"
                                        }
                                    ]
                                },
                                "selected": false
                            },
                            {
                                "id": "557eafa9-355f-424d-b1de-c8e969949bbc",
                                "name": "NumberFontTest",
                                "pricingType": "Uniform",
                                "data": {
                                    "type": "composite",
                                    "image": {
                                        "url": "http://images.nike.net/is/image/GTMDEMO/base_t_cg?$layer_0_opac=0&$layer_0_size=1000,1000&$PNG%20-%20Large%20size%20-%20High%20resolution$&$t1_hide=1&$l1_hide=1&$g1_hide=1"
                                    },
                                    "components": [
                                        {
                                            "type": "text",
                                            "id": "cj80m0nxa0000385prq0eyvdi",
                                            "name": "TEST",
                                            "positionX": 0,
                                            "positionY": 0,
                                            "height": 1000,
                                            "width": 1000,
                                            "rotationDegrees": 0,
                                            "availableFonts": [
                                                {
                                                    "id": "db464ba6-1579-4154-98aa-d4c74aa80e5b",
                                                    "name": "michigan_fill_2010regular",
                                                    "displayName": "Michigan",
                                                    "type": "Michigan State",
                                                    "defaultMaxChars": 10,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "df5fd123-910e-4467-a6e9-fc51e785a25a",
                                                    "name": "FuturConExtBold",
                                                    "displayName": "Futura Extra Bold",
                                                    "type": "Futura",
                                                    "defaultMaxChars": 10,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "ae5b2d23-c424-478b-9e0f-bae79cc4be13",
                                                    "name": "Tuscon-CondensedBold",
                                                    "displayName": "Tuscon",
                                                    "type": "Tucson",
                                                    "defaultMaxChars": 10,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "fd747f60-4757-4deb-a124-75143dbd9297",
                                                    "name": "bureauagencyregular",
                                                    "displayName": "Bureau",
                                                    "type": "Bureau",
                                                    "defaultMaxChars": 10,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "e12de5ae-df24-4515-920d-080dba4bbb50",
                                                    "name": "FutureCourt-SemiBold",
                                                    "displayName": "Futura Semi Bold",
                                                    "type": "Futura",
                                                    "defaultMaxChars": 10,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "f0ad6b2b-d641-4276-b6ed-2460cdbbb009",
                                                    "name": "UnitedSansSemiCondBold",
                                                    "displayName": "United Sans Semi Cond. Bold",
                                                    "type": "United Sans",
                                                    "defaultMaxChars": 10,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "f82af8c3-2780-4793-84e3-5fb0721afc16",
                                                    "name": "NikeUncontestedRegular",
                                                    "displayName": "Nike Uncontested",
                                                    "type": "Nike Uncontested",
                                                    "defaultMaxChars": 12,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "09415d40-a775-49b0-982f-71fb7041a126",
                                                    "name": "kansasregular",
                                                    "displayName": "Kansas",
                                                    "type": "Kansas",
                                                    "defaultMaxChars": 10,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2f0f236b-e73c-4f30-bd69-b1ffec04ef9e",
                                                    "name": "fenwayparkjfregular",
                                                    "displayName": "Fenway",
                                                    "type": "Script",
                                                    "defaultMaxChars": 10,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2fc8c434-3f0f-4a69-bd5e-2c05f1fc8e87",
                                                    "name": "FuturConExtBoldItalic",
                                                    "displayName": "Futura Extra Bold Italic",
                                                    "type": "Futura",
                                                    "defaultMaxChars": 12,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "d2ef4525-3ee3-40df-9150-c681e9534a90",
                                                    "name": "full_block_nine_to_twelveRg",
                                                    "displayName": "Narrow Full Block",
                                                    "type": "Narrow Full Block",
                                                    "defaultMaxChars": 12,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3a0b4539-42ef-412f-bb54-0e2cea194cd4",
                                                    "name": "UnitedSansCdHv",
                                                    "displayName": "United Sans CdHv",
                                                    "type": "United Sans",
                                                    "defaultMaxChars": 10,
                                                    "selected": true
                                                }
                                            ],
                                            "availableColors": [
                                                {
                                                    "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                    "name": "White",
                                                    "rgb": {
                                                        "red": 255,
                                                        "green": 255,
                                                        "blue": 255
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                    "name": "Orange",
                                                    "rgb": {
                                                        "red": 241,
                                                        "green": 95,
                                                        "blue": 49
                                                    },
                                                    "selected": true
                                                },
                                                {
                                                    "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                    "name": "Pink Fire",
                                                    "rgb": {
                                                        "red": 231,
                                                        "green": 127,
                                                        "blue": 179
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                    "name": "Teal",
                                                    "rgb": {
                                                        "red": 3,
                                                        "green": 96,
                                                        "blue": 112
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                    "name": "Dark Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 88,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                    "name": "Crimson",
                                                    "rgb": {
                                                        "red": 170,
                                                        "green": 31,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                    "name": "Blue Grey",
                                                    "rgb": {
                                                        "red": 181,
                                                        "green": 183,
                                                        "blue": 186
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                    "name": "Purple",
                                                    "rgb": {
                                                        "red": 70,
                                                        "green": 56,
                                                        "blue": 140
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                    "name": "Light Blue",
                                                    "rgb": {
                                                        "red": 87,
                                                        "green": 153,
                                                        "blue": 206
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                    "name": "Anthracite",
                                                    "rgb": {
                                                        "red": 68,
                                                        "green": 68,
                                                        "blue": 78
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                    "name": "Brown",
                                                    "rgb": {
                                                        "red": 56,
                                                        "green": 44,
                                                        "blue": 38
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                    "name": "Scarlet",
                                                    "rgb": {
                                                        "red": 209,
                                                        "green": 34,
                                                        "blue": 64
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                    "name": "Dark Maroon",
                                                    "rgb": {
                                                        "red": 95,
                                                        "green": 11,
                                                        "blue": 47
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                    "name": "Cardinal",
                                                    "rgb": {
                                                        "red": 126,
                                                        "green": 25,
                                                        "blue": 56
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "ec68de0a-466c-4f2e-9ec5-115add7af6b2",
                                                    "name": "Desert Orange",
                                                    "rgb": {
                                                        "red": 166,
                                                        "green": 84,
                                                        "blue": 31
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "55e7944b-7ca8-408f-803c-3e226be43789",
                                                    "name": "Vegas Gold",
                                                    "rgb": {
                                                        "red": 220,
                                                        "green": 202,
                                                        "blue": 150
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                                    "name": "Royal",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 79,
                                                        "blue": 163
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "61b2f7f3-14eb-4f8f-981e-a8e38c528c01",
                                                    "name": "Black",
                                                    "rgb": {
                                                        "red": 27,
                                                        "green": 29,
                                                        "blue": 37
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2b4de46c-622f-4427-bba1-eaea608bea50",
                                                    "name": "Kelly Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 147,
                                                        "blue": 75
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a3e3dc9b-e48c-4880-a084-d2649ba8b0c0",
                                                    "name": "Bright Gold",
                                                    "rgb": {
                                                        "red": 253,
                                                        "green": 191,
                                                        "blue": 87
                                                    },
                                                    "selected": false
                                                }
                                            ],
                                            "availableSecondaryColors": [
                                                {
                                                    "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                    "name": "White",
                                                    "rgb": {
                                                        "red": 255,
                                                        "green": 255,
                                                        "blue": 255
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                    "name": "Orange",
                                                    "rgb": {
                                                        "red": 241,
                                                        "green": 95,
                                                        "blue": 49
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                    "name": "Pink Fire",
                                                    "rgb": {
                                                        "red": 231,
                                                        "green": 127,
                                                        "blue": 179
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                    "name": "Teal",
                                                    "rgb": {
                                                        "red": 3,
                                                        "green": 96,
                                                        "blue": 112
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                    "name": "Dark Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 88,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                    "name": "Crimson",
                                                    "rgb": {
                                                        "red": 170,
                                                        "green": 31,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                    "name": "Blue Grey",
                                                    "rgb": {
                                                        "red": 181,
                                                        "green": 183,
                                                        "blue": 186
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                    "name": "Purple",
                                                    "rgb": {
                                                        "red": 70,
                                                        "green": 56,
                                                        "blue": 140
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                    "name": "Light Blue",
                                                    "rgb": {
                                                        "red": 87,
                                                        "green": 153,
                                                        "blue": 206
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                    "name": "Anthracite",
                                                    "rgb": {
                                                        "red": 68,
                                                        "green": 68,
                                                        "blue": 78
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                    "name": "Brown",
                                                    "rgb": {
                                                        "red": 56,
                                                        "green": 44,
                                                        "blue": 38
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                    "name": "Scarlet",
                                                    "rgb": {
                                                        "red": 209,
                                                        "green": 34,
                                                        "blue": 64
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                    "name": "Dark Maroon",
                                                    "rgb": {
                                                        "red": 95,
                                                        "green": 11,
                                                        "blue": 47
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                    "name": "Cardinal",
                                                    "rgb": {
                                                        "red": 126,
                                                        "green": 25,
                                                        "blue": 56
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "ec68de0a-466c-4f2e-9ec5-115add7af6b2",
                                                    "name": "Desert Orange",
                                                    "rgb": {
                                                        "red": 166,
                                                        "green": 84,
                                                        "blue": 31
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "55e7944b-7ca8-408f-803c-3e226be43789",
                                                    "name": "Vegas Gold",
                                                    "rgb": {
                                                        "red": 220,
                                                        "green": 202,
                                                        "blue": 150
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                                    "name": "Royal",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 79,
                                                        "blue": 163
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "61b2f7f3-14eb-4f8f-981e-a8e38c528c01",
                                                    "name": "Black",
                                                    "rgb": {
                                                        "red": 27,
                                                        "green": 29,
                                                        "blue": 37
                                                    },
                                                    "selected": true
                                                },
                                                {
                                                    "id": "2b4de46c-622f-4427-bba1-eaea608bea50",
                                                    "name": "Kelly Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 147,
                                                        "blue": 75
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a3e3dc9b-e48c-4880-a084-d2649ba8b0c0",
                                                    "name": "Bright Gold",
                                                    "rgb": {
                                                        "red": 253,
                                                        "green": 191,
                                                        "blue": 87
                                                    },
                                                    "selected": false
                                                }
                                            ],
                                            "availableBackgroundColors": [],
                                            "label": "",
                                            "fontSize": 200,
                                            "bgHeight": 1000,
                                            "bgWidth": 1000,
                                            "maxCharacters": 7,
                                            "minCharacters": 1,
                                            "textPositionX": 0,
                                            "textPositionY": 0,
                                            "textDistort": "Straight",
                                            "textEditable": "Editable",
                                            "letterSpacing": 1,
                                            "textCategory": "GENERAL",
                                            "primaryColorCategory": "CUSTOM",
                                            "secondaryColorCategory": "CUSTOM",
                                            "backgroundColorCategory": "CUSTOM"
                                        },
                                        {
                                            "type": "text",
                                            "id": "cj80m15yj0001385pxd87gc0l",
                                            "name": "24",
                                            "positionX": 0,
                                            "positionY": 300,
                                            "height": 1000,
                                            "width": 1000,
                                            "rotationDegrees": 0,
                                            "availableFonts": [
                                                {
                                                    "id": "96d2a171-9c14-4030-bd8c-800c4e85266d",
                                                    "name": "fenwayparkjfregular",
                                                    "displayName": "Fenway Number",
                                                    "type": "Script Number",
                                                    "defaultMaxChars": 2,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "d2ef4525-3ee3-40df-9150-c681e9534a90",
                                                    "name": "full_block_nine_to_twelveRg",
                                                    "displayName": "Narrow Full Block",
                                                    "type": "Narrow Full Block",
                                                    "defaultMaxChars": 12,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "279007d8-e068-47f1-bfbb-60e3db03b8d7",
                                                    "name": "michigan_number",
                                                    "displayName": "Michigan Number",
                                                    "type": "Michigan State Number",
                                                    "defaultMaxChars": 2,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "e2362777-6fa5-450f-aba2-da97d1878b84",
                                                    "name": "tuscon_number",
                                                    "displayName": "Tuscon Number",
                                                    "type": "Tucson Number",
                                                    "defaultMaxChars": 2,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "f4ec4747-c51c-4a74-8196-58905e9d5e39",
                                                    "name": "bureauagencynumber",
                                                    "displayName": "Bureau Number",
                                                    "type": "Bureau Number",
                                                    "defaultMaxChars": 2,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "baf06c09-6df7-4cbe-8e8e-e80eaff69495",
                                                    "name": "NikeUncontestedRegular",
                                                    "displayName": "Nike Uncontested Number",
                                                    "type": "Nike Uncontested Number",
                                                    "defaultMaxChars": 2,
                                                    "selected": false
                                                },
                                                {
                                                    "id": "cf2e5201-130d-4dcf-850e-9cf500beea43",
                                                    "name": "full_block_number",
                                                    "displayName": "Narrow Full Block Number",
                                                    "type": "Narrow Full Block Number",
                                                    "defaultMaxChars": 2,
                                                    "selected": true
                                                }
                                            ],
                                            "availableColors": [
                                                {
                                                    "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                    "name": "White",
                                                    "rgb": {
                                                        "red": 255,
                                                        "green": 255,
                                                        "blue": 255
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                    "name": "Orange",
                                                    "rgb": {
                                                        "red": 241,
                                                        "green": 95,
                                                        "blue": 49
                                                    },
                                                    "selected": true
                                                },
                                                {
                                                    "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                    "name": "Pink Fire",
                                                    "rgb": {
                                                        "red": 231,
                                                        "green": 127,
                                                        "blue": 179
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                    "name": "Teal",
                                                    "rgb": {
                                                        "red": 3,
                                                        "green": 96,
                                                        "blue": 112
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                    "name": "Dark Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 88,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                    "name": "Crimson",
                                                    "rgb": {
                                                        "red": 170,
                                                        "green": 31,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                    "name": "Blue Grey",
                                                    "rgb": {
                                                        "red": 181,
                                                        "green": 183,
                                                        "blue": 186
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                    "name": "Purple",
                                                    "rgb": {
                                                        "red": 70,
                                                        "green": 56,
                                                        "blue": 140
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                    "name": "Light Blue",
                                                    "rgb": {
                                                        "red": 87,
                                                        "green": 153,
                                                        "blue": 206
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                    "name": "Anthracite",
                                                    "rgb": {
                                                        "red": 68,
                                                        "green": 68,
                                                        "blue": 78
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                    "name": "Brown",
                                                    "rgb": {
                                                        "red": 56,
                                                        "green": 44,
                                                        "blue": 38
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                    "name": "Scarlet",
                                                    "rgb": {
                                                        "red": 209,
                                                        "green": 34,
                                                        "blue": 64
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                    "name": "Dark Maroon",
                                                    "rgb": {
                                                        "red": 95,
                                                        "green": 11,
                                                        "blue": 47
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                    "name": "Cardinal",
                                                    "rgb": {
                                                        "red": 126,
                                                        "green": 25,
                                                        "blue": 56
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "ec68de0a-466c-4f2e-9ec5-115add7af6b2",
                                                    "name": "Desert Orange",
                                                    "rgb": {
                                                        "red": 166,
                                                        "green": 84,
                                                        "blue": 31
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "55e7944b-7ca8-408f-803c-3e226be43789",
                                                    "name": "Vegas Gold",
                                                    "rgb": {
                                                        "red": 220,
                                                        "green": 202,
                                                        "blue": 150
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                                    "name": "Royal",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 79,
                                                        "blue": 163
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "61b2f7f3-14eb-4f8f-981e-a8e38c528c01",
                                                    "name": "Black",
                                                    "rgb": {
                                                        "red": 27,
                                                        "green": 29,
                                                        "blue": 37
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2b4de46c-622f-4427-bba1-eaea608bea50",
                                                    "name": "Kelly Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 147,
                                                        "blue": 75
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a3e3dc9b-e48c-4880-a084-d2649ba8b0c0",
                                                    "name": "Bright Gold",
                                                    "rgb": {
                                                        "red": 253,
                                                        "green": 191,
                                                        "blue": 87
                                                    },
                                                    "selected": false
                                                }
                                            ],
                                            "availableSecondaryColors": [
                                                {
                                                    "id": "bc24969c-87b7-440b-b757-a0d52ace4de6",
                                                    "name": "White",
                                                    "rgb": {
                                                        "red": 255,
                                                        "green": 255,
                                                        "blue": 255
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2777c9ce-d30c-4207-9c3f-a72fad0e15a5",
                                                    "name": "Orange",
                                                    "rgb": {
                                                        "red": 241,
                                                        "green": 95,
                                                        "blue": 49
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "95c4a030-6623-4276-8503-68b72113ef36",
                                                    "name": "Pink Fire",
                                                    "rgb": {
                                                        "red": 231,
                                                        "green": 127,
                                                        "blue": 179
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1f5e6294-ef3b-4cb1-8da2-0ecfbf916088",
                                                    "name": "Teal",
                                                    "rgb": {
                                                        "red": 3,
                                                        "green": 96,
                                                        "blue": 112
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "aab2e2c3-0a16-4842-8bd8-87c25ed29c97",
                                                    "name": "Dark Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 88,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "65749dd7-4709-4245-95b3-7525f4860895",
                                                    "name": "Crimson",
                                                    "rgb": {
                                                        "red": 170,
                                                        "green": 31,
                                                        "blue": 55
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "1b713b03-b96a-4ed5-9103-5c93c37ea2d6",
                                                    "name": "Blue Grey",
                                                    "rgb": {
                                                        "red": 181,
                                                        "green": 183,
                                                        "blue": 186
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3bde48fc-4b9e-4788-84f8-2f5203a001e3",
                                                    "name": "Purple",
                                                    "rgb": {
                                                        "red": 70,
                                                        "green": 56,
                                                        "blue": 140
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "3a6d7ab0-f779-44c4-b78f-0570fe20d348",
                                                    "name": "Light Blue",
                                                    "rgb": {
                                                        "red": 87,
                                                        "green": 153,
                                                        "blue": 206
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                                    "name": "Anthracite",
                                                    "rgb": {
                                                        "red": 68,
                                                        "green": 68,
                                                        "blue": 78
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "2d88578f-adc3-4318-8955-073122e92d63",
                                                    "name": "Brown",
                                                    "rgb": {
                                                        "red": 56,
                                                        "green": 44,
                                                        "blue": 38
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "5b2ca966-bab7-40c0-8258-878ce4c9976e",
                                                    "name": "Scarlet",
                                                    "rgb": {
                                                        "red": 209,
                                                        "green": 34,
                                                        "blue": 64
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "13315a2f-1a33-49a0-8ae8-e376bb040dff",
                                                    "name": "Dark Maroon",
                                                    "rgb": {
                                                        "red": 95,
                                                        "green": 11,
                                                        "blue": 47
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "c0a54c4e-2bdf-4c28-a296-311e5505e351",
                                                    "name": "Cardinal",
                                                    "rgb": {
                                                        "red": 126,
                                                        "green": 25,
                                                        "blue": 56
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "ec68de0a-466c-4f2e-9ec5-115add7af6b2",
                                                    "name": "Desert Orange",
                                                    "rgb": {
                                                        "red": 166,
                                                        "green": 84,
                                                        "blue": 31
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "55e7944b-7ca8-408f-803c-3e226be43789",
                                                    "name": "Vegas Gold",
                                                    "rgb": {
                                                        "red": 220,
                                                        "green": 202,
                                                        "blue": 150
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                                    "name": "Royal",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 79,
                                                        "blue": 163
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "61b2f7f3-14eb-4f8f-981e-a8e38c528c01",
                                                    "name": "Black",
                                                    "rgb": {
                                                        "red": 27,
                                                        "green": 29,
                                                        "blue": 37
                                                    },
                                                    "selected": true
                                                },
                                                {
                                                    "id": "2b4de46c-622f-4427-bba1-eaea608bea50",
                                                    "name": "Kelly Green",
                                                    "rgb": {
                                                        "red": 0,
                                                        "green": 147,
                                                        "blue": 75
                                                    },
                                                    "selected": false
                                                },
                                                {
                                                    "id": "a3e3dc9b-e48c-4880-a084-d2649ba8b0c0",
                                                    "name": "Bright Gold",
                                                    "rgb": {
                                                        "red": 253,
                                                        "green": 191,
                                                        "blue": 87
                                                    },
                                                    "selected": false
                                                }
                                            ],
                                            "availableBackgroundColors": [],
                                            "label": "",
                                            "fontSize": 300,
                                            "bgHeight": 1000,
                                            "bgWidth": 1000,
                                            "maxCharacters": 2,
                                            "minCharacters": 1,
                                            "textPositionX": 0,
                                            "textPositionY": 0,
                                            "textDistort": "Straight",
                                            "textEditable": "Editable",
                                            "letterSpacing": 1,
                                            "textCategory": "GENERAL",
                                            "primaryColorCategory": "CUSTOM",
                                            "secondaryColorCategory": "CUSTOM",
                                            "backgroundColorCategory": "CUSTOM"
                                        }
                                    ]
                                },
                                "selected": true
                            }
                        ]
                    }
                ]
            },
            "gender": "Men"
        }
    }
}

export const AtOnceDetailsMock = [
  {
    "id": "GD73XANNNMZGVUKFM5QNP64SW663JOTJ",
    "styleColor": "867666-012",
    "season": "FA2017",
    "region": "USA",
    "currency": "USD",
    "priceListType": "USA",
    "style": "867666",
    "color": "012",
    "gender": "MENS",
    "type": "APRL",
    "category": "BASKETBALL",
    "silhouette": "TOP",
    "silo": "UNIFORMS",
    "deliveryUnitNumber": 1,
    "wholesalePrice": 2750,
    "retailPrice": 5500,
    "beginOfferDate": 1498867200000,
    "endOfferDate": 1546214400000,
    "languages": [
      {
        "locale": {
          "region": "USA",
          "language": "EN"
        },
        "name": "M NK JSY HYPERELITE STOCK",
        "nameSec": null,
        "description": "THE NIKE HYPERELITE JERSEY DELIVERS ZERO DISTRACTIONS ON THE COURT. DRI-FIT FABRIC HELPS YOU STAY COOL. SLIM T WEARS CLOSE TO YOUR BODY FOR UNRESTRICTED MOVEMENT. BACK FEATURES OPEN HOLE MESH FOR VENTILATION. NIKE TEAM SPORTS JOCK TAG AT LOWER LEFT HEM. NIKE SWOOSH DESIGN TRADEMARK ON RIGHT CHEST.",
        "colorDescription": "TM BLACK/TM WHITE/TM WHITE",
        "reasonToBuy": null,
        "fabricContent": "100% POLYESTER",
        "productFeatures": null
      }
    ],
    "sizes": [
      {
        "code": "2XL-T",
        "display": "2XL-T",
        "sortOrder": 60,
        "upc": "00826218535437"
      },
      {
        "code": "S-T",
        "display": "S-T",
        "sortOrder": 50,
        "upc": "00887227481689"
      },
      {
        "code": "3XL-T",
        "display": "3XL-T",
        "sortOrder": 62,
        "upc": "00826218535444"
      },
      {
        "code": "M-T",
        "display": "M-T",
        "sortOrder": 52,
        "upc": "00826218535307"
      },
      {
        "code": "2XL",
        "display": "2XL",
        "sortOrder": 15,
        "upc": "00826218535017"
      },
      {
        "code": "L",
        "display": "L",
        "sortOrder": 10,
        "upc": "00826218534966"
      },
      {
        "code": "4XL",
        "display": "4XL",
        "sortOrder": 17,
        "upc": "00887227481610"
      },
      {
        "code": "M",
        "display": "M",
        "sortOrder": 9,
        "upc": "00826218534935"
      },
      {
        "code": "XL",
        "display": "XL",
        "sortOrder": 11,
        "upc": "00826218534980"
      },
      {
        "code": "XL-T",
        "display": "XL-T",
        "sortOrder": 56,
        "upc": "00826218535321"
      },
      {
        "code": "3XL",
        "display": "3XL",
        "sortOrder": 16,
        "upc": "00826218535024"
      },
      {
        "code": "L-T",
        "display": "L-T",
        "sortOrder": 54,
        "upc": "00826218535314"
      },
      {
        "code": "S",
        "display": "S",
        "sortOrder": 8,
        "upc": "00826218534379"
      }
    ],
    "availability": {
      "totalAvailabilities": {
        "0": 2989,
        "15": 2989,
        "30": 2989,
        "60": 2989,
        "90": 3352,
        "180": 4902
      },
      "availability": [
        {
          "distributionCenter": {
            "number": 1017,
            "name": "Robinson",
            "salesOrg": 1000
          },
          "isegName": null,
          "availabilities": [
            {
              "date": 1510041600000,
              "sizes": [
                {
                  "sizeType": null,
                  "code": "XL",
                  "quantity": 64,
                  "upc": "00826218534980"
                },
                {
                  "sizeType": null,
                  "code": "2XL",
                  "quantity": 15,
                  "upc": "00826218535017"
                },
                {
                  "sizeType": null,
                  "code": "M",
                  "quantity": 110,
                  "upc": "00826218534935"
                },
                {
                  "sizeType": null,
                  "code": "L",
                  "quantity": 125,
                  "upc": "00826218534966"
                },
                {
                  "sizeType": null,
                  "code": "S",
                  "quantity": 48,
                  "upc": "00826218534379"
                }
              ]
            },
            {
              "date": 1510646400000,
              "sizes": [
                {
                  "sizeType": null,
                  "code": "2XL",
                  "quantity": 35,
                  "upc": "00826218535017"
                },
                {
                  "sizeType": null,
                  "code": "L",
                  "quantity": 303,
                  "upc": "00826218534966"
                },
                {
                  "sizeType": null,
                  "code": "M",
                  "quantity": 267,
                  "upc": "00826218534935"
                },
                {
                  "sizeType": null,
                  "code": "XL",
                  "quantity": 155,
                  "upc": "00826218534980"
                },
                {
                  "sizeType": null,
                  "code": "S",
                  "quantity": 118,
                  "upc": "00826218534379"
                }
              ]
            },
            {
              "date": 1506409200000,
              "sizes": [
                {
                  "sizeType": null,
                  "code": "L",
                  "quantity": 78,
                  "upc": "00826218534966"
                },
                {
                  "sizeType": null,
                  "code": "S",
                  "quantity": 122,
                  "upc": "00826218534379"
                },
                {
                  "sizeType": null,
                  "code": "M",
                  "quantity": 43,
                  "upc": "00826218534935"
                },
                {
                  "sizeType": null,
                  "code": "XL",
                  "quantity": 120,
                  "upc": "00826218534980"
                }
              ]
            },
            {
              "date": 1514966400000,
              "sizes": [
                {
                  "sizeType": null,
                  "code": "XL-T",
                  "quantity": 35,
                  "upc": "00826218535321"
                },
                {
                  "sizeType": null,
                  "code": "4XL",
                  "quantity": 35,
                  "upc": "00887227481610"
                },
                {
                  "sizeType": null,
                  "code": "S-T",
                  "quantity": 35,
                  "upc": "00887227481689"
                },
                {
                  "sizeType": null,
                  "code": "L-T",
                  "quantity": 35,
                  "upc": "00826218535314"
                },
                {
                  "sizeType": null,
                  "code": "3XL",
                  "quantity": 2,
                  "upc": "00826218535024"
                },
                {
                  "sizeType": null,
                  "code": "M-T",
                  "quantity": 35,
                  "upc": "00826218535307"
                },
                {
                  "sizeType": null,
                  "code": "S",
                  "quantity": 38,
                  "upc": "00826218534379"
                },
                {
                  "sizeType": null,
                  "code": "L",
                  "quantity": 25,
                  "upc": "00826218534966"
                },
                {
                  "sizeType": null,
                  "code": "2XL-T",
                  "quantity": 35,
                  "upc": "00826218535437"
                },
                {
                  "sizeType": null,
                  "code": "3XL-T",
                  "quantity": 35,
                  "upc": "00826218535444"
                }
              ]
            },
            {
              "date": 1500879600000,
              "sizes": [
                {
                  "sizeType": null,
                  "code": "3XL",
                  "quantity": 156,
                  "upc": "00826218535024"
                },
                {
                  "sizeType": null,
                  "code": "S",
                  "quantity": 302,
                  "upc": "00826218534379"
                },
                {
                  "sizeType": null,
                  "code": "2XL",
                  "quantity": 185,
                  "upc": "00826218535017"
                },
                {
                  "sizeType": null,
                  "code": "L",
                  "quantity": 1003,
                  "upc": "00826218534966"
                },
                {
                  "sizeType": null,
                  "code": "M",
                  "quantity": 903,
                  "upc": "00826218534935"
                },
                {
                  "sizeType": null,
                  "code": "XL",
                  "quantity": 440,
                  "upc": "00826218534980"
                }
              ]
            }
          ]
        }
      ]
    },
    "extContractAvailability": {
      "totalAvailabilities": {
        "0": 362,
        "15": 362,
        "30": 362,
        "60": 362,
        "90": 362,
        "180": 362
      },
      "contracts": [
        {
          "name": "FA17 TEAM APPAREL",
          "styleColor": "867666-012",
          "contractId": "0042600933-1000",
          "salesOrg": 1000,
          "beginDate": 1497916800000,
          "endDate": 1508025600000,
          "sizes": [
            "S",
            "XL",
            "2XL",
            "3XL",
            "L",
            "M"
          ],
          "contractAvailability": {
            "totalAvailabilities": {
              "0": 362,
              "15": 362,
              "30": 362,
              "60": 362,
              "90": 362,
              "180": 362
            },
            "availability": [
              {
                "distributionCenter": {
                  "number": 1017,
                  "name": "Robinson",
                  "salesOrg": 1000
                },
                "isegName": null,
                "availabilities": [
                  {
                    "date": 1497916800000,
                    "sizes": [
                      {
                        "sizeType": null,
                        "code": "S",
                        "quantity": 23,
                        "upc": "00826218534379"
                      },
                      {
                        "sizeType": null,
                        "code": "2XL",
                        "quantity": 44,
                        "upc": "00826218535017"
                      },
                      {
                        "sizeType": null,
                        "code": "3XL",
                        "quantity": 17,
                        "upc": "00826218535024"
                      },
                      {
                        "sizeType": null,
                        "code": "L",
                        "quantity": 106,
                        "upc": "00826218534966"
                      },
                      {
                        "sizeType": null,
                        "code": "XL",
                        "quantity": 103,
                        "upc": "00826218534980"
                      },
                      {
                        "sizeType": null,
                        "code": "M",
                        "quantity": 69,
                        "upc": "00826218534935"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          "contractType": "Z002",
          "soldTo": "0000200032",
          "updateSource": "ContractEvent",
          "updatedAt": 1499672001215,
          "searchHash": "-754306018"
        }
      ]
    },
    "searchGender": [
      "MENS"
    ],
    "searchCategory": "TEAM_SPORTS"
  }
]

export const GridWallItemsMock = [
  {
    "styleNumber": "683333",
    "colorNumber": "546",
    "colorDescription": "Black",
    "displayName": "WOMEN'S NIKE FASTBREAK JERSEY",
    "currency": "USD",
    "listPrice": "35",
    "formattedListPrice": "$35.00",
    "formattedRetailPrice": "$0.00",
    "appliedDiscount": "false",
    "nikeType": "APPAREL",
    "prdgroupid": "683333",
    "uniformProgram": "Stock",
    "sportDesc": "BASKETBALL",
    "genderDesc": "WOMENS",
    "_id": "683333-546",
    "colors": [
      "546",
      "012",
      "104",
      "106",
      "107",
      "108",
      "109",
      "110",
      "111",
      "115",
      "342",
      "420",
      "494",
      "612",
      "658",
      "670"
    ],
    "customization": "none",
    "numberOfColors": 16
  }
]

export const SolrDetailsMock = {
        "styleListPrice": 55,
        "fillin": false,
        "featured": true,
        "catalogId": "151861",
        "displayName": "MEN'S NIKE HYPERELITE JERSEY",
        "hidden": false,
        "currency": "USD",
        "publishDate": "2017-04-01T00:00:00Z",
        "styleId": "1196944",
        "gender_desc": "MENS",
        "id": "151861-867666-",
        "language": "en",
        "uniformProgram_desc": "Stock",
        "rowNumber": 3068,
        "nikeType": "APPAREL",
        "sport_desc": "BASKETBALL",
        "description": "<p>The Nike Hyperelite Jersey delivers zero distractions on the court. Dri-FIT fabric helps you stay cool. Slim fit wears close to your body for unrestricted movement. Back features open hole mesh for ventilation. Nike team sports jock tag at lower left hem. Nike Swoosh design trademark on right chest. Body width: 20.75\", Body length: 29.75\" (size large).</p>",
        "styleNumber": "867666",
        "locale": "US",
        "f_sport": [
          "Basketball,Basketball"
        ],
        "model": [
          "Sleeveless / Tanks"
        ],
        "f_size": [
          "S(8),S",
          "M(9),M",
          "L(10),L",
          "XL(11),XL",
          "2XL(15),2XL",
          "3XL(16),3XL",
          "4XL(17),4XL",
          "S-T(50),S-T",
          "M-T(52),M-T",
          "L-T(54),L-T",
          "XL-T(56),XL-T",
          "2XL-T(60),2XL-T",
          "3XL-T(62),3XL-T"
        ],
        "hexValue": [
          "oracle.sql.CLOB@2cfc14f0"
        ],
        "f_bodyType": [
          "Top,Top"
        ],
        "styleColors": [
          "867666-012|867666-106|867666-107|867666-108|867666-109|867666-111|867666-342|867666-420|867666-494|867666-658|"
        ],
        "search_ja": [
          "MEN'S NIKE HYPERELITE JERSEY",
          "MENS",
          "867666-012|867666-106|867666-107|867666-108|867666-109|867666-111|867666-342|867666-420|867666-494|867666-658|",
          "Stock",
          "BASKETBALL",
          "867666"
        ],
        "sport": [
          "Basketball"
        ],
        "f_hc": [
          "Jerseys,Jerseys",
          "Tops,Tops"
        ],
        "f_color": [
          "Black,Black",
          "Black,Black",
          "Black,Black",
          "Black,Black",
          "Black,Black",
          "Black,Black",
          "Black,Black",
          "Black,Black",
          "Black,Black",
          "Black,Black"
        ],
        "hc": [
          "Jerseys",
          "Tops"
        ],
        "productType": [
          "Clothing"
        ],
        "size": [
          "S",
          "M",
          "L",
          "XL",
          "2XL",
          "3XL",
          "4XL",
          "S-T",
          "M-T",
          "L-T",
          "XL-T",
          "2XL-T",
          "3XL-T"
        ],
        "bodyType": [
          "Top"
        ],
        "f_gender": [
          "Men,Men"
        ],
        "colorNumber": [
          "012",
          "106",
          "107",
          "108",
          "109",
          "111",
          "342",
          "420",
          "494",
          "658"
        ],
        "color": [
          "Black",
          "Black",
          "Black",
          "Black",
          "Black",
          "Black",
          "Black",
          "Black",
          "Black",
          "Black"
        ],
        "listPrice": [
          "55",
          "55",
          "55",
          "55",
          "55",
          "55",
          "55",
          "55",
          "55",
          "55"
        ],
        "search": [
          "MEN'S NIKE HYPERELITE JERSEY",
          "MENS",
          "867666-012|867666-106|867666-107|867666-108|867666-109|867666-111|867666-342|867666-420|867666-494|867666-658|",
          "Stock",
          "BASKETBALL",
          "867666"
        ],
        "f_productType": [
          "Clothing,Clothing"
        ],
        "gender": [
          "Men"
        ],
        "f_model": [
          "Sleeveless / Tanks,Sleeveless / Tanks"
        ],
        "uniformProgram": [
          "Stock"
        ],
        "f_uniformProgram": [
          "Stock,Stock"
        ]
      }


export const uniformDesignMock = {
  "name": "none",
  "styleCode": "802354",
  "colorCode": "342",
  "userId": "Temp",
  "isPublished": false,
  "isDefault": false,
  "templates": {
    "Front": {
      "id": "2c06945f-7474-4aef-98c2-6defb8fa5b42",
      "name": "bl-3",
      "viewType": "Front",
      "isActive": false,
      "productType": "Crew",
      "sport": "Baseball",
      "data": {
        "components": [
          {
            "type": "graphic",
            "name": "",
            "positionX": 0,
            "positionY": 0,
            "height": 150,
            "width": 150,
            "rotationDegrees": 0,
            "availableGraphics": [
              {
                "id": "7c776176-1aac-45f8-8b39-902c51163b69",
                "name": "JP - Edge Push",
                "data": {
                  "type": "composite",
                  "image": {
                    "url": "http://images.nike.net/is/image/GTMDEMO/base_t_cg?$layer_0_opac=0&$layer_0_size=1000,1000&$PNG%20-%20Large%20size%20-%20High%20resolution$&$t1_hide=1&$l1_hide=1&$g1_hide=0&$g1_src=Never-Stops-Royal&$g1_size=500,500&$g1_pos=0,335&$g2_hide=0&$g2_src=Swoosh-Anthracite&$g2_size=1000,1000&$g2_pos=0,0"
                  },
                  "components": [
                    {
                      "type": "graphic",
                      "id": "cj5l7riox0000385ry2yunso6",
                      "name": "",
                      "positionX": 0,
                      "positionY": 335,
                      "height": 500,
                      "width": 500,
                      "rotationDegrees": 0,
                      "availableGraphics": [
                        {
                          "id": "0bf7f10e-d94b-4eee-9f81-95868297cb7d",
                          "name": "Never Stops",
                          "displayName": "Never Stops",
                          "data": {
                            "type": "simple",
                            "image": {
                              "url": "Never-Stops"
                            },
                            "availableColors": [
                              {
                                "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                                "name": "Royal",
                                "rgb": {
                                  "red": 0,
                                  "green": 79,
                                  "blue": 163
                                },
                                "selected": true
                              }
                            ]
                          },
                          "selected": false
                        }
                      ]
                    },
                    {
                      "type": "text",
                      "id": "cj5l7uzwl0001385ruk46wnj5",
                      "name": "JOSH",
                      "positionX": 0,
                      "positionY": 0,
                      "height": 1000,
                      "width": 1000,
                      "rotationDegrees": 0,
                      "availableFonts": [],
                      "availableColors": [
                        {
                          "id": "a39b59c3-7e34-415a-ae1c-a5c3046b64aa",
                          "name": "Royal",
                          "rgb": {
                            "red": 0,
                            "green": 79,
                            "blue": 163
                          },
                          "selected": true
                        }
                      ],
                      "availableSecondaryColors": [],
                      "availableBackgroundColors": [],
                      "fontSize": 500,
                      "bgHeight": 1000,
                      "bgWidth": 1115,
                      "maxCharacters": 9,
                      "minCharacters": 1,
                      "textPositionX": -60,
                      "textPositionY": -107,
                      "textDistort": "Straight",
                      "textEditable": "Editable",
                      "letterSpacing": 1
                    },
                    {
                      "type": "graphic",
                      "id": "cj5l7z4px0002385rivm21n6h",
                      "name": "",
                      "positionX": 0,
                      "positionY": 0,
                      "height": 1000,
                      "width": 1000,
                      "rotationDegrees": 0,
                      "availableGraphics": [
                        {
                          "id": "ceeb3e15-7559-494b-a095-c2f797c5ef66",
                          "name": "Swoosh",
                          "displayName": "Swoosh",
                          "data": {
                            "type": "simple",
                            "image": {
                              "url": "Swoosh"
                            },
                            "availableColors": [
                              {
                                "id": "e223ab62-1878-48b6-a69f-19145e2b729b",
                                "name": "Anthracite",
                                "rgb": {
                                  "red": 68,
                                  "green": 68,
                                  "blue": 78
                                },
                                "selected": true
                              }
                            ]
                          },
                          "selected": true
                        }
                      ]
                    }
                  ]
                },
                "selected": true
              }
            ]
          }
        ]
      }
    }
  }
}

export const embellishedDesignPriceMock = {
  "designId": "0d3b3e2e-8b15-415f-b2f9-4abdd2dedef6",
  "embellishments": [
    {
      "code": "S60",
      "price": 7.5,
      "type": "per unit",
      "unitCount": 12,
      "totalPrice": 90
    }
  ],
}
