import React from 'react';

export class NotFoundPage extends React.Component {
  render() {
    return (
      <div className="container nts-not-found-page">
        <h1>PAGE NOT FOUND</h1>
        <h4>We're sorry, that page does not exist.</h4>
      </div>
    );
  }
}
