import * as React from 'react';

import {naturalSortBy, SortDirection} from '../../helpers/typedArrayHelpers';
import {Catalog} from './CatalogPage';
import CatalogTile from './CatalogTile';

interface Props {
    baseUrl: string;
    cacheBuster: string;
    catalogs: Catalog[];
    years: string[];
}

const PastCatalogs: React.FC<Props> = ({
    baseUrl,
    cacheBuster,
    catalogs,
    years,
}) => {
    const renderTile = (cat: Catalog, idx: number) => {
        return (
            <CatalogTile
                key={idx}
                catalogUrl={`${baseUrl}/${cat.catalog}?cb=${cacheBuster}`}
                label={cat.label}
                thumbnailUrl={`${baseUrl}/${cat.thumbnail}?cb=${cacheBuster}`}
            />
        );
    };

    const renderYear = (year: string) => {
        const seasonCatalogs = catalogs.filter(cat => cat.year === year && !cat.current);
        const alphaCatalogs = naturalSortBy(seasonCatalogs, (cat: Catalog) => cat.label, SortDirection.ASC);
        return (
            <div className="season-block" key={year}>
                <div className="season-title">{`${year} Catalogs`}</div>
                <div className="thumbnails-container">
                {
                    alphaCatalogs.map(renderTile)
                }
                </div>
            </div>
        );
    };

    return (
        <div>
        {
            years.map(renderYear)
        }
        </div>
    );
};

export default PastCatalogs;
