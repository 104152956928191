import {
  getAuthorities,
  getUserEmail,
  hasRolePermission, isConsumer, isDealerRole,
  isLoggedIn
} from "./userProfileHelpers";
import {history} from "../store";
import {AssortmentService} from "../api/services/assortmentService";
import {showMessageModal} from "../actions/messageModalActions";
import {getEnvironment} from "./browserHelpers";
import {ENVIRONMENT_PROD} from "./constants";

const trackItLinkAuths = ['ROLE_DEALER', 'ROLE_USER_ADMIN', 'ROLE_CUSTOMER_SERVICE', 'ROLE_ARTWORK_BROWSER'];
const orderDeadlinesLinkAuths = ['ROLE_DEALER', 'ROLE_USER_ADMIN', 'ROLE_CUSTOMER_SERVICE', 'ROLE_ARTWORK_BROWSER'];
export const salesToolsLinkAuths = ['ROLE_DEALER', 'ROLE_USER_ADMIN', 'ROLE_CUSTOMER_SERVICE', 'ROLE_ARTWORK_BROWSER'];
export const ADMIN_PAGE_ROLES = ['ROLE_USER_ADMIN', 'ROLE_CUSTOMER_SERVICE']; // TODO PMENTS-2247: add in 'ROLE_WIP_UPLOAD' when ready to release
export const ADMIN_DEALER_PAGE_ROLES = ['ROLE_DEALER', 'ROLE_USER_ADMIN', 'ROLE_CUSTOMER_SERVICE'];
export const DEALER_SHARE_ROLES = ['ROLE_DCART_SHARE'];

/**
 * Manages logic flow for retrieving display status of nav bar link
 * @param linkName
 * @returns {*}
 */
export function showNavLink(linkName) {
  let showItem = false;
  switch (linkName) {
    case 'locker':
    case 'cartIcon':
    case 'account':
    case 'pastOrders':
    case 'profile':
      showItem = isLoggedIn();
      break;
    case 'login':
      showItem = !isLoggedIn();
      break;
    case 'artwork':
      showItem = hasRolePermission('ROLE_ARTWORK_BROWSER');
      break;
    case 'fillin':
      showItem = isDealerRole() && hasRolePermission('ROLE_DCART_CREATE_FILLIN');
      break;
    case 'pastQuotes':
    case 'couponLookup':
      showItem = isLoggedIn() && isConsumer();
      break;
    case 'dealerCouponLookup':
      showItem = isDealerRole();
      break;
    default:
      showItem = false;
      break;
  }
  return showItem;
}

export const showTrackItLink = () => {
  return getAuthorities().some(auth => trackItLinkAuths.indexOf(auth) >= 0);
}

export const showOrderDeadlinesLink = () => {
  return getAuthorities().some(auth => orderDeadlinesLinkAuths.indexOf(auth) >= 0);
}

export const showSalesToolsLink = () => {
  return getAuthorities().some(auth => salesToolsLinkAuths.indexOf(auth) >= 0);
}

export const navigateToShoppingHome = () => {
  history.push('/en/en_US/nts/gridwall/any/any/1');
}

export function openArtworkUploadEmail() {
  const emailBodyStr = 'Please follow these steps to digitize your artwork:%0D%0A%0D 1. Attach your custom artwork to the email.%0D%0A 2. Choose up to 3 colors from our available color options: https://niketeam.nike.com/en/en_US/nts/help/colors  %0D%0A 3. Copy and paste these color names into your email. (Note: only these colors may be used when digitizing your custom artwork)%0D%0A%0D%0D ';
  window.location.href = `mailto:myniketeam.artwork@nike.com?subject=Artwork request for ${getUserEmail()}&body=${emailBodyStr}`;
}

export function navigateToCartPage() {
  if(isDealerRole()) {
    history.push('/en/en_US/nts/orders');
  } else {
    AssortmentService.getConsumerCart().then(response => {
      window.location = `${window.location.protocol}//${window.location.host}/en/en_US/p/order,detail?cartId=${response}`;
    }).catch(error => {
      showMessageModal({
        header: "ERROR RETRIEVING CART",
        message: "There was an error retrieving your cart, please try again in a few minutes or contact customer support."
      })
    });
  }
}

export function isEmbodeeBuilderPage() {
  return window.location.search.includes('builder');
}

/**
 * Sets visibility of navigation links based on authorities
 * @param isLoggedIn
 * @param auths
 * @returns {{}}
 */
export const getNavigationState = (isLoggedIn = false, auths = []) => {
  let roleSpecificMap = {
    isConsumer: false,
    isDealer: false,
    artwork: false,
    fillin: false,
    orderDeadlines: false,
    trackIt: false,
    salesTools: false
  }
  auths.forEach(auth => {
    if(auth === 'ROLE_ARTWORK_BROWSER') roleSpecificMap.artwork = true;
    if(auth === 'ROLE_DCART_CREATE_FILLIN') roleSpecificMap.fillin = true;
    if(auth === 'ROLE_CONSUMER') roleSpecificMap.isConsumer = true;
    if(auth === 'ROLE_DEALER') roleSpecificMap.isDealer = true;
    if(['ROLE_DEALER', 'ROLE_USER_ADMIN', 'ROLE_CUSTOMER_SERVICE', 'ROLE_ARTWORK_BROWSER'].includes(auth)) {
        roleSpecificMap.orderDeadlines = true;
        roleSpecificMap.trackIt = true;
        roleSpecificMap.salesTools = true;
    }
  })
  return {
    account: isLoggedIn,
    artwork: roleSpecificMap.artwork,
    cartIcon: isLoggedIn,
    couponLookup: isLoggedIn && roleSpecificMap.isConsumer,
    dealerLocator: true,
    dealerCouponLookup: roleSpecificMap.isDealer,
    fillin: roleSpecificMap.isDealer && roleSpecificMap.fillin,
    help: true,
    locker: isLoggedIn,
    login: !isLoggedIn,
    orderDeadlines: roleSpecificMap.orderDeadlines,
    pastOrders: isLoggedIn,
    profile: isLoggedIn,
    pastQuotes: isLoggedIn && roleSpecificMap.isConsumer,
    trackIt: roleSpecificMap.trackIt,
    salesTools: roleSpecificMap.salesTools
  }
}

// Obtains correct Track It URL for the current environment
export const getTrackItUrl = () => {
  const env = getEnvironment();
  if(env === ENVIRONMENT_PROD) {
    return `https://niketeamsports.nike.com/Trackit`;
  } else {
    return 'https://nike-stg1.pegacloud.io/prweb/sso/app/TrackIt/';
  }
}