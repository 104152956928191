import * as React from 'react';

import {uniq, SortDirection, naturalSort} from '../../helpers/typedArrayHelpers';
import {CatalogService} from '../../api/services/ntsWebappService';
import {getCatalogBaseUrl} from '../../api/config/ntsDownloadsBaseUrl';
import CatalogTabs from './CatalogTabs';
import CatalogSportFilter from './CatalogSportFilter';
import CurrentCatalogs from './CurrentCatalogs';
import PastCatalogs from './PastCatalogs';

export interface Catalog {
    label: string;
    sport: string;
    catalog: string;
    thumbnail: string;
    year: string;
    current?: boolean;
}

interface CatalogConfig {
    cb: string;
    catalogs: Catalog[];
}

interface State {
    baseUrl: string;
    cacheBuster: string;
    catalogs: Catalog[];
    selectedTab: Tabs;
    sportFilter: string;
}

const DEFAULT_SPORT_FILTER = 'all sports';
export enum Tabs {
    CURRENT = 'Current Catalogs',
    ALL = 'Past Catalogs',
}

export class CatalogPage extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            baseUrl: '',
            cacheBuster: '',
            catalogs: [],
            selectedTab: Tabs.CURRENT,
            sportFilter: DEFAULT_SPORT_FILTER,
        }
    }

    componentDidMount = () => {
        const fetchCatalogs = async () => {
            const data = await CatalogService.getCatalogConfig()
                .catch((e) => console.error('Error fetching catalogs', e));
            if (data) {
                const baseUrl = getCatalogBaseUrl();
                // TODO: validate response type
                const config = data as unknown as CatalogConfig;
                this.setState({
                    baseUrl,
                    cacheBuster: config.cb,
                    catalogs: config.catalogs,
                });
            }
        };

        fetchCatalogs();
    }

    genSportList = () => {
        const allSports = uniq(this.state.catalogs.map(cat => cat.sport.toLocaleLowerCase()));
        const alphaSports = naturalSort(allSports, SortDirection.ASC);
        const sports: string[] = [
            DEFAULT_SPORT_FILTER,
            ...alphaSports,
        ];
        return sports;
    }

    onFilterSport = (sport: string) => {
        this.setState({ sportFilter: sport });
    }

    onSelectTab = (tab: Tabs) => {
        this.setState({ selectedTab: tab });
    }

    getYearsDesc = () => {
        const years = uniq(this.state.catalogs.filter(cat => !cat.current).map(cat => cat.year));
        return naturalSort(years, SortDirection.DESC);
    }

    getFilteredCatalogs = () => {
        let filtered = [...this.state.catalogs];
        if (this.state.sportFilter !== DEFAULT_SPORT_FILTER) {
            filtered = filtered.filter(cat => {
                return cat.sport.toLocaleLowerCase() === this.state.sportFilter;
            });
        }
        return filtered;
    }

    render() {
        const yearsDesc = this.getYearsDesc();
        const filteredCatalogs = this.getFilteredCatalogs();

        return (
            <div className="nts-catalog-page">
                <div className="heading">Download A Catalog</div>

                <div className="catalog-tab-bar">
                    <CatalogTabs
                        selectedTab={this.state.selectedTab}
                        onSelectTab={this.onSelectTab}
                    />
                    <CatalogSportFilter
                        sports={this.genSportList()}
                        selectedSport={this.state.sportFilter}
                        filterClickHandler={this.onFilterSport}
                    />
                </div>
                {
                    this.state.selectedTab === Tabs.CURRENT && (
                        <CurrentCatalogs
                            baseUrl={this.state.baseUrl}
                            cacheBuster={this.state.cacheBuster}
                            catalogs={filteredCatalogs}
                        />
                    )
                }
                {
                    this.state.selectedTab === Tabs.ALL && (
                        <PastCatalogs
                            baseUrl={this.state.baseUrl}
                            cacheBuster={this.state.cacheBuster}
                            catalogs={filteredCatalogs}
                            years={yearsDesc}
                        />
                    )
                }
            </div>
        );
    }
}
