import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { matchPath } from "react-router-dom";
import { Carousel, CarouselItem } from 'react-bootstrap';

import { AppState } from '../../reducers';

import { AnnouncementBannerService } from '../../api/services/ntsWebappService';

const pagesWithBanner = [
  '/en/en_US/nts', // Main Landing Page
  '/en/en_US/nts/', // also Main Landing Page
  '/en/en_US/nts/email/orders', // also Main Landing Page
  '/en/en_US/nts/locker', // Locker
  '/en/en_US/nts/pdp/:type/:id', // PDP/Stock Builder
  '/en/en_US/nts/gridwall/:gender/:sport/:routeKey', // Sport Landing Page
  '/en/en_US/nts/sport/:gender/:sport', // Sport Landing Page
  '/en/en_US/nts/promo/:gender/:sport', // Sport Landing Page (Promo)
  '/en/en_US/nts/gridwall/:gender/:sport/:routeKey', // Gridwall
  '/en/en_US/nts/checkout/order/:id', // Dealer Checkout
  '/en/en_US/nts/checkout/coupon/:id', // Coupon Checkout
  '/en/en_US/nts/history/orders', // Past Orders
  '/en/en_US/nts/fillin', // Create Fill-in
  '/en/en_US/nts/orders' // Dealer and Consumer Carts List
];

const rolesWithAccess = (bannerRole: string = '') => {
  switch (bannerRole) {
    case 'ROLE_ADMIN':
      return ['ROLE_USER_ADMIN'];
    case 'ROLE_CUSTOMER_SERVICE':
      return ['ROLE_USER_ADMIN', 'ROLE_CUSTOMER_SERVICE'];
    case 'ROLE_DEALER_ELITE':
      return ['ROLE_USER_ADMIN', 'ROLE_CUSTOMER_SERVICE', 'ROLE_DEALER_ELITE'];
    case 'ROLE_DEALER':
      return ['ROLE_USER_ADMIN', 'ROLE_CUSTOMER_SERVICE', 'ROLE_DEALER_ELITE', 'ROLE_DEALER'];
    case 'ROLE_ARTWORK':
      return ['ROLE_USER_ADMIN', 'ROLE_CUSTOMER_SERVICE', 'ROLE_DEALER_ELITE', 'ROLE_DEALER', 'ROLE_ARTWORK_BROWSER'];
    case 'ROLE_CONSUMER':
      return ['ROLE_CONSUMER'];
    default:
      return ['ROLE_USER_ADMIN', 'ROLE_CUSTOMER_SERVICE', 'ROLE_DEALER_ELITE', 'ROLE_DEALER', 'ROLE_ARTWORK_BROWSER', 'ROLE_CONSUMER'];
  }
}

export interface Banner {
  displayText: string;
  linkText?: string;
  linkUrl?: string;
  role?: string;
  target?: string;
};

interface BannerConfig {
  banners: Banner[];
};

interface StateProps {
  pathname: AppState['router']['location']['pathname'];
  userAuthorities: AppState['userState']['authorities'];
};

const AnnouncementBanner: React.FC<StateProps> = ({ pathname, userAuthorities }) => {
  const [announcementBanners, setAnnouncementBanners] = useState<Banner[]>([]);

  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    const data = await AnnouncementBannerService.getAnnouncementBannerConfig()
      .catch((e) => console.error('Error fetching banners', e));
    if (data) {
      const config = data as unknown as BannerConfig;
      setAnnouncementBanners(config.banners);
    }
  };

  const pageHasBanner = pagesWithBanner.some(path => matchPath(pathname, {
    path,
    exact: true,
    strict: false
  }));

  const bannersForUserRole = announcementBanners.filter(banner => !banner.role || userAuthorities.some((auth: string) => rolesWithAccess(banner.role).indexOf(auth) >= 0));

  if (!pageHasBanner || !bannersForUserRole.length) return null;

  return (
    <div id='nts-announcement-banner-carousel-container'>
      <div id="nts-announcement-banner-carousel-wrapper">
        <div id='nts-announcement-banner-carousel'>
          { bannersForUserRole.length > 1 ? (
            <Carousel
              prevIcon={
                <div className='nts-announcement-banner-button prev-banner'>
                  <span className="g72-arrow-thin-left" />
                </div>
              }
              nextIcon={
                <div className='nts-announcement-banner-button next-banner'>
                  <span className="g72-arrow-thin-right" />
                </div>
              }
              indicators={false}
            >
              {bannersForUserRole.map((banner, index) =>
                <CarouselItem>
                  <div key={index} className='nts-announcement-banner-node'>
                    {banner.displayText}&nbsp;
                    <a href={banner.linkUrl} rel="noopener noreferrer" target={banner.target || '_blank'}>
                      {banner.linkText}
                    </a>
                  </div>
                </CarouselItem>
              )}
            </Carousel>
          ) : (
            <div className='nts-announcement-banner-node'>
              {bannersForUserRole[0].displayText}&nbsp;
              <a href={bannersForUserRole[0].linkUrl} rel="noopener noreferrer" target={bannersForUserRole[0].target || '_blank'}>
                {bannersForUserRole[0].linkText}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    pathname: state.router.location.pathname,
    userAuthorities: state.userState.authorities
  }
};

export default connect(mapStateToProps)(AnnouncementBanner);
