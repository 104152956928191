import {calculateEndpointType} from './common';

const endpointTypeToUrl = {
  local: 'http://localhost:3005',
  dev: 'https://niketeam-factory-services-dev.nike.net',
  qa: 'https://niketeam-factory-services-qa.nike.net',
  stg: 'https://niketeam-factory-services-stg.nike.net',
  uat: 'https://niketeam-factory-services-uat.nike.net',
  prod: 'https://niketeam-factory-services.nike.net'
};

export default function ntsFactoryServicesBaseUrl(location) {
  const type = calculateEndpointType(location, 'local');
  return endpointTypeToUrl[type];
}
