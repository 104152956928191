import * as React from "react";
import ReactTable from "react-table";

import {reportingTableConfig, ReportRecord} from "./adminReportingHelper";

interface Props {
    items: ReportRecord[];
    message: string;
}

export const SearchResults: React.FC<Props> = ({
   items,
    message
}) => {
    return (
        <div className="nts-tab-search-results">

            <ReactTable
                className="nts-table"
                data={items}
                noDataText={message}
                loading={false}
                columns={reportingTableConfig}
                minRows={0}
                style={{height:"auto"}}
                pageSize={25}
            />

        </div>
    )
}
