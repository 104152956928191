import React, { useState} from 'react';
import { postFactoryEvent } from '../../../api/services/factoryAdminService';
import { startFullLoadingSpinner, endFullLoadingSpinner } from "../../../actions/loadingSpinnerActions";
import { ReactComponent as UploadIcon} from '../../../../img/SVG/upload.svg';
import { hasValue } from '../../../helpers/stringHelpers';
import { isValidDate, dateFormatter } from '../../../helpers/datetimeHelpers';
import { read, utils } from 'xlsx';

import {Modal} from 'react-bootstrap';
import './FactoryXlsUploadPage.scss'

const MAX_ERROR_CNT = 100;
const XLS_COLUMNS_CONFIG = new Map([
    ["Style",                   { regEx: /^[A-Z0-9]{1,10}$/ }],
    ["PO",                      { regEx: /^[A-Z0-9]+$/      }],
    ["SO",                      { regEx: /^[1-9]\d*$/       }],
    ["Event Date",              { isDate: true }],
    ["GAC",                     { isDate: true }],
    ["Tracking",                { isOptional: (row:any) => true,   regEx: /^[A-Z0-9]{1,40}$/}],
    ["Carrier",                 { isOptional: (row:any) => !hasValue(row['Tracking']),   regEx: /^(?![.\s-]+$)[A-Za-z0-9.\s-]{1,30}$/ }],
    ["Estimated Arrival Date",  { isOptional: (row:any) => true,   isDate: true }],
    ["Shipping Method",         { isOptional: (row:any) => !hasValue(row['Tracking']), regEX: /^(AF|VL)$/i }],
    ["Reason",                  { isOptional: (row:any) => true,   regEx: /^\d+$/ }],
    ["Reason delay",            { isOptional: (row:any) => !hasValue(row['Reason'])}]
]);
const VALID_EVENT_NAMES = ['ReceivedByPartner', 'OrderProcessing', 'ConfirmedByPartner', 'OrderCompleted', 'OrderDelayed', 'OrderShipped']

export const FactoryXlsUploadPage: React.FC = () => {
    const [file, setFile] = useState<any>();
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [successCount, setSuccessCount] = useState(0);
    const [dataErrors, setDataErrors] = useState<any[]>([]);

    const orderEvents: any[] = [];
    const errorEvents: any[] = [];

    const initState = () => {
        setError('');
        setSuccessCount(0);
        setDataErrors([]);
    }

    // TODO : Not in use at this moment, will be deleted
    const findMissedColumns = (obj: any): string[] => {
        const missedColumns:string[] = [];
        if (typeof obj["Event"] === 'undefined') {
            missedColumns.push("Event");
        }
        XLS_COLUMNS_CONFIG.forEach((config: any, key: string) => {
            if ((typeof obj[key] === 'undefined') && (typeof config.isOptional === 'undefined')) {
                missedColumns.push(key);;
            }
        })
        return missedColumns;
    }

    const validateRow = (idx: number, row: any): any => {
        const invalidCols:any[] = [];
        // When event value is not valid, simply ignore rows
        if (!VALID_EVENT_NAMES.includes(row["Event"])) {
            return {isSkipped : true}
        }

        XLS_COLUMNS_CONFIG.forEach((config: any, key: string) => {
            if (hasValue(row[key])) { 
                if (config.isDate && !isValidDate(row[key])) { // Validate Date
                    invalidCols.push({
                        key: key,
                        message: "is invalid. Must be in MM/DD/YY format"
                    });
                } else if (!!config.regEx && !config.regEx.test(row[key])) { // Validate RegEX
                    invalidCols.push({
                        key: key,
                        message: "is invalid."
                    });
                }
            } else {
                if (!config.isOptional || !config.isOptional(row)) { // if mandatory column is missing
                    invalidCols.push({
                        key: key,
                        message: "is mandatory"
                    });
                } 
            }
        });

        if (invalidCols.length > 0) {
            let message = "";
            for (const col of invalidCols) {
                message += `${col.key} ${col.message} <br />`
            }
            return {errorRow:{RowNumber: idx+1, Message: `invalid data on Columns: <br />
            ${message}
            `}};
        }

        return {
            validRow: {
                "RowNumber": idx+1,
                "Style": row['Style'].toString(),
                "PurchaseOrder": row['PO'].toString(),
                "SO": row['SO'],
                "EventName": row['Event'],
                "GAC": dateFormatter(row['GAC']),
                "EstimatedArrivalDate": dateFormatter(row['Estimated Arrival Date']),
                "EventDate": dateFormatter(row['Event Date']),
                "Carrier": row['Carrier']?.toString() ?? '',
                "TrackingNumber": row['Tracking']?.toString() ?? '',
                "Reason": row['Reason'] ?? null,
                "ReasonCode": row['Reason delay']?.toString() ?? '',
                "ShippingMethod": row['Shipping Method']?.toString() ?? '',
            }
        }

    }
    const processFile = async (file:File): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = read(data, { type: 'array', cellDates: true});
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const sheetObjects = utils.sheet_to_json(worksheet);

                if (sheetObjects.length > 0){
                    for(let i = 0; i < sheetObjects.length; i++) {
                        let {isSkipped, validRow, errorRow} = validateRow(i, sheetObjects[i]);
                        if (!isSkipped) {
                            if (validRow) {
                                orderEvents.push(validRow);
                            } else {
                                errorEvents.push(errorRow);
                            }
                        }
                    }
                    resolve('success');
                } else {
                    reject(`File Format Errors: empty data`)
                }

            }
            reader.onerror = (event) => {
                reject(event.target?.error);
            } 
            reader.readAsArrayBuffer(file);
        });           
    }

    const doSubmitValidRows = async () => {
        let result : any = await postFactoryEvent(orderEvents);
        setSuccessCount( result?.successCount ?? 0 );
        if ( result?.Errors?.length > 0 ) {
            errorEvents.push(...result.Errors)
        } 
    }

    const onUploadFile = async (fileInput: any) => {
        const uploadedFile = fileInput.files[0];
        if(uploadedFile) {
            startFullLoadingSpinner()
            setFile(uploadedFile);
            initState();
            try {
                const res = await processFile(uploadedFile);
                if (res ==='success' && orderEvents.length > 0) {
                  await doSubmitValidRows();
                } else {
                  setError('No data to submit!!')
                }
            } catch (error: any) {
                setError(error?.message || error?.toString())
            } finally {
                if ( errorEvents.length > 0 ) {
                    setDataErrors(errorEvents.slice(0, MAX_ERROR_CNT))
                }
                setShowModal(true); 
                setFile(null);
                fileInput.value = null;
                endFullLoadingSpinner()
            }
        }
    }

    return (
        <div className="nts-admin-page nts-admin-factory-page nts-admin-analytics-page nts-xls-file-upload">
            <h4 style={{ textAlign: 'center', marginTop: '20px', fontWeight: 'bolder' }}>UPLOAD FACTORY REPORT</h4>
            <div className='file-button-container'>
                <label htmlFor='fileInput' className='file-button'>
                    <input 
                        style={{ display: 'none' }}
                        id="fileInput" 
                        type="file" 
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={(e) => onUploadFile(e.target)} 
                    />
                    { file
                        ? <span className='file-name'>{file.name}</span>
                        : <UploadIcon className='file-icon' />
                    }
                </label>
            </div>
            {/* Error / Result Mordal Start */}
            <Modal
                className='nts-xls-file-upload'
                show={showModal}
                onHide={() => setShowModal(false)}
                backdrop="static"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{ overflow: 'scroll', maxHeight: '40em'}}>
                    { successCount > 0 && 
                        (<div>{`${successCount} events created successfully`}  </div>)
                    }
                    { !!error && 
                        (<div>{`${error} `}</div>)
                    }
                    {dataErrors.map((error: any, index) => (
                        <div key={index}>{ error.Message ? `Row: ${error.RowNumber} has ${error.Message}`: ''}
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
            {/*  Error / Result Mordal Start */}
        </div>
    )

}
