/** Remove duplicates from array */
export const uniq = (source: string[]) => {
    const identity = (foo: string) => foo;
    return uniqBy(source, identity);
};

export const uniqBy = <A>(source: A[], by: (a: A) => string) => {
    const uniques: A[] = [];
    source.forEach(entry => {
        const value = by(entry);
        if (!uniques.some(u => by(u) === value)) {
            uniques.push(entry);
        }
    });
    return uniques;
};

/** Sort direction enum */
export enum SortDirection {
    ASC = 'asc',
    DESC = 'desc',
}

/** Sort a string array */
export const naturalSort = (source: string[], direction: SortDirection) => {
    const identity = (foo: string) => foo;
    return naturalSortBy(source, identity, direction);
};

/** Sort a generic array */
export const naturalSortBy = <A>(source: A[], sortBy: (a: A) => string, direction: SortDirection) => {
    const sorted = [...source];
    sorted.sort((lRaw, rRaw) => {
        const l = sortBy(lRaw);
        const r = sortBy(rRaw);
        return l > r
            ? (direction === SortDirection.ASC ? 1 : -1)
            : r > l
                ? (direction === SortDirection.ASC ? -1 : 1)
                : 0;
    });
    return sorted;
};
