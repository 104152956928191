import * as types from './actionTypes';
import {store} from '../../App';

export function showMessageModal(messageData){
  return store.dispatch({type: types.SHOW_MESSAGE_MODAL, messageData})
}

export function hideMessageModal(){
  return store.dispatch({type: types.HIDE_MESSAGE_MODAL});
}
