import React from 'react';

interface Props {
    className: string;
    height: number;
    width: number;
}

export class ColorWheelSvg extends React.Component<Props> {
    render() {
        const containerStyle: React.CSSProperties = {
            position: 'relative', // necessary for proper svg position
            display: 'inline-block', 
            height: this.props.height ? this.props.height : 20, 
            width: this.props.width ? this.props.width : 20
        };
        
        return (
            <span style={containerStyle} className={this.props.className}>
                <svg width="100%" height="100%" x="0" y="0"  viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg" style={{position: 'absolute'}}>
                    <g id="Group">
                        <path d="M681.688865,375.455955 C675.571433,309.489009 646.716271,247.450602 600.254375,200.375051 L682.805297,117.826042 C751.538468,187.109782 789.296406,268.07973 798.189622,365.266607 L681.688865,375.455955" id="Fill-1" fill="#FBAD1F"></path>
                        <path d="M631.728563,561.833079 C669.251361,507.655572 686.959415,441.593041 681.698424,375.396692 L798.197269,365.203521 C806.321984,462.455396 783.194267,548.751333 727.54093,628.920279 L631.728563,561.833079" id="Fill-2" fill="#F6EC13"></path>
                        <path d="M472.262404,672.541393 C521.601405,659.105983 565.562801,633.59438 600.854648,598.304444 C612.137487,587.017782 622.537121,574.725567 631.789737,561.741317 L727.598281,628.828518 C671.300701,708.55395 598.117097,759.79509 502.610602,785.794176 L472.262404,672.541393" id="Fill-3" fill="#B8D433"></path>
                        <path d="M281.411908,655.320822 C341.343625,682.744122 409.398144,688.720089 472.235641,672.671388 L502.587662,785.937553 C409.440201,810.330813 320.437301,802.544469 231.82821,761.65326 L281.411908,655.320822" id="Fill-4" fill="#6BC9C6"></path>
                        <path d="M117.852805,682.654272 C85.7152586,650.518638 59.8633734,613.194656 38.8538179,568.596665 L145.203461,519.009143 C159.244879,548.674865 178.212742,575.56289 201.594714,598.942951 C224.967128,622.317276 251.839859,641.281315 281.488376,655.31891 L231.90659,761.649436 C187.31051,740.643704 149.986529,714.787996 117.852805,682.654272" id="Fill-5" fill="#058BC5"></path>
                        <path d="M38.8499945,568.673133 C-2.03930331,480.067865 -9.82564721,391.063053 15.0570074,296.697841 L128.363318,327.057509 C111.823311,391.110846 117.793543,459.157718 145.197726,519.083699 L38.8499945,568.673133" id="Fill-6" fill="#34469D"></path>
                        <path d="M15.0799478,296.867982 C40.7081644,202.70541 91.9512159,129.519895 171.674737,73.2261389 L238.792524,169.07674 C225.808275,178.327444 213.517971,188.727078 202.237045,200.006094 C166.954755,235.286471 141.443152,279.238308 128.374788,327.22765 L15.0799478,296.867982" id="Fill-7" fill="#7E4D9F"></path>
                        <path d="M171.584887,72.9680597 C251.75001,17.3128105 338.042123,-5.81490586 435.297822,2.30598511 L425.104651,118.841153 C358.915948,113.587808 292.868711,131.29395 238.695027,168.816749 L171.584887,72.9680597" id="Fill-8" fill="#C63D96"></path>
                        <path d="M435.242382,2.31936699 C532.427348,11.2087604 613.397296,48.9647859 682.681036,117.697958 L600.130115,200.250791 C553.046917,153.79463 491.014245,124.954761 425.045388,118.844976 L435.242382,2.31936699" id="Fill-9" fill="#ED1944"></path>
                    </g>
                </svg>
            </span>
        );
    }
}
