import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

export interface CovidState {
  showCovidCheckoutModal: Boolean;
}

const initialState: CovidState = {
  showCovidCheckoutModal: false,
};

interface CovidAction extends Action {
  show: boolean;
}

const covidReducer: Reducer<CovidState> = (
    state = initialState,
    incomingAction,
) => {
  const action = incomingAction as unknown as CovidAction;

  switch (action.type) {
    case types.DISPLAY_COVID_CHECKOUT_MODAL:
      return {
        ...state,
        showCovidCheckoutModal: action.show
      };

    default:
      return state;
  }
};

export default covidReducer;
