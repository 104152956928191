import * as React from "react";
import {MemberTabState} from "./MemberTab";

interface Props {
    state: MemberTabState,
    handleSearch: () => void,
    updateState: (state:MemberTabState) => void
}

export const SearchBar: React.FC<Props> = ({
   state,
   handleSearch,
   updateState
}) => {

    return (
        <div className="nts-tab-search-bar">

            {state.results.length > 0 && <div className="nts-member-count">{state.results.length} MEMBERS</div>}

            <label htmlFor="nts-dealer-member-search">
                <input type="text"
                       name="nts-dealer-member-search"
                       placeholder="SEARCH BY NAME OR EMAIL"
                       defaultValue={''}
                       onChange={e => updateState({...state, search: (e.target as HTMLInputElement).value})}
                       onKeyPress={event => { if (event.key === 'Enter') { handleSearch() }}}
                />
            </label>

            <button type="button" className="btn btn-primary" onClick={handleSearch}>Search</button>

            {state.error && <div className="error">{state.error}</div>}

        </div>
    )
}

