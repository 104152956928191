import * as types from './actionTypes';
import {AssortmentService} from "../api/services/assortmentService";
import {NtsStockOrderService} from "../api/services/stockOrderService";
import { store } from '../../App';
import UUID from '../helpers/uuid';
import {showMessageModal} from "./messageModalActions";

/**
 * Performs call to retrieve list of ShipTos for a given SoldTo
 * @param soldTo
 * @returns {function(*)}
 */
export function getShipTos(soldTo) {
  return dispatch => {
    return callGetShipTos(soldTo)
      .then(result => {
        return dispatch(dispatchLoadShipTos(result));
      })
      .catch(error => {
        throw(error);
      });
  }
}

export function callGetShipTos(soldTo) {
  return NtsStockOrderService.getShiptos(soldTo)
    .then(result => {
      if (result.status >= 400) {
        throw new Error('Server error getting account details.');
      } else if(!result.shipTos) {
        result.error = "No account found for " + soldTo;
      } else {
        result.shipTos = result.shipTos.map((address) => {
          address.contactInfo[0] = convertSapAddressToOrderServiceAddress(address.contactInfo[0]);
          return address;
        });
      }
      return result;
    })
    .catch(error => {
      throw(error);
    });
}

export function dispatchLoadShipTos(shipToData) {
  return { type: types.LOAD_SHIP_TOS, shipToData }
}

export function getJbaShipTos(jbaAccountNumber) {
  return dispatch => {
    return callGetJbaShipTos(jbaAccountNumber)
      .then(shipToObject => {
        return dispatch(dispatchLoadJbaShipTos(shipToObject));
      })
      .catch(error => {
        throw(error);
      });
  }
}

export function callGetJbaShipTos(jbaAccountNumber) {
  return AssortmentService.getJbaShippingAddresses(jbaAccountNumber)
    .then(result => {

      if (result.status >= 400) {
        throw new Error('Server error getting account details');
      } else if(!result.response.address) {
        result.error = "No account found for " + jbaAccountNumber;
        return result;
      } else {
        let addressArray = result.response.address;
        //Finagle the jba addresses to look like the sap address list...
        let shippingAddresses = addressArray.filter((address) => {
          return address._type === "shipping"
        });
        let billingAddress = addressArray.filter((address) => {
          return address._type === "billing"
        })[0];

        shippingAddresses = shippingAddresses.map((address) => {
          return convertJbaAddressToOrderServiceAddress(address);
        });

        let addressList = [];
        shippingAddresses.forEach((address, index) => {
          addressList.push({
            customerNumber: "generated" + UUID(),
            customerName: address.name,
            contactInfo: [
              address
            ]
          });
        });

        let shipToObject = {
          billingAddress: {
            customerName: billingAddress.name
          },
          customerNumber: jbaAccountNumber,
          shipTos: addressList
        };

        return shipToObject;
      }
    })
    .catch(error => {
      throw(error);
    });
}

export function dispatchLoadJbaShipTos(shipToData) {
  return { type: types.LOAD_JBA_SHIP_TOS, shipToData }
}

function convertSapAddressToOrderServiceAddress(address) {
  return {
    name: address.addresseeLine,
    addressLine1: address.streetName,
    addressLine2: "",
    additionalInformation: "", //No field for this?
    city: address.city,
    stateProvince: address.provinceStateCode,
    postalCode: address.postalCode,
    country: address.countryCode,
  }
}

function convertJbaAddressToOrderServiceAddress(address) {
  return {
    name: address.name,
    addressLine1: address.line1,
    addressLine2: address.line2,
    additionalInformation: address.line3, //No field for this?
    city: address.city,
    stateProvince: address.state,
    postalCode: address.zip1 + (address.zip2 ? "-" + address.zip2 : ""),
    country: address.country,
  }
}

/**
 * Retrieves Sap Cart (stock-order table)
 * @param cartId
 * @returns {function(*): Promise<T | never>}
 */
export function getSapCart(cartId) {
  return dispatch => {
    return NtsStockOrderService.getCart(cartId)
      .then(result => {
        result.shippingData = {
          ...result.shippingData,
          shipToId: '',
          addressType: 'SELECTED'
        };
        return dispatch(dispatchGetSapCart(result));
      })
      .catch(error => {
        throw(error);
      });
  }
}

/**
 * Retrieves Past Sap Cart (sap-cart-data table)
 * @param cartId
 * @returns {function(*): Promise<T | never>}
 */
export function getPastSapCart(cartId) {
  return dispatch => {
    return NtsStockOrderService.getSapCartData(cartId)
    .then(result => {
      return dispatch(dispatchGetSapCart(result));
    })
    .catch(error => {
      throw(error);
    });
  }
}

export function dispatchGetSapCart(sapCartData) {
  return { type: types.LOAD_SAP_CART_DATA, sapCartData }
}

export function getJbaCart(cartId) {
  return dispatch => {
    return AssortmentService.getCart(cartId)
      .then(result => {
        return dispatch(dispatchGetJbaCart(result));
      })
      .catch(error => {
        throw(error);
      });
  }
}

function dispatchGetJbaCart(jbaCartData) {
  return { type: types.LOAD_JBA_CART_DATA, jbaCartData }
}

/**
 * Sets isValidSapAccount & isValidJbaAccount in (redux) checkoutState to prevent submitting orders with products
 * that the given account can not order
 * @param jbaAcctNumber
 * @param sapAcctNumber
 * @returns {function(*): Promise<T | never>}
 */
export function validateSapAndJbaAccountNumbers(jbaAcctNumber, sapAcctNumber) {
  const payload = {
    jbaAccountNum: jbaAcctNumber,
    sapAccountNum: sapAcctNumber
  };
  return dispatch => {
    return AssortmentService.validateAccounts(payload)
      .then(response => {
        return dispatch(dispatchCheckoutAccountValidation(response));
      })
      .catch(error => {
        dispatchDisableCheckoutSubmit(true);
        showMessageModal({
          header: "ERROR RETRIEVING ACCOUNT",
          message: "There was an error retrieving your account details, please try again in a few minutes or contact customer support."
        });
      });
  }
}

function dispatchCheckoutAccountValidation(validationResults) {
  return { type: types.SET_CHECKOUT_ACCOUNT_VALIDATION, validationResults};
}

/**
 * Manages flow of account info request for billing account information
 * @param type
 * @param soldTo
 * @returns {Promise.<TResult>}
 */
export function searchForSoldTo(type, soldTo) {
  if(type==='jba') {
    return callGetJbaShipTos(soldTo);
  } else {
    return callGetShipTos(soldTo.padStart(10, 0));
  }
}

export function setSubmitAttempted(submitAttempted) {
  return store.dispatch({ type: types.UPDATE_SUBMIT_ATTEMPTED, submitAttempted: submitAttempted });
}

export function dispatchDisableCheckoutSubmit(disable) {
  return store.dispatch({ type: types.DISABLE_CHECKOUT_SUBMIT, disable: disable});
}

/**
 * Updates redux store with Additional Info form entry data/errors
 * @param form
 * @returns {*}
 */
export function dispatchUpdateInfoForm(form) {
  return store.dispatch({ type: types.UPDATE_INFO_FORM, form: form });
}
