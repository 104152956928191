import {calculateEndpointType, EndpointUrlMap} from './common';

/**
 * Provides base URL for Elite Options API (i.e. custom fonts/colors)
 */
const endpointTypeToUrl: EndpointUrlMap = {
    local:  'https://niketeam-custom-elite-options-api-dev.nike.net',
    dev:    'https://niketeam-custom-elite-options-api-dev.nike.net',
    qa:     'https://niketeam-custom-elite-options-api-qa.nike.net',
    stg:    'https://niketeam-custom-elite-options-api-stg.nike.net',
    uat:    'https://niketeam-custom-elite-options-api-uat.nike.net',
    prod:   'https://niketeam-custom-elite-options-api.nike.net'
};

export default function eliteOptionsBaseUrl(location:Location) {
    const type = calculateEndpointType(location, 'prod');
    return endpointTypeToUrl[type];
}