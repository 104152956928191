import {
  fetchRequest,
  getMock,
  mockAll,
  mockForIeAndSafari,
  urlNtsServices,
  urlNtsTemplateServices,
} from "./index";

import {
  embellishedDesignPriceMock,
  StyleTemplateMock,
  uniformDesignMock
} from "../mocks";
import {LS_ACCESS_TOKEN} from "../../helpers/constants";

/**
 * Handler for creating a request for Template API services
 * NOTE: Calls through webapp proxy to ensure OKTA token is valid when calling Template API
 * @param url
 * @param type
 * @param entity
 * @returns {Request}
 */
const createTemplateApiRequest = (url, type, entity) => {
  return new Request(`${urlNtsServices}/route/v1`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({
      path: url,
      methodType: type,
      entity: entity,
      contentType: 'application/json'
    })
  })
};

/**
 * Get templates available for given style from Template API
 * @type {{get: StyleTemplates.get}}
 */
export const StyleTemplates = {
  get: (styleNumber) => {
    if (mockForIeAndSafari || mockAll) {
      return getMock(StyleTemplateMock)
    }
    return fetchRequest(createTemplateApiRequest(`/api/styles/${styleNumber}`, 'GET'));
  }
};

/**
 * Get uniform design details from Template API
 * @type {{post: (function(*=): (PromiseLike<T | never> | Promise<T | never> | *)), get: UniformDesignService.get}}
 */
export const UniformDesignService = {

  // get an inspiration
  get: (designId) => {
    if (mockAll) {
      return getMock(uniformDesignMock)
    }

    return fetchRequest(createTemplateApiRequest(`/api/inspirations/get/${designId}`, 'GET'));
  },

  // save an inspiration + art-less inspiration image
  postConsumer: (payload) => {
    const request = new Request(`${urlNtsTemplateServices}/inspirations/user/save`, {
      method: 'POST',
      body: payload,
      headers: {
        'Authorization': 'Unite ' + localStorage.getItem(LS_ACCESS_TOKEN),
        'Accept': 'application/json'
      }
    });
    return fetchRequest(request,true,"UNITE");
  },

  // save an inspiration
  post: (design) => {
    //TODO hack to remove bogus data getting on the design somehow...
    if (design.templates.hasOwnProperty("null")) {
      delete design.templates["null"]
    }

    return fetchRequest(createTemplateApiRequest(`/api/inspirations/save`, 'POST', design)).then((result) => {
      return result;
    });
  }

};

/**
 * Get embellished design price from Template API
 * @type {{post: EmbellishedDesignPriceService.post, get: EmbellishedDesignPriceService.get}}
 */
export const EmbellishedDesignPriceService = {

  get: (designId, unitCount, isFillin) => {
    if (mockAll) {
      return getMock(embellishedDesignPriceMock)
    }
    return fetchRequest(createTemplateApiRequest(`/api/rules/prices/fullEmbellishedPrice/${designId}/${unitCount}/${isFillin}`, 'GET'));
  },

  post: (design, unitCount) => {
    if (mockAll) {
      return getMock(embellishedDesignPriceMock)
    }
    //Will need to be updated when we have access to the vas type
    return fetchRequest(createTemplateApiRequest(`/api/rules/prices/fullEmbellishedPrice/${unitCount}`, 'POST', design));
  }

};

/**
 * Get ALL colors from Template API
 * @type {{get: (function(): Promise<T | never>)}}
 */
export const ColorsService = {

  get: () => {
    return fetchRequest(createTemplateApiRequest(`/api/attributes/colors`, 'GET')).then(result => {
      if(result && result.sort) {
        result.sort((a, b) => a.order - b.order);
      } else {
        return {}
      }
      return result;
    }).catch(error => {
      throw error;
    });

  }

};

/**
 * Get ALL fonts from Template API
 * @type {{get: (function(): *)}}
 */
export const FontService = {
  get: () => fetchRequest(createTemplateApiRequest(`/api/attributes/fonts`, 'GET'))
};

/**
 * Get specific font rule from Template API
 * @type {{get: (function(*, *): *)}}
 */
export const FontRulesService = {
  //Implementation copied from the admin template builder
  get: (fontType, boxWidth) => fetchRequest(createTemplateApiRequest(`/api/rules/fonts/` + fontType + `/` + boxWidth, 'GET'))
};

/**
 * Get ALL font rules from Template API
 * @type {{get: (function(): *)}}
 */
export const FontRules = {
  get: () => fetchRequest(createTemplateApiRequest(`/api/rules/fonts`, 'GET'))
};
