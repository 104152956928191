import {dateStringSort} from "../../helpers/arrayHelpers";
import {isDealerRole} from "../../helpers/userProfileHelpers";

export function getCurrentOrdersTableConfig() {
  let type = isDealerRole() ? "ORDER" : "QUOTE";
  return [
    {
      Header: 'ID',
      accessor: 'id',
      show: false
    },
    {
      Header: `${type} NAME`,
      accessor: 'name'
    },
    {
      Header: 'CREATED',
      accessor: 'createDate',
      sortMethod: dateStringSort
    },
    {
      Header: 'LAST UPDATED',
      accessor: 'lastUpdated',
      sortMethod: dateStringSort
    },
    {
      Header: 'STATUS',
      accessor: 'status'
    },

    // {
    //   filterable : true,
    //   Header: 'UD NUMBER',
    //   accessor: 'udNumbers',
    //   width: 300,
    //   filterMethod: (filter, row) => {
    //     if (row._original.udNumbers)
    //       return row._original.udNumbers.toUpperCase().includes(filter.value.toUpperCase());
    //     else
    //       return false;
    //   },
    //   Cell: rowInfo => (
    //     <span style={{fontSize: "14px"}}>
    //       {rowInfo.original.udNumbers &&
    //       rowInfo.original.udNumbers.split(',').map((ud) => {
    //         if (ud !== "null")
    //           return (<div>{ud}</div>);
    //         else
    //           return;
    //       })}
    //     </span>
    //   )
    // }
    // {
    //   Header: 'RESERVATION EXPIRES IN',
    //   Cell: row => (
    //     <span>
    //       {formatTimeRemaining(row.original.hoursLeft, row.original.minutesLeft)}
    //     </span>
    //   )
    // }
    ]
}
