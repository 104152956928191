import React, {useState, useEffect} from 'react'
import momentTimezone from 'moment-timezone';

import { dispatchShowToast } from "../../../actions/toastActions";
import { showMessageModal } from "../../../actions/messageModalActions";


import { FactoryShipGroupState, AddOrderDeadline} from "../factory-ship-group/adminFactoryShipGroupHelper";
import { getShipGroupOrderDeadlineList, addShipGroupOrderDeadline, updateShipGroupOrderDeadline, deleteShipGroupOrderDeadline } from "../../../api/services/adminFactoryService";

import { OrderDeadlineTable } from './OrderDeadlineTable'


export const OrderDeadline = ({shipGroup}: {shipGroup:any}) => {
  const orderDeadlineObj: AddOrderDeadline = {
        ddln_dt:"",
        disc_pctg:"",
        lead_tm_dys:0,
        max_cpty:0,
        curr_cpty:0,
        shp_dt:"",
        team_shp_grp_wndw_id:""
  }
  const initialState: FactoryShipGroupState = {
        results: [],
        error: "",
        message: "",
        page: 0,
        size: 10,
        count: 0,
        keyword: '',
        factory:  {id: 0, name: '', code: '', updatedAt: '',priority:'', fcr_comm_cd: 'EDI', fcr_supplier_id: null, fhs_fcr_cd: '' },
        factoryId: 0,
        sort: [{
          id: 'last_mod_tmst',
          desc: true
        }]
  };

  const [loading, setLoading] = useState(false);
  const [orderDeadlineState, setOrderDeadlineState] = useState(initialState);

  const getOrderDeadline = async (page: number, size: number, sort: any) => {
    const response = await getShipGroupOrderDeadlineList(shipGroup.team_shp_grp_id, `page=${(page + 1)}&pageSize=${size}&sortColumn=${sort.id}&sortDesc=${sort.desc ? 'DESC': 'ASC'}`);
    if(response){
      setLoading(false);
      const objects = response.objects;
      for (const item of objects) {
        item.update = false;
      }
      setOrderDeadlineState(prevState => ({
        ...prevState,
        results: objects,
        page: page,
        count: response.pages.totalCount,
        size: size,
      }))
    }
  }

  const handleOrderDeadlinePagination = (size: number, page: number) => {
    setOrderDeadlineState(prevState => ({
      ...prevState,
      page: page,
      size: size
    }))
    if(!loading) {
      setLoading(true);
      getOrderDeadline(page, size, orderDeadlineState.sort[0]);
    }
  }

  const handleOrderDeadlineSort = (item: any) => {
    setOrderDeadlineState(prevState => ({
      ...prevState,
      sort: item
    }))
    getOrderDeadline(orderDeadlineState.page, orderDeadlineState.size, item[0]);
  }

  const updateOrderDeadlineRow = (value: any, key: any, index: any) => {
    const results = orderDeadlineState.results;
    results[index][key] = value;
    results[index]['update'] = true;
    if(key === 'curr_cpty')
    results[index]['max_cpty'] = value
    setOrderDeadlineState(prevState => ({
      ...prevState,
      results: results
    }))
  }

  const addOrderDeadlineRow = () => {
    const results = orderDeadlineState.results;
    results.push(orderDeadlineObj);
    setOrderDeadlineState(prevState => ({
      ...prevState,
      results: results
    }))
  }

  const removeOrderDeadlineRow = (rowData:any, index: number) => {
    showMessageModal({
      header: "",
      message: "Are you sure you want to remove this order deadline?",
      buttonText: "remove",
      cancelable: true,
      onClick: () => {
        deleteOrderDeadline(rowData.team_shp_grp_wndw_id, index);
      }
    });
  }

  const acceptOrderDeadlineRow = async (rowData:any, index: number) => {
    const currrentPstDate = momentTimezone().tz("America/Los_Angeles").format('YYYY-MM-DD')
    if(currrentPstDate <= rowData.ddln_dt && currrentPstDate <= rowData.shp_dt){
      if(rowData.ddln_dt  < rowData.shp_dt){
        const results = orderDeadlineState.results;
        results[index]['update'] = true;
        const reqBody : any = {
          max_cpty: Number(rowData.max_cpty),
          disc_pctg: Number(rowData.disc_pctg),
          ddln_dt: rowData.ddln_dt,
          shp_dt: rowData.shp_dt,
          stat_cd: "A",
          lead_tm_dys: Number(rowData.lead_tm_dys),
          team_shp_grp_id:shipGroup.team_shp_grp_id
        };
        if(rowData?.team_shp_grp_wndw_id){
          await editOrderDeadline(results,rowData.team_shp_grp_wndw_id,reqBody,index)
        }else {
          await createOrderDeadline(results,reqBody,index)
        }
      }else {
        dispatchShowToast(`Arrival date ${rowData.shp_dt} is less then order by date ${rowData.ddln_dt}`);
      }
    }else {
      dispatchShowToast("Order by date or arrival date can not be in the past");
    }
  }

  const createOrderDeadline = async (
    results: any,
    payload: any,
    index: number
  ) => {
    try {
      const reqBody = {
        ...payload,
        team_shp_grp_id :shipGroup.team_shp_grp_id
      }
      let response : any = await addShipGroupOrderDeadline(reqBody);
      if (response.error) {
        throw response;
      } else {
        await getOrderDeadline(orderDeadlineState.page, orderDeadlineState.size, orderDeadlineState.sort[0]);
      }
    } catch (err: any) {
      showMessageModal({ header: "ADD ORDER DEADLINE ERROR", message: err.message, required: true });
    }
  };

  const editOrderDeadline = async (
    results: any,
    teamShpGropWndwId: string,
    payload: any,
    index: number
  ) => {
    try {
      let response: any =
        await updateShipGroupOrderDeadline(
          teamShpGropWndwId,
          payload
        );
      if (response.error) {
        throw response;
      } else {
        await getOrderDeadline(orderDeadlineState.page, orderDeadlineState.size, orderDeadlineState.sort[0]);
      }
    } catch (err: any) {
      showMessageModal({ header: "EDIT ORDER DEADLINE ERROR", message: err.message, required: true });
    }
  };

  const deleteOrderDeadline = async (teamShpGropWndwId: number,rowIndex: number) => {
    if(!teamShpGropWndwId){
      setOrderDeadlineState((prevState) => ({
        ...prevState,
        results: prevState.results?.filter((_, index) => index !== rowIndex),
      }));
    } else {
      try {
        let response = await deleteShipGroupOrderDeadline(teamShpGropWndwId);
        if (response.error) {
          throw response;
        } else {
          await getOrderDeadline(orderDeadlineState.page, orderDeadlineState.size, orderDeadlineState.sort[0]);
        }
      } catch (err: any) {
        showMessageModal({ header: "DELETE ORDER DEADLINE ERROR", message: err.message, required: true });
      }
    }
  };


    useEffect(() => {
        getOrderDeadline(orderDeadlineState.page, orderDeadlineState.size, orderDeadlineState.sort[0]);
    }, [])

    //return (<div>OrderDeadline</div>)
    return (
      <OrderDeadlineTable
        state={orderDeadlineState}
        shipGroup={shipGroup}
        handlePagination={handleOrderDeadlinePagination}
        handleSort={handleOrderDeadlineSort}
        updateRow={updateOrderDeadlineRow}
        addRow={addOrderDeadlineRow}
        deleteRow={removeOrderDeadlineRow}
        acceptRow={acceptOrderDeadlineRow}
      />
    )
}
