import {calculateEndpointType} from './common';

/**
 * Provides base URL for calling embodee services and requesting images/inspirations (i.e. on the Gridwall)
 *
 * OLD? --- http://nikestaging.embodee.com/
 */
const endpointTypeToUrl = {
  local: 'https://builder-stg.nike.com',
  dev: 'https://dev.embodee.com',
  qa: 'https://dev.embodee.com',
  stg: 'https://builder-stg.nike.com',
  uat: 'https://builder-stg.nike.com',
  prod: 'https://builder.nike.com'
};

export default function embodeeBuilderBaseUrl(location) {
  const type = calculateEndpointType(location, 'prod');
  return endpointTypeToUrl[type];
}
