import * as React from 'react';
import {DropdownButton, MenuItem} from 'react-bootstrap';

interface Props {
    sports: string[];
    selectedSport: string;
    filterClickHandler: (sport: string) => void;
}

const CatalogSportFilter: React.FC<Props> = ({
    sports,
    selectedSport,
    filterClickHandler,
}) => {
    return (
        <DropdownButton id="sportFilter" className="sort-dropdown" pullRight title={selectedSport}>
        {
            sports.map(sport => {
                return (
                    <MenuItem onClick={() => filterClickHandler(sport)} id={sport} key={sport}>
                        <span className="sportFilter-label">{sport}</span>
                    </MenuItem>
                )
            })
        }
        </DropdownButton>
    );
};

export default CatalogSportFilter;
