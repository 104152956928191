import React from 'react';

interface Props {
  small?: boolean;
}

/**
 * Nike Sustainability Badge
 *
 * Usage Variations
 *    Regular =>    <SustainabilityBadge />
 *    Small =>      <SustainabilityBadge small />
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const SustainabilityBadge: React.FC<Props> = (props) => {

  return (
    <span className={`nike-sustainability-badge ${props.small ? "small-badge" : ""}`}>
      <svg viewBox="0 0 968 968" xmlns="http://www.w3.org/2000/svg" >
        <path d="M468 .6c-155.9 15-33.9 305.2 11.4 468.4l4.6-.6c-2-224.1-4.1-315.6 48.3-342 30.9-12.3 71.9-9.3 153.7 68.8C614.2 92.5 529.8-2 468 .6zm346.5 130.3C693.7 31.2 574.7 322.7 491.4 470.1l3.7 2.8c157-159.9 220.3-226.1 276-207.7 30.5 13.2 57.4 44.3 60 157.3 21.8-123.3 28.9-249.8-16.6-291.6zM967.4 468c-15-155.9-305.2-33.9-468.4 11.4l.6 4.6c224.1-2 315.6-4.1 342 48.3 12.3 30.9 9.3 71.9-68.8 153.7C875.5 614.2 970 529.8 967.4 468zM837.1 814.5c99.6-120.8-191.8-239.8-339.3-323.1L495 495c159.9 157 226.1 220.3 207.7 276-13.2 30.5-44.3 57.4-157.3 60 123.4 21.9 249.9 29 291.7-16.5zM500 967.4c155.9-15 33.9-305.2-11.4-468.4l-4.6.6c2 224.1 4.1 315.6-48.3 342-30.9 12.3-71.9 9.3-153.7-68.8C353.8 875.5 438.2 970 500 967.4zM153.5 837.1c120.8 99.6 239.8-191.8 323.1-339.3L473 495C316 654.9 252.7 721.1 197 702.7c-30.5-13.2-57.4-44.3-60-157.3-21.9 123.4-29 249.9 16.5 291.7zM.6 500c15 155.9 305.2 33.9 468.4-11.4l-.6-4.6c-224.1 2-315.6 4.1-342-48.3-12.3-30.9-9.3-71.9 68.8-153.7C92.5 353.8-2 438.2.6 500zm130.3-346.5C31.2 274.3 322.7 393.3 470.1 476.6l2.8-3.7c-159.9-157-226.1-220.3-207.7-276 13.2-30.5 44.3-57.4 157.3-60-123.3-21.8-249.8-28.9-291.6 16.6z"></path>
      </svg>
    </span>
  );

};
