import * as types from "./actionTypes";
import {store} from "../../App";
import {getEnvironment} from "../helpers/browserHelpers";
import {NavigationState} from '../reducers/navigationReducer';

export function dispatchPrePopulateDealerState(state: NavigationState['dealerState']) {
  return { type: types.PRE_POPULATE_DEALER_STATE, state };
}

/**
 * Stores environment flag in redux
 * @returns {{environment: string, type: string}}
 */
export const dispatchSetEnvironment = () => {
  return { type: types.SET_ENVIRONMENT, environment: getEnvironment()};
};

/**
 * Updates redux navigation state
 * @param state
 * @returns {*}
 */
export const dispatchLoadNavigationState = (state: NavigationState['display']) => {
  return store.dispatch({
    type: types.LOAD_NAV_STATE,
    state: state
  })
};

/**
 * Restores redux navigation state to default
 * @returns {{type: string}}
 */
export const dispatchClearNavigationState = () => {
  return store.dispatch({
    type: types.CLEAR_NAV_STATE
  })
};

export const showFillinHelperModal = (
  mode: NavigationState['fillinHelperModal']['mode'],
  ud?: string,
  isInLocker: boolean = false,
  cartId?: string,
) => ({
  type: types.SHOW_FILLIN_HELPER_MODAL,
  mode,
  ud,
  isInLocker,
  cartId,
});

export const hideFillinHelperModal = () => ({
  type: types.HIDE_FILLIN_HELPER_MODAL,
});
