import {Reducer, Action} from 'redux';
import cloneDeep from 'lodash/cloneDeep';

import * as types from '../actions/actionTypes';

interface SelectedColor {
  PRIMARY: string | null;
  SECONDARY: string | null;
}

interface SelectedDesign {
  NAME: boolean | false;
  NUMBER: boolean | false;
  DESIGN_CHANGE: string | null;
}

export interface BuilderState {
  showBuilder: boolean;
  activeTemplate: string | null;
  selectedDesigns: SelectedDesign,
  selectedColors: {
    Front: SelectedColor;
    Back: SelectedColor;
    'Reversible Front': SelectedColor;
    'Reversible Back': SelectedColor;
  }
  showDealerArtworkSelect: boolean;
  builderType: string;
  artworkSelectCallback: unknown;
  showArtworkEmailModal: boolean;
  logoOptions: unknown;
  showLogoTerms: boolean;
  logoTermsAccepted: boolean;
  graphicSelected: boolean;
}

// TODO: These actions can be separate types later
interface BuilderAction extends Action {
  template: string;
  logos: unknown;
  show: boolean;
  logoTermsAccepted: boolean;
  callback: unknown;
  builderType: string;
  side: 'Front' | 'Back' | 'Reversible Front' | 'Reversible Back';
  category: 'PRIMARY' | 'SECONDARY';
  color: string;
  graphicSelected: boolean;
  selectedDesignsNameNumber: SelectedDesign;
}

const initialState: BuilderState = {
  showBuilder: false,
  activeTemplate: null,
  selectedColors: {
    Front: {
      PRIMARY: null,
      SECONDARY: null
    },
    Back: {
      PRIMARY: null,
      SECONDARY: null
    },
    'Reversible Front': {
      PRIMARY: null,
      SECONDARY: null
    },
    'Reversible Back': {
      PRIMARY: null,
      SECONDARY: null
    },
  },
  selectedDesigns: {
    NAME : false,
    NUMBER : false,
    DESIGN_CHANGE: null,
  },
  showDealerArtworkSelect: false,
  builderType: 'stock',
  artworkSelectCallback: null,
  showArtworkEmailModal: false,
  logoOptions: {},
  showLogoTerms: false,
  logoTermsAccepted: false,
  graphicSelected: false,
};

const builderReducer: Reducer<BuilderState> = (state = initialState, incomingAction) => {
  const action = incomingAction as unknown as BuilderAction;
  switch (action.type) {
    case types.SHOW_BUILDER:
      return {
        ...state,
        showBuilder: true
      };
    case types.HIDE_BUILDER:
      return {
        ...state,
        showBuilder: false
      };
    case types.ACTIVE_TEMPLATE:
      return {
        ...state,
        activeTemplate: action.template
      };
    case types.UPDATE_DESIGN_CUSTOM_LOGOS:
      return {
        ...state,
        logoOptions: cloneDeep(action.logos)
      };
    case types.BUILDER_GRAPHIC_SELECTED:
      return {
        ...state,
        graphicSelected: action.graphicSelected
      };
    case types.SHOW_LOGO_TERMS_MODAL:
      return {
        ...state,
        showLogoTerms: action.show
      };
    case types.LOGO_TERMS_ACCEPTED:
      return {
        ...state,
        logoTermsAccepted: action.logoTermsAccepted
      };
    case types.SHOW_DEALER_ARTWORK_SELECT:
      return {
        ...state,
        showDealerArtworkSelect: action.show,
        builderType: action.builderType,
        artworkSelectCallback: action.callback
      };
    case types.SAVE_CUSTOM_LOGO:
      return {
        ...state
      };
    case types.SET_SELECTED_COLOR:
      let newState = { ...state };
      newState.selectedColors[action.side][action.category] = action.color;
      return newState;
    case types.SET_SELECTED_DESIGN_NAME_NUMBER:
     return {
       ...state,
       selectedDesigns: action.selectedDesignsNameNumber,
     }
    case types.TOGGLE_ARTWORK_EMAIL_MODAL:
      return {
        ...state,
        showArtworkEmailModal: !state.showArtworkEmailModal,
      };
    default:
      return state;
  }
}

export default builderReducer;