import {AUTH_HEADER_PREFIX, LS_ACCESS_TOKEN, SAP_SALES_ORG} from "../../helpers/constants";
import {getUserSoldTo} from "../../helpers/userProfileHelpers";
import {fetchRequest, urlNtsStockOrderServices} from "./index";

export const NtsStockOrderService = {

  addToCart: (cartId, payload, soldTo) => {
    const request = new Request(`${urlNtsStockOrderServices}/api/v1/stock/${cartId}/add?soldTo=${soldTo}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      }
    });
    return fetchRequest(request, true, 'UNITE');
  },

  getCart: (cartId) => {

    const request = new Request(`${urlNtsStockOrderServices}/api/v1/stock/${cartId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      }
    });
    return fetchRequest(request, true, 'UNITE');
  },
  /**
   *Will use this endpoint again in Artwork-Phase-2
  //  */
  // getInspirationCart: (cartId) => {
  //
  //   const request = new Request(`${urlNtsStockOrderServices}/order/${cartId}/v1`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json',
  //       'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
  //     }
  //   });
  //   return fetchRequest(request, true, 'UNITE');
  //
  // },

  getSapCartData: (cartId) => {

    const request = new Request(`${urlNtsStockOrderServices}/api/v1/stock/pastStockOrder/${cartId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      }
    });
    return fetchRequest(request, true, 'UNITE');

  },

  updateCart: (payload, cartId) => {

    const request = new Request(`${urlNtsStockOrderServices}/api/v1/stock/updateStockOrder/${cartId}?soldTo=${getUserSoldTo()}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      }
    });
    return fetchRequest(request, true, 'UNITE');

  },

  updatePastCart: (payload) => {
    const request = new Request(`${urlNtsStockOrderServices}/api/v1/stock/sapCart?soldTo=${getUserSoldTo()}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      }
    });
    return fetchRequest(request, true, 'UNITE');
  },

  resaveDesign: (cartId) => {
    const request = new Request(`${urlNtsStockOrderServices}/api/v1/stock/order/event/${cartId}`, {
      method: 'POST',
      body: {},
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      }
    });
    return fetchRequest(request, true, 'UNITE');
  },

  reOrderCartProducts: (cartId) => {
    const request = new Request(`${urlNtsStockOrderServices}/order/reorder/${cartId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      }
    });
    return fetchRequest(request, true, 'UNITE');
  },

  getShiptos: (soldTo) => {

    const request = new Request(`${urlNtsStockOrderServices}/shiptos/v1/${soldTo}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      }
    });

    return fetchRequest(request, true, 'UNITE');

  }


};

window.NtsStockOrderService = NtsStockOrderService;

export const AvailabilityCheck = {

  get: (products) => {
    let styleList = '';
    products.forEach((p, i) => {
      styleList += p.styleColor;
      if (i !== (products.length - 1)) {
        styleList += ',';
      }
    });
    let url = `${urlNtsStockOrderServices}/api/v1/stock/availability?childSo=${SAP_SALES_ORG}&isRealTime=true&currency=USD&hzn=180&language=EN&priceList=USA&so=${SAP_SALES_ORG}&` +
        "soldTo=" + getUserSoldTo() +
        "&styleColor=" + styleList;

    const request = new Request(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      }
    });
    return fetchRequest(request, true, 'UNITE');
  }

};

export const RetailerAccess = {
  apply: (accessCode, cartId) => {
    const url = `${urlNtsStockOrderServices}/retailer/apply/v1`;

    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify({
        retailerAccessCode: accessCode,
        retailerExperience: "SCHEELS",
        cartId
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
      }
    });
    return fetchRequest(request, true, 'UNITE');
  }
};
