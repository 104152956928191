import cloneDeep from 'lodash/cloneDeep'
import {updateUrlPath} from "./imageUrlBuilder";
import {store} from '../../App';
import * as types from '../actions/actionTypes';
import {getUserId, isDealerRole} from "./userProfileHelpers";
import {showMessageModal} from "../actions/messageModalActions";

export function loadFullDesignToTemplates(uniformDesign, templates) {
  if (templates) {
    Object.keys(templates).forEach((templateName) => {
      //If the design has a matching template, load it into the template,
      //otherwise clear the graphic selection for the template.
      //Reset the modified flag
      templates[templateName].modified = false;

      if (uniformDesign && uniformDesign.templates[templateName]) {
        loadDesignToTemplate(templates[templateName], uniformDesign.templates[templateName]);
        store.dispatch({type: types.UPDATE_TEMPLATE});
        store.dispatch({type: types.SET_TEMPLATE, side: templateName, template: templates[templateName]});
      } else {
        unselectAll(templates[templateName].data.components[0].availableGraphics);
        store.dispatch({type: types.SET_TEMPLATE, side: templateName, template: templates[templateName]});
      }
    })
  }
}

export function loadDesignToTemplate(template, design) {
  if (!design.data.components[0].availableGraphics[0]) return;
  setSelectedItem(template.data.components[0].availableGraphics, design.data.components[0].availableGraphics[0]);
  let selectedGraphic = getSelectedItem(template.data.components[0].availableGraphics);

  if (design.imageUrl) {
    template.imageUrl = design.imageUrl;
  }

  //TODO: Listing out each prop to copy over is tedious, is there a way to walk all the components and do the needfull based on the type?
  //IE Arrays would get setSelected called, strings would just be set, etc?
  selectedGraphic.data.components.forEach((component) => {

    if (!design.data.components[0].availableGraphics[0]) return;

    //TODO: This assumes the top level selected component will be a composite, which is the current plan.
    let designComponent = design.data.components[0].availableGraphics[0].data.components.find((element) => {
      return element.id === component.id
    })
    if (!designComponent) return;

    if (component.availableFonts && designComponent.availableFonts[0]) {
      setSelectedItem(component.availableFonts, designComponent.availableFonts[0]);
    }
    if (component.availableColors && designComponent.availableColors) {
      setSelectedItem(component.availableColors, designComponent.availableColors[0]);
    }
    if (component.availableGraphics && component.availableGraphics[0].data.availableColors && designComponent.availableGraphics) {
      setSelectedItem(component.availableGraphics[0].data.availableColors, designComponent.availableGraphics[0].data.availableColors[0]);
    }
    if (component.availableSecondaryColors && designComponent.availableSecondaryColors[0]) {
      setSelectedItem(component.availableSecondaryColors, designComponent.availableSecondaryColors[0]);
    }
    if (component.availableBackgroundColors && designComponent.availableBackgroundColors[0]) {
      setSelectedItem(component.availableBackgroundColors, designComponent.availableBackgroundColors[0]);
    }
    if (component.name && designComponent.name) {
      component.name = designComponent.name
    }
    if (designComponent.optOut) {
      component.optOut = designComponent.optOut
    }
    if (designComponent.displayBackground) {
      component.displayBackground = designComponent.displayBackground
    }
    if (designComponent.url) {
      component.url = designComponent.url;
    }
    if (designComponent.artworkId) {
      component.artworkId = designComponent.artworkId;
    }
    if (designComponent.name) {
      component.name = designComponent.name;
    }
    if (designComponent.fontSize) {
      component.fontSize = designComponent.fontSize;
    }
    if (component.printTypeOptions && designComponent.printTypeOptions) {
      let printType = getSelectedItem(designComponent.printTypeOptions);
      setSelectedItem(component.printTypeOptions, printType, "printType");
    }
  });
  let path = updateUrlPath(selectedGraphic.data.components, null, true);
  selectedGraphic.data.image.url = path;
}

/*
  Sets the selected item component in the template object according to
  what is in the design object.
  If the design has no item component selected,
    the default will be used, or the first element if there is no default selected.
  If the design has a item but it is not in the template,
    the default will be used, or the first element if there is no default selected.
  If the design has a item and it is in the template, it will be set to selected.

  Returns true if the design had no item, or the design item is set to selected.
  Returns false if the design has a item that is not in the template.
*/
export function setSelectedItem(templateItems, designItem, field = "id") {
  const hasTemplateItems = templateItems && templateItems.length > 0;
  if(hasTemplateItems) {
    if (designItem) {
      //We have a design item, see if it is present on the templateItems
      let templateItem = templateItems.find((element) => {
        return element[field] === designItem[field]
      });
      if (templateItem) {
        //Template contains the item selected in the design.
        //Unselect all items in the template
        templateItems.map((element) => element.selected = false);
        //Select the one from the design.
        templateItem.selected = true;
      } else if (designItem.id === "none") {
        unselectAll(templateItems);
      } else {
        //Design has a selected item that is not in the template
        insureOneSelected(templateItems);
        return false;
      }
    } else {
      //No selection in the design, insure one is selected in the template.
      insureOneSelected(templateItems)
    }
    return true;
  } else {
    return false;
  }
}

function insureOneSelected(components) {
  if (components.length > 0 && !components.find((item) => {
      return item.selected
    })) {
    components[0].selected = true;
  }
}

export function unselectAll(components) {
  if(components && components.length > 0) {
    components.map((element) => element.selected = false);
  }
}

export function getSelectedItem(components, forceSelection) {
  let selected = components.find((element) => {
    return element.selected
  });
  if (!selected && forceSelection && components.length > 0) {
    selected = components[0];
    components[0].selected = true;
  }
  return selected;
}

//Creates design JSON from a given template by stripping out any options that are not selected.
export function distillDesign(templates, selectedStyle, uniformDesign) {
  let design = {
    id: (uniformDesign && uniformDesign.id) ? uniformDesign.id : null,
    name: "none",
    styleCode: selectedStyle.styleNumber,
    colorCode: selectedStyle.colorNumber,
    userId: getUserId(),
    isPublished: false,
    isDefault: false,
    confirmedOrders: (uniformDesign && uniformDesign.confirmedOrders) ? uniformDesign.confirmedOrders : 0,
    hasPlayerNumber : false,
    hasPlayerName :false
  };
  design.shortDesignId = (uniformDesign && uniformDesign.shortDesignId) ? uniformDesign.shortDesignId : null;

  design.templates = cloneDeep(templates);
  Object.keys(design.templates).forEach((key) => {
    let template = design.templates[key];
    try {
      //Remove all unselected composite graphic options
      template.data.components[0].availableGraphics = removeUnselected(template.data.components[0].availableGraphics);

      //Check the components and remove unslelected options as needed
      let components = template.data.components[0].availableGraphics[0].data.components;
      components.forEach((item) => {
        if (item.availableGraphics) {
          item.availableGraphics.forEach((element) => {
            element.data.availableColors = removeUnselected(element.data.availableColors);
          })
        }

        if (item.availableFonts) {
          item.availableFonts = removeUnselected(item.availableFonts)
        }

        if (item.availableColors) {
          item.availableColors = removeUnselected(item.availableColors)
        }

        if (item.availableSecondaryColors) {
          item.availableSecondaryColors = removeUnselected(item.availableSecondaryColors)
        }

        if (item.availableBackgroundColors) {
          item.availableBackgroundColors = removeUnselected(item.availableBackgroundColors)
        }
      });

    } catch (err) {
      //TODO: Catching undefined errors from bad data, should add better error checking here.
    }
  });
  return design;
}

export function populateDesignNameNumber(design) {
  let selectedDesigns = {
    NAME: false, NUMBER: false,DESIGN_CHANGE: null,
  }
  Object.keys(design.templates).forEach((key) => {
    let template = design.templates[key];
    try {
      let components = template.data.components[0].availableGraphics[0].data.components;
      components.forEach((item) => {
        if (item.label) {
          if (item.label === "PLAYER NAME") {
            selectedDesigns.NAME = true;
          } else if (item.label === "PLAYER NUMBER") {
            selectedDesigns.NUMBER = true;
          }
        }
      });
      selectedDesigns.DESIGN_CHANGE = "YES";
    } catch (error) {

    }
  });
  return selectedDesigns;
}

//For a collection of selectable items in a design, remove all the unselected ones and return
//an array with just the selected ones.
function removeUnselected(collection) {
  if (collection && collection.filter) {
    return collection.filter((item) => {
      return item.selected
    })
  } else {
    return [];
  }
}

function crdsHasNameNumber(product){
  let myCase = 0;
  product.crds.forEach(crd => {
    crd.sizes.forEach(size => {
      if(size.roster[0].name && size.roster[0].number){
        //If "NameAndNumber"
        myCase = 1;
      }else if(size.roster[0].name && !size.roster[0].number){
        //If "Name"
        myCase = 2;
      }else if (!size.roster[0].name && size.roster[0].number){
        //If "Number"
        myCase = 3;
      }
    })
  })
  return myCase;
}

function updateRosterWithSelectedDesign(roster, toRemove){
  let newRoster = [];
  roster.forEach(item => {
    if(toRemove === "Name"){
      newRoster.push({number:item.number});
    }else if(toRemove === "Number"){
    newRoster.push({name:item.name});
    }
  })
  return newRoster;
}

export function handleCrdsBasedOnSelectedeDesign(product, designNameAndNumber) {
  if (designNameAndNumber.NAME || designNameAndNumber.NUMBER) {
    switch (crdsHasNameNumber(product)) {
      //case "NameAndNumber":
      case 1:
        if (designNameAndNumber.NAME && !designNameAndNumber.NUMBER) {
          product.crds.forEach(crd => {
            crd.sizes.forEach(size => {
              size.roster = updateRosterWithSelectedDesign(size.roster, "Number");
            })
          })
        } else if (!designNameAndNumber.NAME && designNameAndNumber.NUMBER) {
          product.crds.forEach(crd => {
            crd.sizes.forEach(size => {
              size.roster = updateRosterWithSelectedDesign(size.roster, "Name");
            })
          })
        }
        break;
      //case "Name" :
      case 2:
        if (designNameAndNumber.NAME && designNameAndNumber.NUMBER) {
          product.crds.forEach(crd => {
            crd.sizes.forEach(size => {
              size.roster.forEach(item => {
                item.number = "";
              })
            })
          })
        }
        break;
      //case "Number" :
      case 3:
        if (designNameAndNumber.NAME && designNameAndNumber.NUMBER) {
          product.crds.forEach(crd => {
            crd.sizes.forEach(size => {
              size.roster.forEach(item => {
                item.name = "";
              })
            })
          })
        }
        break;
      default:
        break;
    }
  } else if(designNameAndNumber.DESIGN_CHANGE){
    product.crds.forEach(crd => {
      crd.sizes.forEach(size => {
        size.roster = [];
      })
    })
  }
}

/**
 * Provides check to see if design has any customizations added
 * Used to help determine Custom Stock vs Stock products
 * @param design
 * @returns {boolean}
 */
export function hasCustomizations(design) {
  const hasTemplates =
    design &&
    design.templates &&
    Object.keys(design.templates).length > 0;

  if (hasTemplates) {
    for (let template in design.templates) {
      if (templateHasCustomizations(design.templates[template])) {
        return true;
      }
    }
  }
  return false;
}

/**
 * Provides check to see if a template has any customizations added
 * @param design
 * @returns {boolean}
 */
export function templateHasCustomizations(template) {
  if (hasGraphic(template)) {
    return true;
  }
}

/**
 * Check a design to see if any of the templates are marked as modified
 *
 * No longer in active use (B-376488)
 * @param design
 * @returns {boolean}
 */
export function designHasModifications(design) {
  for (let template in design.templates) {
    if (design.templates[template].modified) {
      return true;
    }
  }
}

/**
 * Helper function to determine if given template has graphics selected
 * @param template
 * @returns {*|template.data|{components}|Array|boolean}
 */
function hasGraphic(template) {
  return template &&
    template.data &&
    template.data.components &&
    template.data.components[0] &&
    template.data.components[0].availableGraphics &&
    template.data.components[0].availableGraphics.length > 0 &&
    getSelectedItem(template.data.components[0].availableGraphics)
}

/**
 * Validates the design is valid and order-able.
 * @param design
 * @returns {valid: boolean, messages: [string]}
 */
export function validateDesign(design) {

  if (!hasCustomizations(design)) {
    return {valid: true}
  }

  let errors = [];
  Object.keys(design.templates).forEach((key) => {
    let template = design.templates[key];
    if (!template || !template.data.components[0] || !template.data.components[0].availableGraphics[0]) return;

    //If this is a dealer cart, verify design artwork is selected or optOut is true.
    if (isDealerRole()) {
      template.data.components[0].availableGraphics[0].data.components.forEach((component) => {
        if (component.type === "logo") {
          if (!component.optOut && !component.url) {
            errors.push("Error in design, please select a logo for each artwork option or uncheck the checkbox if no logo is desired.")
          }
        }
      })
    }
  });

  return {
    valid: errors.length === 0,
    messages: errors
  };

}

/**
 * Determines if the given UD is for an SAP product
 * SAP - UUID (i.e. 5d037795-9d19-4443-a1bc-313856c31f0c)
 * SAP - Short Design Id (i.e. SB-427164-RP9P8) // Pattern: SB-{UserId(7)}-{Hash(5)}
 * @param id
 */
export function isSapDesignId(id) {
  if(id.match(/[a-z0-9A-Z]{8}-[a-z0-9A-Z]{4}-[a-z0-9A-Z]{4}-[a-z0-9A-Z]{4}-[a-z0-9A-Z]{12}/) != null ) {
    return true;
  } else if(id.match(/SB-[0-9]{1,7}-[a-z0-9A-Z]{3,5}/) != null) {
    return true;
  }
  return false;
}

/**
 * Determines if the given UD is for a JBA product
 * @param id
 */
export function isJbaDesignId(id) {
  return (id.toUpperCase().indexOf('UD') === 0);
}

/**
 * Determines if given id matches the Dynamo UUID format
 * i.e. 1a2b3c4d-5f6g-7h8i-9j0k-1a2b3c4d5e6f (alpha-numeric {8chars-4chars-4chars-12chars})
 * @param id
 * @returns {boolean}
 */
export function isDynamoUUID(id) {
  return (id && isNaN(id) && id.match(/[a-z0-9A-Z]{8}-[a-z0-9A-Z]{4}-[a-z0-9A-Z]{4}-[a-z0-9A-Z]{4}-[a-z0-9A-Z]{12}/) != null );
}

export function dispatchDisablePdpBtnStates(state) {
  store.dispatch({
    type: types.SET_PDP_BTN_STATES,
    states: {
      disableAddToCart: state,
      disableAddToLocker: state,
      disableContactDealer: state
    }
  });

}

/**
 * Shows error modal for invalid designs
 */
export const showInvalidDesignError = () => {
  showMessageModal({
    header: "DESIGN ERROR",
    message: "The number of colors selected exceeds the allowable limit for team name and number, please select for 2 colors or less.",
    callback: () => { dispatchDisablePdpBtnStates(true) }
  });
};

// Determine if a custom logo is being used
export const hasCustomLogo = (artworkState) => {
  return artworkState.logos &&
    Object.keys(artworkState.logos).length > 0;
};

// Retrieves total item quantity from the given cart for a given design
export const getCartQuantityByDesignId = (designId, cart, defaultQty) => {
  let itemQty = 0;
  const hasItems = cart && cart.stockProductList && cart.stockProductList.length > 0;
  if(hasItems) {
    cart.stockProductList.forEach((item) => {
      if(item.graphicDesignID && item.graphicDesignID === designId && item.crds && item.crds.length > 0) {
        item.crds.forEach(crd => {
          itemQty += crd.plannedQuantity;
        })
      }
    })
  }
  return (itemQty !== 0) ? itemQty : defaultQty;
}