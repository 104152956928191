import React from "react";

import {FullPageSpinner} from "../shared/spinners/FullPageSpinner";

/**
 * Empty page with spinner to be used for interim states
 * @returns {JSX.Element}
 * @constructor
 */
export const LoadingPage: React.FC<{}> = () => {
    return (
        <div style={{ minHeight: "calc(100vh - 296px)" }}>
            <FullPageSpinner />
        </div>
    )
};