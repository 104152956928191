export const LS_PRE_LOGIN_URL_KEY = 'nts_pre_login_url';

export interface OidcAccountsConfig {
    clientId: string;
    url: string;
}

export const ACCOUNTS_CONFIG_PREPROD:OidcAccountsConfig = {
    clientId: '750fed25fc0b8b261ce8b048e5bddff',
    url: 'https://accounts.nikedev.com'
}

export const ACCOUNTS_CONFIG_PROD:OidcAccountsConfig = {
    clientId: '5d973b4ab9d5235cc4a4134cab651297',
    url: 'https://accounts.nike.com'
}

/**
 * UserManager settings based on configuration options:
 * https://authts.github.io/oidc-client-ts/interfaces/UserManagerSettings.html
 */
export interface OidcUserManagerConfig {
    authority: string; // The URL of the OIDC provider.
    client_id: string; // Your client application's identifier as registered with the OIDC provider.
    redirect_uri: string; // The URI of your client application to receive a response from the OIDC provider.
    response_type: string; // (default: 'id_token'): The type of response desired from the OIDC provider.
    scope: string; // (default: 'openid'): The scope being requested from the OIDC provider.
    automaticSilentRenew?: boolean; // (default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
    loadUserInfo?: boolean; // (default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
    silent_redirect_uri?: string; // The URL for the page containing the code handling the silent renew.
    post_logout_redirect_uri?: string; // The OIDC post-logout redirect URI.
    audience?: string; // Way to specify the audience when making the jwt
}

/**
 * Metadata for OIDC token
 * Docs:
 * Nike Accounts Settings:
 *  (test) https://accounts.nikedev.com/.well-known/openid-configuration
 *  (prod) https://accounts.nike.com/.well-known/openid-configuration
 */
export interface OidcMetadataConfig {
    issuer: string;
    authorization_endpoint: string;
    userinfo_endpoint: string;
    end_session_endpoint: string;
    jwks_uri: string;
    token_endpoint?: string;
    check_session_iframe?: string;
    revocation_endpoint?: string;
    introspection_endpoint?: string;
}
