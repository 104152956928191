import jwt from "jsonwebtoken";

/**
 * Creates a basic JWT (used for testing)
 * @param user
 * @param expiryMinutes
 */
export function generateJwtToken(user, expiryMinutes) {
  return jwt.sign(user, 'PRETEND_SECRET', {
    expiresIn: expiryMinutes
  })
}

/**
 * Validates token for existence and expiry time
 * @param token
 */
export function isJwtTokenValid(token) {
  if (token && jwt.decode(token)) {
    const expiry = jwt.decode(token).exp;
    const now = new Date();
    return now.getTime() < expiry * 1000;
  }
  return false;
}