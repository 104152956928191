import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

export enum ActionMode {
  SHOW_ACTIONS = 'SHOW_ACTIONS',
  CREATE_SET = 'CREATE_SET',
  EMAIL = 'EMAIL',
  PRINT = 'PRINT',
}

export interface Set {
  name: string;
  elementId: string;
}

export interface Sport {
  display: string;
  value: string;
}

export interface LockerImage {
  imageUrl: string;
  imageLabel: string;
}

export interface LockerItem {
  color: string;
  displayName: string;
  elementId: string;
  formattedRetailPrice: string;
  images: LockerImage[];
  imageUrl: string;
  isOrderable: boolean;
  productType: 'jba' | 'sap' | 'custom sap';
  selected: boolean;
  style: string;
  uniformDesignId: string;
}

export interface Filter {
  label: string;
  value: string;
  isSet: boolean;
}

export interface LockerState {
  actionMode: ActionMode;
  lockerItems: LockerItem[];
  pageStart: number;
  selectedFilter: Filter | null;
  selectionMode: boolean;
  sets: Set[];
  sports: Sport[];
  totalRecords: number;
  fetchLockerItems: boolean;
}

const initialState: LockerState = {
  actionMode: ActionMode.SHOW_ACTIONS,
  lockerItems: [],
  pageStart: 0,
  selectedFilter: null,
  selectionMode: false,
  sets: [],
  sports: [],
  totalRecords: 0,
  fetchLockerItems: true,
};

interface LockerAction extends Action {
  elementId: string;
  lockerItems: LockerItem[];
  sets: Set[];
  actionMode: ActionMode;
  sports: Sport[];
  totalRecords: number;
  pageStart: number;
  filter: Filter;
  imageUrl: string;
}

const lockerReducer: Reducer<LockerState> = (
  state = initialState,
  incomingAction
) => {
  const action = incomingAction as unknown as LockerAction;

  switch (action.type) {
      case types.APPEND_LOCKER_ITEMS:
        return {
          ...state,
          lockerItems: [...state.lockerItems, ...action.lockerItems],
          totalRecords: action.totalRecords,
          fetchLockerItems: false,
      };
    case types.LOAD_FILTERS:
      return {
        ...state,
        sets: [...action.sets],
        sports: [...action.sports],
      };
    case types.TOGGLE_SELECT_LOCKER_ITEM:
      return {
        ...state,
        lockerItems: state.lockerItems.map(li => {
          return li.elementId === action.elementId
            ? {
              ...li,
              selected: !li.selected,
            }
            : li;
        }),
      };
    case types.SET_ACTION_MODE:
      return {
        ...state,
        actionMode: action.actionMode,
        selectionMode: action.actionMode !== ActionMode.SHOW_ACTIONS,
        lockerItems: state.lockerItems.map(li => { return { ...li, selected: false } })
      };
    case types.SET_PAGE_START:
      return {
        ...state,
        pageStart: action.pageStart,
        fetchLockerItems: true,
      };
    case types.SET_SELECTED_FILTER:
      return {
        ...state,
        lockerItems: [],
        pageStart: 0,
        selectedFilter: action.filter,
        totalRecords: 0,
        fetchLockerItems: true,
      };

    case types.SET_LOCKER_ITEM_IMG:
      return {
        ...state,
        lockerItems: state.lockerItems.map(li => {
          return li.elementId === action.elementId
            ? {
              ...li,
              imageUrl: action.imageUrl
            }
            : li;
        }),
      }
    case types.RESET_LOCKER_PAGE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default lockerReducer;
