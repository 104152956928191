export function sendPdpThumbEvent(id, displayName, name, url, side) {
  logData({"event": "pdp_thumb_clicked", "graphic_id": id, "graphic_display_name": displayName, "graphic_name": name, "graphic_url": url, "side": side});
}

export function sendClearAllEvent() {
  logData({"event":"clear_all_clicked"});
}

export function sendColorClickedEvent(color) {
  logData({"event": "color_filter_clicked", "color": color});
}

export function sendNeedByDateClickedEvent(date) {
  logData({"event": "need_by_date_filter_clicked", "date": date});
}

export function sendPdpDetailsEvent(productName, productDesc, styleNumber, colorNumber) {
  logData({"event":"pdp_details", "product_name": productName, "product_description": productDesc, "style_number": styleNumber, "color_number": colorNumber});
}

export const sendCatalogClickedEvent = (label) => {
  logData({ event: 'catalog_download', name: label });
};

//Pushes configured data to GTM
function logData(dataObject) {
  window.dataLayer.push(dataObject);
}