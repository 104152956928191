export const LOAD_GRIDWALL_ITEMS = 'LOAD_GRIDWALL_ITEMS';
export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const TOGGLE_KIDS_FILTER = 'TOGGLE_KIDS_FILTER';
export const SET_NEED_BY_DATE = "SET_NEED_BY_DATE";
export const SET_FILTERS_FROM_URL = "SET_FILTERS_FROM_URL";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const SET_SORT = "SET_SORT";
export const LOAD_STYLE_TEMPLATES = 'LOAD_STYLE_TEMPLATES';
export const LOAD_PRODUCT_DETAILS_ATONCE = 'LOAD_PRODUCT_DETAILS_ATONCE';
export const LOAD_PRODUCT_DETAILS_SOLR = 'LOAD_PRODUCT_DETAILS_SOLR';
export const LOAD_PRODUCT_AVAILABILITY = 'LOAD_PRODUCT_AVAILABILITY';
export const LOAD_DESIGN = 'LOAD_DESIGN';
export const SET_DESIGN_COLOR = 'SET_DESIGN_COLOR';
export const SHOW_BUILDER = 'SHOW_BUILDER';
export const HIDE_BUILDER = 'HIDE_BUILDER';
export const BUILDER_GRAPHIC_SELECTED = 'BUILDER_GRAPHIC_SELECTED';
export const ACTIVE_TEMPLATE = 'ACTIVE_TEMPLATE';
export const SET_SELECTED_COLORWAY = 'SET_SELECTED_COLORWAY';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const UPDATE_SELECTED_GRAPHIC = 'UPDATE_SELECTED_GRAPHIC';
export const REMOVE_SELECTED_GRAPHIC = 'REMOVE_SELECTED_GRAPHIC';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const SET_ACTIVE_VIEW = 'SET_ACTIVE_VIEW';
export const UPDATE_DESIGN_CUSTOM_LOGOS = 'UPDATE_DESIGN_CUSTOM_LOGOS';
export const SHOW_LOGO_TERMS_MODAL = 'SHOW_LOGO_TERMS_MODAL';
export const SHOW_DEALER_ARTWORK_SELECT = 'SHOW_DEALER_ARTWORK_SELECT';
export const LOGO_TERMS_ACCEPTED = 'LOGO_TERMS_ACCEPTED';
export const SAVE_CUSTOM_LOGO = 'SAVE_CUSTOM_LOGO';
export const SET_DESIGN_PRICE = 'SET_DESIGN_PRICE';
export const UPDATE_DESIGN_PLAYER_NAME_NUMBER = 'UPDATE_DESIGN_PLAYER_NAME_NUMBER';
export const GET_MAX_CHARACTERS = 'GET_MAX_CHARACTERS';
export const LOAD_DEALER_ARTWORK= 'LOAD_DEALER_ARTWORK';
export const SHOW_DEALER_CART_MODAL = 'SHOW_DEALER_CART_MODAL';
export const LOAD_DEALER_CARTS = 'LOAD_DEALER_CARTS';
export const SHOW_SHIPPING_MODAL = 'SHOW_SHIPPING_MODAL';
export const LOAD_SHIP_TOS = 'LOAD_SHIP_TOS';
export const LOAD_JBA_SHIP_TOS = 'LOAD_JBA_SHIP_TOS';
export const LOAD_SAP_CART_DATA = 'LOAD_SAP_CART_DATA';
export const UPDATE_SAP_CART_DATA = 'UPDATE_SAP_CART_DATA';
export const LOAD_JBA_CART_DATA = 'LOAD_JBA_CART_DATA';
export const UPDATE_BILLING_ACCOUNT = 'UPDATE_BILLING_ACCOUNT';
export const UPDATE_SHIPPING_DATA = 'UPDATE_SHIPPING_DATA';
export const EMAIL_DESIGN = "EMAIL_DESIGN";
export const SET_SELECTED_COLOR = "SET_SELECTED_COLOR";
export const SET_SELECTED_DESIGN_NAME_NUMBER = "SET_SELECTED_DESIGN_NAME_NUMBER";
export const GET_ALL_RULES = "GET_ALL_RULES";
export const SHOW_MESSAGE_MODAL = "SHOW_MESSAGE_MODAL";
export const HIDE_MESSAGE_MODAL = "HIDE_MESSAGE_MODAL";
export const START_FULL_LOADING_SPINNER = "START_FULL_LOADING_SPINNER";
export const END_FULL_LOADING_SPINNER = "END_FULL_LOADING_SPINNER";
export const LOAD_PAST_ORDERS = 'LOAD_PAST_ORDERS';
export const LOAD_CURRENT_ORDERS = 'LOAD_CURRENT_ORDERS';
export const LOAD_PAST_QUOTES = 'LOAD_PAST_QUOTES';
export const UPDATE_SUBMIT_ATTEMPTED = "UPDATE_SUBMIT_ATTEMPTED";
export const GET_PRODUCT_IMAGES = "GET_PRODUCT_IMAGES";
export const CLEAR_PRODUCT_IMAGES = "CLEAR_PRODUCT_IMAGES";
export const LOADING_USER_STATE = "LOADING_USER_STATE";
export const LOAD_USER_PROFILE = "LOAD_USER_PROFILE";
export const CLEAR_USER_PROFILE = "CLEAR_USER_PROFILE";
export const SHOW_TOAST = "SHOW_TOAST";
export const TOGGLE_SHOW_HIDDEN_FILTER = "ADD_SHOW_HIDDEN_FILTER";
export const ADD_CUSTOM_SEARCH_PARAM = "ADD_CUSTOM_SEARCH_PARAM";
export const CLEAR_CUSTOM_SEARCH_PARAM = "CLEAR_CUSTOM_SEARCH_PARAM";
export const LOAD_PRODUCT_FOR_PDP = "LOAD_PRODUCT_FOR_PDP";
export const CLEAR_PRODUCT_FOR_PDP = "CLEAR_PRODUCT_FOR_PDP";
export const LOAD_COLORS = "LOAD_COLORS";
export const LOAD_CUSTOM_LOGO = "LOAD_CUSTOM_LOGO";
export const START_SMALL_LOADING_SPINNER = "START_SMALL_LOADING_SPINNER";
export const END_SMALL_LOADING_SPINNER = "END_SMALL_LOADING_SPINNER";
export const TOGGLE_LOGIN_MODAL = "TOGGLE_LOGIN_MODAL";
export const PRE_POPULATE_DEALER_STATE = "PRE_POPULATE_DEALER_STATE";
export const LOAD_NAV_STATE = "LOAD_NAV_STATE";
export const CLEAR_NAV_STATE = "CLEAR_NAV_STATE";
export const SET_ENVIRONMENT = "SET_ENVIRONMENT";
export const SHOW_CART_SELECT_MODAL = "SHOW_CART_SELECT_MODAL";
export const HIDE_CART_SELECT_MODAL = "HIDE_CART_SELECT_MODAL";
export const SET_CHECKOUT_ACCOUNT_VALIDATION = "SET_CHECKOUT_ACCOUNT_VALIDATION";
export const DISABLE_CHECKOUT_SUBMIT = "DISABLE_CHECKOUT_SUBMIT";
export const SHOW_COUPON_LOOKUP_MODAL = "SHOW_COUPON_LOOKUP_MODAL";
export const LOAD_COUPON_DETAILS = "LOAD_COUPON_DETAILS";
export const LOAD_COUPON_ERROR = "LOAD_COUPON_ERROR";
export const SHOW_COUPON_BAR = "SHOW_COUPON_BAR";
export const SET_COUPON_MODAL_STATE = 'SET_COUPON_MODAL_STATE';
export const UPDATE_INFO_FORM = 'UPDATE_INFO_FORM';
export const SET_PDP_BTN_STATES = 'SET_PDP_BTN_STATES';
export const SET_ARTWORK_TERMS_MODAL_STATE = 'SET_ARTWORK_TERMS_MODAL_STATE';
export const SET_ARTWORK_ERROR_MODAL_STATE = 'SET_ARTWORK_ERROR_MODAL_STATE';
export const SET_ARTWORK_ERROR_MODAL_HIDDEN_STATE = 'SET_ARTWORK_ERROR_MODAL_HIDDEN_STATE';
export const SET_IMG_COLOR_SELECTOR_MODAL_DISPLAY_STATE = 'SET_IMG_COLOR_SELECTOR_MODAL_DISPLAY_STATE';
export const SHOW_RETAILER_ACCESS_MODAL = 'SHOW_RETAILER_ACCESS_MODAL';
export const DISPLAY_COVID_CHECKOUT_MODAL = 'DISPLAY_COVID_CHECKOUT_MODAL';
export const SET_ARTWORK_DETAILS_MODAL_STATE = 'SET_ARTWORK_DETAILS_MODAL_STATE';
export const TOGGLE_ARTWORK_EMAIL_MODAL = 'TOGGLE_ARTWORK_EMAIL_MODAL';
export const APPEND_LOCKER_ITEMS = 'APPEND_LOCKER_ITEMS';
export const LOAD_FILTERS = 'LOAD_FILTERS';
export const TOGGLE_SELECT_LOCKER_ITEM = 'TOGGLE_SELECT_LOCKER_ITEM';
export const SET_ACTION_MODE = 'SET_ACTION_MODE';
export const SET_PAGE_START = 'SET_PAGE_START';
export const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER';
export const SET_LOCKER_ITEM_IMG = 'SET_LOCKER_ITEM_IMG';
export const RESET_LOCKER_PAGE = 'RESET_LOCKER_PAGE';
export const SHOW_FILLIN_HELPER_MODAL = 'SHOW_FILLIN_HELPER_MODAL';
export const HIDE_FILLIN_HELPER_MODAL = 'HIDE_FILLIN_HELPER_MODAL';
export const SHOW_CUSTOM_FONT_ADD_MODAL = 'SHOW_CUSTOM_FONT_ADD_MODAL';
export const SHOW_CUSTOM_FONT_DETAILS_MODAL = 'SHOW_CUSTOM_FONT_DETAILS_MODAL';
export const SET_CUSTOM_FONT_LIST = 'SET_CUSTOM_FONT_LIST';
export const SHOW_INACTIVE_CUSTOM_FONTS = 'SHOW_INACTIVE_CUSTOM_FONTS';
export const SET_SELECTED_CUSTOM_FONT = 'SET_SELECTED_CUSTOM_FONT';
export const SHOW_CUSTOM_COLOR_ADD_MODAL = 'SHOW_CUSTOM_COLOR_ADD_MODAL';
export const SHOW_CUSTOM_COLOR_DETAILS_MODAL = 'SHOW_CUSTOM_COLOR_DETAILS_MODAL';
export const SET_CUSTOM_COLOR_LIST = 'SET_CUSTOM_COLOR_LIST';
export const SHOW_INACTIVE_CUSTOM_COLORS = 'SHOW_INACTIVE_CUSTOM_COLORS';
export const SET_SELECTED_CUSTOM_COLOR = 'SET_SELECTED_CUSTOM_COLOR';
export const CLEAR_ORDER_DEADLINES = 'CLEAR_ORDER_DEADLINES';
export const SET_ORDER_DEADLINES = 'SET_ORDER_DEADLINES';
