import * as React from "react";
import {OrderDeadlineStyle} from "./orderDeadlinesHelper";

interface Props {
    styles: OrderDeadlineStyle[];
}

export const StyleList: React.FC<Props> = ({styles}) => {
    return (
        <div className="style-list-section">
            {styles.map(({styleNumber, displayName}) => {
                return (
                    <div key={styleNumber}>
                        <p>{styleNumber}</p> <span className="divider">-</span> <p>{displayName}</p>
                    </div>
                )
            })}
        </div>
    );
};
