import * as React from "react";
import ReactTable from "react-table";
import { ShipGroupEmbellishmentsState } from "../factory-ship-group/adminFactoryShipGroupHelper";

import Switch from "react-switch";
import { useForm } from 'react-hook-form';
interface Props {
    state: ShipGroupEmbellishmentsState,
    shipGroup: any, //TODO: need to define type properly
    checkdShowAssignStyle : boolean,
    handleEnableShipGroupEmbellishment : (item: any, index: number) => void;
    handlePagination: (size: number, page: number) => void;
    handleSort: (item: any) => void;
    handleShowAssignEmblishment:(e: any )=> void;
    handleStyleSearch : (item:string)=> void;
    updateRow: (value: any, key: any, index: number) => void;
    acceptRow: (item: any, index: number) => void;
}

export const EmbellishmentsTable:React.FC<Props> = ({
    state,
    shipGroup,
    handleEnableShipGroupEmbellishment,
    handlePagination,
    handleSort,
    checkdShowAssignStyle,
    handleShowAssignEmblishment,
    handleStyleSearch,
    updateRow,
    acceptRow
}) => {
    const trapSpacesForRequiredFields =  (value: any) => !!value.trim(); 
    const { register, handleSubmit,watch, formState: { errors }, reset } = useForm();
    
    const searchData = watch('search')

    React.useEffect(()=>{
        if(!searchData){
            handleStyleSearch('')
        }
    },[searchData])

    const onSubmit =(data: any) => {
        handleStyleSearch(data.search)
    }

    return (
        <div>
            <div className="nts-styles-header">
                <h1>{shipGroup.team_shp_grp_nm}</h1>
                <label className="grid-filter-option">
                    <input type="checkbox"
                        name="sort-by"
                        className="nike-check"
                        checked={checkdShowAssignStyle}
                        onChange={(e) => handleShowAssignEmblishment(e)}
                        id="show_assigned_styles" />
                    <span>Show assigned embellishments</span>
                </label>
                <div className="nts-tab-search-bar">
                <form className="nts-basic-form nts-profile-form nts-style" onSubmit={handleSubmit(onSubmit)}>
                    <label htmlFor="nts-admin-report-search">
                        <input  type="text"
                            className={`form-input`}
                            placeholder={checkdShowAssignStyle ? "Search by code" :"Search by code or name"}
                            {...register("search", {
                                validate: trapSpacesForRequiredFields,
                                pattern: {
                                  value: /^[A-Z0-9a-z\s-.,()]+$/i,
                                  message: "invalid search"
                                }
                              })}
                        />
                      
                  {errors.search && errors.search.type === "maxLength" && (
                    <div className="required">No longer than 30 characters</div>
                  )}
                  {errors.search && errors.search.type === "pattern" && (
                    <div className="required">Only A-Z, a-z, 0-9, period, space and dash allow</div>
                  )}
                    </label>
                    <button type="submit" className="btn btn-primary nts-style-search-btn">Search</button>
                    </form>
                </div>
            </div>
            <div className="nts-tab-search-results">
                <ReactTable
                    className="nts-table nts-styles-table"
                    data={state.results}
                    noDataText={""}
                    loading={false}
                    pages={Math.ceil(state.count / state.size)}
                    page={state.page}
                    pageSize={state.size}
                    manual={true}
                    onSortedChange={(newSorted, column, shiftKey) => {
                        handleSort(newSorted)
                    }}
                    onPageChange={(page: number) => {
                        handlePagination(state.size, page);
                    }}
                    onPageSizeChange={(newPageSize: number, newPage: number) => {
                        handlePagination(newPageSize, 0);
                    }}
                    columns={[
                        {
                            Header: "GROUP ID",
                            accessor: "id",
                            show: false,
                            sortable:false
                        },
                        {
                            Header: "Name",
                            accessor: "displayName",
                            sortable:  checkdShowAssignStyle ? false : true
                        },
                        {
                            Header: "Number",
                            accessor: "prod_cd",
                            sortable: true
                        },
                        {
                            Header: "Lead Days",
                            accessor: "lead_tm_dys",
                            Cell: (rowInfo: any) => {
                                return (
                                    <div style={{ position: "relative" }}>
                                        <input type="number"
                                            name="lead_tm_dys"
                                            placeholder=""
                                            min="0"
                                            value={rowInfo.original.lead_tm_dys}
                                            onChange={e => {
                                                updateRow(e.target.value, 'lead_tm_dys', rowInfo.index);
                                            }}
                                        />
                                    </div>
                                );
                            },
                        },
                        {
                            Header: "Active",
                            accessor: "selected",
                            sortable : false,
                            Cell: (rowInfo: any) => {
                                return (
                                    <div style={{ position: "relative" }}>
                                        {
                                            rowInfo.original.update && rowInfo.original.selected && (
                                                <span className="accept-icon icon" onClick={()=>acceptRow(rowInfo.original, rowInfo.index)}></span>
                                            )
                                        }
                                        <Switch onChange={(e) => handleEnableShipGroupEmbellishment(rowInfo.original, rowInfo.index)} checkedIcon={false} uncheckedIcon={false} checked={rowInfo.original.selected} />
                                    </div>
                                );
                            },
                        },
                    ]}
                    minRows={0}
                    style={{ height: "auto" }}
                />
            </div>
        </div>
    )
}
