import React, {useState} from "react";
import { Modal } from "react-bootstrap";
import { AddFactoryShipGroupState, TabType } from "../factory-ship-group/adminFactoryShipGroupHelper";
import { OrderDeadline } from "./OrderDeadline";
import { Styles } from "./Styles";
import { Embellishments } from "./Embellishments";

interface Props {
  state: AddFactoryShipGroupState,
  onHide: () => void
}

export const EditShipGroup: React.FC<Props> = ({
  state,
  onHide,
}) => {
  const [currentTab, setCurrentTab] = useState(TabType.STYLES);

  return (
    <Modal className="show nts-terms-modal nts-factory-modal nts-factory-style-modal"
      backdrop="static"
      keyboard={false}
      onHide={onHide}
      show={state.show}
      bsSize={"large"} aria-labelledby={"contained-modal-title-lg"}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-lg">

        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-left">
        <div className="nts-admin-page nts-admin-factory-page nts-admin-analytics-page nts-style-tabs">
          <ul className="admin-tabs">
            <li
              className={currentTab === TabType.STYLES ? 'selected' : ''}
              onClick={() => setCurrentTab(TabType.STYLES)}>
              Styles
            </li>
            <li
              className={currentTab === TabType.ORDER_DEADLINES ? 'selected' : ''}
              onClick={() => {
                setCurrentTab(TabType.ORDER_DEADLINES)
              }}>
              Order Deadlines
            </li>
            <li
              className={currentTab === TabType.EMBLISHMENTS ? 'selected' : ''}
              onClick={() => setCurrentTab(TabType.EMBLISHMENTS)}>
              Emblishments
            </li>
          </ul>
          <div className="admin-tabs-content">
          { currentTab == TabType.STYLES          &&  <Styles shipGroup={state.item} /> }
          { currentTab == TabType.ORDER_DEADLINES &&  <OrderDeadline shipGroup={state.item} /> }
          { currentTab == TabType.EMBLISHMENTS    &&  <Embellishments shipGroup={state.item} /> }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
