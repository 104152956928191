import {fetchRequest, urlEliteOptionsServices} from "./index";
import {LS_ACCESS_TOKEN} from "../../helpers/constants";
import {GetCustomFontsPayload, UpdateFontPayload, FactoryFileUrlPayload} from "../../components/customFonts/customFontsHelper";
import {GetCustomColorsPayload, UpdateColorPayload} from "../../components/customColors/customColorsHelper";

export const EliteOptionsService = {

    getCustomFontsForDealership: (payload: GetCustomFontsPayload, dealerShipId: string) => {
        const request = new Request(`${urlEliteOptionsServices}/font/fonts/dealership/v1/${dealerShipId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${localStorage.getItem(LS_ACCESS_TOKEN)}`
            },
            body: JSON.stringify(payload)
        });
        return fetchRequest(request, true, 'UNITE');
    },

    addCustomFont: (payload: FormData) => {
        const request = new Request(`${urlEliteOptionsServices}/font/fonts/v1`, {
            method: 'POST',
            headers: {
                'Authorization': `${localStorage.getItem(LS_ACCESS_TOKEN)}`
            },
            body: payload
        });
        return fetchRequest(request, true, 'UNITE');
    },

    getFactoryFileURL: (payload: FactoryFileUrlPayload) => {
        const request = new Request(`${urlEliteOptionsServices}/download/v1`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${localStorage.getItem(LS_ACCESS_TOKEN)}`
            },
            body: JSON.stringify(payload)
        });    
        return fetchRequest(request, true, 'UNITE');
    },

    updateCustomFont: (payload: UpdateFontPayload) => {
        const request = new Request(`${urlEliteOptionsServices}/font/fonts/v1`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${localStorage.getItem(LS_ACCESS_TOKEN)}`
            },
            body: JSON.stringify(payload)
        });
        return fetchRequest(request, true, 'UNITE');
    },

    getCustomColorsForDealership: (payload: GetCustomColorsPayload, dealerShipId: string) => {
        const request = new Request(`${urlEliteOptionsServices}/color/colors/dealership/v1/${dealerShipId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${localStorage.getItem(LS_ACCESS_TOKEN)}`
            },
            body: JSON.stringify(payload)
        });
        return fetchRequest(request, true, 'UNITE');
    },

    addCustomColor: (payload: any) => {
        const request = new Request(`${urlEliteOptionsServices}/color/colors/v1`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${localStorage.getItem(LS_ACCESS_TOKEN)}`
            },
            body: JSON.stringify(payload)
        });
        return fetchRequest(request, true, 'UNITE');
    },

    updateCustomColor: (payload: UpdateColorPayload) => {
        const request = new Request(`${urlEliteOptionsServices}/color/colors/v1`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${localStorage.getItem(LS_ACCESS_TOKEN)}`
            },
            body: JSON.stringify(payload)
        });
        return fetchRequest(request, true, 'UNITE');
    },

};