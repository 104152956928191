import {cookies, fetchRequest, urlNtsIdentityServices} from "./index";
import {EXPERIENCE_COOKIE_KEY} from "../../helpers/retailHelpers";
import {LS_ACCESS_TOKEN} from "../../helpers/constants";

const NtsIdentityService = {

  registerNtsUser: (user) => {
    const request = new Request(`${urlNtsIdentityServices}/user/register`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('nts_access_token')},
      body: JSON.stringify(user)
    });
    return fetchRequest(request, true, 'UNITE');
  },

  syncUserDetails: () => {
    const request = new Request(`${urlNtsIdentityServices}/user/syncTeamUser`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('nts_access_token')}
    });
    return fetchRequest(request, true, 'UNITE');
  },

  validateUserDetails: () => {
    const request = new Request(`${urlNtsIdentityServices}/aaa/validate`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('nts_access_token')}
    });
    return fetchRequest(request, true, 'UNITE');
  },

  getUserDetails: () => {
    let ntsRetailer = cookies.get(EXPERIENCE_COOKIE_KEY);
    if (typeof ntsRetailer === 'undefined'){ntsRetailer = ''}
    const request = new Request(`${urlNtsIdentityServices}/aaa/validate/userdetails`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN),
        'nts-retailer': ntsRetailer
      }
    });
    return fetchRequest(request, true, 'UNITE');
  },

  getImpersonationDetails: (userId) => {
    const request = new Request(`${urlNtsIdentityServices}/aaa/validate/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      }
    });
    return fetchRequest(request, true, 'UNITE');
  }

};

export default NtsIdentityService;