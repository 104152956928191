import React, {useState} from 'react'
import { showMessageModal } from "../../../actions/messageModalActions";

import { ShipGroupEmbellishmentsState } from "../factory-ship-group/adminFactoryShipGroupHelper";

import { getShipGroupEmbellishmentList, getAssignedShipGroupEmbellishmentList, addShipGroupEmbellishment, deleteShipGroupEmbellishment, updateShipGroupEmbellishment } from "../../../api/services/adminFactoryService";

import { EmbellishmentsTable } from "./EmbellishmentsTable";

export const Embellishments = ({shipGroup}: {shipGroup:any}) => {

    const shipGroupEmbellishmentsState : ShipGroupEmbellishmentsState ={
        results: [],
        error: "",
        message: "",
        page: 0,
        size: 10,
        count: 0,
        keyword: '',
        lead_tm_dys:"",
        factory:  {id: 0, name: '', code: '', updatedAt: '',priority:'', fcr_comm_cd: 'EDI', fcr_supplier_id: null, fhs_fcr_cd: '' },
        factoryId: 0,
        sort: [{
          id: '',
          desc: true
        }]
    }
    const [loading, setLoading] = useState(false);
    const [shipGroupEmbellishments, setShipGroupEmbellishments] = useState(shipGroupEmbellishmentsState);
    const [showAssignedEmblishments,setShowAssignedEmblishments] = useState(true);
    
    const getShipGroupEmbellishments = async (page: number, size: number, sort: any,filter :string,checkedAssignEmblishment: boolean) => {
        let response : any = [];
        try {
          if (checkedAssignEmblishment) {
            response = await getAssignedShipGroupEmbellishmentList(
              shipGroup.team_shp_grp_id,
              `page=${(page + 1)}&pageSize=${size}&sortColumn=${sort.id ?? ''}&sortDir=${sort.desc ? 'DESC': 'ASC'}&filter=${filter}`
            );
          } else {
            response = await getShipGroupEmbellishmentList(
              shipGroup.team_shp_grp_id,
              `page=${(page + 1)}&pageSize=${size}&sortColumn=${sort.id ?? ''}&sortDir=${sort.desc ? 'DESC': 'ASC'}&filter=${filter}`
            );
          }
          if (response.error) {
            throw response;
          }              
          setLoading(false);
          setShipGroupEmbellishments(prevState => ({
            ...prevState, 
            // need to create Ship Group Embellishment type
            results: response.shipGroupEmbellishment.map((item : any ) => {item.update = false; return item}),
            page: page,
            count: response.pages.totalCount,
            size: size,
          }))
         } catch (err: any) {
          setLoading(false);
          showMessageModal({ header: "EMBELLISHMENT LOAD ERROR", message: err.message, required: true });
        }
      }
    
      const handleEnableShipGroupEmbellishment = async (rowData :any ,index : number)=>{
        
        const reqBody = {
            prod_cd:rowData.prod_cd,
            lead_tm_dys: rowData.lead_tm_dys || undefined
          }
          if(!rowData.team_shp_grp_emb_id){
            /* Add shipGroup Embellishment  */
            try {
              let response = await addShipGroupEmbellishment(shipGroup.team_shp_grp_id,reqBody);
              if (response.error) {
                throw response;
              }
            } catch (err: any) {
              // dispatchShowToast(err.message);
              showMessageModal({ header: "ADD EMBELLISHMENT ERROR", message: err.message, required: true });
            }
            await getShipGroupEmbellishments(shipGroupEmbellishments.page, shipGroupEmbellishments.size, shipGroupEmbellishments.sort[0],shipGroupEmbellishments.keyword,showAssignedEmblishments);
          }else {
            showMessageModal({
              header: "",
              message: `Are you sure you want to make this embellishment inactive and remove lead days?`,
              buttonText: "remove",
              cancelable: true,
              onClick: () => { 
                deleteEmbellishment(rowData.team_shp_grp_emb_id);
              }
            });  
            
          }
        }
      
        const handleShipGroupEmbellishmentPagination = (size: number, page: number) => {
          setShipGroupEmbellishments(prevState => ({
            ...prevState,
            page: page,
            size: size
          })) 
          if(!loading) {
            setLoading(true);
            getShipGroupEmbellishments(page, size, shipGroupEmbellishments.sort[0],shipGroupEmbellishments.keyword,showAssignedEmblishments);
          }
        }
      
        const handleShipGroupEmbellishmentSort = (item: any) => {
          setShipGroupEmbellishments(prevState => ({
            ...prevState,
            sort: item
          }))
          getShipGroupEmbellishments(shipGroupEmbellishments.page, shipGroupEmbellishments.size, item[0],shipGroupEmbellishments.keyword,showAssignedEmblishments);
        }
      
        const handleShowAssignEmblishment = (e: any )=>{
          setShowAssignedEmblishments(e.target.checked);
          getShipGroupEmbellishments(0, shipGroupEmbellishments.size, shipGroupEmbellishments.sort[0],shipGroupEmbellishments.keyword,e.target.checked);
        }
      
        const handleEmbellishmentSearch = (searchText : string) =>{
          setShipGroupEmbellishments(prevState => ({
            ...prevState,
            keyword: searchText
          }));
          getShipGroupEmbellishments(0, shipGroupEmbellishments.size, shipGroupEmbellishments.sort[0],searchText,showAssignedEmblishments);
        }
      
        const updateEmbellishmentRow = (value: any, key: any, index: any) => {
          const results = shipGroupEmbellishments.results;
          results[index][key] = value;
          results[index]['update'] = true;
          setShipGroupEmbellishments(prevState => ({
            ...prevState,
            results: results
          }))
        
        }
        const acceptEmbellishmentRow = async (rowData:any, index: number) => {
            const reqBody = {
                lead_tm_dys: rowData.lead_tm_dys || 0
            }
            try {
                let response = await updateShipGroupEmbellishment(rowData.team_shp_grp_emb_id, reqBody);
                if (response.error) {
                  throw response;
                } else {
                  await getShipGroupEmbellishments(shipGroupEmbellishments.page, shipGroupEmbellishments.size, shipGroupEmbellishments.sort[0],shipGroupEmbellishments.keyword,showAssignedEmblishments);
                }
            } catch (err: any) {
                // dispatchShowToast(err.message);
                showMessageModal({ header: "EDIT EMBELLISHMENT ERROR", message: err.message, required: true });
            }
        }

        const deleteEmbellishment = async (teamShipGrpEmbId: number) => {
          try {
            let response = await deleteShipGroupEmbellishment(teamShipGrpEmbId);
            if (response.error) {
              throw response;
            } else {
              await getShipGroupEmbellishments(shipGroupEmbellishments.page, shipGroupEmbellishments.size, shipGroupEmbellishments.sort[0],shipGroupEmbellishments.keyword,showAssignedEmblishments);
            }
          } catch (err: any) {
            // dispatchShowToast(err.message);
            showMessageModal({ header: "DELETE EMBELLISHMENT ERROR", message: err.message, required: true });
          }
        }

    return  (
      <EmbellishmentsTable 
        state={shipGroupEmbellishments}
        shipGroup={shipGroup}
        handleEnableShipGroupEmbellishment= {handleEnableShipGroupEmbellishment} 
        handlePagination={handleShipGroupEmbellishmentPagination}  
        handleSort={handleShipGroupEmbellishmentSort}
        checkdShowAssignStyle={showAssignedEmblishments}
        handleShowAssignEmblishment={handleShowAssignEmblishment}
        handleStyleSearch={handleEmbellishmentSearch}
        updateRow={updateEmbellishmentRow}
        acceptRow={acceptEmbellishmentRow}
      />    
    )
}