import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

interface Coupon {
  activationDate: string;
  balance: number;
  cartBalance: number;
  cartId: string;
  code: string;
  expirationDate: string;
}

export interface CouponState {
  coupon: Partial<Coupon>;
  couponModalType?: string;
  error: string;
  showCouponBar: boolean;
  showCouponLookupModal: boolean;
}

const initialState = {
  showCouponLookupModal: false,
  showCouponBar: false,
  coupon: {},
  error: ''
}

// TODO: These actions can be separate types later
interface CouponAction extends Action {
  state: boolean;
  error: string;
  modalType: string;
  coupon: Coupon;
  couponModalType: string;
}

const couponReducer: Reducer<CouponState> = (
    state = initialState,
    incomingAction
) => {
  const action = incomingAction as unknown as CouponAction;

  switch (action.type) {
    case types.SHOW_COUPON_LOOKUP_MODAL:
      return {
        ...state,
        showCouponLookupModal: action.state,
        couponModalType: action.modalType
      };

    case types.SHOW_COUPON_BAR:
      return {
        ...state,
        showCouponBar: action.state
      };

    case types.LOAD_COUPON_DETAILS:
      return {
        ...state,
        coupon: action.coupon
      };

    case types.LOAD_COUPON_ERROR:
      return {
        ...state,
        error: action.error
      };

    case types.SET_COUPON_MODAL_STATE:
      return {
        ...state,
        couponModalType: action.couponModalType
      };

    default:
      return state;
  }
};

export default couponReducer;
