import React from "react";

import { AuthConsumer } from "../providers/authProvider";
import { LoadingPage } from "../../js/components/loadingPage/LoadingPage";

// Uses AuthConsumer context to perform logout handling
export const LogoutCallback: React.FC<{}> = () => (
    <AuthConsumer>
        {({ signoutRedirectCallback }) => {
            signoutRedirectCallback();
            return <LoadingPage />;
        }}
    </AuthConsumer>
);
