import {calculateEndpointType} from './common';

const endpointTypeToUrl = {
  local: 'http://localhost:3002',
  dev: 'https://niketeam-factory-shipgroup-api-dev.nike.net',
  qa: 'https://niketeam-factory-shipgroup-api-qa.nike.net',
  stg: 'https://niketeam-factory-shipgroup-api-stg.nike.net',
  uat: 'https://niketeam-factory-shipgroup-api-uat.nike.net',
  prod: 'https://niketeam-factory-shipgroup-api.nike.net'
};

export default function ntsFactoryShipgroupBaseUrl(location) {
  const type = calculateEndpointType(location, 'local');
  return endpointTypeToUrl[type];
}
