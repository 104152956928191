import * as React from "react";
import ReactTable from "react-table";
import {DealerMember, dealerMemberTableConfig} from "./dealerShareHelper";

interface Props {
    items: DealerMember[];
    message: string;
}

export const SearchResults: React.FC<Props> = ({
   items,
    message
}) => {
    return (
        <div className="nts-tab-search-results">

            <ReactTable
                className="nts-table"
                data={items}
                noDataText={message}
                loading={false}
                columns={dealerMemberTableConfig}
                minRows={0}
                style={{height:"auto"}}
                showPagination={true}
            />

        </div>
    )
}
