import {applyMiddleware, createStore} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';
import {composeWithDevTools} from 'redux-devtools-extension';
import {createLogger} from 'redux-logger';
import {autoRehydrate} from 'redux-persist';

import createRootReducer from './reducers';
import {getEnvironment} from "./helpers/browserHelpers";
import {ENVIRONMENT_LOCAL} from "./helpers/constants";

export const history = createHistory();
const env = getEnvironment();

// Report page changes to Analytics
history.listen((location) => {
  ReactGA.pageview(`${location.pathname}${location.search}`);
});

const browserMiddleware = routerMiddleware(history);

const getMiddleware = () => {
  // Enable additional logging + tools in local environment
  if(env === ENVIRONMENT_LOCAL) {
    return composeWithDevTools(applyMiddleware(thunk, browserMiddleware, createLogger({ collapsed: true })), autoRehydrate());
  } else {
    return applyMiddleware(thunk, browserMiddleware);
  }
};

export default function configureStore(initialState) {
  return createStore(createRootReducer(history), initialState, getMiddleware());
}
