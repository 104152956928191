import * as React from 'react';
import {Button} from 'react-bootstrap';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';

import {AppState} from '../../reducers';
import {ActionMode, LockerItem, Filter} from '../../reducers/lockerReducer';
import {LockerService} from '../../api/services/lockerService';
import {setActionMode, setSelectedFilter} from '../../actions/lockerActions';
import {showMessageModal} from '../../actions/messageModalActions';
import {CreateSetForm} from './createSetForm';
import {EmailForm, EmailFormData} from './emailForm';
import {LockerFilter} from './lockerFilter';
import {dispatchShowToast} from '../../actions/toastActions';
import {buildFilters, buildPrintUrl} from './lockerPageHelper';
import {EMAIL_ALLOWED_CHARS_REGEX} from "../../helpers/constants";

interface StateProps {
    actionMode: ActionMode;
    lockerState: AppState['lockerState'];
    selectedFilter: Filter | null;
    selectedItems: LockerItem[];
    userEmail: string;
}

interface DispatchProps {
    setActionMode: (actionMode: ActionMode) => void;
    dispatchSetSelectedFilter: (filter: Filter | null) => void;
}

type Props = StateProps & DispatchProps;

const LockerActionRowInner: React.FC<Props> = ({
    actionMode,
    dispatchSetSelectedFilter,
    lockerState,
    selectedFilter,
    selectedItems,
    setActionMode,
    userEmail,
}) => {
    const createSetMode = () => setActionMode(ActionMode.CREATE_SET);
    const onShowActions = () => setActionMode(ActionMode.SHOW_ACTIONS);
    const emailMode = () => setActionMode(ActionMode.EMAIL);
    const printMode = () => setActionMode(ActionMode.PRINT);
    const [emailError, setEmailError] = React.useState('');

    const onCreateSet = async (name: string, elementIds: string[]) => {
        const trimmedName = name.substr(0, 30);
        await LockerService.createSet(trimmedName, elementIds);
        onShowActions();
        dispatchSetSelectedFilter(null);
        dispatchShowToast('Set created successfully.');
    };
    const onFilter = (filter: Filter | null) => {
        dispatchSetSelectedFilter(filter);
    };
    const onEmail = async (formData: EmailFormData, elementIds: string[]) => {
        const filteredMessage = formData.message.replace(EMAIL_ALLOWED_CHARS_REGEX, '');
        const response = await LockerService.sendEmail(userEmail, formData.from, formData.to, filteredMessage, elementIds)
            .catch((e:any) => dispatchShowToast('Email unsuccessful. Please try again.'));
        if(response && !response.html.parsererror) {
            dispatchShowToast('Email sent successfully.');
            setEmailError('');
            onShowActions();
        } else {
            setEmailError('Email unsuccessful. Please try again.');
        }
    };
    const onEmailCancel = () => {
        setEmailError('');
        onShowActions();
    };
    const onPrint = () => {
        window.open(buildPrintUrl(userEmail, selectedElementIds));
        onShowActions();
    };
    const removeSet = async () => {
        if(selectedFilter) {
            await LockerService.removeSet(selectedFilter.value);
            dispatchSetSelectedFilter(null);
            onShowActions();
            dispatchShowToast('Set removed successfully.');
        }
    };

    const noneSelected = selectedItems.length === 0;
    const selectedElementIds = selectedItems.map(li => li.elementId);
    const filters = buildFilters(lockerState);

    switch (actionMode) {
        case ActionMode.SHOW_ACTIONS:
            return (
                <div className="action-row">
                    <div className="button-row">
                        <Button bsStyle="primary" onClick={createSetMode}>create set</Button>
                        <Button bsStyle="default" onClick={emailMode}>email</Button>
                        <Button bsStyle="default" onClick={printMode}>print</Button>
                        <LockerFilter
                            filters={filters}
                            selectedFilter={selectedFilter}
                            filterClickHandler={onFilter}
                            removeSet={() => showMessageModal({
                                header: '',
                                message: 'Are you sure you want to remove this set?',
                                cancelable: true,
                                callback: removeSet,
                            })}
                        />
                    </div>
                </div>
            );
        case ActionMode.CREATE_SET:
            return (
                <CreateSetForm
                    selectedElementIds={selectedElementIds}
                    onCreateSet={onCreateSet}
                    onCancel={onShowActions}
                />
            );
        case ActionMode.EMAIL:
            return (
                <EmailForm
                    selectedElementIds={selectedElementIds}
                    onSendEmail={onEmail}
                    onCancel={onEmailCancel}
                    error={emailError}
                />
            );
        case ActionMode.PRINT:
            return (
                <div className="action-row">
                    <div>Please select the desired locker items to print.</div>
                    <div className="button-row">
                        <Button bsStyle="primary" disabled={noneSelected} onClick={onPrint}>print</Button>
                        <Button bsStyle="default" onClick={onShowActions}>cancel</Button>
                    </div>
                </div>
            );
        default:
            return null;
    }
};

const mapStateToProps = (state: AppState): StateProps => ({
    actionMode: state.lockerState.actionMode,
    lockerState: state.lockerState,
    selectedFilter: state.lockerState.selectedFilter,
    selectedItems: state.lockerState.lockerItems.filter(li => li.selected),
    userEmail: state.userState.profile.email || '',
});

const mapDispatchToProps = (dispatch: Dispatch<unknown>): DispatchProps => ({
    setActionMode: (actionMode) => dispatch(setActionMode(actionMode)),
    dispatchSetSelectedFilter: (filter) => dispatch(setSelectedFilter(filter)),
});

export const LockerActionRow = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LockerActionRowInner);
