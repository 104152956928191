import * as X2JS from 'x2js';
import {fetchRequest, fetchRequestXml, urlNtsServices} from "./index";
import {showMessageModal} from "../../actions/messageModalActions";
import {LS_ACCESS_TOKEN} from "../../helpers/constants";

/**
 * Provides API for Cart & Locker related calls
 */
export const AssortmentService = {

  addAssortment: (type, payload) => {
    const request = new Request(`${urlNtsServices}/assortment/${type}/add?lang_locale=en_US`, {
      method: 'POST',
      body: payload,
      headers: {
        'Content-Type': 'application/xml',
        'Accept': 'application/xml',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      }
    });
    return fetchRequestXml(request);
  },

  createCart: (cartName, isFillIn = false, ordStatId = '') => {
    const request = new Request(`${urlNtsServices}/assortment/order?name=${cartName}&isSapFillin=${isFillIn}&orderStatId=${ordStatId}&lang_locale=en_US`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/xml',
        'Accept': 'application/xml',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      }
    });
    return fetchRequestXml(request, false);
  },

  getCarts: (criteria) => {
    const request = new Request(`${urlNtsServices}/assortment/order/find?${criteria}&lang_locale=en_US`, {
      method: 'GET',
      headers: {
        'Accept': 'application/xml',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      }
    });
    return fetchRequestXml(request, false).then(json => {
      let orders = [];
      let orderGroup = json.response.orders;
      if (orderGroup && orderGroup.order && orderGroup.order.forEach) {
        json.response.orders.order.forEach((element) => {
          orders.push(element);
        });
      } else if (orderGroup && orderGroup.order) {
        orders.push(json.response.orders.order); // Single order returned, xml parser converts to object - needs to be array
      }
      return {
        ...json.response,
        orders: orders
      };
    }).catch(error => {
      return new X2JS().xml2js(error);
    })
  },

  getCurrentOrders: (criteria) => {
    const request = new Request(`${urlNtsServices}/assortment/order/findAllCurrentOrders?${criteria}&lang_locale=en_US`, {
      method: 'GET',
      headers: {
        'Accept': 'application/xml',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      }
    });
    return fetchRequestXml(request).then(json => {
      let orders = [];
      let orderGroup = json.response.orders;
      if (orderGroup && orderGroup.order && orderGroup.order.forEach) {
        json.response.orders.order.forEach((element) => {
          orders.push(element);
        });
      } else if (orderGroup && orderGroup.order) {
        orders.push(json.response.orders.order); // Single order returned, xml parser converts to object - needs to be array
      }
      return {
        ...json.response,
        orders: orders
      };
    }).catch(error => {
      showOrdersListError();
      return new X2JS().xml2js(error);
    })
  },

  getJbaAccount: (soldTo) => {
    const request = new Request(`${urlNtsServices}/assortment/order/account/find?accountNum=${soldTo}`, {
      method: 'GET',
      headers: {'Accept': 'application/xml', 'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)}
    });
    return fetchRequestXml(request).then(json => {
      let orders = [];
      let orderGroup = json.response.orders;
      if (orderGroup && orderGroup.order && orderGroup.order.forEach) {
        json.response.orders.order.forEach((element) => {
          orders.push(element);
        });
      } else if (orderGroup && orderGroup.order) {
        orders.push(json.response.orders.order); // Single order returned, xml parser converts to object - needs to be array
      }
      return {
        ...json.response,
        orders: orders
      };
    }).catch(error => {
      return new X2JS().xml2js(error);
    })
  },

  getJbaShippingAddresses: (jbaAccountNumber) => {
    const request = new Request(`${urlNtsServices}/assortment/order/account/find?accountNum=${jbaAccountNumber}`, {
      method: 'GET',
      headers: {'Accept': 'application/xml', 'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)}
    });
    return fetchRequestXml(request);
  },

  getCart: (cartId) => {
    const request = new Request(`${urlNtsServices}/assortment/order/get?cartId=${cartId}&cb=1`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/xml',
        'Accept': 'application/xml',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      }
    });
    return fetchRequestXml(request);
  },

  returnToCart: (cartId) => {
    const request = new Request(`${urlNtsServices}/assortment/order/checkout/return/${cartId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/xml',
        'Accept': 'application/xml',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      }
    });
    return fetchRequestXml(request);

  },

  getPastOrders: (criteria) => {
    const request = new Request(`${urlNtsServices}/assortment/order/findAllForPastOrders?${criteria}&lang_locale=en_US`, {
      method: 'GET',
      headers: {
        'Accept': 'application/xml',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      },
    });
    return fetchRequestXml(request).then(json => {
      if (json.response.messages) showOrdersListError();
      let orders = [];
      let orderGroup = json.response.orders;
      if (orderGroup && orderGroup.order && orderGroup.order.forEach) {
        json.response.orders.order.forEach((element) => {
          orders.push(element);
        });
      } else if (orderGroup && orderGroup.order) {
        orders.push(json.response.orders.order); // Single order returned, xml parser converts to object - needs to be array
      }
      return {
        ...json.response,
        orders: orders
      };
    }).catch(error => {
      showOrdersListError();
      return new X2JS().xml2js(error);
    })
  },

  getPastQuotes: (criteria) => {
    const request = new Request(`${urlNtsServices}/assortment/order/findAllForPastOrders?${criteria}&lang_locale=en_US`, {
      method: 'GET',
      headers: {
        'Accept': 'application/xml',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      }
    });
    return fetchRequestXml(request).then(json => {
      if (json.response.messages) showOrdersListError();
      let orders = [];
      let orderGroup = json.response.orders;
      if (orderGroup && orderGroup.order && orderGroup.order.forEach) {
        json.response.orders.order.forEach((element) => {
          orders.push(element);
        });
      } else if (orderGroup && orderGroup.order) {
        orders.push(json.response.orders.order); // Single order returned, xml parser converts to object - needs to be array
      }
      return {
        ...json.response,
        orders: orders
      };
    }).catch(error => {
      showOrdersListError();
      return new X2JS().xml2js(error);
    })
  },

  findByDesignId: (designId) => {
    const request = new Request(`${urlNtsServices}/assortment/order/findByDesignId?designId=${designId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/xml',
        'Accept': 'application/xml',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      }
    });
    return fetchRequestXml(request);
  },

  createJbaFillIn: (srcOrderId, uniformId, orderName) => {
    let url = `${urlNtsServices}/assortment/order/createFillIn?orderName=${orderName}`;
    if (srcOrderId) {
      url += `&cartId=${srcOrderId}`;
    }
    if (uniformId) {
      url += `&designId=${uniformId}`;
    }
    const request = new Request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/xml',
        'Accept': 'application/xml',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      }
    });
    return fetchRequestXml(request);
  },

  addJbaProductsToCarts: (payload) => {
    let url = `${urlNtsServices}/assortment/order/add`;
    const request = new Request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/xml',
        'Accept': 'application/xml',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      },
      data: payload
    });
    return fetchRequestXml(request);
  },

  validateAccounts: (payload) => {
    let url = `${urlNtsServices}/assortment/order/account/validate`;
    const request = new Request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN),
      },
      body: JSON.stringify(payload)
    });
    return fetchRequest(request);
  },

  getConsumerCart: () => {
    const request = new Request(`${urlNtsServices}/assortment/order/`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      }
    });
    return fetchRequest(request);
  },

  addSapProductsToConsumerOrder: (productList) => {
    let url = `${urlNtsServices}/assortment/order/product`;
    const request = new Request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      },
      body: JSON.stringify(productList)
    });
    return fetchRequest(request);
  },

  addSapProductsToConsumerQuote: (productList) => {
    let url = `${urlNtsServices}/assortment/quote/stock`;
    const request = new Request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      },
      body: JSON.stringify(productList)
    });
    return fetchRequest(request);
  },

  addJbaProductsToConsumerQuote: (productList) => {
    let url = `${urlNtsServices}/assortment/quote/jba`;
    const request = new Request(url, {
      method: 'POST',
      body: productList,
      headers: {
        'Content-Type': 'application/xml',
        'Accept': 'application/xml',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      }
    });
    return fetchRequestXml(request, false).then(json => {
      return json.request.orders.id;
    }).catch(error => {
      return new X2JS().xml2js(error);
    })
  },

  updateCartName: (name, cartId) => {
    let url = `${urlNtsServices}/assortment/order/update?name=${name}&cartId=${cartId}`;
    const request = new Request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/xml',
        'Accept': 'application/xml',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      }
    });
    return fetchRequestXml(request);
    /*return fetchRequestXml(request, false).then(json => {
      return json.request.orders.id;
    }).catch(error => {
      return new X2JS().xml2js(error);
    })*/
  }
};

const showOrdersListError = function () {
  showMessageModal({
    header: "ERROR RETRIEVING ORDERS",
    message: "There was an error retrieving your orders, please try again in a few minutes or contact customer support."
  });
};