import {calculateEndpointType} from './common';

//TODO: Could be combined with apiBaseUrl as some of the code is the same.
//TODO: Update with atOnce urls for other environements
const endpointTypeToUrl = {
  local: 'https://niketeam-api-qa.nike.net/search-api/rest/quickSearch',
  dev:   'https://niketeam-api-dev.nike.net/search-api/rest/quickSearch',
  qa:    'https://niketeam-api-qa.nike.net/search-api/rest/quickSearch',
  stg:   'https://niketeam-api-stg.nike.net/search-api/rest/quickSearch',
  uat:   'https://niketeam-api-uat.nike.net/search-api/rest/quickSearch',
  prod:  'https://niketeam-api.nike.net/search-api/rest/quickSearch'
};

export default function quickSearchBaseUrl(location) {
  const type = calculateEndpointType(location, 'prod');
  return endpointTypeToUrl[type];
}
