import * as React from "react";
import {Button, Col, Row} from "react-bootstrap";

interface Props {
    onAddFont: () => void,
    onSearch: (val:string) => void,
    onShowInactive: (show:boolean) => void,
    searchValue: string,
    showInactive: boolean
}

export const CustomFontsActionRow: React.FC<Props> = ({
    onAddFont,
    onSearch,
    onShowInactive,
    searchValue,
    showInactive
}) => {
    return (
        <Row className="custom-fonts-action-row">
            <Col xs={12} sm={6}>
                <Button bsStyle="primary" onClick={onAddFont} data-nts-id="customFont.actionBar.button.addFont">Add Font</Button>
                <div className="input-container">
                    <i className="g72-search" />
                    <input
                        type="text"
                        placeholder="Search"
                        data-nts-id="customFont.actionBar.input.search"
                        value={searchValue}
                        onChange={(e) => onSearch((e.target as HTMLInputElement).value)}
                    />
                </div>
            </Col>
            <Col xs={12} sm={6}>
                <label htmlFor="inactive-check">
                    <span>Show Inactive</span>
                    <input
                        type="checkbox"
                        name="inactive-check"
                        className="nike-check"
                        data-nts-id="customFont.actionBar.checkbox.inactive"
                        onChange={(e) => onShowInactive((e.target as HTMLInputElement).checked)}
                    />
                </label>
            </Col>
        </Row>
    );
};