import * as types from './actionTypes';
import {store} from "../../App";
import {LS_ENABLE_TOKEN_REFRESH} from "../helpers/constants";

export function dispatchToggleRegistrationModal(state) {
  if(window && window.nike && window.nike.unite) {
    window.nike.unite.views.join.render();
  }
  return { type: types.TOGGLE_LOGIN_MODAL, state }
}


export function dispatchToggleMobileVerifyModal(state) {
  if(window && window.nike && window.nike.unite) {
    window.nike.unite.destroyAllViews();
    window.nike.unite.views.progressive.render();
  }
  return { type: types.TOGGLE_LOGIN_MODAL, state };
}

/**
 * Create LS flag to turn on refresh token functionality
 * @param enable
 */
export function enableLoginRefreshInterval(enable) {
  if(enable) {
    localStorage.setItem(LS_ENABLE_TOKEN_REFRESH, enable);
  } else {
    localStorage.removeItem(LS_ENABLE_TOKEN_REFRESH);
  }
}
