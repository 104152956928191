import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

export interface ProductImage {
  imageName: string;
  imageType: string;
  quantity: string;
  side: string;
  model: string;
  variant: string;
}

export interface ProductImagesState {
  [styleColor: string]: ProductImage[];
}

// TODO: These actions can be separate types later
interface ProductImagesAction extends Action {
  styleColor: string;
  sortedImages: ProductImage[];
}

const initialState: ProductImagesState = {};

const productImagesReducer: Reducer<ProductImagesState> = (
  state = initialState,
  incomingAction,
) => {
  const action = incomingAction as unknown as ProductImagesAction;
  switch (action.type) {
    case types.GET_PRODUCT_IMAGES:
      return {
        ...state,
        [action.styleColor]: action.sortedImages
      };
    case types.CLEAR_PRODUCT_IMAGES:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default productImagesReducer;
