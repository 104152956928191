import {Action, Reducer} from 'redux';

import * as types from '../actions/actionTypes';

type ActiveView = 'Front' | 'Back' | 'Reversible Front' | 'Reversible Back';

interface DesignPrice {
  pricingType: string;
  embellishments: {
    code: string;
    vasName: string;
    price: number;
  }[];
  isHeatTransfer?: boolean;
}

export interface PdpState {
  activeView: ActiveView;
  currentDesignPrice?: DesignPrice;
  disableAddToCart: boolean;
  disableAddToLocker: boolean;
  disableContactDealer: boolean;
  emailSuccess?: boolean;
  dealerCart?: unknown;
}

// TODO: These actions can be separate types later
interface PdpAction extends Action {
  view: ActiveView;
  show: boolean;
  states: {
    disableAddToCart: boolean;
    disableAddToLocker: boolean;
    disableContactDealer: boolean;
  };
  result: boolean;
  designPrice?: DesignPrice;
  carts: unknown;
}

const defaultState: PdpState = {
  activeView: 'Front',
  disableAddToCart: false,
  disableAddToLocker: false,
  disableContactDealer: false,
  emailSuccess: false,
};

const pdpState: Reducer<PdpState> = (state = defaultState, incomingAction) => {
  const action = incomingAction as unknown as PdpAction;
  switch (action.type) {

    case types.SET_ACTIVE_VIEW:
      return {
        ...state,
        activeView: action.view
      };

    case types.SET_DESIGN_PRICE:
      const disableAddToCart = !(action.designPrice) ? false : (!action.designPrice.embellishments);
      return {
        ...state,
        currentDesignPrice: action.designPrice,
        disableAddToCart: disableAddToCart,
        disableAddToLocker: disableAddToCart,
        disableContactDealer: disableAddToCart
      };

    case types.SET_PDP_BTN_STATES:
      return {
        ...state,
        disableAddToCart: action.states.disableAddToCart,
        disableAddToLocker: action.states.disableAddToCart,
        disableContactDealer: action.states.disableAddToCart
      };

    case types.SHOW_DEALER_CART_MODAL:
      return {
        ...state,
        showDealerModal: action.show
      };

    case types.LOAD_DEALER_CARTS:
      return {
        ...state,
        dealerCart: action.carts
      };

    case types.EMAIL_DESIGN:
      return {
        ...state,
        emailSuccess: action.result
      };

    case types.CLEAR_PRODUCT_FOR_PDP:
      return {
        ...defaultState,
      };

    default:
      return state;
  }
}

export default pdpState;