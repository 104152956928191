import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';
import {setSelectedItem, unselectAll} from '../helpers/designHelpers';
import {Template} from '../models/template';

export interface StyleTemplatesState {
  templates: {
    [side: string]: Template;
  };
}

// TODO: These actions can be separate types later
interface StyleTemplatesAction extends Action {
  side: number;
  selectedGraphic: unknown;
  template: Template;
  templates: {
    [side: string]: Template;
  };
}

const defaultState: StyleTemplatesState = {
  templates: {},
};

const styleTemplatesReducer: Reducer<StyleTemplatesState> = (
    state = defaultState,
    incomingAction,
) => {
  const action = incomingAction as unknown as StyleTemplatesAction;
  switch (action.type) {
    case types.LOAD_STYLE_TEMPLATES:
      return {
        ...state,
        templates: action.templates
      };

    case types.SET_TEMPLATE:
      let newState = {
        ...state
      };
      newState.templates[action.side] = {...action.template}
      return newState;

    case types.UPDATE_SELECTED_GRAPHIC: {
      let graphicState = {
        ...state
      };
      const firstComponent = graphicState.templates[action.side].data.components[0];
      if (firstComponent && firstComponent.type === 'graphic') {
        setSelectedItem(firstComponent.availableGraphics, action.selectedGraphic);
      }
      return graphicState;
    }

    case types.REMOVE_SELECTED_GRAPHIC: {
      let removeState = {
        ...state
      };
      const firstComponent = removeState.templates[action.side].data.components[0];
      if (firstComponent && firstComponent.type === 'graphic') {
        unselectAll(firstComponent.availableGraphics);
      }
      return removeState;
    }

    case types.UPDATE_TEMPLATE:
      return {
        ...state,
      };

    case types.CLEAR_PRODUCT_FOR_PDP:
      return {
        ...defaultState,
      };

    default:
      return state;
  }
}

export default styleTemplatesReducer;
