import { AdminFactoryResponse } from "../factory/adminFactoryHelper";

export interface AdminFactoryShipGroupResponse {
    team_shp_grp_id: number;
    team_shp_grp_nm: string;
    stat_cd: string;
    rec_crtn_tmst: string;
    last_mod_tmst: string;
    rec_crtn_db_user_nm:string;
    number_of_style: number;
    last_mod_db_user_nm: string;
    last_mod_team_user_id: string;
    no_of_styles: string;
    team_fcr_id: string;
}

export enum TabType {
    STYLES = 'styles',
    ORDER_DEADLINES = 'order_deadlines',
    EMBLISHMENTS = 'emblishments',
}

export interface FactoryShipGroupState { 
    results: any[],
    error: string;
    message: string;
    page: number;
    keyword: string;
    size: number;
    count: number;
    factory: AdminFactoryResponse;
    factoryId: number;
    sort: any[];
}

export interface ReportRowWrapper {
    original: AdminFactoryShipGroupResponse
}

export interface AddFactoryShipGroupState { 
    show: boolean,
    edit: boolean, 
    item: any,
}

export interface AddOrderDeadline {
    ddln_dt:string,
    disc_pctg:string,
    lead_tm_dys:number,
    max_cpty:number,
    curr_cpty:number,
    shp_dt:string,
    team_shp_grp_wndw_id:string,
}

export interface ShipGroupStylesState { 
    results: any[],
    error: string;
    message: string;
    page: number;
    keyword: string;
    size: number;
    count: number;
    factory: AdminFactoryResponse;
    factoryId: number;
    sort: any[];
}

export interface ShipGroupEmbellishmentsState { 
    results: any[],
    error: string;
    message: string;
    lead_tm_dys:string,
    page: number;
    keyword: string;
    size: number;
    count: number;
    factory: AdminFactoryResponse;
    factoryId: number;
    sort: any[];
}