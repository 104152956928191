import React from 'react';

import {NtsLazyImage} from "./ntsLazyImage/ntsLazyImage";
import {BrightcovePlayer} from "../video/brightcovePlayer";

import {GenderSportConfig} from '../gridwall/sportVariance/config';
import {getAkamaiImageBaseUrl} from "../../api/config/ntsDownloadsBaseUrl";
import {VID_ID_STRONGER_THAN_ONE} from "../video/videoList";

interface Props {
  config: GenderSportConfig['banner'];
}

/**
 * Banner for displaying image and video simultaneously
 * @param props
 * @constructor
 */
export class BannerImageVideo extends React.Component<Props> {

  render() {

    const positionClass = (this.props.config.copyPos === 'top') ? 'top' : 'bottom';
    const colorClass = (this.props.config.copyTheme === 'dark') ? 'dark' : 'light';
    const imageUrl = `${getAkamaiImageBaseUrl()}${this.props.config.imgSrc}`;

    return (
      <div className="nts-banner-image-video">

        <div className="image-col">
          <NtsLazyImage src={imageUrl} alt={this.props.config.imgDesc} className={"nts-lazy-image"} />
          <div className={`nts-banner-copy-container ${positionClass} ${colorClass}`}>
            <span className="title">{this.props.config.copyTitle}</span>
          </div>
        </div>

        <div className="video-col">
          <div className="video-wrapper">
            <BrightcovePlayer videoId={VID_ID_STRONGER_THAN_ONE} allowFullScreen={true} />
          </div>
        </div>

      </div>
    );
  }
}
