import * as types from './actionTypes';
import {ProductService} from "../api/services/productSearchService";
import {endFullLoadingSpinner, startFullLoadingSpinner} from "./loadingSpinnerActions";

export function dispatchLoadGridwallItems(items) {
  return {type: types.LOAD_GRIDWALL_ITEMS, items};
}

export function dispatchAddCustomSearchParam(searchString){
  return {type: types.ADD_CUSTOM_SEARCH_PARAM, searchString};
}

export function dispatchClearCustomSearchParam(){
  return {type: types.CLEAR_CUSTOM_SEARCH_PARAM};
}

export function toggleGridwallFilter(fieldKey) {
  return dispatch => {
    dispatch ({type: types.TOGGLE_FILTER, fieldKey});
  }
}

export const toggleGridwallKidsFilter = (fieldKey, isSportVariance) => {
  return {
    type: types.TOGGLE_KIDS_FILTER,
    fieldKey,
    isSportVariance,
  };
};

export function setNeedByDate(needByDate) {
  return dispatch => {
    dispatch({type: types.SET_NEED_BY_DATE, needByDate});
  }
}

export function clearFilters() {
  return dispatch => {
    dispatch({type: types.CLEAR_FILTERS});
  }
}

export function selectSort(sortKey) {
  return dispatch => {
    dispatch({type: types.SET_SORT, sortKey});
  }
}

export function alignFiltersFromUrl(urlFilters) {
  return {type: types.SET_FILTERS_FROM_URL, urlFilters};
}

export function loadGridwallForFilters(selectedFilters, sort, showHidden, customSearchString) {
  return dispatch => {
    startFullLoadingSpinner();

    return ProductService.getFiltered(selectedFilters, sort, showHidden, customSearchString)
        .then(results => {
          endFullLoadingSpinner();
          dispatch(dispatchLoadGridwallItems(results));
        })
        .catch(error => {
          throw(error);
        })
  }
}

export function toggleShowHidden(newState) {
  return {type: types.TOGGLE_SHOW_HIDDEN_FILTER, showHidden: newState}
}

