import * as React from 'react';

import {SearchBar} from "./SearchBar";
import {SearchResults} from "./SearchResults";

import {AdminReportingService} from "../../../api/services/adminReportingService";

export interface ReportTabState {
    search: string;
    results: [];
    error: string;
    resultMsg: string;
}

const ERROR_NETWORK = "Unable to retrieve records. Please try again.";
const ERROR_EMPTY_SEARCH = "Please enter a search value."
const INITIAL_RESULT_MSG = "Please perform a search to see results.";
const NO_RESULT_MSG = "There are no results for this search.";

export const ReportTab: React.FC<{}> = () => {

    const initialState: ReportTabState = {
        search: '',
        results: [],
        error: '',
        resultMsg: INITIAL_RESULT_MSG
    }

    const [state, setState] = React.useState(initialState);

    const handleSearch = async () => {

        const searchVal = state.search;

        if(!searchVal) {
            setState({...state, error: ERROR_EMPTY_SEARCH, resultMsg: INITIAL_RESULT_MSG})
            return;
        }
        const response = await AdminReportingService.getDownloadResults(searchVal);

        if(response) {
            if(response.uniforms && response.uniforms.length < 1) {
                setState({...state, results: [], error: '', resultMsg: NO_RESULT_MSG});
            } else {
                setState({...state, results: response.uniforms, error: '', resultMsg: INITIAL_RESULT_MSG});
            }
        } else {
            setState({...state, results: [], error: ERROR_NETWORK, resultMsg: INITIAL_RESULT_MSG});
        }

    }

    return (
        <div>
            <SearchBar state={state} handleSearch={handleSearch} updateState={(state) => setState(state)} />
            <SearchResults items={state.results} message={state.resultMsg} />
        </div>
    )

}

