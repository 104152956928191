import * as React from "react";

import {Button, Modal} from "react-bootstrap";

import {CustomColor} from "../../../reducers/customColorsReducer";
import {getDealershipId} from "../../../helpers/userProfileHelpers";
import {EliteOptionsService} from "../../../api/services/eliteOptionsService";
import {
    EliteOptionsStatusType,
    ERROR_NO_DEALERSHIP,
    ERROR_UPDATE_COLOR_GENERIC,
    getUploadColorPayload
} from "../customColorsHelper";

import {
    FONTNAME_ALLOWED_CHARS_REGEX,
    HEX_COLOR_ALLOWED_CHARS_REGEX
} from "../../../helpers/constants";

interface Props {
    color: CustomColor | null;
    onHide: (refresh:boolean) => void;
}

export const ColorDetailsModal: React.FC<Props> = ({
    color,
    onHide,
}) => {

    const sanitizeName = (value:string) => {
        return value.replace(FONTNAME_ALLOWED_CHARS_REGEX, '');
    }

    const sanitizeHex = (value:string) => {
        return value.replace(HEX_COLOR_ALLOWED_CHARS_REGEX, '');
    }

    const isFieldValueChange = (color: CustomColor, currentSstatus:EliteOptionsStatusType, editedColorName: string, editedHexName: string) => {
        let valueChange = false;
        if(color.status !== currentSstatus || color?.colorName !== editedColorName || color?.presentationHexCode !== editedHexName){
            valueChange = true;
        }
        return valueChange;
    }

    const [active, setActive] = React.useState((color) ? color.status === EliteOptionsStatusType.ACTIVE : false);
    const [editedColorName, setEditedColorName] = React.useState((color && color.colorName) ? color.colorName : '');
    const [editedHexName, setEditedHexName] = React.useState((color) && color.presentationHexCode ? color.presentationHexCode : '');
    const [errorMsg, setErrorMsg] = React.useState((!color) ? 'No color selected. Please close modal and try again.' : '');
   

    const handleUpdate = () => {
        const updateColorCall = async () => {
            const dealershipId = getDealershipId();
            if(!dealershipId) {
                setErrorMsg(ERROR_NO_DEALERSHIP);
                return;
            }
            const currentStatus = (active) ? EliteOptionsStatusType.ACTIVE : EliteOptionsStatusType.INACTIVE;
            if(color && isFieldValueChange(color, currentStatus, editedColorName, editedHexName)) {
                const updatedColor = {...color, status: currentStatus, colorName: editedColorName, presentationHexCode: editedHexName} as CustomColor;
                const payload = getUploadColorPayload(updatedColor, dealershipId);
                const response = await EliteOptionsService.updateCustomColor(payload);
                if(response && response.code === "SUCCESS" && response.data) {
                    setErrorMsg('');
                    onHide(true);
                } else {
                    const error = (response.error && response.message) ? response.message : ERROR_UPDATE_COLOR_GENERIC;
                    setErrorMsg(error);
                }
            } else {
                onHide(false);
            }
        }
        updateColorCall().catch((e) => {
            const error = (e.error && e.message) ? e.message : ERROR_UPDATE_COLOR_GENERIC;
            setErrorMsg(error);
        });
    }

    return (
        <Modal className="show nts-custom-color-details-modal" show={true} onHide={onHide} bsSize="large" aria-labelledby="contained-modal-title-lg">

            <Modal.Header closeButton>VIEW COLOR</Modal.Header>

            <Modal.Body>
                {color &&
                    <>
                        <label htmlFor="active-check">
                            <span>Active</span>
                            <input
                                type="checkbox"
                                name="active-check"
                                className="nike-check"
                                checked={active}
                                data-nts-id="customColor.details.checkbox.active"
                                onChange={(e) => setActive((e.target as HTMLInputElement).checked)}
                            />
                        </label>
                        <label htmlFor="editCustomColorName">
                                <span>Name </span>
                                <input
                                    type="text"
                                    maxLength={30}
                                    placeholder="Enter color name"
                                    name="editCustomColorName"
                                    value={editedColorName}
                                    className={`${color.colorName.length !== 0 ? ' error' : ''}`}
                                    data-nts-id="customColor.addColor.input.name"
                                    onChange={(e) => setEditedColorName(sanitizeName((e.target as HTMLInputElement).value))}
                                />
                            </label>
                        <div className="color-details">
                            <div data-nts-id="customColor.details.text.createdTS">Uploaded {color.createdTimeStamp.split('T')[0]}</div>
                            <div data-nts-id="customColor.details.text.createdBy">{color.createdBy}</div>
                        </div>

                        <div className="color-preview-wrapper">
                            <div className="color-preview" style={{"backgroundColor":color.factoryHexCode}} />
                        </div>  
                        <div className="color-code-details">
                            <div data-nts-id="customColor.details.text.school"><strong>School: </strong>{color.schoolName}</div>
                            <div><strong>Color Code: </strong> {color.code}</div>
                            <div><strong>Factory Hex: </strong> {color.factoryHexCode}</div>
                        </div>
                        <label htmlFor="customColorPresHex" className="update-color-input-label">
                            <span><strong>Presentation Hex: </strong></span>
                            <input
                                type="text"
                                maxLength={7}
                                placeholder="Enter hex including #"
                                name="customColorPresHex"
                                value={editedHexName}
                                data-nts-id="customColor.addColor.input.presHex"
                                onChange={(e) => setEditedHexName(sanitizeHex((e.target as HTMLInputElement).value))}
                            />
                            <span className="color-swatch" style={{"backgroundColor": editedHexName}} />
                       </label>
                    </>
                }

                {errorMsg && <p className="error" data-nts-id="customColor.details.text.error">{errorMsg}</p>}

            </Modal.Body>

            <Modal.Footer>
                <Button bsStyle="default" onClick={() => onHide(false)} data-nts-id="customColor.details.button.cancel">Cancel</Button>
                <Button bsStyle="primary" onClick={handleUpdate} data-nts-id="customColor.details.button.save">Save</Button>
            </Modal.Footer>

        </Modal>
    );
};