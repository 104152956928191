/**
 * Identify if user is using Firefox browser
 * @returns {boolean}
 */
import {ENVIRONMENT_DEV, ENVIRONMENT_PROD, ENVIRONMENT_QA, ENVIRONMENT_STG, ENVIRONMENT_UAT} from "./constants";

export const isFirefoxClient = () => {
  return (navigator.userAgent.toLowerCase().indexOf('firefox') > -1);
};

/**
 * Validates an SVG file has height/width attributes on root element <svg>
 * Firefox won't display SVGs w/o height & width on a canvas
 * https://bugzilla.mozilla.org/show_bug.cgi?id=700533
 * @param file
 * @returns {Promise<unknown>}
 */
export const validateSvgHasHeightAndWithAttributes = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const file = event.target.result;
      const hasWidthAttrib = new RegExp(/<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/).test(file);
      const hasHeightAttrib = new RegExp(/<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/).test(file);
      resolve(hasWidthAttrib && hasHeightAttrib);
    };
    reader.readAsText(file);
  });
};

/**
 * Determines subdomain (used for environment switching)
 * @param location
 * @returns {string}
 */
export function calculateEndpointToken(location) {
  const {hostname} = location;
  if (
      hostname === '127.0.0.1' ||
      hostname === 'localhost' ||
      hostname.indexOf('xip.io') !== -1 ||
      /^d+.d+.d+.d+$/.test(hostname)
  ) {
    return 'local';
  }
  return hostname.split('.', 1)[0].toLowerCase();
}

/**
 * Determines if current page is a React page (vs Angular/Freemarker)
 * @returns {boolean}
 */
export const isReactPage = () => {
  return window.location.pathname.includes('/nts/');
};

/**
 * Removes angular app div to prevent showing on React pages
 */
export const hideAngularPage = (state) => {
  if (document.getElementById("product-search-page")) {
    document.getElementById("product-search-page").hidden = state;
  }
};

/**
 * Forces display of full error container for invalid products
 */
export const showPdpErrorDiv = () => {
  const pdpInvalidMsg = document.getElementById("nts-pdp-invalid-msg");
  if(pdpInvalidMsg) { pdpInvalidMsg.style.display = "block" }
};

/**
 * Returns key for current environment
 * OPTIONS: local, dev, qa, stg, uat, prod
 */
export const getEnvironment = () => {
  let hostname = window.location.hostname.substr(0, window.location.hostname.indexOf("."));
  if(hostname === 't') hostname = 'localhost';
  let env = 'local';
  switch (hostname) {
    case "ntsdev":
      env = ENVIRONMENT_DEV;
      break;
    case "ntsqa":
      env = ENVIRONMENT_QA;
      break;
    case "ntsstage":
      env = ENVIRONMENT_STG;
      break;
    case "ntsuat":
      env = ENVIRONMENT_UAT;
      break;
    case "niketeam":
      env = ENVIRONMENT_PROD;
      break;
    default:
      break;
  }
  return env;
}

/**
 * Async wrapper for Local Storage interactions
 * @type {{getItem: (function(*=): string), setItem: (function(*=, *=): void)}}
 */
export const asyncLocalStorage = {
  setItem: async function (key, value) {
    await null;
    return localStorage.setItem(key, value);
  },
  getItem: async function (key) {
    await null;
    return localStorage.getItem(key);
  }
};
