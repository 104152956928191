const endpointTypeToUrl = {
  local: 'https://images2.nike.net/is/image/',
  dev: 'https://images2.nike.net/is/image/',
  qa: 'https://images2.nike.net/is/image/',
  stg: 'https://images2.nike.net/is/image/',
  uat: 'https://images2.nike.net/is/image/',
  prod: 'https://images2.nike.net/is/image/'
};

const endpointTokenToType = {
  '8090': 'dev',
  '9000': 'stg',
  '9001': 'qa',
  '9002': 'prod',
  '9003': 'uat',
  'localhost': 'local',
  'local': 'local',
  'ntsdev': 'dev',
  'ntsqa': 'qa',
  'ntsstage': 'stg',
  'ntsuat': 'uat',
  'niketeam': 'prod'
};

function calculateEndpointToken(location) {
  const {hostname} = location;
  if (
    hostname === '127.0.0.1' ||
    hostname === 'localhost' ||
    hostname.indexOf('xip.io') !== -1 ||
    /^d+.d+.d+.d+$/.test(hostname)
  ) {
    return 'local';
  }
  return hostname.split('.', 1)[0].toLowerCase();
}

export function BuildImageUrl(location, imageLocation, imageType) {
  let endpointToken = calculateEndpointToken(location);
  if (
    !Object.prototype.hasOwnProperty.call(endpointTokenToType, endpointToken)
  ) {
    endpointToken = 'prod';
  }
  const type = endpointTokenToType[endpointToken];
  let URL = endpointTypeToUrl[type] + imageLocation + "?fmt=jpeg&resMode=sharp2";
  if (imageType === "thumb") {
    URL += "&wid=45&hei=45"
  }
  else if(imageType === "grid") {
    URL += "&wid=200&hei=200"
  }
  else {
    URL += "&wid=400&hei=600"
  }
  return URL;
}

export function ProductImageUrlBuilder(location, style, color, imageType, view, productImages) {
  //Check to see if view is a template name or a DPILS path to an image (for non-personalizable stock products)
  //Call the right URL builder
  //TODO: DPILS check is no longer needed if we are using the image code hierarchy for all products now, rathar
  //than showing all the images from the library. Leaving this check here for now until we confirm the new system works.
  if (view && view.includes("DPILS")) {
    return BuildImageUrl(location, view, imageType);
  } else {
    return templateImageUrlBuilder(location, style, color, imageType, view, productImages);
  }
}

export function templateImageUrlBuilder(location, style, color, imageType, view, productImages) {

  //If we didn't get passed any product images, just return the PV code
  if (productImages) {
    //We have product images, look through the product images using getViewCodeFromImageList()
    //to find the best match.
    let endpointToken = calculateEndpointToken(location);
    if (
      !Object.prototype.hasOwnProperty.call(endpointTokenToType, endpointToken)
    ) {
      endpointToken = 'prod';
    }
    const type = endpointTokenToType[endpointToken];
    let viewCode = getViewCodeFromImageList(style, color, view, productImages);
    let URL = endpointTypeToUrl[type] + viewCode + "?fmt=jpeg&resMode=sharp2";

    if (imageType === "thumb") {
      URL += "&wid=45&hei=45"
    }
    else if (imageType === "grid") {
      URL += "&wid=200&hei=200"
    }
    else {
      URL += "&wid=400&hei=600"
    }

    if (viewCode) {
      return URL;
    }
  // }
  } else if (!view) {
    //We where not passed any product images to choose from, just return the PV code.
    return BuildImageUrl(location, `/DPILS/${style}-${color}-PV`, imageType);
  }
}

//Take the image codes in our list and add the chars around it to make it match what comes back from the service for easier handling.
function buildFullImageNames(style, color, codes) {
  let newCodes = codes.map((code) => {
    return `DPILS/${style}-${color}-${code}`
  });
  return newCodes;
}

//Depending on the side we are looking at, use the list of view codes to find the first one in the list that exists
//for the product
function getViewCodeFromImageList(style, color, view, productImages) {
  let viewCode;
  switch (view) {
    case "Front":
      viewCode = productImagesContains(buildFullImageNames(style, color, ["PHSFH001", "PHSFH000", "PHSFZ000", "PHSFZ001", "PHCFH001", "VPSFH001", "VPSFH000", "VPSRH001", "VPSRH000", "LASFH001"]), productImages);
      break;
    case "Back":
      viewCode = productImagesContains(buildFullImageNames(style, color, ["PHSBH001", "PHSBH000", "PHCBH001", "VPSBH001", "VPSBH000", "VPSUH001", "VPSUH000", "VPSRH000", "LASBH001"]), productImages);
      break;
    case "Reversible Front":
      viewCode = productImagesContains(buildFullImageNames(style, color, ["PHSFH101", "PHSFH100", "VPSFH101", "VPSFH100", "LASFH101"]), productImages);
      break;
    case "Reversible Back":
      viewCode = productImagesContains(buildFullImageNames(style, color, ["PHSBH101", "PHSBH100", "VPSBH101", "VPSBH100", "LASBH101"]), productImages);
      break;
    case "Shoe-F":
      viewCode = productImagesContains(buildFullImageNames(style, color, ["PHCFH000", "PHCFH001"]), productImages);
      break;
    case "Shoe-B":
      viewCode = productImagesContains(buildFullImageNames(style, color, ["PHCBH000", "PHCBH001"]), productImages);
      break;
    case "Shoe-R":
      viewCode = productImagesContains(buildFullImageNames(style, color, ["PHSRH000", "PHSRH001"]), productImages);
      break;
    case "Shoe-L":
      viewCode = productImagesContains(buildFullImageNames(style, color, ["PHSLH000", "PHSLH001"]), productImages);
      break;
    case "Shoe-T":
      viewCode = productImagesContains(buildFullImageNames(style, color, ["PHCTH001"]), productImages);
      break;
    case "Shoe-U":
      viewCode = productImagesContains(buildFullImageNames(style, color, ["PHSUH000"]), productImages);
      break;
    case "Shoe-Y":
      viewCode = productImagesContains(buildFullImageNames(style, color, ["PHSUD001", "PHSYD001"]), productImages);
      break;
    default: //Primary View, will sometimes show two for reversible
      viewCode = `/DPILS/${style}-${color}-PV`;
  }
  return viewCode;
}

function productImagesContains(codes, productImages) {
  let found = codes.length;
  if (productImages.forEach) {
    productImages.forEach((image) => {
      let i = codes.indexOf(image.imageName);
      if (i >= 0 && i < found) {
        found = i;
      }
    })
  }
  return codes[found];
}