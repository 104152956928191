import {LS_ACCESS_TOKEN} from '../../helpers/constants';
import {fetchRequest, fetchRequestSimple, urlNtsServices, urlNtsEnvironment, fetchRequestXml} from './index';
import { getCatalogBaseUrl, getAnnouncementBannerBaseUrl } from '../config/ntsDownloadsBaseUrl';
import { getEnvironment } from '../../helpers/browserHelpers';

export const ConsumerArtworkService = {
    get: (start = 0, searchState) => {
        let cb = Math.floor(Math.random() * (10000));
        let searchParams = "";
        if (searchState) {
            if (searchState.selectedSport) {
                searchParams += "&sport=" + encodeURIComponent(searchState.selectedSport.value);
            }
            if (searchState.showOnlyMine) {
                searchParams += "&ownerFlag=true";
            } else {
                searchParams += "&ownerFlag=false";
            }
            if (searchState.nameFilter) {
                searchParams += "&input=" + searchState.nameFilter;
            }
        }
        searchParams += "&artworkStatusCode=ACT";
        const request = new Request(`${urlNtsServices}/artwork/consumer/buy/find?start=${start}${searchParams}&cb=${cb}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Unite ' + localStorage.getItem(LS_ACCESS_TOKEN)
            },
        });
        return fetchRequest(request);
    }

};

/**
 * Calls webapp to logout user from session
 * @type {{logout: (function(): *)}}
 */
export const UserProfileService = {
    logout: () => {
        const request = new Request(`${urlNtsServices}/logout`, {
            method: 'GET',
            headers: undefined,
        });
        return fetchRequestSimple(request)
    }
};


/**
 * Obtains artwork records from webapp (Oracle) for dealer users
 * @type {{get: (function(*=, *=): *)}}
 */
export const DealerArtworkService = {
    get: (start = 0, searchState) => {
        let searchParams = "";
        if (searchState) {
            if (searchState.selectedSport) {
                searchParams += "&sport=" + encodeURIComponent(searchState.selectedSport.value);
            }
            if (searchState.showOnlyMine) {
                searchParams += "&ownerFlag=true";
            }
            if (searchState.showInactive) {
                searchParams += "&artworkStatusCode=";
            } else {
                searchParams += "&artworkStatusCode=ACT";
            }
            if (searchState.nameFilter) {
                searchParams += "&input=" + searchState.nameFilter;
            }
        }

        //if (process.env.NODE_ENV === 'development' || mockAll) {return getMock(dealerArtworkMock)}
        let cb = Math.floor(Math.random() * (10000));
        const request = new Request(`${urlNtsServices}/artwork/find?start=${start}${searchParams}&cb=${cb}`, {
            method: 'GET', headers: {
                'Accept': 'application/json',
                'Authorization': 'Unite ' + localStorage.getItem(LS_ACCESS_TOKEN)
            }
        });
        return fetchRequest(request);
    }
};


/**
 * Persists artwork file to webapp to be saved in Oracle/S3
 * @type {{post: (function(*=): Promise<T | never>)}}
 */
export const DesignUploadService = {
    post: (payload) => {
        const request = new Request(`${urlNtsServices}/upload/s3`, {
            method: 'POST',
            body: payload,
            headers: {
                accept: 'text/plain',
                'Authorization': 'Unite ' + localStorage.getItem(LS_ACCESS_TOKEN)
            }
        });
        return fetchRequestSimple(request, {})
            .then(response => {
                // NOTE: API returns ResponseEntity<String>, DO NOT USE response.json() to parse body, will treat returned string as JS variable
                return response.text();
            }).catch(error => {
                throw error;
            });
    }
};

export const ArtworkTermsService = {
    get: () => {
        const request = new Request(`${urlNtsServices}/upload/terms/status`, {
            method: 'GET',
            headers: {
                accept: 'text/plain',
                'Authorization': 'Unite ' + localStorage.getItem(LS_ACCESS_TOKEN),
            }
        });

        return fetchRequestSimple(request, {})
            .then(response => response.text())
            .catch(error => {
                throw error;
            })
    },
    update: () => {
        const request = new Request(`${urlNtsServices}/upload/terms/status`, {
            method: 'PUT',
            headers: {
                accept: 'text/plain',
                'Authorization': 'Unite ' + localStorage.getItem(LS_ACCESS_TOKEN),
            }
        });

        return fetchRequestSimple(request, {})
            .then(response => response)
            .catch(error => {
                throw error;
            })
    }
};

/**
 * Webapp endpoint for uploading custom logos to Scene7
 * @type {{post: (function(*=): Promise<T | never>)}}
 */
export const CustomLogoService = {
    post: (payload) => {
        const request = new Request(`${urlNtsServices}/scene7ImageUpload`, {
            method: 'POST',
            body: payload,
            headers: { 'Authorization': 'Unite ' + localStorage.getItem(LS_ACCESS_TOKEN) }
        });
        return fetchRequestSimple(request)
            .then(response => {
                // NOTE: API returns ResponseEntity<String>, DO NOT USE response.json() to parse body, will treat returned string as JS variable
                return response.text();
            }).catch(error => {
                return error;
            });
    },
    updateArtworkStatus: (artworkId, status) => {
        const request = new Request(`${urlNtsEnvironment}/en/en_US/p/art,status?artworkId=${artworkId}&status=${status}`, {
            method: 'GET',
            headers: { 'Authorization': 'Unite ' + localStorage.getItem(LS_ACCESS_TOKEN)  }
        });
        return fetchRequest(request);
    }
};

/**
 * Obtains list of Dealers for Contact a Dealer form
 * @type {{get: (function(*): *)}}
 */
export const DealerLocations = {
    get: (state) => {
        const request = new Request(`${urlNtsServices}/dealer/state/${state}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Unite ' + localStorage.getItem(LS_ACCESS_TOKEN)
            }
        });
        return fetchRequest(request);
    }
};

export const CatalogService = {
    getCatalogConfig: () => {
        const baseUrl = getCatalogBaseUrl();
        const env = getEnvironment();
        return fetch(`${baseUrl}/catalogs_${env}.json?cb=${Date.now()}`)
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
                throw new Error('request failed');
            });
    },
};

export const AnnouncementBannerService = {
    getAnnouncementBannerConfig: () => {
        const baseUrl = getAnnouncementBannerBaseUrl();
        const env = getEnvironment();
        return fetch(`${baseUrl}/banners_${env}.json?cb=${Date.now()}`)
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
                throw new Error('request failed');
            });
    },
};

/**
 * Calls webapp to seach styles
 * @type {{search: (function(payload): *)}}
 */
export const StyleSearchService = {
    search: (payload) => {
        const request = new Request(`${urlNtsServices}/stylesearch/v1?&styleNbr=${payload.styleNbr}&udNbr=${payload.udNbr}&nikeSoNbr=${payload.nikeSoNbr}&nikePoNbr=${payload.nikePoNbr}&cartNameOrTeamName=${payload.cartNameOrTeamName}&customerPoNbr=${payload.customerPoNbr}`, {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem(LS_ACCESS_TOKEN),
                'content-type':'application/json'
            }
        });
        return fetchRequest(request)
    }
};

export const ImpersonateService = {
    asUser: (teamUserId) => {
        const request = new Request(`${urlNtsServices}/admin/user/impersonateUser?teamUserId=${teamUserId}`, {
            method: 'POST',
            headers: {
                'Authorization': localStorage.getItem(LS_ACCESS_TOKEN),
                'content-type':'application/xml'
            }
        });
        return fetchRequestXml(request)
    },
    asAdmin: (teamUserId) => {
        const request = new Request(`${urlNtsServices}/admin/user/impersonate?teamUserId=${teamUserId}`, {
            method: 'POST',
            headers: {
                'Authorization': localStorage.getItem(LS_ACCESS_TOKEN),
                'content-type':'application/xml'
            }
        });
        return fetchRequestXml(request)
    }
}