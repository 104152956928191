import React from 'react';
import Select, {Options, OnChangeSingleHandler, OptionValues} from 'react-select';

interface Props {
  className?: string;
  clearable?: boolean;
  name?: string;
  onChange?: OnChangeSingleHandler<OptionValues>;
  options?: Options;
  placeholder?: string;
  style?: React.CSSProperties;
  value?: string | number | boolean;
}

export class NikeSelect extends React.Component<Props> {
  render() {
    const carrotStyle: React.CSSProperties = {
      color: '#111111',
      float: 'right',
      padding: '5px 5px 5px 0'
    };

    const inputStyle: React.CSSProperties = {
      height: '40px',
      borderRadius: '2px',
      fontFamily: 'Helvetica',
      textAlign: 'left'
    };

    return (
        <Select
          name={this.props.name}
          value={this.props.value}
          options={this.props.options}
          clearable={this.props.clearable}
          onChange={this.props.onChange}
          style={{...inputStyle, ...this.props.style}}
          placeholder={this.props.placeholder}
          arrowRenderer={() => {return <span className="g72-arrow-thick-down" style={carrotStyle}/>}}
          className={this.props.className}
        />
    );
  }
}