import * as React from "react";
import {Button} from "react-bootstrap";
import {IFontForm} from "./AddCustomFontModal";
import {FONTNAME_ALLOWED_CHARS_REGEX, EMAIL_ALLOWED_CHARS_REGEX} from "../../../../js/helpers/constants";

const PNG_FILE_KEY = 'pngFile';
const FACTORY_FILE_KEY = 'factoryFile';

interface Props {
    errors: any;
    fontForm: IFontForm;
    setFontForm: (fontForm:IFontForm) => void;
}

export const AddFontForm: React.FC<Props> = ({
    fontForm,
    setFontForm,
    errors
}) => {

    let pngFileInputRef: HTMLInputElement | null,
        factoryFileInputRef: HTMLInputElement | null;
    const validateFontName = (nameVal: string) => {
        const filteredFontName = nameVal.replace(FONTNAME_ALLOWED_CHARS_REGEX, '');
        setFontForm({...fontForm, name: filteredFontName})
    }
    const validateFontSchool = (schoolName : string) => {
        const filteredFontSchool = schoolName.replace(FONTNAME_ALLOWED_CHARS_REGEX, '');
        setFontForm({...fontForm, school: filteredFontSchool})
    }
    const handleFileSelect = (type: string, files: FileList | null) => {
        const file = (files && files.length > 0) ? files[0] : null;
        setFontForm({...fontForm, [type]: file });
    }

    const clearFileSelect = (type: string, ref: HTMLInputElement | null) => {
        if(ref) ref.value = '';
        setFontForm({...fontForm, [type]: null })
    }

    return (
        <div className="custom-fonts-form">

            <label className="custom-font-label" htmlFor="customFontActiveCheck">
                <span>Active</span>
                <input
                    type="checkbox"
                    name="customFontActiveCheck"
                    className="nike-check"
                    checked={fontForm.active}
                    data-nts-id="customFont.addFont.checkbox.status"
                    onChange={(e) => setFontForm({...fontForm, active: (e.target as HTMLInputElement).checked})}
                />
            </label>

            <label htmlFor="customFontName">
                <span>Name</span>
                <input
                    type="text"
                    required
                    maxLength={30}
                    name="customFontName"
                    placeholder="Enter font name"
                    value={fontForm.name}
                    className={`${errors.name ? ' error' : ''}`}
                    data-nts-id="customFont.addFont.input.name"
                    onChange={(e) => validateFontName((e.target as HTMLInputElement).value)}
                />
            </label>

            <label htmlFor="customFontSchool">
                <span>School</span>
                <input
                    type="text"
                    name="customFontSchool"
                    placeholder="Enter optional school name"
                    maxLength={30}
                    value={fontForm.school}
                    className={`${errors.school ? ' error' : ''}`}
                    data-nts-id="customFont.addFont.input.school"
                    onChange={(e) => validateFontSchool((e.target as HTMLInputElement).value)}
                />
            </label>

            <label htmlFor={PNG_FILE_KEY}>
                <span>PNG File</span>
                <input
                    style={{display:"none"}}
                    type="file"
                    name={PNG_FILE_KEY}
                    accept=".png"
                    data-nts-id="customFont.addFont.input.pngFile"
                    ref={(input) => pngFileInputRef = input}
                    onChange={(e) => handleFileSelect(PNG_FILE_KEY, (e.target as HTMLInputElement).files)}
                />
                {!fontForm.pngFile &&
                    <Button
                        bsStyle="default" onClick={() => pngFileInputRef?.click()}
                        className={`${errors.pngFile ? ' error' : ''}`}
                        data-nts-id="customFont.addFont.button.selectPngFile">SELECT</Button>
                }
                {fontForm.pngFile &&
                    <div className="custom-font-file-info">
                        <div title={fontForm.pngFile.name}>{fontForm.pngFile.name}</div>
                        <span
                            onClick={() => clearFileSelect(PNG_FILE_KEY, pngFileInputRef)}
                            data-nts-id="customFont.addFont.button.removePngFile"
                        >remove</span>
                    </div>
                }
            </label>

            <label htmlFor={FACTORY_FILE_KEY}>
                <span>Factory File</span>
                <input
                    style={{display:"none"}}
                    type="file"
                    name={FACTORY_FILE_KEY}
                    accept=".ai,.svg"
                    data-nts-id="customFont.addFont.input.factoryFile"
                    ref={(input) => factoryFileInputRef = input}
                    onChange={(e) => handleFileSelect(FACTORY_FILE_KEY, (e.target as HTMLInputElement).files)}
                />
                {!fontForm.factoryFile &&
                    <Button
                        bsStyle="default" onClick={() => factoryFileInputRef?.click()}
                        className={`${errors.factoryFile ? ' error' : ''}`}
                        data-nts-id="customFont.addFont.button.selectFactoryFile">SELECT</Button>
                }
                {fontForm.factoryFile &&
                    <div className="custom-font-file-info">
                        <div title={fontForm.factoryFile.name}>{fontForm.factoryFile.name}</div>
                        <span
                            onClick={() => clearFileSelect(FACTORY_FILE_KEY, factoryFileInputRef)}
                            data-nts-id="customFont.addFont.button.removeFactoryFile"
                        >remove</span>
                    </div>
                }
            </label>

        </div>
    );
};