import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

export interface LoadingSpinnerState {
  // keeps track of the number of loading instances
  fullLoadingSpinner: number;
  isLoading: boolean;
}

const initialState: LoadingSpinnerState = {
  fullLoadingSpinner: 0,
  isLoading: false,
};

const loadingSpinnerReducer: Reducer<LoadingSpinnerState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case types.START_FULL_LOADING_SPINNER:
      return {
        ...state,
        fullLoadingSpinner: state.fullLoadingSpinner + 1,
        isLoading: true,
      };

    case types.END_FULL_LOADING_SPINNER:
      let newNumber = state.fullLoadingSpinner - 1;
      if (newNumber < 0)
        newNumber = 0;

      return {
        ...state,
        fullLoadingSpinner: newNumber,
        isLoading: newNumber !== 0,
      };

    default:
      return state;
  }
}

export default loadingSpinnerReducer;