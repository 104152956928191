import * as React from 'react';

import {MemberTab} from "./MemberTab";

export const DealerSharePage: React.FC = () => {

    return (
        <div className="nts-admin-page" id="nts-dealer-share-page">
            <h1 className="title">DEALER SHARE</h1>
            <div className="admin-tabs-content">
                <MemberTab />
            </div>
        </div>
    );
};