import * as React from 'react';

import {Tabs} from './CatalogPage';

interface Props {
    selectedTab: Tabs;
    onSelectTab: (tab: Tabs) => void;
}

const CatalogTabs: React.FC<Props> = ({ selectedTab, onSelectTab }) => {
    const renderTab = (tab: Tabs, selected: boolean) => {
        return (
            <span
                className={`catalog-tab${selected ? ' selected' : ''}`}
                key={tab}
                onClick={() => onSelectTab(tab)}
            >
                {tab}
            </span>
        )
    };

    //Uncomment map() to restore current vs past catalogs
    return (
        <div className="catalog-tabs">
            {/*{
                Object.values(Tabs).map((tabName) => {
                    return renderTab(tabName, selectedTab === tabName)
                })
            }*/}
            <span
                className="catalog-tab"
                key={Tabs.CURRENT}
                onClick={() => onSelectTab(Tabs.CURRENT)}
            >
                {Tabs.CURRENT}
            </span>
        </div>
    );
};

export default CatalogTabs;
