import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

export interface CartSelectorState {
  showSelectCartModal:  boolean;
  showNewCartEntry: boolean;
  searchFilter: string;
  selectedItems: unknown[],
  newCartName: string;
  message: string;
  msgStatus: string;
  orders: [],
  isJba: boolean;
  defaultName: string;
  jbaSrcOrderId: string;
  uniformId: string;
  products: unknown[];
}

const defaultState: CartSelectorState = {
  showSelectCartModal:  false,
  showNewCartEntry: false,
  searchFilter: '',
  selectedItems: [],
  newCartName: '',
  message: '',
  msgStatus: '',
  orders: [],
  isJba: false,
  defaultName: '',
  jbaSrcOrderId: '',
  uniformId: '',
  products: []
};

// TODO: These actions can be separate types later
interface CartSelectorAction extends Action {
  data: Partial<CartSelectorState>;
}

const cartSelectorReducer: Reducer<CartSelectorState> = (
  state = defaultState,
  incomingAction
) => {
  const action = incomingAction as unknown as CartSelectorAction;

  switch (action.type) {
    case types.SHOW_CART_SELECT_MODAL:
      return {
        ...defaultState,
        showSelectCartModal: true,
        ...action.data
      };

    case types.HIDE_CART_SELECT_MODAL:
      return {
        ...defaultState
      };

    default:
      return state;
  }
};

export default cartSelectorReducer;
