import * as types from './actionTypes';
import {store} from '../../App';

export function showCartSelectionModal(data) {
  data = {...data, showSelectCartModal: true};
  return store.dispatch({type: types.SHOW_CART_SELECT_MODAL, data})
}

export function hideCartSelectionMessageModal() {
  return store.dispatch({type: types.HIDE_CART_SELECT_MODAL});
}
