import React from 'react';

import {Spacer} from '../../shared/spacer';
import {getOrderConfirmationTypeFromUrl} from '../../../helpers/orderHelpers';

export class ConfirmationPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      type: getOrderConfirmationTypeFromUrl(this.props.match.path)
    }
  }

  getContent = (type) => {
    let content;
    switch (type) {
      case "coupon":
        content = {
          title: 'ORDER CONFIRMATION',
          subtitle: 'Thank you, your coupon order has been placed',
          body: 'An email confirmation will be sent to you.'
        };
        break;
      case "quote":
        content = {
          title: 'QUOTE CONFIRMATION',
          subtitle: 'Thank you, your quote has been requested',
          body: 'You will receive an email shortly with the next steps.'
        };
        break;
      default: //Type is 'order'
        content = {
          title: 'ORDER CONFIRMATION',
          subtitle: 'Thank you, your order has been placed',
          body: 'An email confirmation will be sent to you.'
        };
    }
    return content;
  };

  render() {
    const {title, subtitle, body} = this.getContent(this.state.type);

    return (
        <div className="container nts-confirmation-page">
          <h1 data-nts-id="confirmation.title">{title}</h1>
          <div className="separator"/>
          <h4 data-nts-id="confirmation.subtitle">{subtitle}</h4>
          <Spacer size={10}/>
          <p data-nts-id="confirmation.body">{body}</p>
        </div>
    );
  }
}