import * as React from "react";
import { showMessageModal } from "../../../actions/messageModalActions";
import { dispatchShowToast } from "../../../actions/toastActions";
import { AdminFactoryService } from "../../../api/services/adminFactoryService";
import { AddFactory } from "./AddFactory";
import { AddFactoryState, AdminFactoryResponse, FactoryState } from "./adminFactoryHelper";
import { RemoveFactory } from "./RemoveFactory";
import { SearchBar } from "./SearchBar";
import { SearchResults } from "./SearchResults";

export const FactoryData: React.FC = () => {
  const initialState: FactoryState = {
    results: [],
    error: "",
    message: "Please create factory to see results.",
    page: 0,
    size: 5,
    count: 0,
    sort: [{
      id: 'last_mod_tmst',
      desc: true
    }]
  };
  const initialCreateState: AddFactoryState = {
    show: false,
    edit: false,
    item: {id: 0, name: "", code: "", updatedAt: "", priority: null, fcr_supplier_id: null, fcr_comm_cd: 'EDI', fhs_fcr_cd: ''}
  };
  const [state, setState] = React.useState(initialState);
  const [createState, setCreateState] = React.useState(initialCreateState);
  const [removeState, setRemoveState] = React.useState(initialCreateState);

  React.useEffect(() => {
    getData(initialState.page, initialState.size, state.sort[0]);
  }, [])

  const getData = async (page: number, size: number, sort: any) => {
    const response = await AdminFactoryService.getFactoryList(`page=${(page + 1)}&pageSize=${size}&sortColumn=${sort.id}&sortDesc=${sort.desc ? 'DESC' : 'ASC'}`);
    if (response) {
      setState(prevState => ({
        ...prevState,
        results: response.factories,
        page: page,
        count: response.pages.totalCount,
        size: size
      }))
    }
  }

  const handleSubmit = () => {
    handleCloseCreate();
    dispatchShowToast('Factory created successfully.');
    getData(0, state.size, state.sort[0]);
  }

  const handlePagination = (size: number, page: number) => {
    setState(prevState => ({
      ...prevState,
      page: page,
      size: size
    }))
    getData(page, size, state.sort[0]);
  }

  const handleCreate = () => {
    setCreateState(prevState => ({
      ...prevState,
      edit: false,
      show: true
    }))
  };
  const handleCloseCreate = () => {
    setCreateState(prevState => ({
      ...prevState,
      show: false
    }))
  }
  const handleRemove = (item: AdminFactoryResponse) => {
    setRemoveState(prevState => ({
      ...prevState,
      show: true,
      item: item
    }))
  }
  
  const handleSort = (item: any) => {
    setState(prevState => ({
      ...prevState,
      sort: item
    }))
    getData(state.page, state.size, item[0]);
  }
  const handleNavigate = (item: AdminFactoryResponse) => {
    window.location.href = `${window.location.protocol}//${window.location.host}/en/en_US/nts/admin/factory/ship/group/${item.id}`;
  }
  const handleCloseRemove = () => {
    setRemoveState(prevState => ({
      ...prevState,
      show: false
    }))
  }
  const handleRemoveSuccess = async (item: AdminFactoryResponse) => {
    await AdminFactoryService.deleteFactory(item.id).then((response) => {
      setRemoveState(prevState => ({
        ...prevState,
        show: false
      }))
      dispatchShowToast('Factory deleted successfully.');
      getData(state.page, state.size, state.sort[0]);
    }).catch((err) => {
      // dispatchShowToast(err.message);
      showMessageModal({ header: "FACTORY DELETE ERROR", message: err.message, required: true });
    });
  }

  const handleEdit = (item: AdminFactoryResponse)=>{
    setCreateState(prevState => ({
      ...prevState,
      edit: true,
      item: item,
      show: true,
    }))
  }

  return (
    <div>
      <SearchBar state={state} handleCreate={handleCreate} />
      <SearchResults state={state}
        handleRemove={handleRemove}
        handleEdit={handleEdit} 
        handleSort={handleSort}
        handlePagination={handlePagination}
        handleNavigate={handleNavigate} />
      <AddFactory state={createState} onHide={handleCloseCreate} onSubmitSuccess={handleSubmit} />
      <RemoveFactory state={removeState} onHide={handleCloseRemove} onSuccess={handleRemoveSuccess} />
    </div>
  );
};
