import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

// TODO improve types here
export interface ColorsState {
  colors: unknown[];
}

interface ColorsAction extends Action {
  colors: unknown[];
}

const initialState: ColorsState = {
  colors: [],
};

const colorsReducer: Reducer<ColorsState> = (state = initialState, incomingAction) => {
  const action = incomingAction as unknown as ColorsAction;
  switch (action.type) {
    case types.LOAD_COLORS:
      return {
        colors: [...action.colors],
      };
    default:
      return state;
  }
}

export default colorsReducer;