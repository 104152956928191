import * as React from 'react';

import {ReportTab} from "./ReportTab";

import {AnalyticsReportType} from "./adminAnalyticsHelper";

interface State {
    currentTab: AnalyticsReportType
}

export const AdminAnalyticsPage: React.FC<State> = () => {

    const [currentTab, setCurrentTab] = React.useState(AnalyticsReportType.QUOTES);

    return (
        <div className="nts-admin-page nts-admin-analytics-page">
            <div>
                <h1>ANALYTICS</h1>
                <ul className="admin-tabs">
                    <li
                        className={currentTab === AnalyticsReportType.QUOTES ? 'selected': ''}
                        onClick={() => setCurrentTab(AnalyticsReportType.QUOTES)}>
                        QUOTES
                    </li>
                    <li
                        className={currentTab === AnalyticsReportType.ORDERS ? 'selected': ''}
                        onClick={() => setCurrentTab(AnalyticsReportType.ORDERS)}>
                        ORDERS
                    </li>
                </ul>
                <div className="admin-tabs-content">
                    <div style={ currentTab === AnalyticsReportType.ORDERS ? {visibility:'visible'} : {visibility:'hidden', height: 0}}>
                        <ReportTab type={AnalyticsReportType.ORDERS} />
                    </div>
                    <div style={ currentTab === AnalyticsReportType.QUOTES ? {visibility:'visible'} : {visibility:'hidden', height: 0}}>
                        <ReportTab type={AnalyticsReportType.QUOTES} />
                    </div>
                </div>
            </div>
        </div>
    )

}
