import * as React from "react";
import ReactTable from "react-table";
import { dateStringSort } from "../../../helpers/arrayHelpers";
import { formatIsoDateString } from "../../../helpers/checkoutHelpers";
import { AdminFactoryResponse, FactoryState, ReportRowWrapper } from "./adminFactoryHelper";

interface Props {
    state: FactoryState;
    handleRemove: (item: AdminFactoryResponse) => void;
    handleEdit: (item: any) => void;
    handleSort: (item: any) => void;
    handleNavigate: (item: AdminFactoryResponse) => void;
    handlePagination: (page: number, size: number) => void;
}

export const SearchResults: React.FC<Props> = ({
    state,
    handleRemove,
    handleEdit,
    handleSort,
    handleNavigate,
    handlePagination
}) => {
    return (
        <div className="nts-tab-search-results">
            <ReactTable
                className="nts-table"
                data={state.results}
                noDataText={state.message}
                loading={false}
                pages={Math.ceil(state.count / state.size)}
                page={state.page}
                pageSize={state.size}
                manual={true}
                sortable={true}
                sorted={state.sort}
                onSortedChange={(newSorted, column, shiftKey) => {
                    handleSort(newSorted)
                }}
                onPageChange={(page: number)=>{
                    handlePagination(state.size, page);
                }}
                onPageSizeChange={(newPageSize: number, newPage: number)=>{
                    handlePagination(newPageSize, 0);
                }}
                columns={[
                    {
                        Header: 'FACTORY ID',
                        accessor: 'id',
                        show: false
                    },
                    {
                        Header: 'FACTORY NAME',
                        accessor: 'name',
                        Cell: (rowInfo:any) =>  {
                            return (
                                <div className='nts-factory-row-wrapper'>
                                    <span>{rowInfo.original.name}</span>
                                </div>
                            ); 
                        }
                    },
                    {
                        Header: 'FACTORY CODE',
                        accessor: 'code',
                        Cell: (rowInfo:any) => {
                            return (
                                <div className='nts-factory-row-wrapper'>
                                    <span>{rowInfo.original.code}</span>
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'PRIORITY',
                        accessor: 'priority',
                        Cell: (rowInfo:any) => {
                            return (
                                <div className='nts-factory-row-wrapper'>
                                    <span>{rowInfo.original.priority}</span>
                                </div>
                            );                           
                        }
                    },
                    {
                        Header: 'COMMUNICATION MODE',
                        accessor: 'fcr_comm_cd',
                        Cell: (rowInfo:any) => {
                            return(
                                <div className='nts-factory-row-wrapper'>
                                    <span>{rowInfo.original.fcr_comm_cd}</span>
                                </div>
                            ) 
                        }
                    },
                    {
                        Header: 'SUPPLIER ID',
                        accessor: 'fcr_supplier_id',
                        Cell: (rowInfo:any) => {
                            return(
                                <div className='nts-factory-row-wrapper'>
                                    <span>{rowInfo.original.fcr_supplier_id}</span>
                                </div>
                            ) 
                        }
                    },
                    {
                        Header: 'FHS CODE',
                        accessor: 'fhs_fcr_cd',
                        Cell: (rowInfo:any) => {
                            return(
                                <div className='nts-factory-row-wrapper'>
                                    <span>{rowInfo.original.fhs_fcr_cd}</span>
                                </div>
                            ) 
                        }
                    },
                    {
                        Header: 'LAST UPDATED',
                        accessor: 'updatedAt',
                        sortMethod: dateStringSort,
                        Cell: (rowInfo:ReportRowWrapper) => {
                            return formatIsoDateString(rowInfo.original.updatedAt)
                        }
                    },{
                        Header: 'ACTION',
                        accessor: '', 
                        Cell: (rowInfo:ReportRowWrapper) => {
                            return (
                            <div style={{position:"relative"}}> 
                                <span className="edit-icon icon"  onClick={()=> handleEdit(rowInfo.original)}></span>
                                <span className="delete-icon icon" onClick={()=> handleRemove(rowInfo.original)}></span>
                                <span className="cog-icon icon" onClick={()=> handleNavigate(rowInfo.original)}></span>
                            </div>);
                        }
                    },
                ]}
                minRows={0}
                style={{height:"auto"}}
            />
        </div>
    )
}
