import {AtOnceDetailsMock} from "../mocks";
import {getUserSoldTo} from "../../helpers/userProfileHelpers";
import {AUTH_HEADER_PREFIX, LS_ACCESS_TOKEN, SAP_SALES_ORG} from "../../helpers/constants";

import {
  fetchRequest, getMock, mockAll,
  urlAtOnce,
  urlNtsStockOrderServices
} from "./index";

export const ProductDetails = {

  get: (styleColor) => {
    if (mockAll) {
      return getMock(AtOnceDetailsMock)
    }
    const request = new Request(`${urlAtOnce}/product?productCodes=${styleColor}`, {method: 'GET'});
    return fetchRequest(request)
    .then((result) => {
      return result;
    })
    .catch(error => {
      throw error;
    });
  },

  getAvailability: (styleColor) => {
    if (mockAll) {
      return getMock(AtOnceDetailsMock)
    }
    let urlRequest;
    const soldTo = (getUserSoldTo()) ? getUserSoldTo() : 0;
    urlRequest = `${urlNtsStockOrderServices}/api/v1/stock/availability?childSo=${SAP_SALES_ORG}&isRealTime=false&currency=USD&hzn=0&language=EN&priceList=USA&so=${SAP_SALES_ORG}&soldTo=${soldTo}&styleColor=${styleColor}`;
    const request = new Request(urlRequest,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
          }
        });
    return fetchRequest(request, true, 'UNITE');
  }

};
