import * as types from "./actionTypes";
import {CustomColor} from "../reducers/customColorsReducer";

export const showAddColorModal = (show: boolean) => {
    return { type: types.SHOW_CUSTOM_COLOR_ADD_MODAL, show: show };
}

export const showColorDetailsModal = (show: boolean) => {
    return { type: types.SHOW_CUSTOM_COLOR_DETAILS_MODAL, show: show };
}

export const setSelectedCustomColor = (color: CustomColor | null) => {
    return { type: types.SET_SELECTED_CUSTOM_COLOR, color: color };
}

export const setCustomColorList = (list: CustomColor[]) => {
    return { type: types.SET_CUSTOM_COLOR_LIST, list: list };
}

export const showInactiveCustomColors = (show: boolean) => {
    return { type: types.SHOW_INACTIVE_CUSTOM_COLORS, show: show };
}