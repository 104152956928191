import * as React from "react";

import {Button, Modal} from "react-bootstrap";

import {CustomFont} from "../../../reducers/customFontsReducer";
import {urlNtsEnvironment} from "../../../api/services";
import {baseUrl} from "../../../helpers/imageUrlBuilder";
import {IMAGE_UNAVAILABLE_NAME} from "../../../helpers/constants";
import {getDealershipId} from "../../../helpers/userProfileHelpers";
import {EliteOptionsService} from "../../../api/services/eliteOptionsService";
import {
    EliteOptionsStatusType,
    ERROR_NO_DEALERSHIP,
    ERROR_UPDATE_FONT_GENERIC,
    ERROR_GETTING_FACTORY_FILE_URL,
    getUploadFontPayload,
    getFactoryFileUrlPayload,
    downloadFactoryFile
} from "../customFontsHelper";

interface Props {
    font: CustomFont | null;
    onHide: (refresh:boolean) => void;
}

export const FontDetailsModal: React.FC<Props> = ({
    font,
    onHide,
}) => {

    const [active, setActive] = React.useState((font) ? font.status ===  EliteOptionsStatusType.ACTIVE : false);
    const [errorMsg, setErrorMsg] = React.useState((!font) ? 'No font selected. Please close modal and try again.' : '');

    const imageUrl = (font) ? urlNtsEnvironment + '/elite-options/' + font.data.displayUrl : baseUrl + IMAGE_UNAVAILABLE_NAME;

    const handleDownload = () => {
        const getFactoryFileUrl = async () => {
            const dealershipId = getDealershipId();
            if(!dealershipId) {
                setErrorMsg(ERROR_NO_DEALERSHIP);
                return;
            }
            const updatedFont = {...font} as CustomFont;
            const reqPayload = getFactoryFileUrlPayload(updatedFont)
            const response = await EliteOptionsService.getFactoryFileURL(reqPayload);
            if (response && response.code === "SUCCESS" && response.data) {
                downloadFactoryFile(response.data.url);
                setErrorMsg('');
                onHide(true);
            } else {
                const error = (response.error && response.message) ? response.message : ERROR_UPDATE_FONT_GENERIC;
                setErrorMsg(error);
            }
        }
        getFactoryFileUrl().catch((e) => {
            const error = (e.error && e.message) ? e.message : ERROR_GETTING_FACTORY_FILE_URL;
            setErrorMsg(error);
        });

    }
    const handleUpdate = () => {
        const updateFontCall = async () => {
            const dealershipId = getDealershipId();
            if(!dealershipId) {
                setErrorMsg(ERROR_NO_DEALERSHIP);
                return;
            }
            const origStatus = font && font.status;
            const currentStatus = (active) ? EliteOptionsStatusType.ACTIVE : EliteOptionsStatusType.INACTIVE;
            if(origStatus !== currentStatus) {
                const updatedFont = {...font, status: currentStatus} as CustomFont;
                const payload = getUploadFontPayload(updatedFont, dealershipId);
                const response = await EliteOptionsService.updateCustomFont(payload);
                if(response && response.code === "SUCCESS" && response.data) {
                    setErrorMsg('');
                    onHide(true);
                } else {
                    const error = (response.error && response.message) ? response.message : ERROR_UPDATE_FONT_GENERIC;
                    setErrorMsg(error);
                }
            } else {
                onHide(false);
            }
        }
        updateFontCall().catch((e) => {
            const error = (e.error && e.message) ? e.message : ERROR_UPDATE_FONT_GENERIC;
            setErrorMsg(error);
        });
    }

    return (
        <Modal className="show nts-custom-font-details-modal" show={true} onHide={onHide} bsSize="large" aria-labelledby="contained-modal-title-lg">

            <Modal.Header closeButton>VIEW FONT</Modal.Header>

            <Modal.Body>
                {font &&
                    <>
                        <label htmlFor="active-check">
                            <span>Active</span>
                            <input
                                type="checkbox"
                                name="active-check"
                                className="nike-check"
                                checked={active}
                                data-nts-id="customFont.details.checkbox.active"
                                onChange={(e) => setActive((e.target as HTMLInputElement).checked)}
                            />
                        </label>
                        <div className="font-details">
                            <div data-nts-id="customFont.details.text.name"><strong>{font.data.fontName}</strong></div>
                            <div data-nts-id="customFont.details.text.school">{font.data.schoolName}</div>
                            <div data-nts-id="customFont.details.text.createdTS">Uploaded {font.createdTimeStamp.split(' ')[0]}</div>
                            <div data-nts-id="customFont.details.text.createdBy">{font.createdBy}</div>
                        </div>
                        <img width="200" height="200" src={imageUrl} alt={`${font.data.fontName} preview`} />
                    </>
                }

                {errorMsg && <p className="error" data-nts-id="customFont.details.text.error">{errorMsg}</p>}

            </Modal.Body>

            <Modal.Footer>
                <Button bsStyle="default" onClick={handleUpdate} data-nts-id="customFont.details.button.done">Done</Button>
                <Button bsStyle="primary" onClick={handleDownload} data-nts-id="customFont.details.button.download">Download</Button>
            </Modal.Footer>

        </Modal>
    );
};
