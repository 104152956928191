import * as React from "react";

import {AnalyticsReportType, AnalyticsState} from "./adminAnalyticsHelper";
import {getDateAsString} from "../../../helpers/checkoutHelpers";

interface Props {
    type: AnalyticsReportType;
    state: AnalyticsState;
}

export const SearchResults: React.FC<Props> = ({
    type,
    state
}) => {
    return (
        <div className="nts-analytics-search-results">

            <table className="table nts-table" >

                <thead>
                    <tr>
                        <th>{type === AnalyticsReportType.ORDERS ? 'Submit Date' : 'Quote Date'}</th>
                        <th>{state.filter}</th>
                        <th>Count</th>
                    </tr>
                </thead>

                <tbody>
                {state.results.map((item, i) => {
                    const date = type === AnalyticsReportType.ORDERS ? item.submitDate : item.quoteDate;
                    return (
                        <tr key={i}>
                            <td>{date ? getDateAsString(new Date(date), 'dashes') : ''}</td>
                            <td>{item.groupName}</td>
                            <td>{item.count}</td>
                        </tr>
                    )
                })}
                </tbody>

            </table>

        </div>
    )
}
