import * as types from "./actionTypes";
import {store} from "../../App";
import {CouponService} from "../api/services/couponService";
import {showMessageModal} from "./messageModalActions";

/**
 * Triggers display of coupon (lookup) modal w/ the type preset
 * Type Options: remove, apply, lookup
 * @param state
 * @param type
 * @returns {*}
 */
export function dispatchDisplayCouponLookupModal(state, type){
  return store.dispatch({type: types.SHOW_COUPON_LOOKUP_MODAL, state: state, modalType: type} )
}

export function dispatchCouponLookupState(couponModalType) {
  return store.dispatch({ type: types.SET_COUPON_MODAL_STATE, couponModalType });
}

/**
 * Loads coupon details into redux
 * @param details - {couponCode, balanceAmount, couponExpirationDate, couponActivationDate}
 * @returns {*}
 */
export function dispatchLoadCouponDetails(details) {
  return store.dispatch({type: types.LOAD_COUPON_DETAILS, coupon: details})
}

/**
 * Sets coupon details into context (display in coupon bar)
 * after looking up coupon details by coupon code
 * @param couponCode
 * @param cartId
 * @param cartBalance
 */
export function loadCouponDetails(couponCode, cartId, cartBalance = 0) {
  return CouponService.lookupCartCoupon(couponCode,cartId).then(response => {
    if(response && response.error) {
      showMessageModal({ header: "COUPON ERROR", message: response.message });
      dispatchLoadCouponDetails({code: couponCode, error: response.message, cartId: cartId});
      return false;
    } else {
      dispatchLoadCouponDetails({
        code: response.data.couponCode,
        balance: response.data.balanceAmount,
        expirationDate: response.data.couponExpirationDate,
        activationDate: response.data.couponActivationDate,
        cartId: cartId, cartBalance: cartBalance,
        effectiveBalanceAmount:response.data.effectiveBalanceAmount
      });
      //ensure coupon covers cart balance
      return (response.data.effectiveBalanceAmount >= 0);
    }
  }).catch(error => {
    showMessageModal({ header: "COUPON ERROR", message: 'There was an issue retrieving your coupon details. Please try again later.' });
    dispatchLoadCouponDetails({code: couponCode, error: 'There was an issue retrieving your coupon details. Please try again later.', cartId: cartId});
    return false;
  });
}
