import React from 'react';
import {Col, Row} from 'react-bootstrap';

import {
  getSanitizedUrlParams,
  validateForm
} from './dealerContactHelpers';
import {ContactDealerService} from '../../api/services/environmentService';
import {showMessageModal} from '../../actions/messageModalActions';

const topicOptions = [
  {key: 'buying', text: 'Buying Uniforms and Products'},
  {key: 'product', text: 'Product Sizing Inquiry'},
  {key: 'custom', text: 'Embellishments and Customization Options'},
  {key: 'general', text: 'General Question'}
];

export class DealerContactForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName:'',
      lastName:'',
      email:'',
      phone:'',
      city:'',
      state:'',
      topic:'',
      message:'',
      formSubmitted: false,
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        city: '',
        state: '',
        topic: '',
        message: ''
      }
    }
  }

  handleSubmit = () => {
    const errors = validateForm(this.state);
    if(errors.errorCount > 0) {
      this.setState({
        ...this.state,
        formSubmitted: true,
        ...errors
      })
    } else {
      ContactDealerService.sendEmail(getSanitizedUrlParams(this.props.dealer, this.state)).then(result => {
        this.props.onSuccess();
      }).catch(error => {
        showMessageModal({
          header: "ERROR SENDING EMAIL",
          message: "We're sorry, we could not send your message. Please try again."
        });
      });
    }
  };

  validateOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if(this.state.formSubmitted) {
      const form = this.state;
      form[name] = value;
      this.setState({
        [name]: value,
        ...validateForm(form)
      })
    } else {
      this.setState({
        [name]: value
      })
    }
  };

  render() {
    return (
        <Col xs={12}>
          <Row>
            <Col xs={12} smHidden mdHidden lgHidden>
              <h3>DEALER CONTACT</h3>
              <div className="nts-dealer-info">{this.props.dealer.name}</div>
              <div className="nts-dealer-info">{this.props.dealer.email}</div>
              <div className="nts-dealer-info">{this.props.dealer.telephone}</div>
              <hr/>
            </Col>
            <Col xs={12} sm={4}>
              <h3>YOUR CONTACT INFO</h3>
              <form onSubmit={this.props.onSubmit} className="nts-basic-form">
                <label>
                  <span className={this.state.errors.firstName ? 'form-error': ''}>{this.state.errors.firstName ? 'Please enter your first name' : 'FIRST NAME'}</span>
                  <input className={this.state.errors.firstName ? 'form-error form-input': 'form-input'} type="text" value={this.state.firstName} name="firstName" onChange={this.validateOnChange} />
                </label>
                <label>
                  <span className={this.state.errors.lastName ? 'form-error': ''}>{this.state.errors.lastName ? 'Please enter your last name' : 'LAST NAME'}</span>
                  <input className={this.state.errors.lastName ? 'form-error form-input': 'form-input'} type="text" value={this.state.lastName} name="lastName" onChange={this.validateOnChange} />
                </label>
                <label>
                  <span className={this.state.errors.email ? 'form-error': ''}>{this.state.errors.email ? 'Please enter your email' : 'EMAIL'}</span>
                  <input className={this.state.errors.email ? 'form-error form-input': 'form-input'} type="text" value={this.state.email} name="email" onChange={this.validateOnChange} />
                </label>
                <label>
                  <span className={this.state.errors.phone ? 'form-error': ''}>{this.state.errors.phone ? 'Please enter a 10-digit number - numbers only' : 'PHONE NUMBER'}</span>
                  <input className={this.state.errors.phone ? 'form-error form-input': 'form-input'} type="text" value={this.state.phone} name="phone" onChange={this.validateOnChange} />
                </label>
                <label>
                  <span className={this.state.errors.city ? 'form-error': ''}>{this.state.errors.city ? 'Please enter your city' : 'CITY'}</span>
                  <input className={this.state.errors.city ? 'form-error form-input': 'form-input'} type="text" value={this.state.city} name="city" onChange={this.validateOnChange} />
                </label>
                <label>
                  <span className={this.state.errors.state ? 'form-error': ''}>{this.state.errors.state ? 'Please select your state' : 'STATE'}</span>
                  <select className={this.state.errors.state ? 'form-error form-input': 'form-input'} value={this.state.state} name="state" onChange={this.validateOnChange}>
                    <option value={''}>Select a state</option>
                    {this.props.states && this.props.states.map((st, i) => (
                        <option key={i} value={st.value}>{st.label}</option>
                    ))}
                  </select>
                </label>
                <label>
                  <span className={this.state.errors.topic ? 'form-error': ''}>{this.state.errors.topic ? 'Please select your topic' : 'SELECT A TOPIC'}</span>
                  <select className={this.state.errors.topic ? 'form-error form-input': 'form-input'} value={this.state.topic} name="topic" onChange={this.validateOnChange}>
                    <option value={''}>Select a topic</option>
                    {topicOptions.map((topic, i) => (
                        <option key={i} value={topic.text}>{topic.text}</option>
                    ))}
                  </select>
                </label>
                <label>
                  <span className={this.state.errors.message ? 'form-error': ''}>{this.state.errors.message ? 'Please enter a message' : 'ADD A MESSAGE'}</span>
                  <textarea className={this.state.errors.message ? 'form-error form-input': 'form-input'} value={this.state.message} name="message" onChange={this.validateOnChange}/>
                </label>
                <button type="button" className="btn btn-primary" onClick={this.handleSubmit}>SEND</button>
              </form>
            </Col>
            <Col xsHidden sm={8}>
              <h3>DEALER CONTACT</h3>
              <div>{this.props.dealer.name}</div>
              <div>{this.props.dealer.email}</div>
              <div>{this.props.dealer.telephone}</div>
            </Col>
          </Row>

        </Col>
    );

  }

}