import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export const CouponBalanceTooltip:React.FC = () => {

    const tooltip = (
        <Tooltip id="tooltip">
            <p>Unfortunately, the coupon balance does not cover the quantity ordered.</p>
            <p>Please either reduce the quantity ordered or remove the embellishments. </p>
            <p>Orders with embellishments include a surcharge, due to the cost of printing.</p>
        </Tooltip>
    )

    return (
        <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={tooltip}>
            <span className="g72-info nts-coupon-bar-info" />
        </OverlayTrigger>
    )

}