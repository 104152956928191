import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

export interface AuthState {
  showLoginModal: boolean;
  headerMessage: string;
  forceReloadOnHide: boolean;
}

const initialState: AuthState = {
  showLoginModal: false,
  headerMessage: '',
  forceReloadOnHide: false
};

interface AuthAction extends Action {
  state: {
    show: boolean;
    message: string;
    forceReload: boolean;
  };
}

const authReducer: Reducer<AuthState> = (
  state = initialState,
  incomingAction
) => {
  const action = incomingAction as unknown as AuthAction;

  switch (action.type) {
    case types.TOGGLE_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: action.state.show,
        headerMessage: action.state.message ? action.state.message : '',
        forceReloadOnHide: action.state.forceReload ? action.state.forceReload : false
      };
  
    default:
      return state;
  }
};

export default authReducer;
