interface FilterItem {
  displayName: string;
  id: string;
}

interface ColorFilter extends FilterItem {
  hex: string;
  border: string;
}

export interface AvailableGridwallFilters {
  categories: {
    [category: string]: FilterItem[];
  };
  filters: {
    sport: FilterItem[];
    gender: FilterItem[];
    kids: FilterItem[];
  };
  special: {
    admin: FilterItem[];
    needByDate: {
      id: string;
    };
    customization: {
      makeItYours: FilterItem[];
      createYourOwn: FilterItem[];
    };
    color: ColorFilter[];
    'sustainable materials': FilterItem[];
    'travel train coach': FilterItem[];
  }
}

const filters: AvailableGridwallFilters = {
  "categories": {
    "uniforms": [
      {
        "displayName": "Tops",
        "id": "hc_UN_Tops"
      },
      {
        "displayName": "Bottoms",
        "id": "hc_UN_Bottoms"
      }
    ],
    "clothing tops": [
      {
        "displayName": "Baselayer and Nike Pro",
        "id": "hc_Baselayer_and_Nike_Pro"
      },
      {
        "displayName": "Short Sleeves",
        "id": "hc_Short_Sleeve"
      },
      {
        "displayName": "Long Sleeves",
        "id": "hc_Long_Sleeve"
      },
      {
        "displayName": "Hoodies and Pullovers",
        "id": "hc_Hoodies_and_Pullovers"
      },
      {
        "displayName": "Sleeveless and Tanks",
        "id": "hc_Sleeveless_and_Tanks"
      },
      {
        "displayName": "Jackets",
        "id": "hc_Jackets"
      },
      {
        "displayName": "Polos",
        "id": "hc_Polos"
      },
      {
        "displayName": "Sports Bras",
        "id": "hc_Sports_Bras"
      }
    ],
    "clothing bottoms": [
      {
        "displayName": "Shorts",
        "id": "hc_Shorts"
      },
      {
        "displayName": "Pants",
        "id": "hc_Pants"
      },
      {
        "displayName": "Tights and Capris",
        "id": "hc_Tights_and_Capris"
      }
    ],
    "gear": [
      {
        "displayName": "Bags",
        "id": "hc_Bags"
      },
      {
        "displayName": "Balls",
        "id": "hc_Balls"
      },
      {
        "displayName": "Belts",
        "id": "hc_Belts"
      },
      {
        "displayName": "Gloves",
        "id": "hc_Gloves"
      },
      {
        "displayName": "Headwear",
        "id": "hc_Headwear"
      },
      {
        "displayName": "Socks",
        "id": "hc_Socks"
      }
    ],
    "graphic tees": [
      {
        "displayName": "Graphic Tee Short Sleeves",
        "id": "hc_GT_Short_Sleeve"
      },
      {
        "displayName": "Graphic Tee Long Sleeves",
        "id": "hc_GT_Long_Sleeve"
      },
      {
        "displayName": "Hoodies and Pullovers",
        "id": "hc_GT_Hoodies_and_Pullovers"
      }
    ],
    "coach gear": [
      {
        "displayName": "Tops",
        "id": "hc_CG_Tops"
      },
      {
        "displayName": "Bottoms",
        "id": "hc_CG_Bottoms"
      },
      {
        "displayName": "Gear",
        "id": "hc_CG_Gear"
      }
    ]
  },
  "filters": {
    "sport": [
      {
        "displayName": "Baseball",
        "id": "sport_Baseball"
      },
      {
        "displayName": "Basketball",
        "id": "sport_Basketball"
      },
      {
        "displayName": "Field Hockey",
        "id": "sport_Field_Hockey"
      },
      {
        "displayName": "Football",
        "id": "sport_Football"
      },
      {
        "displayName": "Lacrosse",
        "id": "sport_Lacrosse"
      },
      {
        "displayName": "Running",
        "id": "sport_Running"
      },
      {
        "displayName": "Soccer",
        "id": "sport_Soccer"
      },
      {
        "displayName": "Softball",
        "id": "sport_Softball"
      },
      {
        "displayName": "Tennis",
        "id": "sport_Tennis"
      },
      {
        "displayName": "Training",
        "id": "sport_Training"
      },
      {
        "displayName": "Volleyball",
        "id": "sport_Volleyball"
      }
    ],
    "gender": [
      {
        "displayName": "Men",
        "id": "gender_Men"
      },
      {
        "displayName": "Women",
        "id": "gender_Women"
      }
    ],
    "kids": [
      {
        "displayName": "Boys",
        "id": "gender_Boys"
      },
      {
        "displayName": "Girls",
        "id": "gender_Girls"
      }
    ]
  },
  "special": {
    "admin": [
        {"displayName": "Show Hidden", "id": "show_hidden"}
    ],
    "needByDate": {
      "id": "shipDate"
    },
    "customization": {
      "makeItYours": [
        {"displayName": "Stock", "id": "uniformProgram_Stock"},
        {"displayName": "Personalized Stock", "id": "uniformProgram_Custom_Stock"}
      ],
      "createYourOwn": [
        {"displayName": "Digital", "id": "uniformProgram_Digital"},
        {"displayName": "DQT", "id": "uniformProgram_DQT"},
        {"displayName": "Custom", "id": "uniformProgram_Custom"}
      ]
    },
    "color": [
      {"displayName": "Black", "id": "color_Black", "hex": "#27292B", "border": ""},
      {"displayName": "Blue Grey / Wolf Grey", "id": "color_Blue_Grey", "hex": "#A2A7AE", "border": ""},
      {"displayName": "Anthracite", "id": "color_Anthracite", "hex": "#3D3F42", "border": ""},
      {"displayName": "Dark Steel Grey", "id": "color_Dark_Steel_Grey", "hex": "#777776", "border": ""},
      {"displayName": "White", "id": "color_White", "hex": "#F0F4FF", "border": "#e5e5e5"},
      {"displayName": "Natural", "id": "color_Natural", "hex": "#F5E5CE", "border": "#e5e5e5"},
      {"displayName": "Brown", "id": "color_Brown", "hex": "#413430", "border": ""},
      {"displayName": "Dark Green / Gorge Green", "id": "color_Dark_Green", "hex": "#2A4F39", "border": ""},
      {"displayName": "Kelly Green", "id": "color_Kelly_Green", "hex": "#367F4F", "border": ""},
      {"displayName": "Aero Blue", "id": "color_Aero_Blue", "hex": "#00699E", "border": ""},
      {"displayName": "Blustery Teal", "id": "color_Blustery_Teal", "hex": "#006C82", "border": ""},
      {"displayName": "College Navy / Navy", "id": "color_Navy", "hex": "#273142", "border": ""},
      {"displayName": "Light Blue / Valor Blue", "id": "color_Light_Blue", "hex": "#6697C8", "border": ""},
      {"displayName": "Royal", "id": "color_Royal", "hex": "#1D5291", "border": ""},
      {"displayName": "Purple", "id": "color_Purple", "hex": "#423A68", "border": ""},
      {"displayName": "Cardinal / Team Maroon", "id": "color_Cardinal", "hex": "#6B2C3C", "border": ""},
      {"displayName": "Crimson", "id": "color_Crimson", "hex": "#85132A", "border": ""},
      {"displayName": "Pinkfire", "id": "color_Pinkfire", "hex": "#D866A6", "border": ""},
      {"displayName": "Scarlet / University Red", "id": "color_Scarlet", "hex": "#B52130", "border": ""},
      {"displayName": "Dark Maroon / Deep Maroon", "id": "color_Dark_Maroon", "hex": "#542A37", "border": ""},
      {"displayName": "Volt", "id": "color_Volt", "hex": "#B9FF4C", "border": ""},
      {"displayName": "Yellow Strike", "id": "color_Yellow_Strike", "hex": "#FFEB47", "border": ""},
      {"displayName": "Bright Gold / Sundown", "id": "color_Bright_Gold", "hex": "#FFA204", "border": ""},
      {"displayName": "Team Gold / Vegas Gold", "id": "color_Vegas_Gold", "hex": "#CDB895", "border": ""},
      {"displayName": "Desert Orange", "id": "color_Desert_Orange", "hex": "#AB5537", "border": ""},
      {"displayName": "Orange / Team Orange", "id": "color_Orange", "hex": "#D9402A", "border": ""},
      {"displayName": "Bright Ceramic", "id": "color_Bright_Ceramic", "hex": "#FD8200", "border": ""}
    ],
    "sustainable materials":  [
      {"displayName":  "Recycled Polyester", "id": "technology_Recycled_Polyester"},
      {"displayName":  "Sustainable Blends", "id": "technology_Sustainable_Blends"}
    ],
    "travel train coach": [
      {"displayName": "Tops", "id": "hc_Tops"},
      {"displayName": "Bottoms", "id": "hc_Bottoms"},
      {"displayName": "Gear", "id": "hc_Gear"},
    ]
  }
};

export default filters;
