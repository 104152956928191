import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

export interface ProductDetailsState {
    age: string;
    bodyType: string;
    category: string;
    colorDescription: string;
    description: string;
    fabricContent: string | null;
    gender: string;
    name: string;
    nameSec: string;
    productFeatures?: string[];
    reasonToBuy: string;
    searchGender: string;
    silhouette: string;
    type: string;
}

// TODO: These actions can be separate types later
interface ProductDetailsAction extends Action {
    details: {
        age: string;
        category: string;
        gender: string;
        silhouette: string;
        languages: {
            colorDescription: string;
            description: string;
            fabricContent: string;
            name: string;
            nameSec: string;
            productFeatures: string[];
            reasonToBuy: string;
        }[];
        wholesalePrice: number;
        retailPrice: number;
        type: string;
        searchGender: string;
    };
}

const defaultState: ProductDetailsState = {
    age: '',
    bodyType: '',
    category: '',
    colorDescription: '',
    description: '',
    fabricContent: '',
    gender: '',
    name: '',
    nameSec: '',
    reasonToBuy: '',
    searchGender: '',
    silhouette: '',
    type: ''
};

const productDetailsReducer: Reducer<ProductDetailsState> = (
    state = defaultState,
    incomingAction,
) => {
    const action = incomingAction as unknown as ProductDetailsAction;
    switch (action.type) {
        case types.LOAD_PRODUCT_DETAILS_ATONCE:
            return {
                ...state,
                age: (action.details.age) ? action.details.age : '',
                bodyType: action.details.silhouette,
                category: action.details.category,
                colorDescription: action.details.languages[0].colorDescription,
                description: action.details.languages[0].description,
                fabricContent: action.details.languages[0].fabricContent
                    ? action.details.languages[0].fabricContent.toLowerCase()
                    : null,
                gender: action.details.gender,
                name: action.details.languages[0].name,
                nameSec: action.details.languages[0].nameSec,
                productFeatures: action.details.languages[0].productFeatures,
                reasonToBuy: action.details.languages[0].reasonToBuy,
                retailPrice: action.details.retailPrice,
                searchGender: action.details.searchGender,
                silhouette: action.details.silhouette,
                type: action.details.type,
                wholesalePrice: action.details.wholesalePrice,
            };

        case types.CLEAR_PRODUCT_FOR_PDP:
            return {
                ...defaultState,
            };

        default:
            return state;
    }
};

export default productDetailsReducer;
