import * as React from 'react';
import {Button} from 'react-bootstrap';

import {emailValidator} from '../../helpers/textValidationHelpers';

interface EmailFormErrors {
    from: boolean;
    to: boolean;
    message: boolean;
}

export interface EmailFormData {
    from: string;
    to: string;
    message: string;
}

const initialData: EmailFormData = {
    from: '',
    to: '',
    message: '',
};

const initialErrors: EmailFormErrors = {
    to: false,
    from: false,
    message: false
}

interface Props {
    onCancel: () => void;
    onSendEmail: (formData: EmailFormData, elementIds: string[]) => void;
    selectedElementIds: string[];
    error: string;
}

export const EmailForm: React.FC<Props> = ({
    onCancel,
    onSendEmail,
    selectedElementIds,
    error
}) => {
    const [formData, setFormData] = React.useState(initialData);
    const [formErrors, setFormErrors] = React.useState(initialErrors);

    const noneSelected = selectedElementIds.length === 0;
    const formDataInvalid = noneSelected || !emailValidator(formData.from)
        || !emailValidator(formData.to) || formData.message.length === 0;

    return (
        <div className="action-row">
            <div>
                Please select the desired locker items to email.
            </div>
            <input
                type="email"
                placeholder="From"
                value={formData.from}
                onChange={(e) => setFormData({ ...formData, from: (e.target as HTMLInputElement).value })}
                onBlur={(e) => setFormErrors({ ...formErrors, from: !emailValidator(formData.from)})}
                className={`email-form-input ${formErrors.from && 'error'}`}
                maxLength={50}
            />
            <input
                type="email"
                placeholder="To"
                value={formData.to}
                onChange={(e) => setFormData({ ...formData, to: (e.target as HTMLInputElement).value })}
                onBlur={(e) => setFormErrors({ ...formErrors, to: !emailValidator(formData.to)})}
                className={`email-form-input ${formErrors.to && 'error'}`}
                maxLength={50}
            />
            <textarea
                placeholder="Message"
                value={formData.message}
                onChange={(e) => setFormData({ ...formData, message: (e.target as HTMLTextAreaElement).value })}
                onBlur={(e) => setFormErrors({ ...formErrors, message: formData.message.length < 1 })}
                className={`email-form-input email-message-input ${formErrors.message && 'error'}`}
                maxLength={200}
            />
            {error && <div style={{color:'red'}}>Email unsuccessful. Please try again.</div>}
            <div className="button-row">
                <Button
                    bsStyle="primary"
                    disabled={formDataInvalid}
                    onClick={() => onSendEmail(formData, selectedElementIds)}
                >
                    email
                </Button>
                <Button bsStyle="default" onClick={onCancel}>cancel</Button>
            </div>
        </div>
    );
};
