import * as React from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Row, Col} from 'react-bootstrap';

import {AppState} from '../../reducers';
import {Filter} from '../../reducers/lockerReducer';
import {PAGE_COUNT} from '../../api/services/lockerService';
import {resetLockerPage, setPageStart} from '../../actions/lockerActions';
import {LockerGrid} from './lockerGrid';
import {LockerActionRow} from './lockerActionRow';
import {useGetLocker, useIntersectionObserver} from './hooks';

interface StateProps {
    fetchLockerItems: boolean;
    isLoading: boolean;
    lockerItemCount: number;
    lockerState: AppState['lockerState'];
    selectedFilter: Filter | null;
    totalRecords: number;
}

interface DispatchProps {
    dispatchSetPageStart: (pageStart: number) => void;
    dispatchResetLockerPage : () => void;
}

type Props = StateProps & DispatchProps;

const LockerPageInner: React.FC<Props> = ({
    dispatchResetLockerPage,
    dispatchSetPageStart,
    fetchLockerItems,
    isLoading,
    lockerItemCount,
    lockerState,
    selectedFilter,
    totalRecords,
}) => {
    const { getLocker } = useGetLocker();
    const loadMoreRef = React.useRef<HTMLDivElement | null>(null);
    const entry = useIntersectionObserver(loadMoreRef);

    // Loads locker items on first render
    // and fetchLockerItems flag is true
    React.useEffect(() => {
        if (fetchLockerItems) {
            getLocker(lockerState.pageStart, selectedFilter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchLockerItems]);

    // Setup infinite scroll listener
    React.useEffect(() => {
        if (!isLoading && !!entry && entry.isIntersecting && lockerItemCount < totalRecords) {
            dispatchSetPageStart(lockerState.pageStart + PAGE_COUNT);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entry?.isIntersecting, isLoading, lockerItemCount, totalRecords]);

    // Clear locker page state when leaving the page
    React.useEffect(() => {
        return () => dispatchResetLockerPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container nts-locker-page">
            <Row>
                <Col xs={12}>
                    <div className="title">locker</div>

                    <LockerActionRow />

                    {!isLoading && lockerItemCount === 0 ? (
                        <div className="locker-empty">
                            Your locker is currently empty. Please save desired products to locker.
                        </div>
                    ): (
                        <LockerGrid items={lockerState.lockerItems} />
                    )}

                    <div className="load-more-target" ref={loadMoreRef}></div>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    fetchLockerItems: state.lockerState.fetchLockerItems,
    isLoading: state.loadingSpinnerState.isLoading,
    lockerItemCount: state.lockerState.lockerItems.length,
    lockerState: state.lockerState,
    selectedFilter: state.lockerState.selectedFilter,
    totalRecords: state.lockerState.totalRecords,
});

const mapDispatchToProps = (dispatch: Dispatch<unknown>): DispatchProps => ({
    dispatchSetPageStart: (pageStart) => dispatch(setPageStart(pageStart)),
    dispatchResetLockerPage: () => dispatch(resetLockerPage()),
})

export const LockerPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LockerPageInner);
