import React from 'react';
import {connect} from 'react-redux';
import {Modal, Button, ButtonToolbar} from 'react-bootstrap';

import {AppState} from '../../reducers';
import {Spacer} from '../shared/spacer';
import {hideMessageModal} from '../../actions/messageModalActions';

interface StateProps {
  data: AppState['messageState']['messageModal'];
}

class MessageModalComponent extends React.Component<StateProps> {

  constructor(props: StateProps) {
    super(props);
    this.onHide = this.onHide.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onHide() {
    if (this.props.data && this.props.data.onHide) {
      this.props.data.onHide();
    }
    hideMessageModal();
  }

  onClick() {
    this.onHide();
    if (this.props.data && this.props.data.onClick) {
      this.props.data.onClick();
    }
    if (this.props.data && this.props.data.callback) {
      this.props.data.callback();
    }
  }

  onCancel() {
    this.onHide();
    if (this.props.data && this.props.data.handleCancel) {
      this.props.data.handleCancel();
    } 
  }

  render() {
    let buttonStyle = {
      width: "190px"
    };

    return (
      <div>
        {this.props.data &&
          <Modal className="show nts-message-modal" show={true} onHide={this.onHide} bsSize="large" aria-labelledby="contained-modal-title-lg">
            
            <Modal.Header closeButton>
              {this.props.data.header}
            </Modal.Header>
  
            <Modal.Body style={{height: "100%", overflow: "auto"}}>
              <p className={this.props.data.required ? 'required' : ''}>{this.props.data.message}</p>
                <Spacer size={10}/>
                <ButtonToolbar>
                  <Button type="submit" style={buttonStyle} bsStyle="primary" onClick={this.onClick}>{this.props.data.buttonText ? this.props.data.buttonText : "OK"}</Button>
                  {this.props.data.cancelable &&
                    <Button type="button" style={buttonStyle} bsStyle="default" data-dismiss="modal" onClick={this.onCancel}>CANCEL</Button>
                  }
                </ButtonToolbar>
            </Modal.Body>
            
          </Modal>
        }
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    data: state.messageState.messageModal,
  };
};

export const MessageModal = connect(
  mapStateToProps
)(MessageModalComponent);
