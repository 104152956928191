import {LockerItem, ActionMode, Set, Sport, Filter} from '../reducers/lockerReducer';
import * as types from './actionTypes';

export const appendLockerItems = (items: LockerItem[], totalRecords: number) => ({
    type: types.APPEND_LOCKER_ITEMS,
    lockerItems: items,
    totalRecords,
});

export const loadFilters = (sets: Set[], sports: Sport[]) => ({
    type: types.LOAD_FILTERS,
    sets,
    sports,
});

export const toggleSelectLockerItem = (elementId: string) => ({
    type: types.TOGGLE_SELECT_LOCKER_ITEM,
    elementId,
});

export const setActionMode = (actionMode: ActionMode) => ({
    type: types.SET_ACTION_MODE,
    actionMode,
});

export const setPageStart = (pageStart: number) => ({
    type: types.SET_PAGE_START,
    pageStart,
});

export const setSelectedFilter = (filter: Filter | null) => ({
    type: types.SET_SELECTED_FILTER,
    filter,
});

export const setLockerItemImage = (elementId: string, imageUrl: string) => ({
    type: types.SET_LOCKER_ITEM_IMG,
    elementId,
    imageUrl
})

export const resetLockerPage = () => ({
    type: types.RESET_LOCKER_PAGE,
});
