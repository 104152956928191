import * as React from "react";
import { Col, Modal, Row, DropdownButton, MenuItem } from "react-bootstrap";
import { AddFactoryState } from "./adminFactoryHelper";
import { useForm } from 'react-hook-form';
import { AdminFactoryService } from "../../../api/services/adminFactoryService";
import { showMessageModal } from "../../../actions/messageModalActions";
import './addFactoryPage.scss';

interface Props {
  state: AddFactoryState,
  onHide: () => void,
  onSubmitSuccess: () => void,
}

export const AddFactory: React.FC<Props> = ({
  state,
  onHide,
  onSubmitSuccess,
  
}) => {
  const trapSpacesForRequiredFields =  (data: any) => {
    Object.keys(data).forEach(key => {
      if(typeof data[key] === "string") {
        data[key]=data[key].trim();
      }
    })
  }

  const [disableState, setDisableState] = React.useState(false);
  const communicationModes = ['EDI', 'API'];
  const [selectedCommunicationMode, setSelectedCommunicationMode] = React.useState('EDI')
  const { register, setValue, handleSubmit, formState: { errors }, reset } = useForm();
  React.useEffect(() => {
    if (state.show) {
      setDisableState(false);
      reset()
      if(state.edit) {
        const {name, code, priority,  fcr_comm_cd, fcr_supplier_id, fhs_fcr_cd} = state.item;
        setValue('name', name);
        setValue('code', code);
        setValue('priority', priority);
        setSelectedCommunicationMode(fcr_comm_cd)
        setValue('supplierID', fcr_supplier_id);
        setValue('fhsCode', fhs_fcr_cd);
      }
    }
  }, [state.show])

  const onCloseModal = () => {
    setSelectedCommunicationMode('EDI');
    onHide();
  }

  const onSubmit =async (formData: any) => {
    const {['supplierID']: _, ['fhsCode']:__,  ...data} = formData;
    data['fcr_supplier_id']=formData['supplierID'];
    data['fhs_fcr_cd']=formData['fhsCode'];
    data['fcr_comm_cd'] = selectedCommunicationMode;
    trapSpacesForRequiredFields(data);
    setDisableState(true);
    if(state.edit) {      
      AdminFactoryService.updateFactory(state.item.id, data).then((response)=>{
        setDisableState(false);
        setSelectedCommunicationMode('EDI');
        onSubmitSuccess()
      }).catch((err)=>{ 
        setDisableState(false);
        // dispatchShowToast(err.message);
        showMessageModal({ header: "UPDATE FACTORY ERROR", message: err.message, required: true });
      });
    }else{
      AdminFactoryService.addFactory(data).then((response)=>{
        setDisableState(false);
        setSelectedCommunicationMode('EDI');
        onSubmitSuccess()
      }).catch((err)=>{ 
        setDisableState(false);
        // dispatchShowToast(err.message);
        showMessageModal({ header: "ADD FACTORY ERROR", message: err.message, required: true });
      }); 
    }    
  };

  const onChangeCommunicationMode = (mode:string) => {
    setValue('supplierID', '');
    setValue('fhsCode', '');
    setSelectedCommunicationMode(mode);
  }

  const renderErrorMessage = (message: string) => {
    return <div className="required">{message}</div>
  }

  const handleSupplierIDChange = (e:any) => {
    const inputValue= e.target.value;
    if(inputValue < 0) {
      setValue('supplierID', '');
    }
  }

  const validateFHSCodeLength = (v:any) => {
    if(selectedCommunicationMode === 'API') {
      return !!v && v.length<10
    }
    return true;
  }

  console.log('errors:', errors);
  const { name: nameErr, code: codeErr, fhsCode: fhsCodeErr, supplierID: supplierIDErr } = errors;
  return (
    <Modal className="show nts-terms-modal nts-factory-modal"
      backdrop="static"
      keyboard={false}
      onHide={onCloseModal}
      show={state.show}
      bsSize={"medium"} aria-labelledby={"contained-modal-title-lg"}>

      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-lg">
          {state.edit ? 'UPDATE' : 'ADD'} FACTORY
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-left">
        <form className="nts-basic-form nts-profile-form add-factory-form" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12} sm={12}>
              <div className="nts-basic-form-section form-section-spacer">
                <label>
                  <span>Factory Name <span className="required">*</span></span>
                  <input type="text"
                    className={`form-input`}
                    {...register("name", {
                      required: true,
                      validate: {
                        isNameEmpty: v => !!v.trim(),
                        minLengthTwo: v => v.trim().length>=2,
                        maxLengthThirty: v => v.length<30,
                        patternMatch: v => /^[A-Z0-9a-z\s-.]+$/i.test(v)
                      }                         
                    })}
                  />
                  {(nameErr?.type === "required" || nameErr?.type === "isNameEmpty" ) && renderErrorMessage("Factory name is required")}
                  {nameErr?.type === "minLengthTwo" && renderErrorMessage("Must be more than 1 characters")}
                  {nameErr?.type === "maxLengthThirty" && renderErrorMessage("No longer than 30 characters")}
                  {nameErr?.type === "patternMatch" && renderErrorMessage("Only A-Z, a-z, 0-9, period, space and dash allow")}
                </label>

              </div>
            </Col>
            <Col xs={12} sm={12}>
              <div className="nts-basic-form-section form-section-spacer">
                <label>
                  <span>Factory Code <span className="required">*</span></span>
                  <input type="text"
                    {...register("code", {
                      required: true,
                      validate: {
                        isCodeEmpty: v => !!v.trim(),
                        minLengthTwo: v => v.length>=2,
                        patternMatch: v => /^[A-Z0-9a-z]+$/i.test(v)
                      }    
                    })}
                    className={`form-input`}
                  />

                  {(codeErr?.type === "required" || codeErr?.type === "isCodeEmpty" ) && renderErrorMessage("Factory code is required")}
                  {codeErr?.type === "minLengthTwo" && renderErrorMessage("Must be more than 1 characters")}
                  {codeErr?.type === "patternMatch" && renderErrorMessage("Only A-Z, a-z, and 0-9 allowed")}
                </label>

              </div>

            </Col>
            <Col xs={6} sm={6}>
              <div className="nts-basic-form-section form-section-spacer">
                <label>
                  <span>Factory Priority </span>
                  <input type="number"
                    {...register("priority", {
                      required: false,
                    })}
                    className={`form-input`}
                  />
                </label>
              </div>
            </Col>
            <Col xs={6} sm={6}>
              <div className="nts-basic-form-section form-section-spacer">
                <label>
                  <span>Communication Mode</span>
                  <DropdownButton  id="communicationMode" title={selectedCommunicationMode}>
                    {
                      communicationModes.map(mode => {
                        return (
                          <MenuItem onClick={() => onChangeCommunicationMode(mode)} id={mode} key={mode}>
                            <span>{mode}</span>
                          </MenuItem>
                        )
                      })
                    }
                  </DropdownButton>
                </label>
              </div>
            </Col>
            <Col xs={6} sm={6}>
              <div className="nts-basic-form-section form-section-spacer">
                <label>
                  <span>Supplier ID {selectedCommunicationMode === 'API' && <span className="required">*</span>}</span>
                  <input type="number"
                    {...register("supplierID", {
                      required: selectedCommunicationMode === 'API',
                      
                    })}
                    onChange= {handleSupplierIDChange} 
                    className={`form-input`}
                    disabled={selectedCommunicationMode === 'EDI'}
                  />
                  {supplierIDErr?.type === "required" && renderErrorMessage("Supplier ID is required")}
                </label>
              </div>
            </Col>
            <Col xs={6} sm={6}>
              <div className="nts-basic-form-section form-section-spacer">
                <label>
                  <span>FHS Code {selectedCommunicationMode === 'API' && <span className="required">*</span>}</span>
                  <input type="text"
                    className={`form-input`}
                    disabled={selectedCommunicationMode === 'EDI'}                       
                    {...register("fhsCode", {
                      required: selectedCommunicationMode === 'API',
                      validate: {
                        maxLengthTen: validateFHSCodeLength,
                      }                         
                    })}
                    maxLength={3}
                  />
                  {fhsCodeErr?.type === "required" && renderErrorMessage("FHS code is required")}
                  {fhsCodeErr?.type === "maxLengthTen" && renderErrorMessage("No longer than 10 characters")}
                </label>

              </div>
            </Col>
            <Col xs={12}>
              <div className="nts-profile-req-container"><span className="required">*</span> Required Fields</div>
            </Col>
          </Row>
          <div className={'container-modal text-right'}>
            <button type="button" className={'pdp-cart-btn btn btn-default mr-15'} onClick={onCloseModal}>
              Cancel
            </button>
            <button className={'pdp-cart-btn btn btn-primary'} disabled={disableState} type="submit">
              {state.edit ? 'UPDATE' : 'ADD'}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )

}
