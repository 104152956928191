import * as React from 'react';

import {LockerItem} from '../../reducers/lockerReducer';
import {GridItem} from './gridItem';

interface Props {
    items: LockerItem[];
}

export const LockerGrid: React.FC<Props> = (props) => {
    return (
        <div className="grid-wrapper">
            {props.items.map(item => <GridItem key={item.elementId} item={item} />)}
        </div>
    );
};
