import * as React from 'react';
import {FormControl, Button} from 'react-bootstrap';

interface Props {
    onCancel: () => void;
    onCreateSet: (name: string, elementIds: string[]) => void;
    selectedElementIds: string[];
}

export const CreateSetForm: React.FC<Props> = ({
    onCancel,
    onCreateSet,
    selectedElementIds,
}) => {
    const [setName, setSetName] = React.useState('');

    const noneSelected = selectedElementIds.length === 0;
    const createSetInvalid = noneSelected || setName.length === 0;

    return (
        <div className="action-row">
            <div>
                Please select the desired locker items to assign to this set.
            </div>
            <FormControl
                type="text"
                placeholder="Enter set name"
                value={setName}
                onChange={(e) => setSetName((e.target as HTMLInputElement).value)}
                bsClass="set-name-input"
                bsSize="lg"
                maxLength={30}
            />
            <div className="button-row">
                <Button
                    bsStyle="primary"
                    disabled={createSetInvalid}
                    onClick={() => onCreateSet(setName, selectedElementIds)}
                >
                    create
                </Button>
                <Button bsStyle="default" onClick={onCancel}>cancel</Button>
            </div>
        </div>
    );
};
