//NOTE: Lifted from template tool, but heavily mdified. Can not copy/paste updates between the apps.

import {decorateUrlWithCacheBuster} from "./imageUploadHelpers";
import {isDynamoUUID} from "./designHelpers";
import {DEFAULT_SCENE7_LOGO} from "./constants";

export const scene7Env = (
  window.location.hostname === 'localhost' ||
  window.location.hostname === 'localhost.nikedev.com' ||
  window.location.hostname === '127.0.0.1' ||
  window.location.hostname === 'ntsadmin-dev.nike.net' ||
  window.location.hostname === 'ntsqa.nikedev.com' ||
  window.location.hostname === 'ntsstage.nike.com'
) ?
  'GTMDEMO' :
  'GTM';
export const baseUrl = `http://images.nike.net/is/image/${scene7Env}/`;

let hasTextComponent = false;
let hasLogoComponent = false;
let hasGraphicComponent = false;

export const buildUrl = (text, logo, graphic, widthRatio=1, heightRatio=1 ) => {
  const baseCGUrl = `${baseUrl}base_t_cg?$layer_0_opac=0&$layer_0_size=${1000 * widthRatio},${1000 * heightRatio}&$PNG%20-%20Large%20size%20-%20High%20resolution$`;
  return decorateUrlWithCacheBuster(baseCGUrl + text + logo + graphic);
};

export function buildPosition (obj, layerName, widthRatio=1, heightRatio=1) {
  let path = '';
  if (obj.rotationDegrees) {
    path += `&${layerName}_rotate=${obj.rotationDegrees}`;
  }
  if (obj.width && obj.height) {
    const convertedWidth = Math.round(obj.width * (1000 / 13) * widthRatio);
    const convertedHeight = Math.round(obj.height * (1000 / 13) * heightRatio);
    path += `&${layerName}_size=${convertedWidth},${convertedHeight}`;
  }
  // Using isNaN to accept 0 as value
  if (!isNaN(obj.positionX) && !isNaN(obj.positionY)) {
    path += `&${layerName}_pos=${obj.positionX * widthRatio},${obj.positionY * heightRatio}`;
  }
  return path;
}

export function buildImages (obj, availableItems, layerName) {
  const available = obj[availableItems];

  if (available.length > 0) {

    const currOrLast = obj.availableGraphics[0];
    let url = currOrLast && currOrLast.data.image && currOrLast.data.image.url;

    const hasColorOptions =
      currOrLast &&
      currOrLast.data &&
      currOrLast.data.availableColors &&
      currOrLast.data.availableColors.length > 0;

    const itemColor = hasColorOptions ? currOrLast.data.availableColors.filter(c => c.selected) : '';
    if(itemColor[0] && itemColor[0].name)
    {
      let friendlyImageName = currOrLast.name.replace(/\s/g, "-");
      if(friendlyImageName.replace(/\s/g,'') === "Just-Do-It") friendlyImageName = "JDI";
      const friendlyColorName = itemColor[0].name.replace(/\s/g, "-");
      url = friendlyImageName + "-" + friendlyColorName;
    }

    if (url) {
      return `&${layerName}_src=${url}`;
    }
  }
  return '';
}

export function buildLogoPath (obj, currIndex, widthRatio, heightRatio) {
  let level = currIndex + 1;
  let path = '&$l' + level + '_hide=0';
  path += `&$l` + level + `_src=${scene7Env}/`;
  if(obj.name && obj.artworkId && isDynamoUUID(obj.artworkId)) {
    path += obj.name; // Consumer Logo
  } else if(obj.name && obj.artworkId) {
    path += obj.artworkId + "_" + obj.name; // Dealer Logo
  } else {
    path += DEFAULT_SCENE7_LOGO; // Placeholder Logo
  }
  path += buildPosition(obj, `$l` + level, widthRatio, heightRatio);
  return path;
}

export function buildGraphicPath (obj, currIndex, widthRatio, heightRatio) {
  const hasGraphics =
    obj &&
    obj.availableGraphics &&
    obj.availableGraphics.length > 0;
  let path = (hasGraphics) ? `&$g${currIndex + 1}_hide=0` : '';
  path += buildImages(obj, 'availableGraphics', `$g${currIndex + 1}`);
  path += buildPosition(obj, `$g${currIndex + 1}`, widthRatio, heightRatio);
  return path;
}

//TODO: Remove totalColors, but we're passing in null there all over, so we need to clean up the calls to this function too.
export function updateUrlPath (componentsArr, totalColors, isCustomGraphic, width=1000, height=1000) {

  // Compute the ratio we need to modify the size and positions of the graphics by so we can call scene76 with the size
  // of the final image we want to show.
  let widthRatio = width / 1000;
  let heightRatio = height / 1000;

  let graphicComponentIndex = 0;
  let logoComponentIndex = 0;
  hasTextComponent = false;
  hasLogoComponent = false;
  hasGraphicComponent = false;
  // Reset url
  let tempTextPath = '';
  let tempLogoPath = '';
  let tempGraphicPath = '';
  [...componentsArr].forEach((obj) => {
    if (obj.type === 'logo' && !obj.optOut && (!obj.url || isScene7Url(obj.url))) {
      tempLogoPath += buildLogoPath(obj, logoComponentIndex, widthRatio, heightRatio);
      logoComponentIndex++;
      hasLogoComponent = true;
    }

    if (obj.type === 'graphic') {
      tempGraphicPath += buildGraphicPath(obj, graphicComponentIndex, widthRatio, heightRatio);
      graphicComponentIndex++;
      hasGraphicComponent = true;
    }
  });

  if (!hasTextComponent) {
    tempTextPath += `&$t1_hide=1`;
  }
  if (!hasLogoComponent) {
    tempLogoPath = `&$l1_hide=1`;
  }
  if (!hasGraphicComponent) {
    tempGraphicPath += `&$g1_hide=1&&$g2_hide=1`;
  }

  // Build Url back up
  return buildUrl(tempTextPath, tempLogoPath, tempGraphicPath, widthRatio, heightRatio);
}

/**
 * Test for Scene7 Urls
 * Used to determine artwork related storage location
 * @param url
 * @returns {*|boolean}
 */
export const isScene7Url = (url) => {
  return (url && url.indexOf('images.nike.net') > -1);
};