import {GRID_ITEM_FLAG_RECYCLED_MATERIALS, GRID_ITEM_FLAG_SUSTAIN_BLENDS} from "./constants";

const BASE_NIKE_SIZE_GUIDE_URL = 'https://www.nike.com/size-fit';

export function isStockProduct(product) {
  return product && product.uniformProgram && product.uniformProgram.toLocaleLowerCase().indexOf("stock") > -1;
}

export function isCustomStockProduct(product) {
  return product && product.uniformProgram && product.uniformProgram.toLocaleLowerCase().replace(/\s/g, '').indexOf("customstock") > -1;
}

/**
 * Determines if product data is sufficient for being added to a cart
 * @param product
 * @returns {*|boolean}
 */
export const hasProductDetails = (product) => {
  return product.styleNumber &&
         product.details &&
         Object.keys(product.details).length > 0;
};

/**
 * Inspects product details to determine default style guide to present to user based off gender/product
 * @param product - AtOnce Product Details Svc Object
 * @returns {string}
 */
export function getNikeStyleGuideUrl(product) {
  let nikeSizeGuide = '';
  if(product.age.toUpperCase() === 'CHILD') {
    if(product.category === 'FOOTBALL_SOCCER') {
      nikeSizeGuide = 'kids-soccer-clothing';
    } else {
      nikeSizeGuide = 'little-kids-clothing';
    }
  } else if (product.age.toUpperCase() === 'YOUTH') {
    if(getProductGender(product) === 'GIRLS') {
      nikeSizeGuide = 'girls-clothing';
    } else {
      nikeSizeGuide = 'boys-clothing';
    }
  } else if(product.type.toUpperCase() === 'EQMT') {
    nikeSizeGuide = product.bodyType && product.bodyType.toUpperCase() === 'SOCK'
        ? 'unisex-socks'
        : 'american-football-gloves';
  } else if (product.bodyType.toUpperCase() === 'BOTTOM' || product.silhouette.toUpperCase() === 'TIGHT') {
    if(getProductGender(product) === 'WOMENS') {
      nikeSizeGuide = 'womens-bottoms-alpha';
    } else {
      nikeSizeGuide = 'mens-bottoms-alpha';
    }
  } else {
    if(getProductGender(product) === 'WOMENS') {
      nikeSizeGuide = 'womens-tops-alpha';
    } else {
      nikeSizeGuide = 'mens-tops-alpha';
    }
  }
  return `${BASE_NIKE_SIZE_GUIDE_URL}/${nikeSizeGuide}`;
}

/**
 * Determines gender for given product
 * @param product
 * @returns {*}
 */
function getProductGender(product) {
  if (product.searchGender && product.searchGender[0]) {
    return product.searchGender[0].toUpperCase();
  } else {
    return product.gender.toUpperCase();
  }
}

/**
 * Determines image to use for a given Personalize Tile on the PDP
 * @param product
 * @param templateSide
 * @param style
 * @returns {string}
 */
export function getPersonalizeIcon(product, templateSide, style) {
  const { isUniform, isGraphicTee, isHeadwear} = getProductCategoryFlags(style.details.hcs);
  if(templateSide) {
    const side = templateSide.replace(/ +/g, "").toLowerCase();
    if (product && product.type && (product.gender || product.searchGender)) {
      if (isHeadwear) {
        return 'personalize-tile-accessories.png';
      } else {
        const typeSuffix = (isUniform && !isGraphicTee) ? 'uniform' : 'graphic';
        let gender = 'mens';
        if(product.searchGender && product.searchGender[0]) {
          gender = getPersonalizeIconGender(product.searchGender[0]);
        } else {
          gender = getPersonalizeIconGender(product.gender);
        }
        return `personalize-tile-${gender}-${side}-${typeSuffix}.png`;
      }
    } else {
      return `personalize-tile-mens-${side}-graphic.png`;
    }
  } else {
    return `personalize-tile-mens-front-graphic.png`;
  }
}

/**
 * Groups by gender to help select between personalize icons
 * @param gender
 * @returns {string}
 */
function getPersonalizeIconGender(gender) {
  let iconGndr = "";
  switch (gender.toLowerCase()) {
    case "boys":
    case "mens":
    case "youth":
      iconGndr =  "mens";
      break;
    case "girls":
    case "womens":
      iconGndr =  "womens";
      break;
    default:
      iconGndr = "mens";
      break;
  }
  return iconGndr;
}

/**
 * Determines category for a Stock Product (uniform vs graphic tee)
 * Origin Story: https://www10.v1host.com/NIKE01a/story.mvc/Summary?oidToken=Story%3A2520175
 * @param hcs
 */
export function getProductCategoryFlags(hcs) {
  const UNIFORM_PREFIX = 'un ';
  const GRAPHIC_TEE_PREFIX = 'gt ';
  const HEADWEAR_PREFIX = 'headwear';
  const hcsArray = Object.values(hcs)[0];
  let categories = [];
  if (typeof(hcsArray) === 'string') {
    categories.push(hcsArray);
  } else {
    hcsArray.forEach(h => categories.push(h));
  }
  const hasUniformCategory = (categories.findIndex((c) => {return c.toString().toLowerCase().startsWith(UNIFORM_PREFIX)}) > -1);
  const hasGraphicTeeCategory = (categories.findIndex((c) => {return c.toString().toLowerCase().startsWith(GRAPHIC_TEE_PREFIX)}) > -1);
  const hasHeadwearCategory = (categories.findIndex((c) => {return c.toString().toLowerCase().startsWith(HEADWEAR_PREFIX)}) > -1);
  return { isUniform: hasUniformCategory, isGraphicTee: hasGraphicTeeCategory, isHeadwear: hasHeadwearCategory };
}

/**
 * Determines if given item is made of Sustainable Materials
 * @param product
 * @returns {*|boolean}
 */
export const isSustainableItemType = (product) => {
  return product && product.technology &&
      (product.technology.replace(/\s+/g, '').includes(GRID_ITEM_FLAG_SUSTAIN_BLENDS)
          || product.technology.replace(/\s+/g, '').includes(GRID_ITEM_FLAG_RECYCLED_MATERIALS));
};
