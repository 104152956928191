import React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {isMobile} from 'react-device-detect';

import logo from '../../../img/SVG/NIKE_TEAM_Mobile.svg';
import scheelsLogo from '../../../img/SVG/SCHEELS-NIKE-TEAM_Square.svg';

// components
import {Image} from 'react-bootstrap';
import {NtsLink} from '../shared/ntsLink';
import {AccountOptions} from './menuOptions/accountOptions';
import {AdminOptions} from './menuOptions/adminOptions';
import {MensOptions} from './menuOptions/mensOptions';
import {WomensOptions} from './menuOptions/womensOptions';
import {KidsOptions} from "./menuOptions/kidsOptions";
import SearchBar from './searchBar';
import {CouponBar} from "../coupons/bar/couponBar";

// services + helpers
import {handleLogout} from "../../actions/userProfileActions";
import {navigateToCartPage} from "../../helpers/navbarHelpers";
import {
  dispatchDisplayCouponLookupModal,
  dispatchLoadCouponDetails
} from "../../actions/couponActions";
import {isCouponBarSupportedPage} from "../../helpers/couponHelpers";
import {hasAdminMenu, getUserEmail} from '../../helpers/userProfileHelpers';
import {isRetailExperience} from "../../helpers/retailHelpers";
import {setPreloginUrl} from "../../../auth/utils/helpers";
import {authService} from "../../../index";

const MOBILE_NAV_OPTS = {
  MAIN: "MAIN",
  MEN: "MEN",
  WOMEN: "WOMEN",
  KIDS: "KIDS",
  ACCOUNT: "ACCOUNT",
  ADMIN: "ADMIN"
};

let timeout;

class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMobileNav: null,
      navIsOpen: false,
      dropdownState: 0
    }
  }

  componentDidMount(){
    this.forceHideDropdown();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    //Hide coupon bar (needed for transitioning between Angular and React pages)
    if(!isCouponBarSupportedPage(nextProps.match.location)) {
      dispatchLoadCouponDetails({});
    }
  }

  handleLinkHover = (e) => {

    if(isMobile) {
      return false;
    }

    const target = e.target.id;
    const callback = this.showDropdown;
    // Create 500 millisecond delay to prevent showing menu when user is just passing over link
    timeout = setTimeout(function() {callback(target)}, 250);
  };

  //The dropdown state uses an int code for the context menu
  //I was going to use an enum, but jumping through two hoops seemed excessive
  //0 is hidden, the rest are display, with content encoded 1-4
  showDropdown = (menuItem) => {
    switch (menuItem) {
      case "men-menu-toggle":
        this.setState({dropdownState: 1});
        break;

      case "women-menu-toggle":
        this.setState({dropdownState: 2});
        break;

      case "kids-menu-toggle":
        this.setState({dropdownState: 3});
        break;

      default:
        this.setState({dropdownState: 0});
    }
  };

  hideDropdown = (e) => {
    clearTimeout(timeout);
    if(e.relatedTarget.id === "nav-dropdown" || e.relatedTarget.id === "nav-bar" || e.relatedTarget.id === "primary-nav"){
      return;
    } else if(e.relatedTarget && e.relatedTarget.classList && e.relatedTarget.classList.contains('nts-nav-bar-item') && e.relatedTarget.href) {
      // Workaround for mobile click emulation, stops propogration of other click handlers (behind nav element)
      window.location = e.relatedTarget.href;
      return false;
    }
    this.setState({dropdownState: 0});
  };

  forceHideDropdown = () => {
    this.setState({dropdownState: 0});
  };

  changeMobileNav = (menuToOpen) => {
    this.setState({activeMobileNav: menuToOpen})
  };

  toggleMobileNav = () => {
    let newState = {navIsOpen: !this.state.navIsOpen};
    if(!this.state.navIsOpen) {
      newState.activeMobileNav = MOBILE_NAV_OPTS.MAIN;
      // TODO: Once entire app is in react, find a redux way to do this
      document.body.style.overflow = "hidden";
    } else {
      // TODO: Once entire app is in react, find a redux way to do this
      document.body.style.overflow = "";
    }
    this.setState(newState)
  };

  login = () => {
    setPreloginUrl(window.location.href);
    authService.signinRedirect();
  };

  navigateTo = (url) => {
    this.toggleMobileNav();
    window.location = `${window.location.protocol}//${window.location.host}/en/en_US/${url}`;
  };

  handleCartClick = () => {
    navigateToCartPage();
  };

  handleCouponLookup = () => {
    dispatchDisplayCouponLookupModal(true, 'lookup');
  };

  handleDealerCouponLookup = () => {
    dispatchDisplayCouponLookupModal(true, 'dealer-lookup');
  };

  handleTopNavClick = (e) => {
    if(this.state.dropdownState === 0) {
      const navCategory = e.target.dataset.navCategory;
      this.showDropdown(navCategory)
    } else {
      window.location = e.target.dataset.navUrl;
    }
  };
  componentDidUpdate(prevProps) {
    const { history } = this.props;
    const isLoginOrders = history.location.pathname.includes("/en/en_US/nts/email/orders");

    if(isLoginOrders && !this.props.userProfile.isLoading) {
      if(!this.props.userProfile.isLoggedIn) {
        this.login();
      } else {
        history.push('/en/en_US/nts/history/orders');
      }
    }
  }
  render() {
    const mobileLogo = isRetailExperience() ? scheelsLogo : logo;
    const userEmail = getUserEmail();
    return (
        <div id="nav-bar">
          {/* ===( COVID-19 BANNER )===
            <div id="nts-covid-msg-banner">Nike Team Statement on COVID-19. <a href="/en/en_US/nts/help/covid" rel="noopener noreferrer" target="_blank">View Here</a></div>
          */}
          <div className="top-nav hidden-xs hidden-sm">
            <div className="pull-left">
              <NtsLink className={isRetailExperience() ? 'nts-jock-tag scheels': 'nts-jock-tag'} to="/en/en_US/nts/">&nbsp;</NtsLink>
            </div>
            <NtsLink className="muted-link pull-left" to={'/en/en_US/nts/find_dealer'}>Dealer Locator</NtsLink>
            {this.props.navState.display.locker && <NtsLink className="muted-link pull-left" to={'/en/en_US/nts/locker'}>Locker</NtsLink> }
            {this.props.navState.display.cartIcon && <span className="user-link pull-right cart-link" onClick={this.handleCartClick}><i className="g72-cart" /></span> }
            {this.props.navState.display.login && <span className="user-link pull-right login-link" onClick={this.login}>Log In</span> }
            {this.props.navState.display.account &&
            <div className="user-link pull-right account-drop nts-simple-menu-toggle">
              <i className="g72-profile account-link" /><span className="user-link account-link">My Account</span>
              <div className="nts-simple-menu menu-option-container">
                <div className="nts-simple-menu-item muted">{userEmail}</div>
                <hr className="nts-simple-menu-bar" />
                <Link className="nts-simple-menu-item" to="/en/en_US/nts/user/profile">Profile</Link>
                <Link className="nts-simple-menu-item" to="/en/en_US/nts/history/orders">Orders</Link>
                {this.props.navState.display.pastQuotes && <div className="nts-simple-menu-item"><NtsLink to='/en/en_US/nts/history/quotes'>Quotes</NtsLink></div>}
                {this.props.navState.display.fillin  &&  <Link className="nts-simple-menu-item" to={'/en/en_US/nts/fillin'}>Create Fill-In Order</Link>}
                {this.props.navState.display.couponLookup && <div className="nts-simple-menu-item" onClick={this.handleCouponLookup}><span className="clickable">Lookup Coupon...</span></div>}
                {this.props.navState.display.dealerCouponLookup && <div className="nts-simple-menu-item" onClick={this.handleDealerCouponLookup}><span className="clickable">Coupons...</span></div>}
                <div className="nts-simple-menu-item" onClick={handleLogout}><span className="clickable">Logout</span></div>
              </div>
            </div>
            }
            <NtsLink className="user-link pull-right" to={'/en/en_US/nts/help/faq'}>Help</NtsLink>
            <div className="nts-account-options-menu pull-right">
              <AccountOptions />
            </div>
            {this.props.userProfile.isLoggedIn && hasAdminMenu() &&
            <div className="user-link pull-right admin-link nts-simple-menu-toggle">
              Admin
              <div className="nts-simple-menu admin-menu-container">
                <AdminOptions />
              </div>
            </div>
            }
          </div>
          <div className="primary-nav" id="primary-nav">
            <NtsLink to="/en/en_US/nts/" className="brand-container">
              <Image className="logo" src={mobileLogo} responsive  />
            </NtsLink>
            <div className="nav-menu-container hidden-xs hidden-sm">
              <div className="nts-nav-item nts-simple-menu-toggle" id="men-menu-toggle"
                   onMouseEnter={this.handleLinkHover}
                   onMouseLeave={this.hideDropdown}
                   onClick={this.handleTopNavClick}
                   data-nav-category="men-menu-toggle"
                   data-nav-url="/en/en_US/nts/gridwall/M/any/1">
                <p data-nav-category="men-menu-toggle" data-nav-url="/en/en_US/nts/gridwall/M/any/1">Men</p>
              </div>
              <div className="nts-nav-item nts-simple-menu-toggle" id="women-menu-toggle"
                   onMouseEnter={this.handleLinkHover}
                   onMouseLeave={this.hideDropdown}
                   onClick={this.handleTopNavClick}
                   data-nav-category="women-menu-toggle"
                   data-nav-url="/en/en_US/nts/gridwall/W/any/1">
                <p data-nav-category="women-menu-toggle" data-nav-url="/en/en_US/nts/gridwall/W/any/1">Women</p>
              </div>
              <div className="nts-nav-item nts-simple-menu-toggle" id="kids-menu-toggle"
                   onMouseEnter={this.handleLinkHover}
                   onMouseLeave={this.hideDropdown}
                   onClick={this.handleTopNavClick}
                   data-nav-category="kids-menu-toggle"
                   data-nav-url="/en/en_US/nts/gridwall/BG/any/1">
                <p data-nav-category="kids-menu-toggle" data-nav-url="/en/en_US/nts/gridwall/BG/any/1">Kids</p>
              </div>
             <div className="nts-nav-item nts-simple-menu-toggle" id="gfx-tees-menu-toggle"
                   data-nav-category="kids-menu-toggle"
                   data-nav-url="/en/en_US/nts/gridwall/BG/any/1">
                <Link data-nav-category="gfx-tees-menu-toggle"
                   data-nav-url="/en/en_US/nts/gridwall/M/any/950/?sort=rowNumber|desc&selected=hc_GT_Short_Sleeve+hc_GT_Long_Sleeve+hc_GT_Hoodies_and_Pullovers"
                   to="/en/en_US/nts/gridwall/any/any/950/?sort=rowNumber|desc&selected=hc_GT_Short_Sleeve+hc_GT_Long_Sleeve+hc_GT_Hoodies_and_Pullovers">
                  Graphic Tees
                </Link>
              </div>
              <div className="nts-nav-item nts-simple-menu-toggle" id="customize-menu-toggle"
                   data-nav-category="customize-menu-toggle"
                   data-nav-url="/en/en_US/nts/gridwall/BG/any/1">
                <Link data-nav-category="kcustomize-menu-toggle"
                   data-nav-url="/en/en_US/nts/gridwall/M/any/749/?sort=rowNumber|desc&selected=uniformProgram_Custom+uniformProgram_DQT+uniformProgram_Digital+uniformProgram_Custom_Stock+uniformProgram_Stock"
                   to="/en/en_US/nts/gridwall/any/any/749/?sort=rowNumber|desc&selected=uniformProgram_Custom+uniformProgram_DQT+uniformProgram_Digital+uniformProgram_Custom_Stock">
                  Customize
                </Link>
              </div>
              <div className="nts-nav-item nts-simple-menu-toggle" id="shop-menu-toggle"
                   data-nav-category="shop-menu-toggle"
                   data-nav-url="/en/en_US/nts/gridwall/BG/any/1">
                <Link data-nav-category="shop-menu-toggle"
                   data-nav-url="/en/en_US/nts/gridwall/any/any/1"
                   to="/en/en_US/nts/gridwall/any/any/1">
                  Shop All
                </Link>
              </div>

            </div>
            {/*Perhaps an inelegant way to solve this problem, but it works*/}
            {/*This is the full-screen dropdown*/}
            {this.state.dropdownState !== 0 &&
            <div className="nts-nav-bar-dropdown" id="nav-dropdown" onMouseLeave={this.hideDropdown} onClick={this.forceHideDropdown}>
              {this.state.dropdownState === 1 && <MensOptions/>}
              {this.state.dropdownState === 2 && <WomensOptions/>}
              {this.state.dropdownState === 3 && <KidsOptions/>}
            </div>
            }
            <div className="mobile-nav-container visible-xs-block visible-sm-block">
              <div className="nts-nav-item">
                {this.props.navState.display.cartIcon && <span onClick={this.handleCartClick}><i className="g72-cart" /></span>}
              </div>
              <div className="nts-nav-item mobile-menu-toggle" onClick={this.toggleMobileNav}>
                <i className="g72-menu"></i>
              </div>
              <div className={`mobile-nav ${this.state.navIsOpen ? 'open' : ''}`} >
                <div className="mobile-nav-side"  onClick={this.toggleMobileNav}>
                  <i className="g72-menu"></i>
                </div>
                <div className={`main-options ${this.state.activeMobileNav === MOBILE_NAV_OPTS.MAIN ? 'open' : ''}`}>
                  {!this.props.userProfile.isLoggedIn &&
                  <div className="mobile-option account-option" onClick={this.login}>Join / Log In To Nike Team Account</div>
                  }
                  {this.props.userProfile.isLoggedIn &&
                  <div className="mobile-option account-option" onClick={() => this.changeMobileNav(MOBILE_NAV_OPTS.ACCOUNT)}><i className="g72-profile profile-icon"></i> {userEmail}</div>
                  }
                  <div className="mobile-option" onClick={() => this.changeMobileNav(MOBILE_NAV_OPTS.MEN)}>Men</div>
                  <div className="mobile-option" onClick={() => this.changeMobileNav(MOBILE_NAV_OPTS.WOMEN)}>Women</div>
                  <div className="mobile-option" onClick={() => this.changeMobileNav(MOBILE_NAV_OPTS.KIDS)}>Kids</div>
                  {this.props.userProfile.isLoggedIn && <div className="nts-sub-nav-item mobile-option" onClick={() => this.navigateTo('nts/locker')}>Locker</div> }
                  <div className="nts-sub-nav-item mobile-option" onClick={() => this.navigateTo('nts/help/faq')}>Help</div>
                </div>
                <div className={`sub-options ${this.state.activeMobileNav === MOBILE_NAV_OPTS.MEN ? 'open' : ''}`}>
                  <div className="mobile-option mobile-menu-header" onClick={() => this.changeMobileNav(MOBILE_NAV_OPTS.MAIN)}><i className="cta-back-icon g72-arrow-thin-left"></i> Men</div>
                  <MensOptions clickHandler={this.toggleMobileNav} />
                </div>
                <div className={`sub-options ${this.state.activeMobileNav === MOBILE_NAV_OPTS.WOMEN ? 'open' : ''}`}>
                  <div className="mobile-option mobile-menu-header" onClick={() => this.changeMobileNav(MOBILE_NAV_OPTS.MAIN)}><i className="cta-back-icon g72-arrow-thin-left"></i> Women</div>
                  <WomensOptions clickHandler={this.toggleMobileNav} />
                </div>
                <div className={`sub-options ${this.state.activeMobileNav === MOBILE_NAV_OPTS.KIDS ? 'open' : ''}`}>
                  <div className="mobile-option mobile-menu-header" onClick={() => this.changeMobileNav(MOBILE_NAV_OPTS.MAIN)}><i className="cta-back-icon g72-arrow-thin-left"></i> Kids</div>
                  <KidsOptions clickHandler={this.toggleMobileNav} />
                </div>
                <div className={`sub-options ${this.state.activeMobileNav === MOBILE_NAV_OPTS.ACCOUNT ? 'open' : ''}`}>
                  <div className="mobile-option mobile-menu-header" onClick={() => this.changeMobileNav(MOBILE_NAV_OPTS.MAIN)}><i className="cta-back-icon g72-arrow-thin-left"></i> Account</div>
                  {this.props.navState.display.profile && <Link className="user-link mobile-option" to={'/en/en_US/nts/user/profile'}>Profile</Link>}
                  {this.props.navState.display.pastOrders && <Link className="user-link mobile-option" to={'/en/en_US/nts/history/orders'}>Orders</Link>}
                  {this.props.navState.display.pastQuotes && <Link className="user-link mobile-option" to={'/en/en_US/nts/history/quotes'}>Quotes</Link>}
                  {this.props.navState.display.fillin &&  <Link className="user-link mobile-option" to={'/en/en_US/nts/fillin'}>Create Fill-In Order</Link>}
                  {this.props.navState.display.couponLookup && <div className="user-link mobile-option" onClick={this.handleCouponLookup}><span className="clickable">Lookup Coupon...</span></div>}
                  {this.props.navState.display.dealerCouponLookup && <div className="user-link mobile-option" onClick={this.handleDealerCouponLookup}><span className="clickable">Coupons...</span></div>}
                  {hasAdminMenu() && <div className="user-link mobile-option" onClick={() => this.changeMobileNav(MOBILE_NAV_OPTS.ADMIN)}>Admin</div> }
                  <AccountOptions clickHandler={this.toggleMobileNav} />
                  <div className="mobile-option" onClick={handleLogout}>Logout</div>
                </div>
                {hasAdminMenu() &&
                <div className={`sub-options ${this.state.activeMobileNav === MOBILE_NAV_OPTS.ADMIN ? 'open' : ''}`}>
                  <div className="mobile-option mobile-menu-header" onClick={() => this.changeMobileNav(MOBILE_NAV_OPTS.ACCOUNT)}><i className="cta-back-icon g72-arrow-thin-left"></i> Admin</div>
                  <AdminOptions clickHandler={this.toggleMobileNav} />
                </div>
                }
              </div>
            </div>
            <SearchBar />
          </div>
          <CouponBar />
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userProfile: state.userState,
    navState: state.navigationState
  }
};

export const ConnectedNavbar = connect(
    mapStateToProps
)(Navbar);

export default withRouter(ConnectedNavbar);
