import {fetchRequestXml, urlNtsEnvironment, fetchRequest} from "./index";

/**
 * ======================================================================
 * =====( Services Based on Environment URL - Proxy to Webapp )==========
 * ======================================================================
 */

/**
 * Post email payload to URL to trigger webapp email process
 * @type {{sendEmail: (function(*): *)}}
 */
export const ContactDealerService = {
  sendEmail: (params) => {
    const request = new Request(`${urlNtsEnvironment}/en/en_US/p/mail?mail=contact${params}`, {
      method: 'GET',
      headers: {'Accept': 'application/json'}
    });
    return fetchRequestXml(request);
  }
};

/**
 * Posts email payload to URL to trigger webapp email process
 * @type {{post: (function(*=): *), postUniform: (function(*=): *)}}
 */
export const EmailDesignService = {
  post: (payload) => {
    const request = new Request(`${urlNtsEnvironment}/en/en_US/p/mail?mail=pdp`, {
      method: 'POST',
      headers: undefined,
      body: payload
    });
    return fetchRequestXml(request);
  },
  postUniform: (payload) => {
    const request = new Request(`${urlNtsEnvironment}/en/en_US/p/mail/design`, {
      method: 'POST',
      headers: undefined,
      body: payload
    });
    return fetchRequestXml(request);
  }
};

/**
 * Obtains set of images for given styleColor
 * Source: imagelibrary.nike.com
 * @type {{get: (function(*): *)}}
 */
export const ImageSet = {
  get: (styleColor) => {
    const request = new Request(`${urlNtsEnvironment}/imgprx/${styleColor}?req=set`, {
      method: 'GET',
      headers: {'Accept': 'application/json'}
    });
    return fetchRequestXml(request);
  }
};
