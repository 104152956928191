import * as React from "react";

import {ColorForm} from "./AddCustomColorModal";
import {
    FONTNAME_ALLOWED_CHARS_REGEX,
    HEX_COLOR_ALLOWED_CHARS_REGEX
} from "../../../helpers/constants";

interface Props {
    errors: any;
    colorForm: ColorForm;
    setColorForm: (colorForm:ColorForm) => void;
}

export const AddColorForm: React.FC<Props> = ({
    colorForm,
    setColorForm,
    errors
}) => {

    const sanitizeName = (value:string) => {
        return value.replace(FONTNAME_ALLOWED_CHARS_REGEX, '');
    }

    const sanitizeHex = (value:string) => {
        return value.replace(HEX_COLOR_ALLOWED_CHARS_REGEX, '');
    }

    return (
        <div className="custom-colors-form">

            <label className="custom-color-label" htmlFor="customColorActiveCheck">
                <span>Active</span>
                <input
                    type="checkbox"
                    name="customColorActiveCheck"
                    className="nike-check"
                    checked={colorForm.active}
                    data-nts-id="customColor.addColor.checkbox.status"
                    onChange={(e) => setColorForm({...colorForm, active: (e.target as HTMLInputElement).checked})}
                />
            </label>

            <label htmlFor="customColorName">
                <span>Name</span>
                <input
                    type="text"
                    placeholder="Enter color name"
                    maxLength={30}
                    name="customColorName"
                    value={colorForm.name}
                    className={`${errors.name ? ' error' : ''}`}
                    data-nts-id="customColor.addColor.input.name"
                    onChange={(e) => setColorForm({...colorForm, name: sanitizeName((e.target as HTMLInputElement).value)})}
                />
            </label>

            <label htmlFor="customColorSchool">
                <span>School</span>
                <input
                    type="text"
                    placeholder="Enter optional school name"
                    maxLength={30}
                    name="customColorSchool"
                    value={colorForm.school}
                    className={`${errors.school ? ' error' : ''}`}
                    data-nts-id="customColor.addColor.input.school"
                    onChange={(e) => setColorForm({...colorForm, school: sanitizeName((e.target as HTMLInputElement).value)})}
                />
            </label>

            <label htmlFor="customColorCode">
                <span>Color Code</span>
                <input
                    type="text"
                    placeholder="Enter 3 digit color code"
                    maxLength={3}
                    name="customColorCode"
                    value={colorForm.colorCode}
                    className={`${errors.colorCode ? ' error' : ''}`}
                    data-nts-id="customColor.addColor.input.colorCode"
                    onChange={(e) => setColorForm({...colorForm, colorCode: (e.target as HTMLInputElement).value.trim()})}
                />
            </label>

            <label htmlFor="customColorFactoryHex" className="color-input-label">
                <span>Factory Hex Code</span>
                <input
                    type="text"
                    placeholder="Enter hex including #"
                    maxLength={7}
                    name="customColorFactoryHex"
                    value={colorForm.factoryHexCode}
                    className={`${errors.factoryHexCode ? ' error' : ''}`}
                    data-nts-id="customColor.addColor.input.factoryHex"
                    onChange={(e) => setColorForm({...colorForm, factoryHexCode: sanitizeHex((e.target as HTMLInputElement).value.trim())})}
                />
                <span className="color-swatch" style={{"backgroundColor": colorForm.factoryHexCode}} />
            </label>

            <label htmlFor="customColorPresHex" className="color-input-label">
                <span>Presentation Hex Code</span>
                <input
                    type="text"
                    placeholder="Enter hex including #"
                    maxLength={7}
                    name="customColorPresHex"
                    value={colorForm.presentationHexCode}
                    className={`${errors.presentationHexCode ? ' error' : ''}`}
                    data-nts-id="customColor.addColor.input.presHex"
                    onChange={(e) => setColorForm({...colorForm, presentationHexCode: sanitizeHex((e.target as HTMLInputElement).value.trim())})}
                />
                <span className="color-swatch" style={{"backgroundColor": colorForm.presentationHexCode}} />
            </label>

        </div>
    );
};