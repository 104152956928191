import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

interface MessageData {
  header: string;
  message: string;
  callback?: () => void;
  cancelable?: boolean;
  handleCancel?: () => void;
  buttonText?: string;
  onClick?: () => void;
  onHide?: () => void;
  required?:boolean;
}

export interface MessageState {
  messageModal: MessageData | null;
  callback?: () => null;
}

const initialState: MessageState = {
  messageModal: null,
};

interface MessageAction extends Action {
  messageData: MessageData & {
    callback?: () => null;
  };
}

const messageReducer: Reducer<MessageState> = (
  state = initialState,
  incomingAction
) => {
  const action = incomingAction as unknown as MessageAction;
  
  switch (action.type) {
    
    case types.SHOW_MESSAGE_MODAL:
      return {
        ...state,
        messageModal: action.messageData,
        callback: action.messageData.callback
      };

    case types.HIDE_MESSAGE_MODAL:
      return {
        ...state,
        messageModal: null
      };

    default:
      return state;
  }
};

export default messageReducer;
