import React from 'react';
import {Row, Col} from 'react-bootstrap';

export class CovidStatementPage extends React.Component {

  componentDidMount() {
    // hacks to hide navigation options
    document.getElementById('nav-bar').hidden = true;
    document.getElementById('nts-footer').hidden = true;
    document.getElementById('nts-app-container').style.margin = "0";
  }

  render() {
    return (
        <div className="container nts-covid-message-container">
          <Row className="landing">
            <Col xs={12}>
              <h2 className="center-title">Nike Team Update on COVID-19</h2>
              <p className="terms-text">
                We are committed to the continued safety and well-being of our teammates and customers.
                Our Nike Team distribution center will remain open, but in a reduced capacity, based on
                guidance from health and government authorities. Our build-to-order uniform facility is
                temporarily closed due to governmental mandates, and product deliveries may be delayed as
                we manage through the dynamic situation. We appreciate your understanding and support.
              </p>
            </Col>
          </Row>
        </div>
    );
  }
}