import React from 'react';
import {connect} from 'react-redux';
import Autosuggest from 'react-autosuggest';
import {push} from 'connected-react-router';

import {QuickSearchService} from '../../api/services/productSearchService';
import {dispatchAddCustomSearchParam} from '../../actions/gridwallActions';
import {isJbaDesignId, isSapDesignId} from '../../helpers/designHelpers';
import {checkDesignSearch} from '../locker/lockerPageHelper';
import {showFillinHelperModal} from '../../actions/navigationActions';
import {isConsumer} from "../../helpers/userProfileHelpers";

const getSuggestionValue = suggestion => suggestion.displayName;

const renderSuggestion = suggestion => (
  <div className="suggestion-content">{suggestion.displayName}</div>
);

const NO_SELECTION_STYLE_NO = "0000000000";

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.customSearchParam !== this.props.customSearchParam) {
      this.setState({value: this.props.customSearchParam})
    }
  }

  handleUDSearch = async (designId) => {
    const designSearchData = await checkDesignSearch(designId);

    if(designSearchData.belongsToPastOrder) {
      if(!isConsumer()) {
        this.props.showFillinHelperModal('fill in', designId);
        return true;
      } else {
        return false;
      }
    }

    if (!designSearchData.belongsToLocker && !designSearchData.cartId && !designSearchData.belongsToPastOrder) {
      return false;
    }

    this.props.showFillinHelperModal('navbar search', designId, designSearchData.belongsToLocker, designSearchData.cartId);
    return true;
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if(inputLength > 0) {
      QuickSearchService.get(inputValue, false).then((products)=> {
        let noSelection = {
          displayName: value,
          styleNumber: NO_SELECTION_STYLE_NO,
          uniformProgram: ""
        };
        products.unshift(noSelection);
        this.setState({
          suggestions: products
        });
      });
    }
  };

  onSelectSuggestion = async (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    suggestionValue = suggestionValue.trim();
    if (suggestion.styleNumber === NO_SELECTION_STYLE_NO) {
      if (isJbaDesignId(suggestionValue)) { // is embodee UD
        // Ensure Embodee UD matches format (UD1a2b3c4d) -- UD prefix is uppercase, all following characters are lowercase
        const prefix = suggestionValue.substring(0, 2);
        const id = suggestionValue.substring(2, suggestionValue.length);
        const transformedUD = `${prefix.toUpperCase()}${id.toLowerCase()}`;
        const handled = await this.handleUDSearch(transformedUD);
        if (!handled) {
          window.location = `${window.location.protocol}//${window.location.host}/en/en_US/p/builder?styleNumber=${transformedUD}`;
        }
      } else if (isSapDesignId(suggestionValue)) { // is custom stock UD
        window.location = `${window.location.protocol}//${window.location.host}/en/en_US/nts/pdp/design/${suggestionValue}`;
      } else {
        window.location = `${window.location.protocol}//${window.location.host}/en/en_US/nts/gridwall/any/any/100?search=${suggestionValue}`;
      }
    } else if (suggestion.uniformProgram.toLowerCase().indexOf('stock') === -1) {
      window.location = `${window.location.protocol}//${window.location.host}/en/en_US/p/builder?styleNumber=${suggestion.styleNumber}`;
    } else {
      window.location = `${window.location.protocol}//${window.location.host}/en/en_US/nts/pdp/style/${suggestion.styleNumber}`;
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: '',
      value: decodeURIComponent(value),
      onChange: this.onChange
    };
    return (
      <div className="searchbar-container">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={this.onSelectSuggestion}
          focusInputOnSuggestionClick={false}
          inputProps={inputProps}
          highlightFirstSuggestion={true}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { customSearchParam: state.gridwall.userFilters.customSearchParam };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSearchParam: (searchString) => {
      dispatch(dispatchAddCustomSearchParam(searchString));
    },
    push: (path) => dispatch(push(path)),
    showFillinHelperModal: (mode, ud, isInLocker, cartId) => dispatch(showFillinHelperModal(mode, ud, isInLocker, cartId)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar);