
export const SORT_NEWEST = 'rowNumber|desc';
export const SORT_PRICE_ASC = 'styleListPrice|asc';
export const SORT_PRICE_DESC = 'styleListPrice|desc';

export const SPORT_PARAM_PREFIX = 'sport';
export const GENDER_PARAM_PREFIX = 'gender';
export const SHIPDATE_PARAM_PREFIX = 'shipDate';
export const ANY_PARAM = 'any';

export const GENDER_DISPLAY_MEN = 'Men';
export const GENDER_DISPLAY_WOMEN = 'Women';
export const GENDER_DISPLAY_BOYS = 'Boys';
export const GENDER_DISPLAY_GIRLS = 'Girls';

export const GENDER_FILTER_BOYS = 'gender_Boys';
export const GENDER_FILTER_GIRLS = 'gender_Girls';

export const BUILDER_URL_BASE = '/en/en_US/p/builder?styleNumber=';
export const GRIDWALL_URL_BASE = '/en/en_US/nts/gridwall';
export const GRIDWALL_SPORT_URL_BASE = '/en/en_US/nts/sport';
export const PDP_URL_BASE = '/en/en_US/nts/pdp/style/';
export const PDP_DESIGN_URL_BASE = '/en/en_US/nts/pdp/design/';
