import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

interface Details {
  nikeType: string;
  colors: string[];
  displayName: string;
  styleNumber: string;
  colorNumber: string;
  endDate: string;
  listPrice: string;
  formattedListPrice: string;
}

export interface SelectedStyleState {
  details: Partial<Details>;
  styleNumber: string;
  colorNumber: string;
}

// TODO: These actions can be separate types later
interface SelectedStyleAction extends Action {
  items: {
    styleNumber: string;
    colorNumber: string;
  }[];
  styleNumber: string;
  colorNumber: string;
  details: {
    styleNumber: string;
    colorNumber: string;
  }
}

const defaultState: SelectedStyleState = {
  details: {},
  styleNumber: '',
  colorNumber: '',
};

const selectedStyleReducer: Reducer<SelectedStyleState> = (
  state = defaultState,
  incomingAction,
) => {
  const action = incomingAction as unknown as SelectedStyleAction;
  switch (action.type) {

    case types.LOAD_PRODUCT_FOR_PDP: 
      return {
        ...state,
        styleNumber: action.items[0].styleNumber,
        details: action.items[0],
        colorNumber: action.items[0].colorNumber
      };

    case types.LOAD_PRODUCT_DETAILS_SOLR:
      return {
        ...state,
        styleNumber: action.details.styleNumber,
        details: action.details,
        colorNumber: '',
      };

    case types.SET_SELECTED_COLORWAY:
      return {
        ...state,
        styleNumber: action.styleNumber,
        colorNumber: action.colorNumber
      };

    case types.CLEAR_PRODUCT_FOR_PDP:
      return {
        ...defaultState
      };

    default:
      return state;
  }
};

export default selectedStyleReducer;
