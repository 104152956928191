import * as React from "react";
import {ReportTabState} from "./ReportTab";

interface Props {
    state: ReportTabState,
    handleSearch: () => void,
    updateState: (state:ReportTabState) => void
}

export const SearchBar: React.FC<Props> = ({
   state,
   handleSearch,
   updateState
}) => {
    return (
        <div className="nts-tab-search-bar">

            <label htmlFor="nts-admin-report-search">
                <input type="text"
                       name="nts-admin-report-search"
                       placeholder="Search by SO#, PO#, UD#, or cart name"
                       defaultValue={''}
                       onChange={e => updateState({...state, search: (e.target as HTMLInputElement).value})}
                />
            </label>

            <button type="button" className="btn btn-primary" onClick={handleSearch}>Search</button>

            {state.error && <div className="error">{state.error}</div>}

        </div>
    )
}
