import React from "react";
import {ImpersonateService} from "../../api/services/ntsWebappService";
import {cookies} from "../../api/services";
import {COOKIE_IMPERSONATED_AS_ADMIN, COOKIE_IMPERSONATED_USER_ID} from "../../helpers/constants";
import {getCookieDomain} from "../../helpers/uniteHelpers";
import {showMessageModal} from "../../actions/messageModalActions";

export interface DealerMemberCriteria {
    sortField: string;
    sortDirection: string;
    start: number;
    count: number;
    input: string;
    dealerId: string;
}

export interface DealerMemberResponse {
    count: number;
    member: any[];
    start: number;
    totalPages: number;
    totalResults: number;
}

export interface DealerMember {
    email: string;
    isRoleAssigner: boolean;
    memberOf: { id: string; name: string; };
    memberSince: number;
    name: string;
    userId: number;
}

interface MemberRowWrapper {
    original: DealerMember
}

// Converts millisecond date to (month day, year) format w/o local timezone
export const getMemberSinceFormattedDate = (utcSeconds: number) => {
    utcSeconds = +utcSeconds;
    if(isNaN(utcSeconds) || utcSeconds === null) {
        return 'Invalid Date';
    }
    const rawDate = new Date(utcSeconds); // The 0 there is the key, which sets the date to the epoch
    const userTimezoneOffset = rawDate.getTimezoneOffset() * 60000;
    return new Date(rawDate.getTime() + userTimezoneOffset).toLocaleDateString('en-us', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });
}

export const dealerMemberTableConfig = [
    {
        Header: 'ORDER ID',
        accessor: 'orderId',
        show: false
    },
    {
        Header: 'NAME',
        accessor: 'name'
    },
    {
        Header: 'EMAIL',
        accessor: 'email'
    },
    {
        Header: 'MEMBER SINCE',
        accessor: 'memberSince',
        Cell: (rowInfo:MemberRowWrapper) => {
            return (<span>{getMemberSinceFormattedDate(rowInfo.original.memberSince)}</span>);
        }
    },
    {
        Header: '',
        accessor: 'isRoleAssigner',
        width: 60,
        Cell: (rowInfo:MemberRowWrapper) => {
            return (
                <div className="user-impersonate-wrapper">
                    {!rowInfo.original.isRoleAssigner && <span className="user-impersonate-icon" onClick={() => handleImpersonation(rowInfo.original.userId)}>&nbsp;</span>}
                </div>
            );
        }
    }
]

const handleImpersonation = (teamUserId:number) => {

    cookies.set(COOKIE_IMPERSONATED_AS_ADMIN, 'true', {path: '/', domain: getCookieDomain()});
    cookies.set(COOKIE_IMPERSONATED_USER_ID, teamUserId.toString(), {path: '/', domain: getCookieDomain()});

    ImpersonateService.asAdmin(teamUserId).then((r:any) => {
        window.location.reload();
    }).catch((e:any) => {
        showMessageModal({
            header: "IMPERSONATION ERROR",
            message: "Issue while trying to share user account. Please try again."
        });
    })

}

