
/**
 * Takes in Unite User model and transforms to NTS UserDetailsImpl
 * Unite User Docs:
 *    https://confluence.nike.com/pages/viewpage.action?spaceKey=USER&title=Unite+Web+SDK+-+Developer+Documentation#UniteWebSDK-DeveloperDocumentation-UserObject
 * @param uniteUser
 */
export function mapUniteUserToNtsUser(uniteUser) {
  return {
    user: {
      screenName: (uniteUser.screenname) ? uniteUser.screenname : '',
      upmId: (uniteUser.upmId) ? uniteUser.upmId : '',
    },
    upmProfile: {
      email: (uniteUser.emails.primary.email) ? uniteUser.emails.primary.email : ''
    }
  };
}

/**
 * Returns Date for x minutes from now
 * @param minutes
 * @returns {Date}
 */
export function getDateObjectMinutesFromNow(minutes) {
  return new Date(Date.now() + (minutes*60*1000));
}

/**
 * Returns site domain name for cookie
 * @returns {string}
 */
export function getCookieDomain() {
  let hostname = window.location.hostname.substr(window.location.hostname.indexOf("."));
  if(hostname === 't') hostname = 'localhost';
  return hostname;
}