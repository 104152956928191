import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {connect} from 'react-redux';

import {ColorPalette} from "./colorPalette";
import {getAllColors} from '../../../actions/colorActions';

const hiddenColors = ['volt', 'dark steel grey'];

export class ColorOptions extends React.Component {

  componentDidMount() {
    if (this.props.colors.length === 0) {
      this.props.fetchColors();
    }
  }

  render() {
    const hasColors = this.props.colors.length > 0;
    const colorList = (hasColors) ? this.props.colors.filter(color => !hiddenColors.includes(color.name.trim().toLowerCase())) : null;
    return (
      <div className="container nts-color-help-page">
        <Row>
          <Col xs={12}>
            <h1>TEAM SPORTS COLORS</h1>
            <h4>The following colors are supported in the Team Sports customization process. </h4><br /><br />
            <p style={{fontFamily:"Helvetica,arial,sans-serif"}}>Click any color to copy the name</p>
            <div className="nts-color-palette-container">
              {hasColors && colorList.map((color, i) => (
                <ColorPalette key={i} color={color} />
              ))}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    colors: state.colorsState.colors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      fetchColors: () => dispatch(getAllColors()),
  };
};

export const ColorOptionsPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ColorOptions);