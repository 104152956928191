//TODO: Lifted from template tool while adding svg text components, not sure we need it here. Delete if not used.
export function insertItem(array, action) {
  return [
    ...array.slice(0, action.index),
    action.item,
    ...array.slice(action.index)
  ];
}

export function removeItem(array, action) {
  return [
    ...array.slice(0, action.index),
    ...array.slice(action.index + 1)
  ];
}

/**
 * Remove an item from a (single-dimesion) array
 * Example: array[1,2,3,4] -> removeItemByValue(array, 2) -> array[1,3,4]
 * @param array
 * @param value
 * @returns {*}
 */
export const removeItemByValue = (array, value) => {
  let index = array.indexOf(value);
  array.splice(index, 1);
  return array;
};

export function updateValueInObj(obj, key, value) {
  return {
    ...obj,
    [key] : value
  };
}

export function updateObjectInArray(array, action) {
  return array.map(item => {
    if(item.id !== action.id) {
      return item;
    }

    return {
      ...item,
      ...action
    };
  });
}

// Input 'rgb(250, 100, 50)'
// Output [250, 100, 50)
export function splitRgb (str) {
  return str.substring(4, str.length-1).replace(/ /g, '').split(',');
}

/**
 * Sorts by date using string versions of dates as input
 * @param a - date string
 * @param b - date string
 * Date String Examples: "1995-12-17T03:24:00", "December 17, 1995 03:24:00", "2018-08-10"
 * Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
 * @returns {number}
 */
export function dateStringSort(a, b) {
  return new Date(b) - new Date(a);
}

/**
 * Parses a string into an array based on (, space ;) separators
 * @param value
 * @returns {*}
 */
export function parseStringListToArray(value) {
  try {
    return value.split(/(?:,| |;)+/).filter(el => el.trim() !== '');
  } catch(e) {
    return null;
  }
}

/**
 * Converts array to a comma separated string
 * @param array
 * @param key
 * @param separator
 */
export function parseArrayToString(array, key, separator) {
  let string = '';
  if (array && array.length && array.length > 0) {
    array.forEach((item, i) => {
      const value = (item[key] && item[key].trim()) ? item[key].trim() : '';
      if (value) {
        string += item[key];
        if (i+1 < array.length) {
          string += separator;
        }
      }
    })
  }
  return string;
}