import * as React from "react";

import {CustomColorsTile} from "./CustomColorsTile";
import {CustomColor} from "../../reducers/customColorsReducer";

interface Props {
    colors: CustomColor[],
    error: string;
    onColorSelect: (font:CustomColor) => void;
}

export const CustomColorsGrid: React.FC<Props> = ({
    colors,
    error,
    onColorSelect
}) => {
    return (
        <div className="custom-colors-grid">
            {colors.length > 0 && colors.map((color, i) =>
                <div onClick={() => onColorSelect(color)} key={i}>
                    <CustomColorsTile key={i} index={i+1} color={color} />
                </div>
            )}
            {error && <div className="page-error">{error}</div>}
        </div>
    )
};