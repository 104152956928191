import * as types from "./actionTypes";
import {CustomFont} from "../reducers/customFontsReducer";

export const showAddFontModal = (show: boolean) => {
    return { type: types.SHOW_CUSTOM_FONT_ADD_MODAL, show: show };
}

export const showFontDetailsModal = (show: boolean) => {
    return { type: types.SHOW_CUSTOM_FONT_DETAILS_MODAL, show: show };
}

export const setSelectedCustomFont = (font: CustomFont | null) => {
    return { type: types.SET_SELECTED_CUSTOM_FONT, font: font };
}

export const setCustomFontList = (list: CustomFont[]) => {
    return { type: types.SET_CUSTOM_FONT_LIST, list: list };
}

export const showInactiveCustomFonts = (show: boolean) => {
    return { type: types.SHOW_INACTIVE_CUSTOM_FONTS, show: show };
}