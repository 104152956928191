import {calculateEndpointType} from './common';

const endpointTypeToUrl = {
  local: 'http://localhost:8080/artwork-api/artwork',
  dev:   'https://niketeam-api-dev.nike.net/artwork-api/artwork',
  qa:    'https://niketeam-api-qa.nike.net/artwork-api/artwork',
  stg:   'https://niketeam-api-stg.nike.net/artwork-api/artwork',
  uat:   'https://niketeam-api-uat.nike.net/artwork-api/artwork',
  prod:  'https://niketeam-api.nike.net/artwork-api/artwork'
};

const ntsArtworkBaseUrl = (location) => {
  const type = calculateEndpointType(location, 'prod');
  return endpointTypeToUrl[type];
};

export default ntsArtworkBaseUrl;