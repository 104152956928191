import React from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Row, Col} from 'react-bootstrap';

import {store} from '../../../App';
import {getCarts} from '../../actions/dealerActions';
import {
  createCart,
  addSapProductsToCarts,
  addJbaProductsToCarts,
  createJbaFillIn,
  handleAddProductResponse
} from '../../actions/orderActions';
import {sendPdpDetailsEvent} from '../../helpers/dataAnalyticsHelper';
import {hideCartSelectionMessageModal} from "../../actions/cartSelectorActions";
import {ORDER_STATUS_ID_OPEN_QUOTE} from "../../helpers/constants";

//components
import {FillInErrorPanel} from "./fillInErrorPanel";
import {isDealerRole} from "../../helpers/userProfileHelpers";
import CartSelectorRow from "./cartSelectorRow";

class CartSelector extends React.Component {

  defaultState = {
    showNewCartEntry: false,
    searchFilter: '',
    selectedItems: [],
    newCartName: '',
    message: '',
    msgStatus: '',
    orders: [],
    orderType: this.getOrderTypeFilter(),
    blockSaveBtn: false
  };

  constructor(props) {
    super(props);
    this.state = this.defaultState;
  }
  
  getOrderTypeFilter(isJba = false) {
    if(isJba) {
      // Dealers get Order carts, Consumer Buy/Anon/Consumers get Quote carts
      return (isDealerRole()) ? 'ORDERS' : 'QUOTES';
    } else {
      //TODO: Apply isLoggedIn logic here?
      //return (isConsumerBuyRole() || isDealerRole()) ? 'ORDERS' : 'QUOTES';
      return 'ORDERS';
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.cartSelectorState.showSelectCartModal) return;

    if (nextProps.cartSelectorState.isFillIn) {
      this.setState({
        showNewCartEntry: true,
        newCartName: nextProps.cartSelectorState.defaultName ? nextProps.cartSelectorState.defaultName.replace('Fill-in: ', '') : ""
      })
    } else if (!this.props.cartSelectorState.showSelectCartModal) {
      const orderType = this.getOrderTypeFilter(nextProps.cartSelectorState.isJba);
      //Only runs if the old state was not to show the modal, but the new state does show the modal.
      store.dispatch(getCarts(orderType))
        .then(result => {
          // If no carts, show New Cart Entry form
          const hasCarts = result && result.carts && result.carts.orders && result.carts.orders.length > 0;
          if (!hasCarts) {
            this.setState({
              showNewCartEntry: true,
              newCartName: '',
              cartSelectorState: nextProps.cartSelectorState,
              orderType: orderType
            })
          } else {
            this.setState({
              orders: result.carts.orders,
              newCartName: '',
              cartSelectorState: nextProps.cartSelectorState,
              orderType: orderType
            })
          }
        });
    }
  }

  onHide = () => {
    this.hideModal();
  };

  onCancel = () => {
    this.hideModal();
  };

  onSubmitCarts = () => {
    this.clearMessage();

    if (this.state.selectedItems.length < 1) {

      this.setState({
        message: 'Please select a cart',
        msgStatus: 'error'
      })

    } else {

      const carts = [];
      this.state.selectedItems.forEach(id => {
        this.state.orders.forEach(item => {
          if (+item.id === id) {
            carts.push(item);
          }
        });
      });

      const redirectToCart = this.props.cartSelectorState.isFillIn ? true : false;

      if (this.props.cartSelectorState.products) {
        let addToCartPromises = [];
        this.props.cartSelectorState.products.forEach(product => {
          if (!product.error) {
            if(typeof this.props.cartSelectorState.isJba !== 'undefined' && this.props.cartSelectorState.isJba) {
              addJbaProductsToCarts(product, carts);
            } else {
              let promises = addSapProductsToCarts(product.selectedStyle, product.productDetails, product.design, true, carts, product.priceDetails, redirectToCart);
              addToCartPromises.push(...promises);
              Promise.all(addToCartPromises).then((orders) => {
                if (redirectToCart) {
                  window.location = window.location.protocol + '//' + window.location.host + '/en/en_US/p/order,detail?cartId=' + carts[0].id;
                } else {
                  handleAddProductResponse(orders[0]);
                }
              });
            }
          }
        });
      }

      //TODO: Should this report for fillins too? Need to handle multiple SAP products
      if (!this.props.cartSelectorState.isFillIn && this.props.cartSelectorState.selectedStyle && this.props.cartSelectorState.productDetails)
        sendPdpDetailsEvent(this.props.cartSelectorState.selectedStyle.details.displayName, this.props.cartSelectorState.productDetails.description, this.props.cartSelectorState.selectedStyle.details.styleNumber, this.props.cartSelectorState.selectedStyle.details.colorNumber);
    }
    
    // Clear cart selections, otherwise, auto-selects newly added carts
    const clearSelectedItems = this.state.selectedItems;
    clearSelectedItems.length = 0;
    this.setState({ selectedItems: clearSelectedItems });
    
    this.toggleCreateCartDialog();
    this.hideModal();
  };

  toggleCreateCartDialog = () => {
    if (this.props.cartSelectorState.isFillIn) {
      this.hideModal();
    } else {
      this.setState({
        showNewCartEntry: !this.state.showNewCartEntry
      })
    }
  };

  updateSearchFilter = (e) => {
    const value = e.target.value;
    this.setState({
      searchFilter: value
    })
  };

  toggleSelection = (e) => {

    let cartId = e.target.dataset.cartId;
    if (!cartId) {
    }
    cartId = +cartId; //convert to number

    let selectedItems = this.state.selectedItems.slice();
    const existingIndex = selectedItems.indexOf(cartId);

    if (existingIndex > -1) {
      selectedItems.splice(existingIndex, 1);
    } else {
      selectedItems.push(cartId);
    }

    this.setState({
      selectedItems: selectedItems
    });

  };

  isSelected = (id) => {

    let selected = false;
    if (this.state.selectedItems.length > 0) {
      selected = this.state.selectedItems.includes(+id);
    }

    return selected;

  };

  updateCartName = (e) => {
    const name = e.target.value;
    if (name && !this.validateCartName(name)) {
      this.setState({
        message: 'Cart name doesn\'t support '+ e.target.value + ' special character',
        // message: 'Cart name can only contain alpha-numeric characters'+ e.target.value,
        msgStatus: 'error'
      })
    } else {
      this.setState({
        newCartName: name,
        message: '',
        msgStatus: ''
      });
    }

  };

  checkCartName = () => {
    if (!this.state.newCartName) {
      this.setState({
        message: 'Please enter a cart name',
        msgStatus: 'error'
      });
      return false;
    } else if (!this.validateCartName(this.state.newCartName)) {
      this.setState({
        message: 'Cart name can only contain alpha-numeric characters',
        msgStatus: 'error'
      });
      return false;
    }
    return true;
  };

  saveNewCart = () => {

    this.setState({
      ...this.state,
      blockSaveBtn: true,
      message: '',
      msgStatus: ''
    })

    if (this.checkCartName()) {
      if (this.props.cartSelectorState.isFillIn && this.props.cartSelectorState.isJba) {
        this.submitJbaFillInCart();
        this.setState({ ...this.state, blockSaveBtn: false });
      } else {
        let ordStatId = '';
        if(this.props.cartSelectorState.isJba && !isDealerRole()) { // JBA product + Not a dealer = Quote
          ordStatId = ORDER_STATUS_ID_OPEN_QUOTE;
        }
        createCart(this.getNewCartName(), this.props.cartSelectorState.isFillIn, ordStatId).then(result => {

          let selectedCarts = this.state.selectedItems;
          selectedCarts.push(+result.response.id);

          let orders = this.state.orders;
          orders.unshift(result.response);

          this.setState({
            newCartName: '',
            message: '',
            msgStatus: '',
            showNewCartEntry: false,
            selectedItems: selectedCarts,
            orders: orders,
            blockSaveBtn: false
          });

          if (this.props.cartSelectorState.isFillIn) {
            this.onSubmitCarts();
          }
        }).catch(e => {
          this.setState({
            ...this.state,
            blockSaveBtn: false,
            message: 'Unable to create cart. Please try again.',
            msgStatus: 'error'
          })
        });
      }
    }
  };

  getNewCartName = () => {
    let newCartName = this.state.newCartName;
    if (this.props.cartSelectorState.isFillIn) {
      newCartName = "Fill-in: " + newCartName;
    }
    return encodeURIComponent(newCartName);
  };

  submitJbaFillInCart = () => {
    if (this.props.cartSelectorState.products[0].uniformId) {
      //This is from the fillin page, is there a better way to tell this?
      createJbaFillIn(this.props.cartSelectorState.jbaSrcOrderId, this.props.cartSelectorState.products[0].uniformId, this.getNewCartName());
    } else {
      //This is from a past cart page.
      createJbaFillIn(this.props.cartSelectorState.jbaSrcOrderId, null, this.getNewCartName());
    }
  };

  // Verify cart name is alpha-numeric characters only
  validateCartName(name) {
    const cartNameRegex = new RegExp(/^(?! )[\w\s\-#().:!\[\]'\/\\]{1,50}$/);
    return cartNameRegex.test(name);
  }

  clearMessage = () => {
    this.setState({
      message: '',
      msgStatus: ''
    })
  };

  hideModal = () => {
    hideCartSelectionMessageModal();
    this.setState(this.defaultState);
  };

  hasProductsWithoutErrors = () => {
    if (Array.isArray(this.props.cartSelectorState.products) && this.props.cartSelectorState.products.length) {
      const productsWithOutErrors = this.props.cartSelectorState.products.filter((product) => {
        return (!product.error)
      });
      return productsWithOutErrors.length === 0;
    } else {
      return true;
    }
  };

  render() {

    let orders = [];
    const orderTypeText = this.state.orderType.toUpperCase() || 'CARTS';
    const orderTypeTextSingular = orderTypeText.substring(0, orderTypeText.length - 1);

    const hasExistingCarts =
      this.state.orders &&
      this.state.orders.length > 0;

    if (hasExistingCarts) {
      if (this.state.searchFilter) {
        orders = this.state.orders.filter(order => order.name.toLowerCase().indexOf(this.state.searchFilter.toLowerCase()) > -1);
      } else {
        orders = this.state.orders.slice();
      }
    }

    let staticValueStyle = {
      position: "absolute",
      left: "10px",
      top: "8px",
      fontSize: "15px",
      fontFamily: "Helvetica",
      paddingTop: "3px",
      fontWeight: "400",
      color: "#111111",
      cursor: "text",
      textTransform: "none"

    };

    let inputStyle = {
      padding: "5px 5px 5px 52px",
      width: "88%", height: "40px",

    };
    let wrapperStyle = {
      position: "relative",
      width: "250px"
    };

    if(this.props.cartSelectorState.showSelectCartModal) {
      return (
        <Modal className="show dlr-cart-modal"
               show={this.props.cartSelectorState && this.props.cartSelectorState.showSelectCartModal}
               onHide={this.onHide}
               aria-labelledby="contained-modal-title-lg">
          <Modal.Header closeButton>
            <Modal.Title>ADD PRODUCT TO {orderTypeText} </Modal.Title>
          </Modal.Header>
          <Modal.Body className="dlr-cart-body">
        
            {this.state.message &&
            <Row>
              <Col sm={12} className="dlr-cart-msg-display">
                <span className={this.state.msgStatus}>{this.state.message}</span>
              </Col>
            </Row>
            }
        
            {(!this.state.showNewCartEntry) &&
            <Row className="dlr-cart-hdr-options">
              <Col xs={6} sm={4} className="dlr-cart-col">
                <button type="button" className="btn btn-sm btn-default" style={{width: "100%"}}
                        onClick={this.toggleCreateCartDialog}>NEW {orderTypeTextSingular}
                </button>
              </Col>
              <Col xs={6} sm={8} className="dlr-cart-col" style={{position: 'relative'}}>
                <span className="searchImage gnav-bar--search--focus nsg-glyph--search nsg-text--dark-grey"></span>
                <input type="text" className="form-control search" style={{width: "100%", height: "40px"}}
                       value={this.state.searchFilter} placeholder="Search" onChange={this.updateSearchFilter}/>
              </Col>
            </Row>
            }
        
            {(this.state.showNewCartEntry && !this.hasProductsWithoutErrors()) &&
            <Row className="dlr-cart-hdr-options">
              <Col xs={6} className="dlr-cart-col">
                {(this.props.cartSelectorState.isFillIn) &&
                <div style={wrapperStyle}>
                  <label htmlFor="cart-name" style={staticValueStyle}>Fill-in:</label>
                  <input id="cart-name" style={inputStyle} type="text" value={this.state.newCartName}
                         onChange={this.updateCartName}
                         className="form-control" placeholder={`Enter ${orderTypeTextSingular.toLowerCase()} name`}
                         maxLength="40" autoFocus/>
                </div>
                }
                {(!this.props.cartSelectorState.isFillIn) &&
                <input id="cart-name" type="text" value={this.state.newCartName} style={{width: "100%", height: "40px"}}
                       className="form-control" placeholder={`Enter ${orderTypeTextSingular.toLowerCase()} name`} onChange={this.updateCartName}
                       maxLength="40"/>
                }
              </Col>
              <Col xs={3} className="dlr-cart-col">
                <button type="button" className="btn btn-sm btn-default" style={{width: "100%"}}
                        onClick={this.toggleCreateCartDialog}>CANCEL
                </button>
              </Col>
              <Col xs={3} className="dlr-cart-col">
                <button disabled={this.state.blockSaveBtn} type="button" className="btn btn-sm btn-primary" style={{width: "100%"}}
                        onClick={this.saveNewCart}>SAVE
                </button>
              </Col>
            </Row>
            }
        
            {!this.props.cartSelectorState.isFillIn &&
            <div className="dlr-cart-order-list">
              {
                orders && orders.map((cart, i) => (
                  <Row key={i}>
                    <Col xs={12}>
                      <CartSelectorRow cart={cart} isJba={this.props.cartSelectorState.isJba} isSelected={this.isSelected(cart.id)} onClick={this.toggleSelection}/>
                    </Col>
                  </Row>
                ))
              }
            </div>
            }
        
            <FillInErrorPanel products={this.props.cartSelectorState.products} isJba={this.props.cartSelectorState.isJba} />
      
          </Modal.Body>
      
          {!this.props.cartSelectorState.isFillIn &&
          <Modal.Footer>
            <Row>
              <Col sm={12}>
                <button type="button" data-dismiss="modal"
                        onClick={this.onSubmitCarts}
                        disabled={this.state.selectedItems.length <= 0}
                        className="btn btn-sm btn-primary">SAVE TO SELECTED {orderTypeText}
                </button>
              </Col>
            </Row>
          </Modal.Footer>
          }
    
        </Modal>
      );
    } else {
      return null;
    }

  }

}

const mapStateToProps = state => {
  return {
    builderState: state.builderState,
    pdpState: state.pdpState,
    cartSelectorState: state.cartSelectorState,
  }
};

export const CartSelectorModal = connect(
  mapStateToProps
)(CartSelector);
