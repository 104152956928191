import React from 'react';

interface Props {
  size: number;
}

export class Spacer extends React.Component<Props> {
  render() {
    return (
      <div style={{ marginTop: `${this.props.size}px`, clear: 'both' }}>
        {this.props.children}
      </div>
    );
  }
}
