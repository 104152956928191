import {getEnvironment} from "../../helpers/browserHelpers";
import {ENVIRONMENT_PROD, ENVIRONMENT_STG, ENVIRONMENT_UAT} from "../../helpers/constants";

const PROD_BASE_URL = 'https://niketeam-asset-download.nike.net';
const PREPROD_BASE_URL = 'https://niketeam-asset-download-internal.nike.net';

const env = getEnvironment();
const baseUrl = ([ENVIRONMENT_PROD, ENVIRONMENT_UAT, ENVIRONMENT_STG].includes(env)) ? PROD_BASE_URL : PREPROD_BASE_URL;

//  Returns URL for Catalog PDF & Thumbnail files
export const getCatalogBaseUrl = () => {
    return `${baseUrl}/catalogs`;
};

// Returns URL for image assets (stored in Akamai NetStorage)
export const getAkamaiImageBaseUrl = () => {
    return `${baseUrl}/images`;
}

export const getAkamaiGuidesBaseUrl = () => {
    return `${baseUrl}/guides`
}

//  Returns URL for Announcement Banner text and link
export const getAnnouncementBannerBaseUrl = () => {
    return `${baseUrl}/banners`;
};
