import {calculateEndpointType} from './common';

const endpointTypeToUrl = {
  local: 'https://niketeam-api-qa.nike.net/order-api',
  dev:   'https://niketeam-api-dev.nike.net/order-api',
  qa:    'https://niketeam-api-qa.nike.net/order-api',
  stg:   'https://niketeam-api-stg.nike.net/order-api',
  uat:   'https://niketeam-api-uat.nike.net/order-api',
  prod:  'https://niketeam-api.nike.net/order-api'
};

// Swagger API
// https://nts-stockorder-api-dev.nikecloud.net/swagger-ui.html

export default function ntsStockOrderServicesBaseUrl(location) {
  const type = calculateEndpointType(location, 'prod');
  return endpointTypeToUrl[type];
}
