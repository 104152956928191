import {AssortmentService} from "../api/services/assortmentService";
import * as types from "../actions/actionTypes";

/**
 * Calls to retrieve orders Dealer currently has open (for cart selection)
 */
export function getCarts(orderType = 'ALL') {
  return dispatch => {
    return AssortmentService.getCarts(getFindOrdersQueryStr('', true, 0, 0, 'lastUpdated', 'desc', false, false, orderType))
      .then(result => {
        return dispatch(dispatchLoadDealerCarts(result));
      })
      .catch(error => {
        throw(error);
      });
  };
}

/**
 * Creates query parameter string for requesting orders (carts) for a dealer
 * @param input
 * @param isOpen
 * @param start
 * @param count
 * @param sortField
 * @param sortOrder
 * @param showFillins
 * @param isPilot
 * @param orderType
 * @returns {string}
 */
export const getFindOrdersQueryStr = (input = '', isOpen = true, start = 0, count = 0, sortField = 'lastUpdated', sortOrder = 'desc', showFillins = false, isPilot = false, orderType = 'ALL') => {

  let str = '';
  str += 'input=' + input;
  str += '&isOpen=' + isOpen;
  str += '&start=' + start;
  str += '&count=' + count;
  str += '&sortField=' + sortField;
  str += '&sortOrder=' + sortOrder;
  str += '&showFillins=' + showFillins;
  str += '&isPilot=' + isPilot;
  str += '&orderType=' + orderType;
  str += '&cb='+ Math.round(new Date().getTime() / 1000);
  return str;

};

export function dispatchShowDealerCartModal(state) {
  return { type: types.SHOW_DEALER_CART_MODAL, show: state};
}

function dispatchLoadDealerCarts(carts) {
  return { type: types.LOAD_DEALER_CARTS, carts };
}
