import * as X2JS from 'x2js';
import Cookies from 'universal-cookie';

import {LS_ACCESS_TOKEN} from "../../helpers/constants";
import {endFullLoadingSpinner, startFullLoadingSpinner} from "../../actions/loadingSpinnerActions";
import {handleLogout} from "../../actions/userProfileActions";
import atOnceBaseUrl from '../config/atOnceBaseUrl';
import ntsServicesBaseUrl from '../config/ntsServicesBaseUrl';
import ntsStockOrderServicesBaseUrl from '../config/ntsStockOrderServicesBaseUrl';
import ntsEnvironmentUrl from '../config/ntsEnvironmentUrl';
import embodeeBuilderBaseUrl from '../config/embodeeBuilderBaseUrl';
import ntsIdentityServicesUrl from '../config/ntsIdentityServiceBaseUrl';
import ntsArtworkBaseUrl from '../config/ntsArtworkBaseUrl';
import apiBaseUrl from '../config/apiBaseUrl';
import {isJwtTokenValid} from "../../helpers/jwtHelpers";
import eliteOptionsBaseUrl from "../config/eliteOptionsBaseUrl";
import ntsFactoryShipgroupBaseUrl from '../config/ntsFactoryShipgroupBaseUrl';
import ntsFactoryServicesBaseUrl from '../config/ntsFactoryServicesBaseUrl';

// Triggers to enable/disable mock service payload (on some services)
export const mockAll = false;
export const mockForIeAndSafari = false;

export const cookies = new Cookies();

// URL Config exports
export const urlAtOnce = atOnceBaseUrl(window.location);
export const urlNtsServices = ntsServicesBaseUrl(window.location);
export const urlNtsFactoryShipgroupServices = ntsFactoryShipgroupBaseUrl(window.location);
export const urlNtsFactoryServices = ntsFactoryServicesBaseUrl(window.location);
export const urlNtsStockOrderServices = ntsStockOrderServicesBaseUrl(window.location);
export const urlNtsEnvironment = ntsEnvironmentUrl(window.location);
export const urlEmbodeeServices = embodeeBuilderBaseUrl(window.location);
export const urlNtsIdentityServices = ntsIdentityServicesUrl(window.location);
export const urlNtsArtworkServices = ntsArtworkBaseUrl(window.location);
export const urlNtsTemplateServices = apiBaseUrl(window.location);
export const urlEliteOptionsServices = eliteOptionsBaseUrl(window.location);

const getFetchFunc = (baseUrl) => {
  return (url, method = 'GET', data = {}) => {
    const request = new Request(`${baseUrl}${url}`, {
        method,
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
            'Authorization': `Unite ${localStorage.getItem(LS_ACCESS_TOKEN)}`
        },
        body: ['POST', 'PUT', 'PATCH'].includes(method) ? JSON.stringify(data) : undefined
    });
    return fetchRequest(request, true, 'UNITE');
  }
}

export const factoryShipgroupFetch = getFetchFunc(urlNtsFactoryShipgroupServices);
export const factoryServicesFetch = getFetchFunc(urlNtsFactoryServices);

/**
 * Generic handler for all AJAX requests
 *    returns JSON from the service response
 * @param request
 * @param showSpinner
 * @param authType
 * @returns {Promise<any>}
 */
export const fetchRequest = (request, showSpinner = true, authType = 'OKTA') => {
  // Prevent making calls with invalid/missing Unite access token
  const accessToken = localStorage.getItem(LS_ACCESS_TOKEN);
  if(authType === 'UNITE' && !accessToken) {
    handleLogout();
  } else {
    return new Promise((resolve, reject) => {
      fetchRequestSimple(request, showSpinner, authType)
      .then(response => {
        //TODO: Some of our services (like StockOrder.getCart) return empty body rather than JSON when there is no data.
        // This check will look for that and return an empty object instead.
        // These services should be updated to return non-200 responses like 204 No content, or valid JSON.
        try {
          response.text().then(text => resolve(text ? JSON.parse(text) : {} )).catch(e => reject({}))
        } catch(e) {
          resolve(response);
        }
      })
      .catch(error => {
        // Try to parse error body for specific error messaging
        try {
          error.text().then(text => {
            reject(text ? JSON.parse(text) : {})
          }).catch(e => reject({}));
        } catch(e) {
          reject(error);
        }
      });
    });
  }
};

/**
 * Generic handler for simple JSON requests
 *    Returns a service response with no processing
 * @param request
 * @param showSpinner
 * @param authType
 * @returns {Promise<Response | never>}
 */
export const fetchRequestSimple = (request, showSpinner = true, authType = 'OKTA') => {
  if (showSpinner) startFullLoadingSpinner();
  return fetch(request)
  .then(response => {
    if (showSpinner) endFullLoadingSpinner();
    if (!response.ok) {
      throw response;
    } else {
      return response;
    }
  })
  .catch(error => {
    if (showSpinner) endFullLoadingSpinner();
    throw error;
  });
};

/**
 * Generic handler for all XML based AJAX requests
 *    Returns JSON from an XML service response.
 * @param request
 * @param showSpinner
 * @param authType
 * @returns {Promise<T | never> | * | *}
 */
export const fetchRequestXml = (request, showSpinner = true, authType = 'OKTA') => {
  /*return validateAccessToken().then(() => {*/
  return fetchRequestSimple(request, showSpinner)
  .then(response => {
    return response.text()
  })
  .then(xml => {
    return new X2JS().xml2js(xml);
  })
  .catch(error => {
    throw error;
  });
  /*});*/
};

/**
 *
 * @param result
 * @returns {Promise<any>}
 */
export function getMock(result) {
  return new Promise((resolve) => {
    resolve(result);
  });
}
