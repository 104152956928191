import * as React from "react";

import {CustomColor} from "../../reducers/customColorsReducer";
import {EliteOptionsStatusType} from "./customColorsHelper";

interface Props {
    color: CustomColor,
    index: number
}

export const CustomColorsTile: React.FC<Props> = ({
    color,
    index
}) => {
    return (
        <div className="custom-colors-tile" data-nts-id={`customColors.grid.tile.${index}`}>
            <div className="color-preview" style={{"backgroundColor":color.factoryHexCode}} />
            <div className="details">
                {color.colorName && <h5>{color.colorName}</h5>}
                {color.schoolName && <div>{color.schoolName}</div>}
                {color.status && <div>{color.status === EliteOptionsStatusType.ACTIVE ? 'ACTIVE' : 'INACTIVE'}</div>}
            </div>
        </div>
    );
}