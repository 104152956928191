import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

export class NtsLink extends React.Component {

    render() {
        let isReactPage = this.props.to.includes('/nts');
        if(isReactPage) {
            return(<Link {...this.props}> {this.props.children} </Link>);
        }
        return(<a href={this.props.to} {...this.props}> {this.props.children} </a>);
    }
}

NtsLink.propTypes = {
    to: PropTypes.string
};