import { UserManager } from "oidc-client-ts";

import {getAuthProviderConfig, getPreloginUrl, setPreloginUrl} from "../utils/helpers";
import {handleLogin, setAuthCookies} from "../../js/actions/userProfileActions";
import {LS_ACCESS_TOKEN, TEAM_HOME_URL_PATH} from "../../js/helpers/constants";
import {history} from "../../js/store";

interface OidcSessionObject {
    access_token: string;
    expires_at: number;
    id_token: string;
    profile: {
        aud: string;
        exp: number;
        iat: number;
        iss: string;
        sub: string;
    },
    refresh_token: string;
    scope: string;
    session_state: string;
    token_type: string;
}

const { identityConfig, userStore, metadataConfig } = getAuthProviderConfig();

/**
 * Provides implementation of UserManager + OIDC Client for interacting with Nike Accounts
 *    > OIDC (oidc-client-ts) Documentation: https://authts.github.io/oidc-client-ts/
 *    > Nike Accounts Documentation: https://miniature-couscous-57c7acad.pages.github.io/
 *          >> To gain access, submit an ID Locker request for the AD Group "App.GHEC.nike-internal"
 */
export default class AuthService {
    UserManager;

    constructor() {

        this.UserManager = new UserManager({
            ...identityConfig,
            userStore: userStore,
            metadata: {
                ...metadataConfig
            }
        });

        this.UserManager.events.addUserLoaded((user) => {
            if (window.location.href.indexOf("signin-oidc") !== -1) {
                history.push(TEAM_HOME_URL_PATH);
            }
        });

        this.UserManager.events.addSilentRenewError((e) => {
            console.log("Failed to restore user session (error): ", e);
        });

        this.UserManager.events.addAccessTokenExpiring(() => {
            this.signinSilent()
        });

    }

    // Invoke OIDC signin process
    signinRedirect = () => {
        this.UserManager.signinRedirect({});
    };

    // Handle OIDC signin process callback
    signinRedirectCallback = async () => {
        this.UserManager.signinRedirectCallback().then((user) => {
            if(user && user.access_token) {
                const accessToken = user.access_token;
                localStorage.setItem(LS_ACCESS_TOKEN, accessToken);
                setAuthCookies(accessToken);
                handleLogin(accessToken)
                    .then(() => {
                        window.location.href = getPreloginUrl();
                    })
                    .catch((e) => { console.error('Failed to create user session (error): ', e) });
            }
        });
    };

    // Invoke OIDC silent signin process
    signinSilent = () => {
        this.UserManager.signinSilent()
            .then((user) => {
                if(user && user.access_token) {
                    localStorage.setItem(LS_ACCESS_TOKEN, user.access_token);
                    setAuthCookies(user.access_token);
                }
            })
            .catch((e) => { console.error('Failed to create user session (error): ', e) });
    };

    // Handle OIDC silent signin process callback
    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback().then(() => {
            console.log('session restored');
        });
    };

    // Invoke OIDC logout process
    signoutRedirect = () => {
        setPreloginUrl(TEAM_HOME_URL_PATH);
        const sessionObj:OidcSessionObject = this.getOidcSession();
        if(sessionObj && sessionObj.id_token) {
            this.UserManager.signoutRedirect({ id_token_hint: sessionObj.id_token });
            this.UserManager.clearStaleState();
        }
    };

    // Handle OIDC logout callback
    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            history.push(TEAM_HOME_URL_PATH);
        });
    };

    // Check if user has existing, valid session
    isAuthenticated = () => {
        const oidcStorage = this.getOidcSession();
        return (!!oidcStorage && !!oidcStorage.access_token)
    };

    // Check for existence of OIDC session object in session storage
    getOidcSession = () => {
        try {
            const oidcObject = localStorage.getItem(`oidc.user:${identityConfig.authority}:${identityConfig.client_id}`);
            return oidcObject && JSON.parse(oidcObject);
        } catch(e) {
            console.log('Failed to retrieve user session (error): ', e)
            return {};
        }
    }

    getAccessToken = () => {
        const oidcStorage = this.getOidcSession();
        return oidcStorage?.access_token;
    }

    refreshSession = () => {
        const oidcStorage = this.getOidcSession();
        const accessToken = oidcStorage?.access_token;
        if (accessToken) {
            localStorage.setItem(LS_ACCESS_TOKEN, accessToken);
            setAuthCookies(accessToken);
        }
    }

    // Get OIDC user object
    getUser = async () => {
        const user = await this.UserManager.getUser();
        if (!user) {
            return await this.UserManager.signinRedirectCallback();
        }
        return user;
    };

    // Parse OIDC access token (debug)
    parseJwt = (token:string) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    };

}
