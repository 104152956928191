import {IFontForm} from "./addFont/AddCustomFontModal";
import {CustomFont} from "../../reducers/customFontsReducer";

export const ERROR_NETWORK_CALL = "Unable to obtain fonts. Please try again.";
export const ERROR_NO_RESULTS = "No fonts found. Please add a custom font.";
export const ERROR_NO_DEALERSHIP = "Unable to obtain dealership. Please log in / impersonate again.";
export const ERROR_MISSING_FORM_FIELDS = "Please fill out the missing items.";
export const ERROR_ADD_FONT_NETWORK = "Unable to call add font service. Please try again.";
export const ERROR_ADD_FONT_GENERIC = "Error while creating new font. Please try again";
export const ERROR_UPDATE_FONT_GENERIC = "Error while updating font. Please try again";
export const CUSTOM_FONT_DB_PREFIX = "CSTM_FONT#";
export const ERROR_GETTING_FACTORY_FILE_URL = "Error getting factory file URL..."; 

export enum EliteOptionsServiceType {
    CUSTOM_FONT = 'CUSTOM_FONT',
    CUSTOM_COLOR = 'CUSTOM_COLOR',
}

export enum EliteOptionsStatusType {
    ACTIVE = 'A',
    INACTIVE = 'I',
}

export interface GetCustomFontsPayload {
    pageSize: number;
    type: EliteOptionsServiceType;
    queryKey: string | null;
}

export interface UpdateFontPayload {
    fontId: string;
    dealerShipId: string;
    fontName: string;
    schoolName: string;
    status: EliteOptionsStatusType
}

export interface FactoryFileUrlPayload {
    relativePath: string;
}

export const DEFAULT_GET_FONTS_PAYLOAD: GetCustomFontsPayload = {
    pageSize: 1000,
    type: EliteOptionsServiceType.CUSTOM_FONT,
    queryKey: null,
}

export interface AddFontFormErrors {
    name: boolean;
    school: boolean;
    pngFile: boolean;
    factoryFile: boolean;
    displayMsg: string;
    numErrors: number;
}

export const defaultAddFontFormErrors: AddFontFormErrors = {
    name: false,
    school: false,
    pngFile: false,
    factoryFile: false,
    displayMsg: '',
    numErrors: 0,
}

export const getAddFontPayload = (form:IFontForm, dealershipId:string) => {
    let formData = new FormData();
    if(form.pngFile && form.factoryFile) {
        formData.append('fontName', form.name);
        formData.append('pngFile', form.pngFile);
        formData.append('factoryFile', form.factoryFile);
        formData.append('schoolName', form.school);
        formData.append('status', (form.active) ? EliteOptionsStatusType.ACTIVE : EliteOptionsStatusType.INACTIVE);
        formData.append('dealerShipId', dealershipId);
    }
    return formData;
}

export const getFactoryFileUrlPayload = (font: CustomFont) => {
const fontRelativePath = font.data.factoryFileKeyPath.substr(1, font.data.factoryFileKeyPath.length - 1)
    return {
        relativePath: fontRelativePath,
    }
}

export const getUploadFontPayload = (font: CustomFont, dealershipId: string) => {
    return {
        fontId: font.PK.replace(CUSTOM_FONT_DB_PREFIX,''),
        dealerShipId: dealershipId,
        fontName: font.data.fontName,
        schoolName: font.data.schoolName,
        status: font.status,
    }
}

export const checkAddFontsFormForErrors = (form:IFontForm) => {
    let errors = {...defaultAddFontFormErrors};
    let numErrors = 0;
    if(!form.name || form.name.trim().length === 0 || form.name.trim().length > 30) {
        errors.name = true;
        numErrors++;
    }
    if(!form.pngFile) {
        errors.pngFile = true;
        numErrors++;
    }
    if(!form.factoryFile) {
        errors.factoryFile = true;
        numErrors++;
    }
    if(numErrors > 0) {
        errors.displayMsg = ERROR_MISSING_FORM_FIELDS;
        errors.numErrors = numErrors;
    }
    return errors;
}
export const downloadFactoryFile = (factoryFileUrl: string) => {
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = factoryFileUrl;
    document.body.appendChild(link);
    link.click();
}