import React from 'react';
import {Helmet} from 'react-helmet';

import {getEnvironment} from "../../helpers/browserHelpers";
import {ENVIRONMENT_PROD} from "../../helpers/constants";

/**
 * Activates script for New Relic integration
 * @constructor
 */
export const NewRelic = () => {
    const env = getEnvironment();
    const basePath = `${process.env.PUBLIC_URL}/lib/newRelic`;
    const filePath = (env === ENVIRONMENT_PROD) ? `${basePath}/new_relic_prod.js` : `${basePath}/new_relic_preprod.js`
    return (
        <Helmet>
            <script defer type="text/javascript" src={filePath} />
        </Helmet>
    )
}