import * as React from "react";
import { FactoryShipGroupState } from "./adminFactoryShipGroupHelper";
interface Props {
    state: FactoryShipGroupState,
    handleCreate: () => void,
    updateState: (keyword: string) => void,
    handleSearch: () => void,
    loading: boolean
}
export const SearchBar: React.FC<Props> = ({
    state,
    handleCreate,
    updateState,
    handleSearch,
    loading
 }) => {
    return (
        <div className="nts-tab-search-bar ship-group-search-bar">
            <button type="button" className="btn btn-primary" onClick={handleCreate}>Add Ship Group</button>
            <span>
                <label htmlFor="nts-admin-report-search">
                    <input type="search"
                        name="nts-admin-report-search"
                        placeholder="Search by name"
                        defaultValue={state.keyword}
                        value={state.keyword}
                        onChange={e => {
                            updateState(e.target.value);
                        }}
                    />
                </label>
                <button type="button" className="btn btn-primary" onClick={handleSearch} disabled={loading}>Search</button>
            </span>
        </div>
    )
}
