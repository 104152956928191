import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

export interface DealerArtworkState {
  count: number;
  artwork: unknown[];
}

const initialState: DealerArtworkState = {
  count: 0,
  artwork: []
};

interface DealerArtworkAction extends Action {
  result: {
    start: number;
    count: number;
    artwork: unknown[];
  };
}

const dealerArtworkReducer: Reducer<DealerArtworkState> = (
  state = initialState,
  incomingAction
) => {
  const action = incomingAction as unknown as DealerArtworkAction;

  switch (action.type) {
    case types.LOAD_DEALER_ARTWORK:
      if (action.result.start === 0) {
        return action.result;
      } else {
        state.count = state.count + action.result.count;
        state.artwork.push(...action.result.artwork);
        return {...state};
      }
    default:
      return state;
  }
}

export default dealerArtworkReducer;
