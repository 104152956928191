import * as React from 'react';

import {sendCatalogClickedEvent} from '../../helpers/dataAnalyticsHelper';

interface Props {
    catalogUrl: string;
    thumbnailUrl: string;
    label: string;
}

const CatalogTile: React.FC<Props> = (props) => {
    const handleClick = () => {
        sendCatalogClickedEvent(props.label);
    };

    return (
        <a
            href={props.catalogUrl}
            className="nts-catalog-tile"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClick}
        >
            <img alt="thumbnail" src={props.thumbnailUrl} />

            <div className="nts-catalog-tile-label">
                {props.label}
            </div>
        </a>
    );
};

export default CatalogTile;
