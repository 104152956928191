// Provides content for the Scheels Landing Page (/retailer/scheels/scheelsLandingPage.js)
export const scheelsLanding = {
    "seo": {
        "title": "Nike Team | Scheels | Custom Team Uniforms | Stock Uniforms",
        "description": "Nike TEAM & Scheels Landing Page"
    },
    "logo": {
        "src": "/landing/Team_Scheels_Logo.svg",
        "altText": "Nike TEAM & Scheels Branding"
    },
    "banner": {
        "src": "/landing/Scheels_Stock_Banner.png",
        "mobileSrc": "/landing/Scheels_Stock_Banner.png",
        "altText": "Design Your Look with Nike Team Stock Products"
    },
    "instructionBox" : {
        "instruct1": {
            "step": "1",
            "title": "CHOOSE YOUR PRODUCT",
            "desc": "Shop the innovation in Nike TEAM"
        },
        "instruct2": {
            "step": "2",
            "title": "MAKE IT YOURS",
            "desc": "Personalize with name, number, and custom artwork"
        },
        "instruct3": {
            "step": "3",
            "title": "ADD TO CART",
            "desc": "Update player info and quantities"
        },
        "instruct4": {
            "step": "4",
            "title": "SEE SCHEELS AGENT",
            "desc": "Work with a Scheels agent to checkout or schedule next steps"
        }
    },
    "stockUniformsBlock": {
        "header": "Stock Uniforms",
        "description": "Personalized product delivered in 2-3 weeks.",
        "image": {
            "src": "/landing/Scheels_Stock_Uniforms_Block.jpg",
            "altText": "Nike Team Softball - Stock Uniforms"
        },
        "cta": {
            "text": "Shop All",
            "route": "/en/en_US/nts/gridwall/any/any/625/?sort=rowNumber|desc&selected=hc_UN_Tops+hc_UN_Bottoms+uniformProgram_Custom_Stock+uniformProgram_Stock"
        }
    },
    "graphicTeesBlock": {
        "header": "Graphic Tees & Fleece",
        "description": "Personalized fundamentals delivered in 2-3 weeks.",
        "image": {
            "src": "/landing/Scheels_Graphic_Tee_Block.jpg",

            "altText": "Nike Team Softball - Graphic Tees & Fleece"
        },
        "cta": {
            "text": "Shop All",
            "route": "/en/en_US/nts/gridwall/any/any/746/?sort=rowNumber|desc&selected=hc_GT_Short_Sleeve+hc_GT_Long_Sleeve+hc_GT_Hoodies_and_Pullovers"
        }
    },
    "coachBlock": {
        "header": "Travel, Train, Coach",
        "description": "Blank fundamentals and equipment (retail price applies).",
        "image": {
            "src": "/landing/Scheels_Consumer_Block.png",
            "altText": "Nike Team Softball - Travel, Train, Coach"
        },
        "cta": {
            "text": "Shop All",
            "route": "/en/en_US/nts/gridwall/any/any/404/?sort=rowNumber|desc&selected=uniformProgram_Stock+uniformProgram_Custom_Stock"
        }
    },
    "moreOptions": {
        "catalog": {
            "imageSrc": "/landing/Download_Catalog.jpg",
            "headerText": "Download a catalog",
            "callToAction": "download",
            "callToActionRoute": "/en/en_US/p/shop,download_catalog"
        },
        "customizeHeader": "Customize more",
        "contactDealerHeader": "Contact a team dealer"
    }
}

