import React from 'react';
import {NtsLink} from './../../shared/ntsLink';

export const MensOptions = (props) => {

  return(
    <div className="nts-nav-bar-flex">

      <div className="nts-nav-bar-col">
        <NtsLink to='/en/en_US/nts/sport/mens/baseball' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Baseball</NtsLink>
        <NtsLink to='/en/en_US/nts/sport/mens/basketball' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Basketball</NtsLink>
        <NtsLink to='/en/en_US/nts/sport/mens/football' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Football</NtsLink>
        <NtsLink to='/en/en_US/nts/sport/mens/lacrosse' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Lacrosse</NtsLink>
        <NtsLink to='/en/en_US/nts/sport/mens/running' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Running</NtsLink>
        <NtsLink to='/en/en_US/nts/sport/mens/soccer' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Soccer</NtsLink>
        <NtsLink to='/en/en_US/nts/sport/mens/tennis' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Tennis</NtsLink>
        <NtsLink to='/en/en_US/nts/sport/mens/training' onClick={props.clickHandler} className="nts-nav-bolded-item mobile-option">Training</NtsLink>
      </div>

        <div className="nts-nav-bar-col with-left-bar hidden-xs hidden-sm">
            <div className="nts-nav-bar-title">Uniforms</div>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/9/?sort=rowNumber|desc&selected=hc_UN_Tops' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Tops</NtsLink>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/10/?sort=rowNumber|desc&selected=hc_UN_Bottoms' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Bottoms</NtsLink>
        </div>

        <div className="nts-nav-bar-col hidden-xs hidden-sm">
            <div className="nts-nav-bar-title">Clothing Tops</div>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/12/?sort=rowNumber|desc&selected=hc_Baselayer_and_Nike_Pro' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Baselayer & Nike Pro</NtsLink>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/13/?sort=rowNumber|desc&selected=hc_Short_Sleeve' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Short Sleeves</NtsLink>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/14/?sort=rowNumber|desc&selected=hc_Long_Sleeve' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Long Sleeves</NtsLink>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/15/?sort=rowNumber|desc&selected=hc_Hoodies_and_Pullovers' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Hoodies & Pullovers</NtsLink>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/16/?sort=rowNumber|desc&selected=hc_Sleeveless_and_Tanks' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Sleeveless & Tanks</NtsLink>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/17/?sort=rowNumber|desc&selected=hc_Jackets' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Jackets</NtsLink>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/18/?sort=rowNumber|desc&selected=hc_Polos' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Polos</NtsLink>
        </div>

        <div className="nts-nav-bar-col hidden-xs hidden-sm">
            <div className="nts-nav-bar-title">Clothing Bottoms</div>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/20/?sort=rowNumber|desc&selected=hc_Shorts' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Shorts</NtsLink>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/21/?sort=rowNumber|desc&selected=hc_Pants' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Pants</NtsLink>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/23/?sort=rowNumber|desc&selected=hc_Tights_and_Capris' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Tights & Capris</NtsLink>
        </div>

        <div className="nts-nav-bar-col hidden-xs hidden-sm">
            <div className="nts-nav-bar-title">Gear</div>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/25/?sort=rowNumber|desc&selected=hc_Bags' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Bags</NtsLink>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/25/?sort=rowNumber|desc&selected=hc_Balls' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Balls</NtsLink>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/25/?sort=rowNumber|desc&selected=hc_Belts' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Belts</NtsLink>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/27/?sort=rowNumber|desc&selected=hc_Gloves' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Gloves</NtsLink>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/28/?sort=rowNumber|desc&selected=hc_Headwear' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Headwear</NtsLink>
            <NtsLink to='/en/en_US/nts/gridwall/M/any/29/?sort=rowNumber|desc&selected=hc_Socks' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Socks</NtsLink>
        </div>

    <div className="nts-nav-bar-col hidden-xs hidden-sm">
        <div className="nts-nav-bar-title">Graphic Tees</div>
        <NtsLink to='/en/en_US/nts/gridwall/M/any/30/?sort=rowNumber|desc&selected=hc_GT_Short_Sleeve' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Short Sleeve</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/M/any/31/?sort=rowNumber|desc&selected=hc_GT_Long_Sleeve' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Long Sleeve</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/M/any/32/?sort=rowNumber|desc&selected=hc_GT_Hoodies_and_Pullovers' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Hoodies & Pullovers</NtsLink>
        <div className="nts-nav-bar-title">Coach Gear</div>
        <NtsLink to='/en/en_US/nts/gridwall/M/any/33/?sort=rowNumber|desc&selected=hc_CG_Tops' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Tops</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/M/any/34/?sort=rowNumber|desc&selected=hc_CG_Bottoms' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Bottoms</NtsLink>
        <NtsLink to='/en/en_US/nts/gridwall/M/any/35/?sort=rowNumber|desc&selected=hc_CG_Gear' onClick={props.clickHandler} className="nts-nav-bar-item mobile-option">Gear</NtsLink>
    </div>

    </div>
  )
};
