import React from "react";
import {Col, Popover, Row, OverlayTrigger} from "react-bootstrap";

/**
 * Provides a clickable/selectable row for each cart
 * @param props
 * @returns {*}
 * @constructor
 */
const CartSelectorRow = (props) => {

  const popover = (
    <Popover style={{zIndex:"100000"}} id="popover-trigger-hover-focus" title="">
      <strong>Custom products are not eligible to be redeemed with a coupon</strong>
    </Popover>
  );

  const getCartSelectRow = () => {
    if(props.isJba && props.cart.couponCode) {
      return (
        <OverlayTrigger trigger={['hover','focus']} placement="bottom" overlay={popover}>
          <div className="dlr-cart-order-line disabled" data-cart-id={props.cart.id}>
            <input type="checkbox" checked={false} disabled data-cart-id={props.cart.id} className="nike-check"/>
            <span className="disabled">{props.cart.name}</span>
          </div>
        </OverlayTrigger>
      )
    } else {
      return (
        <div className="dlr-cart-order-line" onClick={props.onClick} data-cart-id={props.cart.id} data-coupon-code={props.cart.couponCode}>
          <input type="checkbox" checked={props.isSelected} onClick={props.onClick} data-cart-id={props.cart.id} className="nike-check"/>
          <span onClick={props.onClick} data-cart-id={props.cart.id}>{props.cart.name}</span>
        </div>
      )
    }
  };

  return (
      <Row key={props.cart.id}>
        <Col xs={12}>
          {getCartSelectRow()}
        </Col>
      </Row>
  );

};

export default CartSelectorRow;