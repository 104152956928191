import * as React from "react";
import ReactTable from "react-table";
import { FactoryShipGroupState } from "../factory-ship-group/adminFactoryShipGroupHelper";
interface Props {
    state: FactoryShipGroupState,
    shipGroup: any, //TODO: need to define type properly
    handleSort: (item: any) => void;
    handlePagination: (size: number, page: number) => void;
    updateRow: (value: any, key: any, index: number) => void;
    addRow: () => void;
    deleteRow: (item: any, index: number) => void;
    acceptRow: (item: any, index: number) => void;
}
export const OrderDeadlineTable: React.FC<Props> = ({
    state,
    shipGroup,
    handlePagination,
    handleSort,
    updateRow,
    addRow,
    deleteRow,
    acceptRow
}) => {

    return (
        <div>
            <div className="nts-styles-header">
                <h1>{shipGroup.team_shp_grp_nm}</h1>
            </div>
            <div className="nts-tab-search-results">
                <ReactTable
                    className="nts-table"
                    data={state.results}
                    noDataText={state.message}
                    loading={false}
                    pages={Math.ceil(state.count / state.size)}
                    page={state.page}
                    pageSize={state.size}
                    manual={true}
                    onSortedChange={(newSorted, column, shiftKey) => {
                        handleSort(newSorted)
                    }}
                    onPageChange={(page: number) => {
                        handlePagination(state.size, page);
                    }}
                    onPageSizeChange={(newPageSize: number, newPage: number) => {
                        handlePagination(newPageSize, 0);
                    }}
                    columns={[
                        {
                            Header: "GROUP ID",
                            accessor: "id",
                            show: false,
                        },
                        {
                            Header: "ORDER BY",
                            accessor: "ddln_dt",
                            Cell: (rowInfo: any) => {
                                return (
                                    <div style={{ position: "relative" }}>
                                        <input type="date"
                                            name="queryFromDate"
                                            placeholder="yyyy-MM-dd"
                                            maxLength={10}
                                            value={rowInfo.original.ddln_dt}
                                            data-date-inline-picker="true"
                                            onChange={e => {
                                                updateRow(e.target.value, 'ddln_dt', rowInfo.index);
                                            }}
                                        />
                                    </div>
                                );
                            },
                        },
                        {
                            Header: "ESTIMATED ARRIVAL DATE",
                            accessor: "shp_dt",
                            Cell: (rowInfo: any) => {
                                return (
                                    <div style={{ position: "relative" }}>
                                        <input type="date"
                                            name="shp_dt"
                                            placeholder="yyyy-MM-dd"
                                            maxLength={10}
                                            value={rowInfo.original.shp_dt}
                                            data-date-inline-picker="true"
                                            onChange={e => {
                                                updateRow(e.target.value, 'shp_dt', rowInfo.index);
                                            }}
                                        />
                                    </div>
                                );
                            },
                        },
                        {
                            Header: "LEAD DAYS",
                            accessor: "lead_tm_dys",
                            Cell: (rowInfo: any) => {
                                return (
                                    <div style={{ position: "relative" }}>
                                        <input type="number"
                                            name="lead_tm_dys"
                                            placeholder=""
                                            min="0"
                                            value={rowInfo.original.lead_tm_dys}
                                            onChange={e => {
                                                updateRow(e.target.value, 'lead_tm_dys', rowInfo.index);
                                            }}
                                        />
                                    </div>
                                );
                            },
                        },
                        {
                            Header: "DISCOUNT",
                            accessor: "disc_pctg",
                            Cell: (rowInfo: any) => {
                                return (
                                    <div style={{ position: "relative" }}>
                                        <input type="number"
                                            name="disc_pctg"
                                            placeholder=""
                                            min="0"
                                            max="100"
                                            value={rowInfo.original.disc_pctg}
                                            onChange={e => {
                                                updateRow(e.target.value, 'disc_pctg', rowInfo.index);
                                            }}
                                        />%
                                    </div>
                                );
                            },
                        },
                        {
                            Header: "CAPACITY",
                            accessor: "max_cpty",
                            Cell: (rowInfo: any) => {
                                return (
                                    <div style={{ position: "relative" }}>
                                        <input type="number"
                                            name="max_cpty"
                                            placeholder=""
                                            min="0"
                                            value={rowInfo.original.max_cpty}
                                            onChange={e => {
                                                updateRow(e.target.value, 'max_cpty', rowInfo.index);
                                            }}
                                        />
                                    </div>
                                );
                            },
                        },
                        {
                            Header: "REMAINING CAPACITY",
                            accessor: "curr_cpty",
                            Cell: (rowInfo: any) => {
                                return Number(rowInfo.original.curr_cpty);
                            },
                        },
                        {
                            Header: "ACTION",
                            accessor: "",
                            Cell: (rowInfo: any) => {
                                return (
                                    <div style={{ position: "relative" }}>
                                        {
                                            rowInfo.original.update && (
                                                <span className="accept-icon icon" onClick={()=>acceptRow(rowInfo.original, rowInfo.index)}></span>
                                            )
                                        }
                                        <span className="delete-icon icon" onClick={()=>deleteRow(rowInfo.original, rowInfo.index)}></span>
                                    </div>
                                );
                            },
                        },
                    ]}
                    minRows={0}
                    style={{ height: "auto" }}
                />
            </div>
            <div className={'container-modal text-left'}>
                <button className={'pdp-cart-btn btn btn-primary'} onClick={addRow}>
                    Add
                </button>
            </div>
        </div>
    )
}
