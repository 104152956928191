import React from "react";

export class ColorPalette extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isActive:false,
      message: ''
    }
  }

  getColor = (rgb) => {
    if(rgb.red === 255 && rgb.green === 255 && rgb.blue === 255) {
      return '#000';
    } else {
      return '#FFF';
    }
  };

  getRGB = (rgb) => {
    return `rgb(${rgb.red}, ${rgb.green}, ${rgb.blue})`;
  };

  copyToClipboard = (e) => {
    let dummy = document.createElement('input');
    let text = this.props.color.name.toUpperCase();
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  };

  render() {
    return (
      <div className="nts-color-palette"
       style={{ color: this.getColor(this.props.color.rgb), backgroundColor: this.getRGB(this.props.color.rgb) }}
       onClick={this.copyToClipboard}
      >
        <div className="color-name">{this.props.color.name}</div>
      </div>
    );
  }

}