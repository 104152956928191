import React from 'react';
import Spinner from "./Spinner";

/**
 * Transitional (blank) loading page for use with Suspense/Lazy Loading
 * @constructor
 */
const LoadingPage:React.FC = () => (
    <div className="container nts-loading-screen">
        <Spinner />
    </div>
);

export default LoadingPage;