import {Reducer, Action} from 'redux';

import {TemplateComponent} from '../models/template';
import * as types from '../actions/actionTypes';

export interface UniformDesignState {
  id: string | null;
  styleCode: string;
  colorCode: string;
  templates: {
    [side: string]: {
      modified: boolean;
      data: {
        components: TemplateComponent[];
      };
    };
  };
}

// TODO: These actions can be separate types later
interface UniformDesignAction extends Action {
  colorNumber: string;
  design: UniformDesignState;
}

const initialState: UniformDesignState = {
  id: null,
  styleCode: '',
  colorCode: '',
  templates: {},
};

const uniformDesignReducer: Reducer<UniformDesignState> = (
    state = initialState,
    incomingAction,
) => {
  const action = incomingAction as unknown as UniformDesignAction;
  switch (action.type) {
    case types.LOAD_DESIGN:
      return {
        ...action.design
      };

    case types.SET_DESIGN_COLOR:
      let designState = {
        ...state,
        colorCode: action.colorNumber
      };
      //Flag all templates as modified so new images will be rendered to S3
      for (let side in designState.templates) {
        designState.templates[side].modified = true;
      }
      return designState;

    case types.CLEAR_PRODUCT_FOR_PDP:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default uniformDesignReducer;
