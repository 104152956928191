import {fetchRequest, urlNtsServices} from "./index";
import {LS_ACCESS_TOKEN} from "../../helpers/constants";

export const DesignSearchService = {

  designSearchById: (designId) => {
    let url = `${urlNtsServices}/designsearch/v1?designId=${designId}`;
    const request = new Request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': localStorage.getItem(LS_ACCESS_TOKEN)
      }
    });
    return fetchRequest(request);
  }

};