import {Reducer, Action} from 'redux';

import * as types from "../actions/actionTypes";

export interface OrderDeadlineStyle {
    groupId: number;
    groupName: string;
    name: string;
    primaryGender: string;
    primarySport: string;
    styleCode: string;
}

export interface ShipGroup {
    name: string;
    orderByDate: string;
    shipByDate: string;
    discount: number;
    remainingCapacity: number;
    styles: OrderDeadlineStyle[];
}

interface OrderDeadlinesAction extends Action {
    shipGroups: ShipGroup[],
    totalShipGroups: number;
}

export interface OrderDeadlinesState {
    shipGroups: ShipGroup[];
    totalShipGroups: number;
}

const initialState: OrderDeadlinesState = {
    shipGroups: [],
    totalShipGroups: 0
};

const orderDeadlinesReducer: Reducer<OrderDeadlinesState> = (
    state = initialState,
    incomingAction
) => {

    const action = incomingAction as unknown as OrderDeadlinesAction;

    switch (action.type) {

        case types.SET_ORDER_DEADLINES:
            return {
                ...state,
                shipGroups: action.shipGroups,
                totalShipGroups: action.totalShipGroups
            };

        case types.CLEAR_ORDER_DEADLINES:
            return {
                shipGroups: [],
                totalShipGroups: 0
            };

        default:
            return state;
    }
}

export default orderDeadlinesReducer;