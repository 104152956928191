import * as React from 'react';
import {calculateCapacityPercentage, OrderDeadlineDetails, getFormattedDate} from "./orderDeadlinesHelper";

interface Props {
    orderDeadlines: OrderDeadlineDetails[];
}

export const OrderDeadlinesTable: React.FC<Props> = ({orderDeadlines}) => {
    return (
        <table className="table nts-table" id="order-deadlines-table">
            <thead>
            <tr>
                <th>ORDER BY</th>
                <th>ESTIMATED ARRIVAL DATE</th>
                <th>DISCOUNT</th>
                <th>REMAINING CAPACITY</th>
            </tr>
            </thead>
            <tbody>
            {orderDeadlines.map(({deadlineDate,shipDate,leadtimeDays, discount, currentCapacity, maxCapacity}) => {
                return (
                <tr key={`${deadlineDate}-${shipDate}`}>
                    <td>{getFormattedDate(deadlineDate)}</td>
                    <td>
                        <p>{getFormattedDate(shipDate)}</p>
                        <p>{leadtimeDays ? leadtimeDays + ' days' : ''}</p>
                    </td>
                    <td>{discount}</td>
                    <td>{calculateCapacityPercentage(currentCapacity, maxCapacity)}</td>
                </tr>
                )
            })}
            </tbody>
        </table>
    );
};
