export interface EndpointUrlMap { [key: string]: string }

interface EndpointTokenMap { [key: string]: string }

const endpointTokenToType: EndpointTokenMap = {
    '8090': 'dev',
    '9000': 'stg',
    '9001': 'qa',
    '9003': 'uat',
    '9002': 'prod',
    'localhost': 'local',
    'local': 'local',
    'ntsdev': 'dev',
    'ntsqa': 'qa',
    'ntsstage': 'stg',
    'ntsuat': 'uat',
    'niketeam': 'prod'
};

function calculateEndpointToken(location:Location) {
    const { hostname } = location;
    if (
        hostname === '127.0.0.1' ||
        hostname === 'localhost' ||
        hostname.indexOf('xip.io') !== -1 ||
        /^\d+\.\d+\.\d+\.\d+$/.test(hostname)
    ) {
        return 'local';
    }
    return hostname.split('.', 1)[0].toLowerCase();
}

export const calculateEndpointType = (location:Location, defaultType:string) => {
    const endpointToken = calculateEndpointToken(location);
    return endpointTokenToType.hasOwnProperty(endpointToken)
        ? endpointTokenToType[endpointToken]
        : defaultType;
};
