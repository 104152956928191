import { factoryShipgroupFetch } from ".";

export const getShipGroupOrderDeadlineList = async (shipGroupId:number, query: any) => {
    const url = `/order_deadline/order_deadlines/v1/ship_group/${shipGroupId}?${query}`;
    return factoryShipgroupFetch(url);
}

export const addShipGroupOrderDeadline = async (body: any) => {
    const url = `/order_deadline/order_deadlines/v1`
    return factoryShipgroupFetch(url, 'POST', body);
}

export const deleteShipGroupOrderDeadline = async (teamShipGrpWndwId: number) => {
    const url = `/order_deadline/order_deadlines/v1/${teamShipGrpWndwId}`;
    return factoryShipgroupFetch(url, 'DELETE');
}

export const updateShipGroupOrderDeadline = async (teamShipGrpWndwId: string, body: any) => {
    const url = `/order_deadline/order_deadlines/v1/${teamShipGrpWndwId}`;
    return factoryShipgroupFetch(url, 'PATCH', body);
}

export const getShipGroupEmbellishmentList = async (shipGroupId:number, query: any) => {
    const url = `/ship_group/embellishment/v1/all/${shipGroupId}?${query}`;
    return factoryShipgroupFetch(url);
}

export const getAssignedShipGroupEmbellishmentList = async (shipGroupId:number, query: any) => {
    const url = `/ship_group/embellishment/v1/${shipGroupId}?${query}`;
    return factoryShipgroupFetch(url);
}

export const addShipGroupEmbellishment = async (shipGroupId: number, body: any) => {
    const url = `/ship_group/embellishment/v1/${shipGroupId}`
    return factoryShipgroupFetch(url, 'POST', body);
}

export const deleteShipGroupEmbellishment = async (teamShipGrpEmbId: number) => {
    const url = `/ship_group/embellishment/v1/id/${teamShipGrpEmbId}`
    return factoryShipgroupFetch(url, 'DELETE');
}

export const updateShipGroupEmbellishment = async (teamShipGrpEmbId: number, body: any) => {
    const url = `/ship_group/embellishment/v1`
    return factoryShipgroupFetch(url, 'PATCH', {...body, team_shp_grp_emb_id: teamShipGrpEmbId});
}

export const getFactoryList = async (query: any) => {
    const url = `/factory/factories/v1?${query}`;
    return factoryShipgroupFetch(url);
}

export const addFactory = async (body: any) => {
    const url = `/factory/factories/v1`;
    return factoryShipgroupFetch(url, 'POST', body);
}

export const updateFactory = async (factoryId: any, body: any) => {
    const url = `/factory/factories/v1/${factoryId}`
    return factoryShipgroupFetch(url, 'PATCH', body);
}

export const deleteFactory = async (factoryId: any) => {
    const url = `/factory/factories/v1/${factoryId}`
    return factoryShipgroupFetch(url, 'DELETE');
}

export const getFactoryShipGroupList = async (factoryId:number, query: any) => {
    const url = `/ship_group/ship_groups/v1/factory/${factoryId}?${query}`;
    return factoryShipgroupFetch(url);
}

export const addFactoryShipGroup = async (body: any) => {
    const url = `/ship_group/ship_groups/v1`;
    return factoryShipgroupFetch(url, 'POST', body);
}

export const deleteFactoryShipGroup = async (shipGroupId: any) => {
    const url = `/ship_group/ship_groups/v1/${shipGroupId}`
    return factoryShipgroupFetch(url, 'DELETE');
}

export const updateFactoryShipGroup = async (shipGroupId: any, body: any) => {
    const url = `/ship_group/ship_groups/v1/${shipGroupId}`
    return factoryShipgroupFetch(url, 'PATCH', body);
}

export const getShipGroupStyleList = async (shipGroupId:number, query: any) => {
    const url = `/ship_group/styles/v1/all/${shipGroupId}?${query}`;
    return factoryShipgroupFetch(url);
}

export const getAssignedShipGroupStyleList = async (shipGroupId:number, query: any) => {
    const url = `/ship_group/styles/v1/${shipGroupId}?${query}`;
    return factoryShipgroupFetch(url);
}

export const addShipGroupStyle = async (shipGroupId: number, body: any) => {
    const url = `/ship_group/styles/v1/${shipGroupId}`;
    return factoryShipgroupFetch(url, 'POST', body);
}

export const deleteShipGroupStyle = async (shipGroupId: number, productStyleCode: string) => {
    const url = `/ship_group/styles/v1/${shipGroupId}/${productStyleCode}`
    return factoryShipgroupFetch(url, 'DELETE');
}

export const AdminFactoryService = {
    getFactoryList,
    addFactory,
    deleteFactory,
    getFactoryShipGroupList,
    addFactoryShipGroup,
    deleteFactoryShipGroup,
    getShipGroupStyleList,
    getAssignedShipGroupStyleList,
    addShipGroupStyle,
    deleteShipGroupStyle,
    updateFactoryShipGroup,
    updateFactory
};
