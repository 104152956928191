import React from 'react';

interface Props {
    videoId: string;
    allowFullScreen: boolean;
}

const BRIGHTCOVE_PLAYER_URL = "//players.brightcove.net/72451143001/V8N0lbPfK_default/index.html"

/**
 *
 * Wrapper for NikeTeam Brightcove video player
 *    https://studio.brightcove.com/products/videocloud/players/players/V8N0lbPfK
 *
 */
export class BrightcovePlayer extends React.Component<Props> {

    render() {

        const videoUrl = `${BRIGHTCOVE_PLAYER_URL}?videoId=${this.props.videoId}`;

        return (
            <iframe
                src={videoUrl}
                allowFullScreen={this.props.allowFullScreen}
                allow="encrypted-media"
            />
        );
    }
}
