import React from 'react';

/**
 * Nike Team Spinner Element
 *   Notes:
 *      Parent elements should manage visibility state
 *      8 Empty <div> elements are necessary, do not remove
 * @constructor
 */
const Spinner:React.FC = () => (
    <div className="spinner-container">
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
);

export default Spinner;