/**
 * Provides utility functions for redux "userState" object
 */
import * as constants from './constants';
import {store} from '../../App';
import {isRetailExperience} from "./retailHelpers";
import {removeUserEmailFromList} from "./checkoutHelpers";
import {cookies} from "../api/services";
import {
  COOKIE_IMPERSONATED_AS_ADMIN,
  COOKIE_IMPERSONATED_USER_ID,
} from "./constants";

const adminAuths = [
    'ROLE_USER_SEARCH', 'ROLE_DCART_SHARE', 'ROLE_DEALER_SEARCH', 'ROLE_DEALER_EDIT',
    'ROLE_SHIP_GROUP_SEARCH', 'ROLE_ADMIN_REPORTING','ROLE_ADMIN_ANALYTICS'
];

//--------------------------------//
//-----( User State Helpers )-----//
//--------------------------------//
export const isLoggedIn = () => {
  return (store.getState().userState && !!(store.getState().userState.isLoggedIn) === true);
}

//----------------------------------//
//-----( User Account Helpers )-----//
//----------------------------------//
export function getUpmId() {
  if (!store.getState().userState ||
      !store.getState().userState.profile) {
        return null;
  }
  return store.getState().userState.profile.id;
}

export function getUserId() {
  if (!store.getState().userState ||
      !store.getState().userState.profile) {
    return "Temp"; //Used for dev systems
  }

  return store.getState().userState.profile.teamUserId;
}

export function getUsername() {
  if (!store.getState().userState ||
      !store.getState().userState.profile) {
    return '';
  }

  return store.getState().userState.profile.screenname;
}

export function getUserEmail() {
    return store.getState().userState.profile.email;
}

export const getJbaAccountNumber = () => {
  if (store.getState().userState &&
      store.getState().userState.accounts &&
      store.getState().userState.accounts.jbaAccountNumber) {
    return store.getState().userState.accounts.jbaAccountNumber;
  } else {
    return '';
  }
}

export function getUserSoldTo() {
  /*
   * //-- Reactivate to support retailer SoldTo override on front end
   * if(isRetailExperience()) {
   *   return getRetailerConfig().soldTo;
   * } else if( ... )
  */
  if (store.getState().userState &&
      store.getState().userState.accounts &&
      store.getState().userState.accounts.sapAccountNumber &&
      store.getState().userState.accounts.sapAccountNumber !== '') {
    return store.getState().userState.accounts.sapAccountNumber;
  } else {
    return constants.DEFAULT_TEAM_SPORTS_SOLD_TO;
  }
}

export function getConsumerCouponSoldTo() {
  if (store.getState().userState &&
      store.getState().userState.accounts &&
      store.getState().userState.accounts.sapCouponAccountNumber &&
      store.getState().userState.accounts.sapCouponAccountNumber !== '') {
    return store.getState().userState.accounts.sapCouponAccountNumber;
  } else {
    return constants.DEFAULT_TEAM_SPORTS_COUPON_SOLD_TO;
  }
}

// Returns list of default email(s) to notify when an order is placed
export function getDealerDefaultEmails() {
  if(isRetailExperience() && isConsumer()) {
    const userState = store.getState().userState;
    const defaultEmails = (store.getState().userState.dealership && store.getState().userState.dealership.defaultEmailAddresses) ? store.getState().userState.dealership.defaultEmailAddresses : '';
    return removeUserEmailFromList(userState.email, defaultEmails, ',');
  } else {
    return (store.getState().userState.dealership && store.getState().userState.dealership.defaultEmailAddresses) ? store.getState().userState.dealership.defaultEmailAddresses : "";
  }
}

// Returns dealershipId based on role
export function getDealershipId() {
  const userState = store.getState().userState;
  try {
    if(isImpersonated()) {
      return userState.impersonatedUser.teamDlrId;
    } else {
      return userState.dealership.dealershipId;
    }
  } catch(e) {
    return null;
  }

}

// Returns if user is impersonated (either as admin or as user)
export function isImpersonated() {
  return !!cookies.get(COOKIE_IMPERSONATED_USER_ID);
}

// Returns if user is impersonated (as admin)
export const isImpersonatedAsAdmin = () => {
  const asAdminFlag = cookies.get(COOKIE_IMPERSONATED_AS_ADMIN) === 'true';
  return isImpersonated() && asAdminFlag;
}

// Returns if user is impersonated (as user)
export function isImpersonatedAsUser() {
  const asAdminFlag = cookies.get(COOKIE_IMPERSONATED_AS_ADMIN) === 'true';
  return isImpersonated() && !asAdminFlag;
}

// Retrieves ID of user being impersonated
export const getImpersonatedUserId = () => {
  return cookies.get(COOKIE_IMPERSONATED_USER_ID);
}

//-------------------------------//
//-----( User Role Helpers )-----//
//-------------------------------//
export function hasRolePermission(permission) {
  return getAuthorities().includes(permission);
}

export function isCustomerServiceRole() {
  return hasRolePermission('ROLE_CUSTOMER_SERVICE');
}

export function isEliteDealerRole() {
  return hasRolePermission('ROLE_DEALER_ELITE');
}

export function isDealerRole() {
  return hasRolePermission('ROLE_DEALER');
}

export function isAdminRole() {
  return hasRolePermission('ROLE_USER_ADMIN');
}
export function isFactoryAdminRole() {
  return hasRolePermission('ROLE_WIP_UPLOAD');
}

export function isConsumer() {
  return hasRolePermission('ROLE_CONSUMER');
}

export function hasAdminMenu() {
  const userAuths = getAuthorities();
  return adminAuths.some(auth => userAuths.indexOf(auth) >= 0);
}

export function hasAtLeastOneRole(roles) {
  const userAuths = getAuthorities();
  return roles.some(auth => userAuths.indexOf(auth) >= 0);
}

/**
 * Determines if user is only associated with Artwork roles (Uploader, Selector)
 * NOTE: Some dealers have those roles with other roles as well
 * @returns {boolean}
 */
export function isArtworkRolesOnly() {
  return isDealerRole() && !hasRolePermission('ROLE_DCART_ORDER') && !isImpersonated();
}

//--------------------------------//
//-----( UI DISPLAY HELPERS )-----//
//--------------------------------//
export function showJbaAddToCart() {
  return isDealerRole();
}

//-------------------------------//
//-----( User Misc Helpers )-----//
//-------------------------------//

export function getOktaToken() {
  return store.getState().userState.oktaToken ? store.getState().userState.oktaToken : '';
}

/**
 * Prefixes string with 0s to match size parameter given
 * @param string
 * @param size
 * @returns {*}
 */
export function padStringLeadingZeros(string, size) {
    string = string.trim();
    while(string.length < size) string = "0" + string;
    return string;
}

/**
 * Wrapper for getting user authority list
 * @returns {*}
 */
export function getAuthorities() {
  return (store.getState().userState && store.getState().userState.authorities) ? store.getState().userState.authorities : {};
}

/**
 * Provides payload (list of fields) idnAccount/user/read call
 * @returns {{fields: string[]}}
 */
export function getFullUserProfileFieldList() {
  return {
    "fields": [
      "contact", "dob", "gender", "location", "marketing", "measurements",
      "name", "preferences", "screenname", "upmId", "registration"
    ]
  };
}
