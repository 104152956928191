import React from 'react';
import {
    getTrackItUrl,
    showNavLink,
    showOrderDeadlinesLink,
    showSalesToolsLink,
    showTrackItLink
} from "../../../helpers/navbarHelpers";
// import {
//     isAdminRole,
//     isFactoryAdminRole
// } from "../../../helpers/userProfileHelpers"
import { NtsLink } from "../../shared/ntsLink";

export const AccountOptions = (props) => {

    const timeStamp = (new Date()).getTime() / 1000;

    return (
        <div>
            {/* TODO PMENTS-2247: uncomment following link */}
            {/* {(isAdminRole() || isFactoryAdminRole()) &&
                <a className="user-link mobile-option" href={"/en/en_US/nts/factory/xls-upload"}>Factory Spreadsheet Upload</a>
            } */}
            {showTrackItLink() &&
                <a className="user-link mobile-option" onClick={props.clickHandler} href={getTrackItUrl()}>Track It</a>
            }
            {showSalesToolsLink() &&
                <div className="user-link mobile-option nts-simple-menu-toggle">
                    Sales Tools
                    <div className="nts-simple-menu sales-tools-container">
                        <NtsLink
                            className="nts-simple-menu-item mobile-option"
                            to="/en/en_US/nts/documents"
                        >
                            Documents
                        </NtsLink>
                        <NtsLink
                            className="nts-simple-menu-item mobile-option"
                            to="/en/en_US/nts/style_search"
                        >
                            Style Search
                        </NtsLink>
                    </div>
                </div>
            }
            {showOrderDeadlinesLink() &&
                <a className="user-link mobile-option" onClick={props.clickHandler} href={`/en/en_US/nts/view_deadlines`}>Order Deadlines</a>
            }
            {showNavLink('artwork') &&
                <a className="user-link mobile-option" onClick={props.clickHandler} href={`/en/en_US/p/art,browse?_t=${timeStamp}`}>Artwork</a>
            }
        </div>
    );

};
