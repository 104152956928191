import * as React from 'react';
import { FactoryShipGroupData } from './FactoryShipGroupData';

export const AdminFactoryShipGroupPage: React.FC = () => {

    return (
        <div className="nts-admin-page nts-admin-factory-page nts-admin-analytics-page">
            <div>
                <a className="nts-back-list" href="/en/en_US/nts/admin/factory">Back to Factory List</a>
                <br /><br />
                <FactoryShipGroupData />
                
            </div>
        </div>
    )

}
