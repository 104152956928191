import React from 'react';
import {connect} from "react-redux";
import Spinner from "./Spinner";

class FullPageSpinnerComponent extends React.Component {
  render() {
    return (
      <div>
        {this.props.fullLoadingSpinner > 0 && <Spinner /> }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    fullLoadingSpinner: state.loadingSpinnerState.fullLoadingSpinner
  }
};

export const FullPageSpinner = connect(
  mapStateToProps
)(FullPageSpinnerComponent);


