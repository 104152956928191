import React, {useEffect} from 'react';
import {Provider} from 'react-redux';

import './css/App.scss';
import {AppRoutes} from './AppRoutes';
import configureStore, {history} from './js/store';
import {checkForLoggedInUser, dispatchUserStateIsLoading} from "./js/actions/userProfileActions";
import {dispatchSetEnvironment} from "./js/actions/navigationActions";
import {initializeGoogleTools} from "./js/helpers/googleHelpers";
import {usePageVisibility} from "./js/helpers/pageVisibilityHelpers";
import {getAkamaiImageBaseUrl} from "./js/api/config/ntsDownloadsBaseUrl";
import {authService} from "./index";

const PRESERVE_SCROLL_PAGES = [
  'gridwall',
  'sport',
];

export const store = configureStore();
export const akamaiImageUrl = getAkamaiImageBaseUrl();

const App = () => {

  /**
   * Check if site is current tab being viewed
   * Allows for session check on startup or when users return to tab after an absence
   */
  const siteIsVisible = usePageVisibility();
  if(siteIsVisible) {
    if (authService.isAuthenticated()) {
      authService.refreshSession()
    }
    dispatchUserStateIsLoading(true);
    checkForLoggedInUser().then(() => console.debug('User session restored on startup'));
  }

  useEffect(() => {
    dispatchSetEnvironment();
    initializeGoogleTools();
  }, []);

  useEffect(() => {
      // Prevent scroll to top of page on gridwall filters
      history.listen((location, action) => {
          // @ts-ignore
          const preventScroll = location.state && location.state.preventScroll;
          const isPreserveScrollPage = PRESERVE_SCROLL_PAGES.some(regex => location.pathname.includes(regex));
          if (!isPreserveScrollPage || (isPreserveScrollPage && !preventScroll)) {
              window.scrollTo(0, 0);
          }
      });
  }, []);

  return (
    <div id="nts-app-container">
      {/* @ts-ignore */}
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    </div>
  );
}

export default App;
