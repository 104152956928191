import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

interface StyleAvailability {
  sizes: {
    [size: string]: number;
  };
}

export interface ProductAvailabilityState {
  deliveryUnitNumber: number;
  styles: {
    [style: string]: StyleAvailability;
  }
}

interface ProductAvailabilityAction extends Action {
  details: {
    [style: string]: StyleAvailability;
  };
}

const defaultState: ProductAvailabilityState = {
  deliveryUnitNumber: 1,
  styles: {},
};

const productAvailabilityReducer: Reducer<ProductAvailabilityState> = (
  state = defaultState,
  incomingAction,
) => {
  const action = incomingAction as unknown as ProductAvailabilityAction;
  switch (action.type) {
    case types.LOAD_PRODUCT_AVAILABILITY:
      return {
        ...state,
        styles: action.details,
      };

    default:
        return state;
  }
};

export default productAvailabilityReducer;
