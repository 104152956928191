import * as React from "react";
import {useEffect} from "react";
import {Dispatch} from "redux";
import {connect} from "react-redux";

// services & helpers
import {EliteOptionsService} from "../../api/services/eliteOptionsService";
import {getDealershipId} from "../../helpers/userProfileHelpers";
import {
    DEFAULT_GET_COLORS_PAYLOAD,
    ERROR_NETWORK_CALL, ERROR_NO_DEALERSHIP,
    ERROR_NO_RESULTS,
} from "./customColorsHelper";
import {
    setCustomColorList, setSelectedCustomColor,
    showAddColorModal,
    showColorDetailsModal,
    showInactiveCustomColors
} from "../../actions/customColorsActions";

// types
import {AppState} from "../../reducers";
import {CustomColor} from "../../reducers/customColorsReducer";

// components
import {Col, Row} from "react-bootstrap";
import {CustomColorsActionRow} from "./CustomColorsActionRow";
import {CustomColorsGrid} from "./CustomColorsGrid";
import {AddCustomColorModal} from "./addColor/AddCustomColorModal";
import {ColorDetailsModal} from "./colorDetails/colorDetailsModal";

export const CUSTOM_COLORS_PAGE_ROLES = ['ROLE_DEALER_ELITE','ROLE_USER_ADMIN','ROLE_CUSTOMER_SERVICE'];

interface StateProps {
    customColorState: AppState['customColorsState'],
    customColorList: CustomColor[],
    showAddColorModal: boolean,
    showColorDetailsModal: boolean,
    showInactiveColors: boolean,
    selectedColor: CustomColor | null,
}

interface DispatchProps {
    dispatchShowAddColorModal: (show:boolean) => void
    dispatchShowColorDetailsModal: (show:boolean) => void,
    dispatchSetCustomColorList: (list:CustomColor[]) => void,
    dispatchShowInactiveCustomColors: (show:boolean) => void,
    dispatchSetSelectedCustomColor: (color: CustomColor | null) => void,
}

type Props = StateProps & DispatchProps;

const CustomColorsClass: React.FC<Props> = ({
   dispatchShowAddColorModal,
   dispatchShowColorDetailsModal,
   dispatchShowInactiveCustomColors,
   dispatchSetCustomColorList,
   dispatchSetSelectedCustomColor,
   customColorState,
   customColorList,
   showAddColorModal,
   showColorDetailsModal,
   showInactiveColors,
   selectedColor,
}) => {

    const [searchValue, setSearchValue] = React.useState('');
    const [pageError, setPageError] = React.useState('');
    const dealershipId = getDealershipId();

    const loadColors = () => {
        const fetchColorData = async () => {
            if(!dealershipId) {
                setPageError(ERROR_NO_DEALERSHIP);
                return;
            }
            const response = await EliteOptionsService.getCustomColorsForDealership(DEFAULT_GET_COLORS_PAYLOAD, dealershipId);
            const hasColors = response && response.data && response.data.result && response.data.result.length > 0;
            if(hasColors) {
                setPageError('');
                dispatchSetCustomColorList(response.data.result);
            } else {
                const error = (response.error && response.message) ? response.message : ERROR_NO_RESULTS;
                setPageError(error);
            }
        }
        fetchColorData().catch((e) => {
            const error = (e.error && e.message) ? e.message : ERROR_NETWORK_CALL;
            setPageError(error);
        });
    }

    useEffect( () => {
        loadColors();
    }, []);

    const handleAddCustomColor = () => {
        dispatchShowAddColorModal(false);
        loadColors();
    }

    const handleColorSelect = (clear: boolean, color:CustomColor | null, refresh: boolean = false) => {
        if(clear) {
            dispatchSetSelectedCustomColor(null);
            dispatchShowColorDetailsModal(false);
        } else {
            dispatchSetSelectedCustomColor(color);
            dispatchShowColorDetailsModal(true);
        }
        if(refresh) {
            loadColors();
        }
    }

    let filteredColorList: CustomColor[] | [] = customColorList;

    if(searchValue) {
        filteredColorList = filteredColorList.filter((color) =>
            color.colorName.toLowerCase().includes(searchValue.trim().toLowerCase()) || color.schoolName?.toLowerCase().includes(searchValue.trim().toLowerCase())
        )
    }

    if(!showInactiveColors) {
        filteredColorList = filteredColorList.filter(color => color.status.toLowerCase() === 'a');
    }

    return (
        <div className="container nts-custom-colors-page">
            <Row>
                <Col xs={12}>
                    <div className="title">Custom Colors</div>

                    {dealershipId &&
                        <CustomColorsActionRow
                            onAddColor={() => dispatchShowAddColorModal(true)}
                            onSearch={(val) => setSearchValue(val)}
                            onShowInactive={(show) => dispatchShowInactiveCustomColors(show)}
                            searchValue={searchValue}
                            showInactive={showInactiveColors}
                        />
                    }

                    <CustomColorsGrid
                        colors={filteredColorList}
                        error={pageError}
                        onColorSelect={(color) => handleColorSelect(false, color, false)}
                    />

                    {showAddColorModal &&
                        <AddCustomColorModal
                            onHide={() => dispatchShowAddColorModal(false)}
                            onAdd={() => handleAddCustomColor()}
                        />
                    }

                    {showColorDetailsModal && selectedColor !== null &&
                        <ColorDetailsModal
                            color={selectedColor}
                            onHide={(refresh) => handleColorSelect(true, null, refresh)}
                        />
                    }

                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    customColorState: state.customColorsState,
    customColorList: state.customColorsState.customColorList,
    showAddColorModal: state.customColorsState.showAddColorModal,
    showColorDetailsModal: state.customColorsState.showColorDetailsModal,
    showInactiveColors: state.customColorsState.showInactiveColors,
    selectedColor: state.customColorsState.selectedColor,
});

const mapDispatchToProps = (dispatch: Dispatch<unknown>): DispatchProps => ({
    dispatchShowAddColorModal: (show: boolean) => dispatch(showAddColorModal(show)),
    dispatchShowColorDetailsModal: (show: boolean) => dispatch(showColorDetailsModal(show)),
    dispatchSetCustomColorList: (list: CustomColor[]) => dispatch(setCustomColorList(list)),
    dispatchShowInactiveCustomColors: (show: boolean) => dispatch(showInactiveCustomColors(show)),
    dispatchSetSelectedCustomColor: (color: CustomColor | null) => dispatch(setSelectedCustomColor(color)),
})

export const CustomColorsPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomColorsClass);