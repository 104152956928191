import React from 'react';
import {Row, Col} from 'react-bootstrap';

import embodeeBuilderBaseUrl from "../../api/config/embodeeBuilderBaseUrl";
import {getSapStockImageUrl} from "../../helpers/checkoutHelpers";

/**
 * Displays list of invalid fill-in cart products. Supports JBA, Custom Stock and Blank Stock products.
 *
 * @usage:
 * <FillInErrorPanel products={[ { design:{}, productDetails:{}, error:'' } ]} isJba={true|false} />
 *
 * @props
 * errors - Array of objects with imgSrc, errMsg
 *
 */
export class FillInErrorPanel extends React.Component {
  
  getImageSrc = (product) => {
    if (this.props.isJba) { //is jba product
      const embodeeImageUrl = embodeeBuilderBaseUrl(window.location);
      return `${embodeeImageUrl}/builder/ud/image/${product.designId}/uniform/200,400,png`;
    } else if (product.design) { //is custom stock product
      //TODO: User checkoutHelpers.getSapCustomStockImageUrl(uniformDetails, product)?
      return product.design.templates['Front'].imageUrl ? product.design.templates['Front'].imageUrl : product.design.templates['Back'].imageUrl;
    } else { //is stock product
      try {
        const styleColor = `${product.selectedStyle.styleNumber}-${product.selectedStyle.colorNumber}`;
        return getSapStockImageUrl(styleColor);
      } catch(e) {
        return 'https://images.nike.net/is/image/GTM/SEARCH_002_A?wid=160&hei=240&fmt=png-alpha&qlt=90,0&resMode=sharp&op_usm=0.5,0.3,0,0';
      }
    }
  };

  getProductName = (product) => {
    try {
      if(this.props.isJba){
        return product.name;
      } else {
        return product.selectedStyle.details.displayName;
      }
    } catch(e) {
      return 'Name Not Available';
    }
  };

  getProductUid = (product) => {
    if (this.props.isJba) { //is jba product
      return product.designId || 'Uniform ID Not Available';
    } else if (product.design) { //is custom stock product
      return product.design.id || 'Design ID Not Available';
    } else {
      try {
        return `${product.selectedStyle.styleNumber}-${product.selectedStyle.colorNumber}`;
      } catch(e) {
        return 'Style-Color Not Available';
      }
    }
  };
  
  render() {
    
    if (Array.isArray(this.props.products) && this.props.products.length) {
      
      const productsWithErrors = this.props.products.filter((product) => {
        return (product.error)
      });

      if (Array.isArray(productsWithErrors) && productsWithErrors.length) {
        
        const jbaClass = this.props.isJba ? 'is-jba' : '';
        
        return (

          <div className="nts-cart-fillin-error-panel">
            <Row>
              <Col xs={12}>
                <div className="nts-cart-fillin-error-title">
                  <h4>FILL-IN ERROR</h4>
                </div>
              </Col>
            </Row>

            {productsWithErrors.map((product, i) => (
              <Row className={`nts-cart-fillin-error-product-container ${jbaClass}`} key={i}>
                <Col xs={12}>
                   <div className="nts-cart-fillin-product-image">
                     <span className={`nsg-glyph--alert ${jbaClass}`}></span>
                     <img className={`${jbaClass}`} src={this.getImageSrc(product)} alt="Product ineligible for fill-in" />
                   </div>
                   <div className={`nts-cart-fillin-product-content ${jbaClass}`}>
                     <div className="nts-cart-filling-product-details">
                       <p className="nts-cart-fillin-product-name">{this.getProductName(product)}</p>
                       <p className="nts-cart-fillin-product-id">{this.getProductUid(product)}</p>
                       <div className="nts-cart-fillin-error-msg">{product.error}</div>
                     </div>
                   </div>
                </Col>
              </Row>
            ))}

          </div>
        );
      } else {
        // No errors to display for fill-in cart
        return null;
      }
    }
  }
}