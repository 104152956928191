import * as React from "react";

interface Props {
    state: any,
    handleSearch: () => void,
    updateState: (state:any) => void
}

export const SearchBar: React.FC<Props> = ({
    state,
    handleSearch,
    updateState
}) => {
    return (
        <div className="nts-analytics-search-bar">

            <label htmlFor="queryFromDate">
                <span>From Date:</span>
                <input type="date"
                       name="queryFromDate"
                       placeholder="yyyy-MM-dd"
                       maxLength={10}
                       defaultValue={state.fromDate}
                       data-date-inline-picker="true"
                       onChange={e => updateState({...state, results: [], fromDate: (e.target as HTMLInputElement).value})}
                />
            </label>

            <label htmlFor="queryToDate">
                <span>To Date:</span>
                <input type="date"
                       name="queryToDate"
                       placeholder="yyyy-MM-dd"
                       maxLength={10}
                       defaultValue={state.toDate}
                       onChange={e => updateState({...state, results: [], toDate: (e.target as HTMLInputElement).value})}
                />
            </label>

            <label>
                <span>Group By:</span>
                <select onChange={e => updateState({...state, results: [], filter: (e.target as HTMLSelectElement).value})}>
                    {state.options && Object.keys(state.options).map((key, i) => {
                        const item = state.options[key];
                        return (<option value={key} key={i}>{item}</option>)
                    })}
                </select>
            </label>

            <button type="button" className="btn btn-primary" onClick={handleSearch}>Search</button>

        </div>
    )
}
