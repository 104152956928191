import React from 'react';
import {LazyImage} from 'react-lazy-images';

/**
 * Provides lazy loading wrapper for <img> tags
 * @usage
 *    <LazyImage src={myImgSrc} alt={myImgAltText} {...otherProps} />
 * @documentation
 *    https://github.com/fpapado/react-lazy-images
 */
export class NtsLazyImage extends React.Component {

  getLoadingElement = () => {
    return (
      <div className="nts-lazy-img-loading">
        <p>Loading...</p>
      </div>
    )
  };

  getErrorElement = () => {
    return (
      <div className="nts-lazy-img-error">
        <p>Error loading image</p>
      </div>
    )
  };

  render() {
    return (
      <LazyImage
        src={this.props.src}
        alt={this.props.alt}
        key={this.props.key}
        actual={({imageProps}) => <img alt="" className={this.props.className} {...imageProps} />}
        placeholder={({ref}) => <div ref={ref} />}
        loading={() => this.getLoadingElement()}
        error={() => this.getErrorElement()}
      />
    );
  }

}