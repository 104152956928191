import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import {AuthProvider} from "./auth/providers/authProvider";
import AuthService from "./auth/services/authService";

// import reportWebVitals from './reportWebVitals';

export const authService = new AuthService();

ReactDOM.render(
  <React.StrictMode>
      <AuthProvider authService={authService}>
          <App />
      </AuthProvider>
  </React.StrictMode>,
  document.getElementById('nts-stock-builder-app')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
