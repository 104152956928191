import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

import {getEnvironment} from "./browserHelpers";
import {ENVIRONMENT_PROD} from "./constants";

const env = getEnvironment();
const googleTagManagerAcct = (env === ENVIRONMENT_PROD) ? 'GTM-5TS8KV7' : 'GTM-N4PFKSB';
const googleAnalyticsAcct = (env === ENVIRONMENT_PROD) ? 'UA-98099401-1' : 'UA-109187511-1';

/**
 * Initializes Google Tag Manager & Analytics by setting account IDs
 */
export const initializeGoogleTools = () => {
    ReactGA.initialize(googleAnalyticsAcct);
    TagManager.initialize({gtmId: googleTagManagerAcct});
}

/**
 * Adds a new dimension to the dataLayer (to be read by Analytics)
 * @param type
 * @param value
 */
export const setAnalyticsDimension = (type, value) => {
    ReactGA.set({ [type]: value });
}