export interface OrderDeadlineStyle {
    displayName: string;
    styleNumber: string;
}

export interface OrderDeadlineDetails {
    currentCapacity: number|null;
    deadlineDate: string;
    discount: number;
    leadtimeDays: number;
    maxCapacity: number|null;
    shipDate: string;
}

export interface OrderDeadline {
    ids: number[];
    deadlines: OrderDeadlineDetails[];
    lastUpdated: number;
    name: string;
    styleCount: number;
    styles: OrderDeadlineStyle[];
}

// Converts millisecond date to (month day, year) format w/o local timezone
export const getDeadlineFormattedDate = (utcSeconds: number) => {
    utcSeconds = +utcSeconds;
    if(isNaN(utcSeconds) || utcSeconds === null) {
        return 'Invalid Date';
    }
    const rawDate = new Date(utcSeconds); // The 0 there is the key, which sets the date to the epoch
    const userTimezoneOffset = rawDate.getTimezoneOffset() * 60000;
    return new Date(rawDate.getTime() + userTimezoneOffset).toLocaleDateString('en-us', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });
}

// Converts string date or number to us format
export const getFormattedDate = (strDate: string|number) => {
    const rawDate = new Date(strDate); // The 0 there is the key, which sets the date to the epoch
    const userTimezoneOffset = rawDate.getTimezoneOffset() * 60000;
    return new Date(rawDate.getTime() + userTimezoneOffset).toLocaleDateString('en-us', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });
}

// Provides percentage left of capacity by comparing current vs max
export const calculateCapacityPercentage = (curCapacity:number|null, maxCapacity:number|null) => {
    if((curCapacity === null || isNaN(curCapacity)) || (maxCapacity === null || isNaN(maxCapacity) || maxCapacity === 0)) {
        return '';
    } else {
        const calculation = ((Number(curCapacity) / Number(maxCapacity)) * 100);
        return Math.round(calculation) + '%';
    }
}