import {
  addressNameValidator,
  addressValidator,
  emailValidator,
  phoneNumberValidator,
  teamNameValidator,
  zipValidator
} from "./textValidationHelpers";
import {isDealerRole} from "./userProfileHelpers";
import {parseStringListToArray} from "./arrayHelpers";
import {dispatchUpdateInfoForm} from "../actions/checkoutActions";
import {MAX_LENGTH_CCEMAILS} from "./constants";

/**
 * Manages validation results, updates redux
 * @param form
 * @returns {boolean}
 */
export function validateCouponInfoForm(form) {
  const errors = getCouponInfoFormValidationErrors(form);
  dispatchUpdateInfoForm({
    ...form,
    formSubmitted: true,
    ...errors
  });
  return !(errors.errorCount > 0);
}

/**
 * Removes excess inputs with no value
 * @param emails
 * @returns {*}
 */
export function cleanInfoFormEmails(emails) {
  if(emails && emails.length) {
    let cleanEmails = emails.filter(email => email.value.trim() !== '');
    if(cleanEmails && cleanEmails.length > 0) {
      return cleanEmails;
    } else {
      return [{value:"",error:true}];
    }
  } else {
    return [{value:"",error:true}];
  }
}

/**
 * Manages validation logic for Consumer Coupon Add'l Information Form
 * URL: /en/en_US/nts/checkout/coupon/:cartId
 * @param form
 * @returns {{errorCount: number, errors: {customerState: boolean, ccemails: boolean, phoneNum: boolean, customerTeamName: boolean, customerCity: boolean}}}
 */
export function getCouponInfoFormValidationErrors(form) {
  // NOTE: Watch out, Lots of double negatives
  let validations = {
    ccemails: !(form.ccemails && form.ccemails.trim() !== '' && form.ccemails.length <= MAX_LENGTH_CCEMAILS && validateEmailString(form.ccemails.trim())),
    customerTeamName: !(form.customerTeamName && form.customerTeamName.trim() !== '' && teamNameValidator(form.customerTeamName.trim())),
    customerType: !(form.customerType && form.customerType !== ''),
    customerCity: !(form.customerCity && form.customerCity.trim() !== '' && addressValidator(form.customerCity.trim())),
    customerState: !(form.customerState && form.customerState !== ''),
    phoneNum: !(form.phoneNum && form.phoneNum.trim() !== '' && phoneNumberValidator(form.phoneNum.trim())),
    poNum: isDealerRole() && !(form.poNum && form.poNum.trim() !== '' && addressValidator(form.poNum.trim()))
  };
  let errorCount = 0;
  Object.keys(validations).forEach(v => {
    if (validations[v] === true) {
      errorCount++;
    }
  });
  return {
    ccemailsList: validateEmailList(form.ccemailsList),
    errorCount: errorCount,
    errors: {
      ...validations
    }
  }
}

/**
 * Manages validation logic for Consumer Coupon Address Form
 * URL: /en/en_US/nts/checkout/coupon/:cartId
 * @param form
 * @returns {{errorCount: number, errors: {country: boolean, additionalInformation: boolean, city: boolean, postalCode: boolean, name: boolean, addressLine1: boolean, stateProvince: boolean, addressLine2: boolean}}}
 */
export function validateCouponAddressForm(form) {
  // NOTE: Watch out, Lots of double negatives
  let validations = {
    name: !(form.name && form.name.trim() !== '' && addressNameValidator(form.name.trim())),
    addressLine1: !(form.addressLine1 && form.addressLine1.trim() !== '' && addressValidator(form.addressLine1.trim())),
    addressLine2:!(form.addressLine2 === '' || (form.addressLine2 && addressValidator(form.addressLine2.trim()))),
    city: !(form.city && form.city.trim() !== '' && addressValidator(form.city.trim())),
    stateProvince: !(form.stateProvince && form.stateProvince !== ''),
    postalCode: !(form.postalCode && form.postalCode !== '' && zipValidator(form.postalCode.trim())),
    country: !(form.country && form.country !== ''),
    additionalInformation: false
  };
  let errorCount = 0;
  Object.keys(validations).forEach(v => {
    if (validations[v] === true) {
      errorCount++;
    }
  });
  return {
    errorCount: errorCount,
    errors: {
      ...validations
    }
  }
}

/**
 * Maps form + user details to payload used for Order Submit service
 * URL: (webapp) /services/assortment/order/submit/v2 -- payload format
 * @param infoForm
 * @param shipToId
 * @param addressForm
 * @param userEmail
 * @param orderId
 * @param couponCode
 */
export function prepareCouponCheckoutPayload(infoForm, shipToId, addressForm, userEmail, orderId, couponCode, teamUserId) {
  let payload = {
    cartId: orderId,
    cartName: orderId,
    soldTo: '',
    poNum: infoForm.poNum, //coupon code
    email: userEmail,
    ccemails: infoForm.ccemails,
    teamUserId: teamUserId,

    // jba only fields?
    customerTeamName: (infoForm.customerTeamName) ? infoForm.customerTeamName.trim() : '',
    customerType: (infoForm.customerType) ? infoForm.customerType.trim() : '',
    customerCity: (infoForm.customerCity) ? infoForm.customerCity.trim() : '',
    customerState: (infoForm.customerState) ? infoForm.customerState.trim() : '',
    phoneNum: (infoForm.phoneNum) ? infoForm.phoneNum.trim() : '',
    ioNum: '',
    cartCoupon: null,
    shippingData: {
      shippingMethod: '', //blank('') = STANDARD method
      shippingCondition: '20', //20 = STANDARD condition
      shipToId: shipToId
    }
  };
  if(addressForm) {
    payload.shippingData.oneTimeShipToAddress = {
      name: addressForm.name,
      shippingName: '',
      addressLine1: addressForm.addressLine1,
      addressLine2: addressForm.addressLine2,
      additionalInformation: addressForm.additionalInformation,
      city: addressForm.city,
      postalCode: addressForm.postalCode,
      stateProvince: addressForm.stateProvince,
      country: addressForm.country
    }
  }
  return payload;
}

/**
 * Provides flag if current page is Coupon Bar eligible
 * @returns {boolean}
 */
export function isCouponBarSupportedPage(location = window.location) {
  let isCartPage = location.pathname.includes('order,detail');
  let isCouponCheckoutPage = location.pathname.includes('checkout/coupon');
  return isCartPage || isCouponCheckoutPage;
}

/**
 * Retrieves selected ShipTo from ShipTos list or get address from OneTimeShipTo entry
 * @param state
 */
export function getShipToAddress(state) {
  const isOneTimeShipTo =
      state.sapCartData &&
      state.sapCartData.shippingData &&
      state.sapCartData.shippingData.oneTimeShipToAddress &&
      Object.keys(state.sapCartData.shippingData.oneTimeShipToAddress).length > 0;
  if(isOneTimeShipTo) {
    return {...state.sapCartData.shippingData.oneTimeShipToAddress};
  } else {
    return null;
  }
}

/**
 * Iterates email entries and validates each email separately
 * @param list
 * @returns {*}
 */
export function validateEmailList(list) {
  let inputs = [];
  if (list && list.length && list.length > 0) {
    list.forEach(email => {
      inputs.push( { value: email.value, error: !emailValidator(email.value) } );
    });
  } else {
    inputs.push( { value: '', error: true } );
  }
  return inputs;
}

/**
 * Parses and validates each email in the ccemails string
 * @param emailStr
 */
export function validateEmailString(emailStr) {
  const list = parseStringListToArray(emailStr);
  if((list && list.length && list.length > 0) || emailStr.length > MAX_LENGTH_CCEMAILS ) {
    return !list.some(email => emailValidator(email) === false);
  } else {
    return false;
  }
}

/**
 * Parses email string (comma separated) into array with { value, error } objects
 * @param emailString
 * @param showErrors
 * @returns {Array}
 */
export function getEmailList(emailString, showErrors) {
  let inputs = [];
  const list = parseStringListToArray(emailString);
  if (list && list.length && list.length > 0) {
    list.forEach(email => {
      inputs.push( { value: email.trim(), error: (showErrors && !emailValidator(email)) } );
    });
  } else {
    inputs.push( { value: '', error: showErrors } );
  }
  return inputs;
}

/**
 * Returns boolean based on if any errors were found in the email list
 * @param infoForm
 */
export function checkForEmailErrors(infoForm) {
  const hasEmails = infoForm && infoForm.ccemailsList && infoForm.ccemailsList.length > 0;
  return (hasEmails && infoForm.ccemailsList.some(email => email.error));
}
