import {Action, Reducer} from 'redux';

import * as types from '../actions/actionTypes';

export interface UserProfileState {
  isLoading: boolean;
  isLoggedIn: boolean;
  authorities: string[];
  accounts: {
    jbaAccountNumber?: string;
    sapAccountNumber?: string;
  };
  profile: {
    email?: string
  };
  dealership: {};
  impersonatedUser?: {};
}

// TODO: These actions can be separate types later
interface UserProfileAction extends Action {
  state: boolean;
  user: UserProfileState;
}

const defaultState: UserProfileState = {
  isLoading: true,
  isLoggedIn: false,
  authorities: [],
  accounts: {},
  profile: {},
  dealership: {}
};

const userProfileReducer: Reducer<UserProfileState> = (
  state = defaultState,
  incomingAction,
) => {
  const action = incomingAction as unknown as UserProfileAction;
  switch (action.type) {
    case types.LOADING_USER_STATE:
      return {
        ...state,
        isLoading: action.state
      }
    case types.LOAD_USER_PROFILE:
      return {
        ...state,
        ...action.user
      };
    case types.CLEAR_USER_PROFILE:
      return {...defaultState};
    default:
      return state;
  }
}

export default userProfileReducer;