import {calculateEndpointType} from './common';

const endpointTypeToUrl = {
  // local: 'http://localhost.nikedev.com/services',
  local: 'https://ntsdev.nikedev.com/services',
  dev: 'https://ntsdev.nikedev.com/services',
  qa: 'https://ntsqa.nikedev.com/services',
  stg: 'https://ntsstage.nike.com/services',
  uat: 'https://ntsuat.nike.com/services',
  prod: 'https://niketeam.nike.com/services'
};

export default function ntsServicesBaseUrl(location) {
  const type = calculateEndpointType(location, 'prod');
  return endpointTypeToUrl[type];
}
