import * as React from 'react';
import {ReportTab} from "./ReportTab";

export const AdminReportingPage: React.FC = () => {

    return (
        <div className="nts-admin-page nts-admin-reporting-page">
            <h1>REPORTING</h1>
            <ul className="admin-tabs">
                <li className="selected">DOWNLOAD ZIP FILES</li>
            </ul>
            <div className="admin-tabs-content">
                <ReportTab />
            </div>
        </div>
    )

}
