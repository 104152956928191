import React from "react";
import {Col} from 'react-bootstrap';

export class DealerContactButton extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {}
  }
  
  render() {
    
    return (
      <Col xs={12} sm={4} md={3} className="nts-dealer-contact-cell" key={this.props.location.id}>
        <div className="nts-dealer-contact-name">{this.props.location.name}</div>
        <div className="nts-dealer-contact-email">{this.props.location.email}</div>
        <div><button className="btn btn-primary" data-id={this.props.location.id} onClick={this.props.onClick}>CONTACT</button></div>
      </Col>
    );
    
  }
  
}