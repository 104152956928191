import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {Link} from 'react-router-dom';

import {store} from "../../../../App";
import {NtsLazyImage} from "../../shared/ntsLazyImage/ntsLazyImage";
import {ColorWheelSvg} from "../../shared/colorWheelSvg";
import {NikeSelect} from "../../nikeSelect";
import {dispatchPrePopulateDealerState} from "../../../actions/navigationActions";
import {getDealerStateList} from "../../../helpers/stateList";
import {getAkamaiImageBaseUrl} from "../../../api/config/ntsDownloadsBaseUrl";

const imageUrl = getAkamaiImageBaseUrl();

export class MoreOptionsRowInner extends React.Component {
  
  navigateToCustomizeSite = (elem) => {
    const newWindow = window.open(elem.url);
    if (newWindow) {
      newWindow.focus();
    }
  };
  
  navigateToFindADealer = (elem) => {
    store.dispatch(dispatchPrePopulateDealerState({value:elem.value, label:elem.label}));
    this.props.push('/en/en_US/nts/find_dealer');
  };
  
  render() {
  
    const stateList = getDealerStateList();
    
    return(
      <Row className="landing-row more-options-row">
        <Col xs={12} md={6} lg={4}>
          <Row className="catalog-row">
            <Col xs={12} md={6} className="catalog-col">
              <NtsLazyImage alt="" src={imageUrl+this.props.content.moreOptions.catalog.imageSrc} />
            </Col>
            <Col xs={12} md={6} className="catalog-col catalog-download">
              <div className="default-sub-header">{this.props.content.moreOptions.catalog.headerText}</div>
              <div className="cta-container">
                <Link to={this.props.content.moreOptions.catalog.callToActionRoute} className="cta-button">{this.props.content.moreOptions.catalog.callToAction}</Link>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={3} lg={4}>
          <div className="more-options-container">
            <div className="default-sub-header"><ColorWheelSvg className="inline-color-wheel" height={18} width={18} />{this.props.content.moreOptions.customizeHeader}</div>
            <NikeSelect
              name="customizeIt"
              value=""
              placeholder={"Select to customize"}
              clearable={false}
              options={[
                {value: "headwear", label: "Headwear", url: "https://nike.teamheadwear.com/"},
                {value: "wrestling", label: "Wrestling", url: "https://www.athleteps.com/nike-wrestling/"},
                {value: "lacrosse", label: "Lacrosse Gear", url: "http://vaporcustomlax.com"}
              ]}
              onChange={this.navigateToCustomizeSite}
            />
          </div>
    
        </Col>
        <Col xs={12} md={3} lg={4}>
          <div className="more-options-container">
            <div className="default-sub-header">{this.props.content.moreOptions.contactDealerHeader}</div>
            <NikeSelect
              name="customizeIt"
              value=""
              placeholder={"Select a state"}
              clearable={false}
              options={stateList}
              onChange={this.navigateToFindADealer}
            />
          </div>
        </Col>
      </Row>
    ); 
  }
}

export const MoreOptionsRow = connect(
  null, { push },
)(MoreOptionsRowInner);