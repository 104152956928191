import {calculateEndpointType} from './common';

//TODO: Could be combined with apiBaseUrl as some of the code is the same.
//TODO: Update with atOnce urls for other environements
const endpointTypeToUrl = {
  local: 'https://niketeam-api-qa.nike.net/search-api/rest/search.xml',
  dev:   'https://niketeam-api-dev.nike.net/search-api/rest/search.xml',
  qa:    'https://niketeam-api-qa.nike.net/search-api/rest/search.xml',
  stg:   'https://niketeam-api-stg.nike.net/search-api/rest/search.xml',
  uat:   'https://niketeam-api-uat.nike.net/search-api/rest/search.xml',
  prod:  'https://niketeam-api.nike.net/search-api/rest/search.xml'
};

export default function searchBaseUrl(location) {
  const type = calculateEndpointType(location, 'prod');
  return endpointTypeToUrl[type];
}
