import React from 'react';
import {Row, Col} from 'react-bootstrap';

export class ArtworkTermsPage extends React.Component {

  componentDidMount() {
    // hacks to hide navigation options
    document.getElementById('nav-bar').hidden = true;
    document.getElementById('nts-footer').hidden = true;
    document.getElementById('nts-app-container').style.margin = "0";
  }

  render() {
    return (
      <div className="container nts-terms-container">
        <Row className="landing">
          <Col xs={12}>
            <h2 className="center-title">NIKE TERMS & CONDITIONS</h2>
            <p>
              <strong><i>Please read carefully</i></strong>
            </p>
            <p className="terms-text">
              By submitting materials, you represent and warrant that you own or
              otherwise possess all necessary rights with respect to the materials
              submitted by you to this website, including, without limitation,
              photographs, videos, images, text, graphics and other materials
              (collectively, "Designs") for incorporation into Nike products
              (the "Products") and that the Designs and Products do not and will
              not infringe, misappropriate, use or disclose without authorization
              or otherwise violate any copyright, trademark, service mark, trade
              secret right, rights of publicity or other intellectual property or
              other property right of any third party. You agree that you will
              indemnify, defend and hold Nike, Inc., its affiliates and subsidiaries,
              and their respective officers, directors, employees and agents
              harmless against all claims, demands, liability, damages, costs and
              expenses, including reasonable legal fees and expenses, arising out
              of or related to the Designs, Products or breach of your representations
              and warranties. You agree that your submission of the materials
              shall not be deemed to create an agency, partnership, franchise,
              employment or joint venture relationship between you and Nike.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}
