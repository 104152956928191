import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

export interface ToastState {
  toast: string | null;
}

const initialState: ToastState = {
  toast: null
};

interface ToastAction extends Action {
  toastMessage: string;
}

const toastReducer: Reducer<ToastState> = (
  state = initialState,
  incomingAction
) => {
  const action = incomingAction as unknown as ToastAction;

  switch (action.type) {
    case types.SHOW_TOAST:
      return {
        ...state,
        toast: action.toastMessage
      };

    default:
      return state;
  }
};

export default toastReducer;
