import React from 'react';
import {Dispatch} from 'redux';
import {Modal, Button} from 'react-bootstrap';
import {connect} from 'react-redux';

import {AppState} from '../../reducers';
import {hideFillinHelperModal} from '../../actions/navigationActions';
import {isJbaDesignId} from '../../helpers/designHelpers';
import {BUILDER_URL_BASE, PDP_DESIGN_URL_BASE} from '../gridwall/gridwallConstants';
import {useNavigateTo} from '../locker/hooks';

interface StateProps {
    cartId?: string;
    isInLocker: boolean;
    mode: AppState['navigationState']['fillinHelperModal']['mode'];
    show: boolean;
    ud?: string;
}

interface DispatchProps {
    hideModal: () => void;
}

type Props = StateProps & DispatchProps;

const getCustomBuilderUrl = (designId: string, update: boolean) => {
    const id = designId.substring(2, designId.length);
    const transformedUD = `UD${id.toLowerCase()}`;
    return `${BUILDER_URL_BASE}${transformedUD}${update ? '&update=true' : ''}`;
};

const FillinHelperModalComponent: React.FC<Props> = ({
    hideModal,
    mode,
    show,
    ud,
    isInLocker,
    cartId,
}) => {
    const navigateTo = useNavigateTo();
    if (!ud) {
        return null;
    }
    const isJbaDesign = isJbaDesignId(ud);

    const createFillIn = () => {
        navigateTo('/en/en_US/nts/fillin');
    }
    const updateDesign = () => {
        const url = getCustomBuilderUrl(ud, true);
        navigateTo(url);
    }
    const goToLocker = () => {
        navigateTo('/en/en_US/nts/locker');
        hideModal();
    }
    const goToCart = () => {
        navigateTo(`/en/en_US/p/order,detail?cartId=${cartId}`);
    }

    const buildNewDesign = () => {
        if (isJbaDesign) {
            const url = getCustomBuilderUrl(ud, false);
            navigateTo(url);
        } else {
            hideModal();
            navigateTo(`${PDP_DESIGN_URL_BASE}${ud}`);
        }
    };

    const buildNavbarSearchMessage = () => {
        return isInLocker && !!cartId
            ? 'your locker, your cart'
            : isInLocker
                ? 'your locker'
                : 'your cart';
    };

    return (
        <Modal
            className="artwork-email-modal show fillin-helper-modal"
            onHide={hideModal}
            show={show}
        >
            <Modal.Header closeButton>
            </Modal.Header>
            {mode === 'navbar search' && (
                <Modal.Body>
                    <div>
                        Do you want to go to {buildNavbarSearchMessage()}
                        , or create a new design based on this UD?
                    </div>
                </Modal.Body>
            )}
            {mode !== 'navbar search' && (
                <Modal.Body>
                    <div>
                        Do you want to {mode === 'fill in' ? 'create a fill in order' : 'update the design'}
                    </div>
                    <div>or</div>
                    <div>
                        create a new design based on this {isJbaDesign ? 'UD' : 'Design ID'}?
                    </div>
                </Modal.Body>
            )}
            <Modal.Footer>
                {mode === 'navbar search' && isInLocker && (
                    <Button bsStyle="primary" onClick={goToLocker} block={true}>Go To Locker</Button>
                )}
                {mode === 'navbar search' && !!cartId && (
                    <Button bsStyle="primary" onClick={goToCart} block={true}>Go To Cart</Button>
                )}
                {mode === 'fill in' && (
                    <Button bsStyle="primary" onClick={createFillIn} block={true}>Create Fill In</Button>
                )}
                {mode === 'update design' && (
                    <Button bsStyle="primary" onClick={updateDesign} block={true}>Update Design</Button>
                )}
                <Button bsStyle="primary" onClick={buildNewDesign} block={true}>Build New Design</Button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    cartId: state.navigationState.fillinHelperModal.cartId,
    isInLocker: state.navigationState.fillinHelperModal.isInLocker,
    mode: state.navigationState.fillinHelperModal.mode,
    show: state.navigationState.fillinHelperModal.show,
    ud: state.navigationState.fillinHelperModal.ud,
});

const mapDispatchToProps = (dispatch: Dispatch<unknown>): DispatchProps => ({
    hideModal: () => dispatch(hideFillinHelperModal()),
});

export const FillinHelperModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FillinHelperModalComponent);
