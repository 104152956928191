import React from 'react';

// Obtain browser specific value for 'hidden' check
const getBrowserVisibilityProp = () => {
    if (typeof document.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        return "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
        return "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
        return "webkitvisibilitychange";
    }
}

// Obtain browser specific property for 'hidden' check
const getBrowserDocumentHiddenProp = () => {
    if (typeof document.hidden !== "undefined") {
        return "hidden";
    } else if (typeof document.msHidden !== "undefined") {
        return "msHidden";
    } else if (typeof document.webkitHidden !== "undefined") {
        return "webkitHidden";
    }
}

// Check if Document is 'hidden' (a.k.a. site not being currently viewed)
const getIsDocumentHidden = () => {
    return !document[getBrowserDocumentHiddenProp()];
}

// React lifecycle friendly check if Document is 'hidden'
export const usePageVisibility = () => {
    const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden());
    const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

    React.useEffect(() => {
        const visibilityChange = getBrowserVisibilityProp();
        window.addEventListener(visibilityChange, onVisibilityChange, false);
        return () => {
            window.removeEventListener(visibilityChange, onVisibilityChange);
        };
    }, []);

    return isVisible;
}