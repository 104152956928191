import {
  ANY_PARAM,
  GENDER_DISPLAY_MEN,
  GENDER_DISPLAY_WOMEN,
  GENDER_PARAM_PREFIX,
  SPORT_PARAM_PREFIX,
  GRIDWALL_URL_BASE,
  GENDER_FILTER_BOYS,
  GENDER_FILTER_GIRLS,
  GENDER_DISPLAY_GIRLS,
  GENDER_DISPLAY_BOYS
} from "./gridwallConstants";
import {AppState} from '../../reducers';
import {AvailableGridwallFilters} from '../../../content/filters/availableGridwallFilters';

export class FilterManager {
  availableFilters: AvailableGridwallFilters;

  constructor(availableFilters: AvailableGridwallFilters) {
    this.availableFilters = availableFilters;
  }

  getGridwallTitle(selectedFilters: string[]) {

    let title = "All Products";

    let genderFilters = selectedFilters.filter(selectedFilter => {
      return selectedFilter.startsWith(GENDER_PARAM_PREFIX);
    });
    let sportsFilters = selectedFilters.filter(selectedFilter => {
      return selectedFilter.startsWith(SPORT_PARAM_PREFIX)
    });

    // If there's one gender and one sport selected, we render as below
    if (genderFilters.length === 1 && sportsFilters.length === 1) {
      title = this.buildSingleGenderSingleSportTitle(genderFilters[0], sportsFilters[0]);
    } else if (genderFilters.length === 1 && (sportsFilters.length === 0 || sportsFilters.length > 1)) {
      title = this.getGenderDisplayName(genderFilters[0]);
    } else if ((genderFilters.length === 0 || genderFilters.length > 1) && sportsFilters.length === 1) {
      title = this.getSportDisplayName(sportsFilters[0]);
    }

    return title;
  }

  getFilterDisplayName(filter: string) {

    let displayName = "";
    const categoryFilters = this.availableFilters.categories;
    const specialFilters = this.availableFilters.special;

    if (filter.startsWith(GENDER_PARAM_PREFIX)) {
      displayName = this.getGenderDisplayName(filter);
    }
    else if (filter.startsWith(SPORT_PARAM_PREFIX)) {
      displayName = this.getSportDisplayName(filter);
    }
    else {
      // check if it's a Category filter
      Object.keys(categoryFilters).forEach(subCatHeaders => {
        categoryFilters[subCatHeaders].forEach(subCatFilter => {
          if (subCatFilter.id === filter) {
            displayName = subCatFilter.displayName;
          }
        })
      });

      if (displayName === "") {
        // check all these special filters
        const allSpecialFilters = [
          ...specialFilters.customization.makeItYours,
          ...specialFilters.customization.createYourOwn,
          ...specialFilters.color,
          ...specialFilters['sustainable materials'],
          ...specialFilters['travel train coach'],
        ];
        allSpecialFilters.forEach(specialFilter => {
          if (specialFilter.id === filter) {
            displayName = specialFilter.displayName;
          }
        });
      }

      if (displayName === "" && filter.startsWith("shipDate")) {
        let shipDate = filter.substr(filter.indexOf('_') + 1, filter.length);
        displayName = "Ship By " + shipDate;
      }

      if (displayName === "") {
        console.error("couldn't find displayname for filter=" + filter);
      }
    }

    return displayName;
  }

  getUrlForFilters(userFilters: AppState['gridwall']['userFilters'], routeKey: number, isAdminRole: boolean) {
    let selectedFilters = userFilters.selectedFilters;
    let sortDirection = userFilters.sortDirection;
    let showHidden = userFilters.showHidden;
    let searchString = userFilters.customSearchParam;
    let genderKey = this.buildGenderKey(selectedFilters);
    let sportsKeysObject = this.buildSportsKeysObject(selectedFilters);

    let filterParams = this.buildSelectedFilterParams(selectedFilters);
    let allSelected = this.mergeSelectedWithSports(filterParams, sportsKeysObject.sportParams);
    let sortParam = "?sort=" + sortDirection;
    let queryParams = (allSelected.length > 0) ? "&selected=" + allSelected : "";
    let searchParam = searchString ? "&search=" + encodeURIComponent(searchString) : "";
    let showHiddenParam = showHidden && isAdminRole ? "&showHidden=true" : "";

    // When returning *to* the Gridwall (such as from PDP), this can be undefined. This
    // ensures a valid value.
    routeKey = routeKey !== undefined ? routeKey : Math.floor(Math.random() * 1000) + 1;

    return this.getFullUrl(genderKey, sportsKeysObject, routeKey, sortParam, queryParams, searchParam, showHiddenParam);
  }

  buildGenderKey(selectedFilters: string[]) {
    // if all genders OR no genders are selected, we use the key "any"
    let genderKey = ANY_PARAM;

    let genders = selectedFilters.filter(selectedFilter => {
      return selectedFilter.startsWith(GENDER_PARAM_PREFIX);
    });

    if (genders.length > 0) {
      // if there are between 1 and 3 selected, we create a composite key of the selections
      let compositeGenderKey = "";
      genders.forEach(gender => {
        // build a composite key using the first letter of each selected gender.
        // MW = Men + Women. BG = Boys + Girls. MWG = Men + Women + Girls.
        // Note that selecting all 4 genders is the same as selecting none.
        compositeGenderKey += gender.substr(gender.indexOf("_") + 1, 1);
      });
      genderKey = compositeGenderKey;
    }

    return genderKey;
  }

  buildSportsKeysObject(selectedFilters: string[]) {
    // if all sports OR no sports are selected, we use the sportKey value "any".
    // if there is at least 1 (but less than maximum) sports, the sportKey will have
    // the value of the first in the list
    let sportKey = ANY_PARAM;

    // if there  are between 2 and 1 less than maximum, sports beyond the first will
    // be collected to concatenate as "?selected=" parameters
    let sportParams: string[] = [];

    let sportFilters = selectedFilters.filter(selectedFilter => {
      return selectedFilter.startsWith(SPORT_PARAM_PREFIX);
    });

    if (sportFilters.length >= this.availableFilters.filters.sport.length || sportFilters.length === 0) {
      // ALL sports have been selected, we can use the defaults
    }
    else {
      // we'll grab the first sport in the list as the "key" sport. the remaining
      // sports will be added to another
      let firstSport = sportFilters[0];
      sportKey = firstSport.substr(firstSport.indexOf("_") + 1, firstSport.length - SPORT_PARAM_PREFIX.length);

      // for sports with a space character in the name, we need to express that correctly in the URL ("Field Hockey")
      sportKey = sportKey.replace("_", "%20");

      // the remaining sports (if any) will be collected as "?selected" filter values
      sportParams = sportFilters.slice(1);
    }

    return {
      sportKey,
      sportParams
    };
  }

  buildSelectedFilterParams(selectedFilters: string[]) {
    const isSportVariancePage = window.location.pathname.includes('/sport/');
    let remainingFilters;

    if(isSportVariancePage) {
      remainingFilters = selectedFilters.filter(selectedFilter => {
        return (!selectedFilter.startsWith(SPORT_PARAM_PREFIX));
      });
    } else {
      // return all non-sports & non-gender filters
      remainingFilters = selectedFilters.filter(selectedFilter => {
        return ((!selectedFilter.startsWith(GENDER_PARAM_PREFIX)) && (!selectedFilter.startsWith(SPORT_PARAM_PREFIX)));
      });
    }

    return remainingFilters;
  }

  mergeSelectedWithSports(filterParams: string[], sportsParams: string[]) {
    let mergedArray = [];

    if (filterParams.length > 0) {
      mergedArray.push(filterParams.join("+"));
    }
    if (sportsParams.length > 0) {
      mergedArray.push(sportsParams.join("+"));
    }

    return mergedArray.join("+");
  }

  buildSingleGenderSingleSportTitle(gender: string, sport: string) {
    let genderDisplayName = this.getFilterDisplayName(gender);

    if (genderDisplayName === GENDER_DISPLAY_MEN || genderDisplayName === GENDER_DISPLAY_WOMEN) {
      genderDisplayName += "'s";
    } else {
      genderDisplayName += "'";
    }
    let sportDisplayName = this.getFilterDisplayName(sport);

    return genderDisplayName + " " + sportDisplayName;
  }

  getGenderDisplayName(filter: string) {
    let displayName = "";

    if(filter === GENDER_FILTER_GIRLS) {
      displayName = GENDER_DISPLAY_GIRLS;
    } else if(filter === GENDER_FILTER_BOYS) {
      displayName = GENDER_DISPLAY_BOYS;
    } else {
      this.availableFilters.filters.gender.forEach(gender => {
        if (gender.id === filter) {
          displayName = gender.displayName;
        }
      });
    }

    return displayName;
  }


  getSportDisplayName(filter: string) {
    let displayName = "";

    this.availableFilters.filters.sport.forEach(sport => {
      if (sport.id.toLowerCase() === filter.toLowerCase()) {
        displayName = sport.displayName;
      }
    });

    return displayName;
  };

  static areFiltersDifferent = (filterA: AppState['gridwall']['userFilters'], filterB: AppState['gridwall']['userFilters']) =>  {
    return (filterA.selectedFilters.join("") !== filterB.selectedFilters.join("") ||
        filterA.sortDirection !== filterB.sortDirection ||
        filterA.showHidden !== filterB.showHidden ||
        filterA.customSearchParam !== filterB.customSearchParam);
  };

  getFullUrl(
    genderKey: string,
    sportsKeysObject: { sportKey: string },
    routeKey: number,
    sortParam: string,
    queryParams: string,
    searchParam: string,
    showHiddenParam: string
  ) {
    let url;
    const isSportVariancePage = window.location.pathname.includes('/sport/');
    if(isSportVariancePage) {
      //TODO: Auto add graphicTee filters, etc?
      url = window.location.pathname +
          sortParam +
          queryParams +
          searchParam +
          showHiddenParam;
    } else {
      url = GRIDWALL_URL_BASE +
            "/" + genderKey +
            "/" + sportsKeysObject.sportKey +
            "/" + routeKey + "/" +
            sortParam +
            queryParams +
            searchParam +
            showHiddenParam;
    }
    return url;
  }

}
