import React from 'react';

import {Row, Col} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import {NtsLink} from "../../shared/ntsLink";
import {NtsLazyImage} from "../../shared/ntsLazyImage/ntsLazyImage";

import {getAkamaiImageBaseUrl} from "../../../api/config/ntsDownloadsBaseUrl";

import logo from '../../../../img/SVG/SCHEELS-NIKE-TEAM_Square.svg';
import {scheelsLanding} from "../../../../content/retailers/scheelsLandingPage";

const imageUrl = getAkamaiImageBaseUrl();

export class ScheelsLandingPage extends React.Component {

  render() {

    return (
      <div id="scheels-landing-page" className="landing">
        <div className="container">

          <Helmet>
            <title>{scheelsLanding.seo.title}</title>
            <meta name="description" content={scheelsLanding.seo.description} />
          </Helmet>

          <div className="nts-scheels-team-logo">
            <img src={logo} alt={scheelsLanding.logo.altText} />
          </div>

          <div className="nts-scheels-main-banner">
            <img src={`${imageUrl}${scheelsLanding.banner.src}`} alt={scheelsLanding.banner.altText} />
          </div>

          <Row>
            <Col xs={12} mdPull={1} md={10} mdPush={1} className="nts-instruction-flow">
              <Row>
                <Col xs={6} md={3} className="nts-instruction-box box-1">
                  <div className="number-icon">{scheelsLanding.instructionBox.instruct1.step}</div>
                  <div className="instruction-title">{scheelsLanding.instructionBox.instruct1.title}</div>
                  <div className="instruction-detail">{scheelsLanding.instructionBox.instruct1.desc}</div>
                </Col>
                <Col xs={6} md={3} className="nts-instruction-box box-2">
                  <div className="number-icon">{scheelsLanding.instructionBox.instruct2.step}</div>
                  <div className="instruction-title">{scheelsLanding.instructionBox.instruct2.title}</div>
                  <div className="instruction-detail">{scheelsLanding.instructionBox.instruct2.desc}</div>
                </Col>
                <Col xs={6} md={3} className="nts-instruction-box box-3">
                  <div className="number-icon">{scheelsLanding.instructionBox.instruct3.step}</div>
                  <div className="instruction-title">{scheelsLanding.instructionBox.instruct3.title}</div>
                  <div className="instruction-detail">{scheelsLanding.instructionBox.instruct3.desc}</div>
                </Col>
                <Col xs={6} md={3} className="nts-instruction-box box-4">
                  <div className="number-icon">{scheelsLanding.instructionBox.instruct4.step}</div>
                  <div className="instruction-title">{scheelsLanding.instructionBox.instruct4.title}</div>
                  <div className="instruction-detail">{scheelsLanding.instructionBox.instruct4.desc}</div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xs={6} sm={4} className="nts-cta-container">
              <NtsLazyImage src={`${imageUrl}${scheelsLanding.stockUniformsBlock.image.src}`} alt={scheelsLanding.stockUniformsBlock.image.altText} />
              <p className="cta-header">{scheelsLanding.stockUniformsBlock.header}</p>
              <p className="cta-desc">{scheelsLanding.stockUniformsBlock.description}</p>
              <NtsLink to={scheelsLanding.stockUniformsBlock.cta.route} className="cta-button">{scheelsLanding.stockUniformsBlock.cta.text}</NtsLink>
            </Col>
            <Col xs={6} sm={4} className="nts-cta-container">
              <NtsLazyImage src={`${imageUrl}${scheelsLanding.graphicTeesBlock.image.src}`} alt={scheelsLanding.graphicTeesBlock.image.altText} />
              <p className="cta-header">{scheelsLanding.graphicTeesBlock.header}</p>
              <p className="cta-desc">{scheelsLanding.graphicTeesBlock.description}</p>
              <NtsLink to={scheelsLanding.graphicTeesBlock.cta.route} className="cta-button">{scheelsLanding.graphicTeesBlock.cta.text}</NtsLink>
            </Col>
            <Col xs={6} sm={4} className="nts-cta-container">
              <NtsLazyImage src={`${imageUrl}${scheelsLanding.coachBlock.image.src}`} alt={scheelsLanding.coachBlock.image.altText} />
              <p className="cta-header">{scheelsLanding.coachBlock.header}</p>
              <p className="cta-desc">{scheelsLanding.coachBlock.description}</p>
              <NtsLink to={scheelsLanding.coachBlock.cta.route} className="cta-button">{scheelsLanding.coachBlock.cta.text}</NtsLink>
            </Col>
          </Row>

        </div>

      </div>
    );
  }

}
