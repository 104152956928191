import React from 'react';
import {dateStringSort} from "../../helpers/arrayHelpers";
import {isAdminRole, isCustomerServiceRole, isConsumer} from "../../helpers/userProfileHelpers";

/**
 * Provides 'react-table' column configuration for Past Orders table
 * @info - https://www.npmjs.com/package/react-table#columns
 * @returns [] - Column Config Array
 */
export function getPastOrderTableConfig() {

  const adminRole = isAdminRole();
  const customerServiceRole = isCustomerServiceRole();
  const consumerRole = isConsumer();

  // TODO PMENTS-2247: uncomment following new columns and Tracking Number changes when ready to release
  return [
    {
      Header: 'ID',
      accessor: 'id',
      show: false
    },
    {
      Header: 'ORDER NAME',
      accessor: 'name',
      show: (consumerRole) ? false : true
    },
    {
      Header: (consumerRole) ? 'ORDER' : 'CUSTOMER PO',
      accessor: 'customerOrderNumber'
    },
    {
      Header: 'NIKE PO',
      accessor: 'purchaseOrderNumber',
      width: 90,
      show: (consumerRole) ? false : true
    },
    {
      Header: (consumerRole) ? 'NIKE ORDER' : 'NIKE SO',
      accessor: 'salesOrderNumber',
      width: 90
    },
    {
      Header: 'CREATED',
      accessor: 'createDate',
      width: 140,
      sortMethod: dateStringSort
    },
    {
      Header: 'STATUS LAST UPDATED',
      accessor: 'statLastMod',
      width: 140,
      sortMethod: dateStringSort
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      Cell: rowInfo => (
          <span>
          {rowInfo.original.status &&
          rowInfo.original.status === 'ERROR' ? (
              <div>IN REVIEW</div>
          ) : (
              <div>{rowInfo.original.status}</div>
          )}
        </span>
      )
    },
    {
      filterable : true,
      Header: 'UD NUMBER',
      accessor: 'udNumbers',
      width: 150,
      filterMethod: (filter, row) => {
        if (row._original.udNumbers)
          return row._original.udNumbers.toUpperCase().includes(filter.value.toUpperCase());
        else
          return false;
      },
      Cell: rowInfo => (
          <span style={{fontSize: "14px"}}>
          {rowInfo.original.udNumbers &&
          rowInfo.original.udNumbers.split(',').map((ud, i) => {
            if (ud !== "null")
              return (<div key={i}>{ud}</div>);
            else
              return "";
          })}
        </span>
      )
    },
    {
      filterable : true,
      Header: 'TRACKING NUMBERS',
      accessor: 'trackingNumber',
      width: 300,
      filterMethod: (filter, row) => {
        if (row._original.trackingNumber)
          return row._original.trackingNumber.toUpperCase().includes(filter.value.toUpperCase());
        else
          return false;
      },
      Cell: rowInfo => (
        <span style={{fontSize: "14px"}}>
          {rowInfo.original.trackingNumber && rowInfo.original.trackingNumber.split(',').map((tn, i) => {
            if (tn !== "null")
              return (<div data-link={tn} key={i} onClick={handleTrackingClick} className="nts-table-tracking-nbr">{tn.substr(tn.lastIndexOf("=")+1)} </div>);
            else
              return "";
          })}
        </span>
      )
    },
    // {
    //   Header: 'TRACKING NUMBERS',
    //   accessor: 'shippingInfo',
    //   Cell: rowInfo => (
    //     <span style={{fontSize: "14px"}}>
    //     {rowInfo.original.shippingInfo &&
    //     rowInfo.original.shippingInfo.split('|').map((ud, i) => {
    //       if (ud !== "null"){
    //         let shipping = ud.split("^");
    //         return (
    //         <div  key={`wrap_${i}`} style={{fontSize: "14px"}}>
    //         <a target='_blank' href={
    //           shipping[1] == 'FedEx' ? `https://www.fedex.com/fedextrack/?trknbr=${shipping[0]}` :
    //           `http://wwwapps.ups.com/etracking/tracking.cgi?tracknum=${shipping[0]}`
    //         } key={i}>{shipping[0]}</a>
    //         </div>
    //         );
    //       }
    //       else
    //         return "";
    //     })}
    //   </span>
    // )
    // },
    // {
    //   Header: 'ESTIMATED ARRIVAL DATE',
    //   accessor: 'estimatedShipDate',
    //   width: 140,
    // },
    // {
    //   Header: 'SHIP METHOD',
    //   accessor: 'shippingDesc',
    //   width: 100,
    //   show: (consumerRole) ? false : true
    // },
    {
      Header: 'FACTORY',
      accessor: 'factoryCode',
      width: 105,
      show: (adminRole || customerServiceRole) ? true : false,
    },

    // {
    //   Header: 'REASON CODE',
    //   accessor: 'reasonCode',
    //   width: 100,
    //   show: (adminRole || customerServiceRole) ? true : false
    // },

  ];
}

function handleTrackingClick (e) {
  e.stopPropagation();
  const url = e.target.dataset.link;
  window.open(url);
}
